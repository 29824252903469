import InfiniteScroll from 'react-infinite-scroll-component';
import { useCallback } from 'react';
import { List, Skeleton } from 'antd';
import PropTypes from 'prop-types';

const ScrollingLoadedList = (props) => {
  const loadMoreData = useCallback(() => {
    props.loadMoreItems && props.loadMoreItems();

    /* if ( virtuosoRef.current.scrollTop ) {
            virtuosoRef.current.scrollTop = 0
        }

        console.log(virtuosoRef)*/
  }, [props.loadMoreItems]);

  const renderItem = useCallback(
    (itemList) => {
      if (!itemList) return;
      return <>{props.renderListItem(itemList)}</>;
    },
    [props.renderListItem],
  );

  const loader = () => {
    return (
      <Skeleton
        paragraph={{
          rows: 2,
        }}
        active
      />
    );
  };
  return (
    <>
      <div
        id="scrollableDiv"
        ref={props.scrollingLoadedListRef}
        style={{
          height: 600,
          width: '100%',
          overflow: 'auto',
        }}
      >
        <InfiniteScroll
          dataLength={props.dataList?.length}
          next={loadMoreData}
          height={props.setDefaultHeight ? 600 : ''}
          hasMore={props.dataList?.length < props.totalData}
          loader={() => {
            loader();
          }}
          //endMessage={<Divider plain>Данных больше нет</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={props.dataList}
            renderItem={(item) => renderItem(item)}
          />
        </InfiniteScroll>
      </div>
    </>
  );
};

ScrollingLoadedList.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalData: PropTypes.number,
  scrollingLoadedListRef: PropTypes.any,
  renderListItem: PropTypes.func.isRequired,
  setDefaultHeight: PropTypes.bool,
  loadMoreItems: PropTypes.func.isRequired,
  endListMessage: PropTypes.string,
};

export default ScrollingLoadedList;
