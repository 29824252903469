import { useContext, useEffect, useState } from 'react';
import { ErrorBoundary } from '../../../ErrorBoundary';
import PropTypes from 'prop-types';
import { userInformations } from '../../../../contex';
import getHistoryDigestSubs from '../../../../logic/customer/history/historyApiFunctions';
import CustomerChangeHistoryTabs from './CustomerChangeHistoryTabs';

const CustomerChangeHistory = (props) => {
  const { userInfo } = useContext(userInformations);
  const [currenTab, setCurrenTab] = useState(1);
  const [subsHistory, setSubsHistory] = useState([]);

  useEffect(async () => {
    if (props.customerId) {
      await callGetHistoryDigestSubs(props.customerId);
    }
    return () => setCurrenTab(1);
  }, []);

  const callGetHistoryDigestSubs = async (customerId) => {
    const res = props.isLeadHistory
      ? await getHistoryDigestSubs(customerId, 'leads', 'lead', userInfo)
      : await getHistoryDigestSubs(
          customerId,
          'customers',
          'customer',
          userInfo,
        );
    setSubsHistory(res ? res : []);
  };

  return (
    <>
      <ErrorBoundary>
        <CustomerChangeHistoryTabs
          currenTab={currenTab}
          subsHistory={subsHistory}
        />
      </ErrorBoundary>
    </>
  );
};

CustomerChangeHistory.propTypes = {
  customerId: PropTypes.number.isRequired,
  isLeadHistory: PropTypes.bool,
};

export default CustomerChangeHistory;
