import React, { useCallback, useContext, useState } from 'react';
import DashboardSidebar from './DashboardSidebar';
import { userInformations } from '../contex';
import { restApiHost } from '../utils/appVariables';
import { Form, Layout, message, Spin } from 'antd';
import Sider from 'antd/es/layout/Sider';
import LayoutHeader from './layout/header/LayoutHeader';
import LayoutOutletContent from './layout/outletContent/LayoutOutletContent';
import { addCompanyIdToLocalStorage, call } from '../apiUtils/call';
import { globalStore } from '../store';
import { TodoDemoAccessTypes } from '../store/todo/demoAccess/demoAccessTodo';
import { getCompanyStatus } from '../logic/account/AccountsApiFunctions';
import useAsyncEffect from 'use-async-effect';
import { TodoUserInfoActionTypes } from '../store/todo/userInfo/userInfoTodo';
import { userInfoHaveViewAllBranch } from '../logic/account/utils';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';
import { searchBranchList } from '../logic/branch/BrachApiFunctional';
import { useNavigate } from 'react-router-dom';

const DashboardLayout = () => {
  const [loadProfileInfo, setLoadProfileInfo] = useState(true);
  const { userInfo } = useContext(userInformations);
  const [closeFullMenu, setCloseFullMenu] = useState(true);
  const [branchList, setBranchList] = useState([]);
  const [branchForm] = Form.useForm();
  const isMobile = useCheckMobileScreen();
  const [isOpenedMobile, setIsOpenedMobile] = useState(false);
  const navigation = useNavigate();

  useAsyncEffect(async () => {
    await getProfileInfo();
  }, []);

  const getProfileInfo = useCallback(async () => {
    setLoadProfileInfo(true);
    try {
      const url = `${restApiHost}/accounts/profile/`;
      const res = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      const json = await res.json();

      if (res.status === 401) {
        localStorage.removeItem('token');
        navigation('/login');
        return;
      }

      if (json?.data?.company) {
        addCompanyIdToLocalStorage(json.data.company);
      }

      await handleGetStatusCompany(json?.data?.company);

      const haveViewBranch = userInfoHaveViewAllBranch(json?.data);
      userInfo.current = json?.data;
      if (haveViewBranch) {
        userInfo.current['haveViewBranch'] = true;
        //json?.data?.branch?.id && localStorage.setItem('branchId', `${json?.data?.branch?.id}`)
        await getBranchList();
      }
      globalStore.dispatch({
        type: TodoUserInfoActionTypes.SET_USER_INFO,
        payload: userInfo.current,
      });
      setLoadProfileInfo(false);
    } catch (e) {
      console.log('fetch profile info error', e);
      setLoadProfileInfo(false);
    }
  }, []);

  const getBranchList = async () => {
    const branchIdFromLocalStore = localStorage.getItem('branchId');
    if (!userInfo.current.haveViewBranch) {
      localStorage.removeItem('branchId');
      return;
    }
    try {
      const url = `${restApiHost}/accounts/branches/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      const branchList = json.data ? json.data : [];
      setBranchList(branchList);
      if (branchList?.length > 0) {
        if (!branchIdFromLocalStore) {
          if (branchList.some((o) => o.id === userInfo.current?.branch?.id)) {
            branchForm.setFieldsValue({
              branch: parseInt(userInfo.current?.branch?.id),
            });
            localStorage.setItem('branchId', userInfo.current?.branch?.id);
          } else {
            localStorage.setItem('branchId', branchList[0]?.id);
            branchForm.setFieldsValue({
              branch: parseInt(branchList?.id),
            });
          }
        }
      }
    } catch (e) {
      console.log('get branch list err', e);
      message.error('Ошибка запроса на получение списка филиалов');
    }
  };

  const handleGetStatusCompany = useCallback(async (companyId) => {
    const res = await getCompanyStatus();
    if (res?.data) {
      globalStore.dispatch({
        type: TodoDemoAccessTypes.SET_DEMO_ACCESS_IS_USED,
        payload: res.data?.demo ? res.data.demo : false,
      });
    } else if (companyId) {
      globalStore.dispatch({
        type: TodoDemoAccessTypes.SET_DEMO_ACCESS_IS_USED,
        payload: companyId !== 1,
      });
    }
  }, []);

  const handleToggleMobileMenu = () => {
    setIsOpenedMobile(!isOpenedMobile);
  };

  const handleSearchBranchList = useCallback(
    async (keyword) => {
      const res = await searchBranchList(keyword, userInfo);

      setBranchList(res?.data.length > 0 ? res.data : []);
    },
    [setBranchList],
  );

  return (
    <>
      {loadProfileInfo ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Spin spinning={loadProfileInfo} />
        </div>
      ) : (
        <Layout style={{ overflow: 'hidden', height: '100vh' }}>
          <Sider
            className={isOpenedMobile ? 'LeftSider menu-active' : 'LeftSider'}
            onMouseEnter={() => setCloseFullMenu(false)}
            onMouseLeave={() => setCloseFullMenu(true)}
            trigger={null}
            collapsed={isMobile ? false : closeFullMenu}
            onClick={() => {
              handleToggleMobileMenu();
            }}
          >
            <DashboardSidebar
              closeFullMenu={isMobile ? false : closeFullMenu}
              branchList={branchList}
              branchForm={branchForm}
              handleToggleMobileMenu={handleToggleMobileMenu}
              handleSearchBranchList={handleSearchBranchList}
              getBranchList={async () => {
                await getBranchList();
              }}
            />
          </Sider>
          <Layout
            className="site-layout"
            style={{ overflow: 'hidden', height: '100vh' }}
          >
            <LayoutHeader
              handleToggleMobileMenu={() => {
                handleToggleMobileMenu();
              }}
              closeFullMenu={isMobile ? false : closeFullMenu}
            />
            <LayoutOutletContent />
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default DashboardLayout;
