import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Card, Checkbox, Col, Divider, Dropdown, Row, Space } from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';

const CustomersBankListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>{props.bank?.account || '-'}</Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.bank)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.bank, props.menuActions]);

  return (
    <>
      <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[25, 10]}>
          <Col xs={24}>
            <div className={'secondText'}>{'Банк'}</div>
            <div className={'valueText'}>
              {!props.isForeign
                ? props.bank?.bank?.name || '-'
                : props.bank?.bank || '-'}
            </div>
          </Col>

          <Col xs={16}>
            <div className={'secondText'}>{'Корреспондентский счет'}</div>
            <div className={'valueText'}>
              {!props.isForeign
                ? props.bank?.bank?.correspondent_account || '-'
                : props.bank?.correspondent_account || '-'}
            </div>
          </Col>

          <Col xs={8}>
            <div className={'secondText'}>{'В договоре'}</div>
            <div className={'valueText'}>
              {<Checkbox checked={props.bank?.contract} disabled={true} />}
            </div>
          </Col>
          {!props.isForeign && (
            <>
              <Col xs={12}>
                <div className={'secondText'}>{'БИК'}</div>
                <div className={'valueText'}>
                  {props.bank?.bank?.bank_code || '-'}
                </div>
              </Col>

              <Col xs={12}>
                <div className={'secondText'}>{'Адрес'}</div>
                <div className={'valueText'}>
                  {props.bank?.bank?.address || '-'}
                </div>
              </Col>
            </>
          )}

          {props.isForeign && (
            <Col xs={12}>
              <div className={'secondText'}>{'Получатель'}</div>
              <div className={'valueText'}>{props.bank?.recipient || '-'}</div>
            </Col>
          )}
        </Row>
      </Card>
    </>
  );
};

CustomersBankListItem.propTypes = {
  bank: PropTypes.object,
  isForeign: PropTypes.bool,
  menuActions: PropTypes.func,
};

export default CustomersBankListItem;
