import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';

export async function getCompanyStatus() {
  try {
    const url = `${restApiHost}/accounts/company_status/`;
    const json = await call(url, {
      method: 'GET',
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });

    if (!json) return;

    return json;
  } catch (e) {
    console.log('get company status fetch err', e);
    message.error('Ошибка запрос на получение статуса компании');
  }
}

export async function editUserSections(sections, userInfo) {
  const body = {
    sections: sections,
  };
  try {
    const url = `${restApiHost}/base/usv_bulk_update/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );
    if (!json?.data) return;

    message.success('Успешно отредактировано');
  } catch (e) {
    console.log('edit user sections err', e);
    message.error('Ошибка запроса на редактирование позиций секций меню');
  }
}

export async function logout(userInfo) {
  try {
    const url = `${restApiHost}/accounts/logout_user/`;
    await call(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );
  } catch (e) {
    console.log('fetch profile logout error', e);
    message.error('Ошибка запроса на завершении ссесии пользователя');
  }
}

export async function getProfileInfo(userInfo) {
  try {
    const url = `${restApiHost}/accounts/profile/`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );
    if (!json.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch profile info error', e);
    message.error('Ошибка запроса на получение информации профиля');
  }
}
