import PropTypes from 'prop-types';
import DayOfMonth from './dayOfMonth/DayOfMonth';
import { Spin } from 'antd';
import {
  filtersTasksByDayIncludeLeadId,
  mergedClassicAndGroupPersonallyCommonTask,
} from './tasks/tasksUtils/filtersTasksByDay';
import { useContext } from 'react';
import { userInformations } from '../../../contex';

const Month = (props) => {
  const { userInfo } = useContext(userInformations);

  return (
    <>
      <Spin spinning={props.loading} style={{ top: '26%' }}>
        {props.month?.map((row, i) => (
          <div className={'calendar-wrapper'} key={i}>
            {row.map((day, idx) => (
              <DayOfMonth
                key={idx}
                day={day}
                tasks={mergedClassicAndGroupPersonallyCommonTask(
                  day,
                  props.tasks,
                  props?.useGroupCallLead,
                  props.selectedStaff,
                  userInfo.current?.id,
                )}
                tasksLeadCall={
                  props?.useGroupCallLead
                    ? filtersTasksByDayIncludeLeadId(day, props.tasks)
                    : []
                }
                selectedStaff={props.selectedStaff}
                goToCompetitionDate={(day) => {
                  props.goToCompetitionDate(day);
                }}
                index={idx}
                rowIndex={i}
                handleEditTask={(task) => props.handleEditTask(task)}
                handleCreateTask={(day) => props.handleCreateTask(day)}
              />
            ))}
          </div>
        ))}
      </Spin>
    </>
  );
};

Month.propTypes = {
  month: PropTypes.any,
  tasks: PropTypes.any,
  loading: PropTypes.bool,
  selectedStaff: PropTypes.number,
  goToCompetitionDate: PropTypes.func,
  handleEditTask: PropTypes.func,
  handleCreateTask: PropTypes.func,
  useGroupCallLead: PropTypes.bool,
};

export default Month;
