import { Card, CardContent, Typography } from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';

const TasksProgress = (props) => (
  <Card sx={{ height: '100%' }} {...props}>
    <CardContent className={'TaskProgress-item'}>
      <div className="TaskProgress-content-item">
        <Typography
          color="textSecondary"
          gutterBottom
          variant="h6"
          style={{
            maxWidth: '200px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {props.title}
        </Typography>
        <Typography color="textPrimary" variant="h3">
          {props?.stat && props?.title === 'Заявок'
            ? props?.stat?.tasks?.all
            : props?.stat && props?.title === 'Пред. заявок'
            ? props?.stat?.pre_tasks?.all
            : props?.stat && props?.title === 'Отправлено КП'
            ? props?.stat?.present_kp_stats?.['Отправлено КП']
            : props?.stat && props?.title === 'Отправлено презентаций'
            ? props?.stat?.present_kp_stats?.['Отправлено презентаций']
            : 0}
        </Typography>
      </div>
      <div className="TaskProgress-content-item TaskProgress-avatar avatar-yellow">
        <InsertChartIcon />
      </div>
    </CardContent>
  </Card>
);

export default TasksProgress;
