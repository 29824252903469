import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';
import { convertObjFilterToUrl } from '../../utils/getFilters';

export async function getBranchList(userInfo, getAll, page = 1) {
  let urlParams = ``;
  if (getAll) {
    urlParams = convertObjFilterToUrl(getAll, null);
  }
  try {
    const url = urlParams
      ? `${restApiHost}/accounts/branches/?page=${page}${urlParams}`
      : `${restApiHost}/accounts/branches/?page=${page}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;
    return json;
  } catch (e) {
    console.log('fetch profile info error', e);
    message.error('Ошибка запроса на получение списка филиалов');
  }
}

export async function searchBranchList(keyword, userInfo) {
  const urlParams = new URLSearchParams();
  urlParams.append('page', '1');

  if (keyword) {
    urlParams.append('search', `${keyword}`);
  }

  try {
    const url = `${restApiHost}/accounts/branches/?${urlParams}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;
    return json;
  } catch (e) {
    console.log('fetch profile info error', e);
    message.error('Ошибка запроса на получение списка филиалов');
  }
}
