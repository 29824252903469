export function objectToFormData(object) {
  const formData = new FormData();
  for (let key in object) {
    if (object[key] !== undefined) {
      object[key] === null
        ? formData.append(key, '')
        : formData.append(key, object[key]);
    }
  }
  return formData;
}
