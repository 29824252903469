import React from 'react';
import { Button, Modal } from 'antd';
import HistoryCallCustomerTable from './HistoryCallCustomerTable';
import { ErrorBoundary } from '../../ErrorBoundary';

const HistoryCallCustomerModal = ({
  data,
  customer,
  open,
  getData,
  handleClose,
  //handleOpen,
  refreshData,
}) => {
  return (
    <Modal
      visible={open}
      width={1000}
      title={`История вызовов #${customer?.id} ${
        customer?.phone_numbers?.length > 0
          ? `(номер телефона: ${customer?.phone_numbers[0]?.phone_number})`
          : ''
      }`}
      onCancel={() => {
        handleClose();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            handleClose();
          }}
        >
          Закрыть
        </Button>,
        <Button key="submit" type="primary" onClick={() => refreshData()}>
          Обновить
        </Button>,
      ]}
    >
      <ErrorBoundary>
        <HistoryCallCustomerTable
          customer={customer}
          getData={getData}
          data={data}
        />
      </ErrorBoundary>
    </Modal>
  );
};

export default HistoryCallCustomerModal;
