import React, { useState, useEffect, useContext } from 'react';
import { List, Button, Space, Drawer } from 'antd';
import { RotateCw } from 'react-feather';
import { userInformations } from '../../../contex';
import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import TableItem from './TableItem';
import TableModal from './TableModal';
import TableColumnModal from './TableColumnModal';

export default function Tables({ open, tables, setTables, onClose }) {
  const { userInfo } = useContext(userInformations);
  const [loadingTables, setLoadingTables] = useState(false);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openAddColumnModal, setOpenAddColumnModal] = useState(false);
  const [updateTable, setUpdateTable] = useState(undefined);

  useEffect(() => {
    refreshTables();
  }, []);

  async function refreshTables() {
    setLoadingTables(true);
    const json = await call(
      `${restApiHost}/administration/tables/`,
      {
        method: 'GET',
        headers: { Authorization: `Token ${localStorage.getItem('token')}` },
      },
      userInfo.current.haveViewBranch,
      null,
    );
    if (!json) return;
    setTables(json?.data ? json.data : []);
    // setTotalPages(json.count)
    setLoadingTables(false);
  }

  const renderTable = (table) => (
    <TableItem
      table={table}
      setTableContext={setTableContext}
      refreshTables={refreshTables}
    />
  );

  const setTableContext = (table) => {
    setUpdateTable(table);
    setOpenAddColumnModal((value) => !value);
  };

  const onOpenTableModal = () => {
    setOpenAddModal(true);
  };

  const onCloseTableModal = () => {
    setOpenAddModal(false);
  };

  const onCloseTableColumnModal = () => {
    setOpenAddColumnModal(false);
  };

  return (
    <Drawer visible={open} title="Таблицы" onClose={onClose} width={'100%'}>
      <TableModal
        open={openAddModal}
        onClose={onCloseTableModal}
        refreshTables={refreshTables}
      />

      <TableColumnModal
        open={openAddColumnModal}
        table={updateTable}
        onClose={onCloseTableColumnModal}
        refreshTables={refreshTables}
      />

      <Space direction="vertical">
        <Space direction={'horizontal'}>
          <Button loading={loadingTables} onClick={onOpenTableModal}>
            Добавить новую таблицу
          </Button>
          <Button
            icon={<RotateCw size={12} />}
            loading={loadingTables}
            onClick={refreshTables}
          ></Button>
        </Space>

        <List
          dataSource={tables}
          bordered
          loading={loadingTables}
          renderItem={renderTable}
        />
      </Space>
    </Drawer>
  );
}
