import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import { restApiHost } from '../../utils/appVariables';
import BillTemplate from '../Bill/BillTemplate';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';
import FilePdfOutlined from '@ant-design/icons/lib/icons/FilePdfOutlined';
import { priceFormatter } from '../../utils/priceFormatter';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const { Option } = Select;

const CustomerBill = ({
  data,
  preTaskId,
  taskData,
  transportList,
  refresh,
  customerBillSum,
}) => {
  const [visiblePreCostModal, setVisiblePreCostModal] = useState(false);
  const [visiblePreCostModalEdit, setVisiblePreCostModalEdit] = useState(false);
  const [costForm] = Form.useForm();
  const [costFormEdit] = Form.useForm();
  const [services, setServices] = useState([]);
  const [operations, setOperations] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);
  const [costs, setCosts] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [currEditCost, setCurrEditCost] = useState(null);
  const [totalSum, setTotalSum] = useState(0);

  //create bill
  const [, setCargos] = useState([]);
  const [visibleBillModal, setVisibleBillModal] = useState(false);

  //bill
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  //pdf bill
  const [createdBill, setCreatedBill] = useState(null);
  const [loadingBill, setLoadingBill] = useState(false);

  const [inCurrency, setInCurrency] = useState(false);
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    getTemplatesOperation();
  }, []);

  useEffect(() => {
    if (data) {
      setCosts(data);
    }
  }, [data]);

  useEffect(() => {
    if (taskData && taskData.cargos && taskData.cargos.length > 0) {
      setCargos(taskData.cargos);
    }
  }, [taskData]);

  useEffect(() => {
    setTotalSum(customerBillSum);
  }, [customerBillSum]);

  const onSelectChange = (keys) => {
    setSelectedRowKeys(keys);
  };

  const getTemplatesOperation = async () => {
    try {
      const url = `${restApiHost}/refbook/operation_waypoint/?active=true`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setOperations(json.data);
      }
    } catch (e) {
      setOperations([]);
      console.log('get template operations error', e);
      message.error('Ошибка запроса для получения шаблонов операций!');
    }
  };

  const columns = [
    // {
    //     title: 'Перевозка',
    //     key: 'type_transport',
    //     ellipsis: {
    //         showTitle: false
    //     },
    //     width: 150,
    //     render: (data) => data?.transport?.transport?.number_container || ''
    // },
    {
      title: 'Операция',
      dataIndex: 'operation',
      key: 'operation',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (operation) =>
        operation?.name ? (
          <Tooltip title={operation.name} placement={'topLeft'}>
            {operation.name}
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      title: 'Наименование',
      dataIndex: 'service',
      key: 'service',
      ellipsis: {
        showTitle: false,
      },
      render: (service) =>
        service?.name ? (
          <Tooltip title={service.name} placement={'topLeft'}>
            {service.name}
          </Tooltip>
        ) : (
          ''
        ),
      width: 190,
    },
    // {
    //     title: 'Поставщик',
    //     key: 'customer',
    //     dataIndex: 'customer',
    //     ellipsis: {
    //         showTitle: false,
    //     },
    //     width: 200,
    //     render: (customer) => customer?.legal_name ?
    //         <Tooltip title={customer.legal_name} placement={'topLeft'}>{customer?.short_name || customer.legal_name}</Tooltip> : ''
    // },
    {
      title: 'Валюта',
      children: [
        {
          title: 'Вал.',
          key: 'currency',
          dataIndex: 'currency',
          render: (currency) =>
            currency == 'rub'
              ? 'Рубли'
              : currency == 'dol'
              ? 'Доллар'
              : currency == 'eur'
              ? 'Евро'
              : null,
          width: 100,
        },
        {
          title: 'Курс',
          key: 'currency_rates',
          dataIndex: 'currency_rates',
          width: 70,
        },
      ],
    },
    {
      title: 'Цена',
      children: [
        {
          title: 'Вал.',
          key: 'price_currency',
          width: 150,
          render: (data) =>
            data?.in_currency
              ? priceFormatter(+data.price_currency)
              : priceFormatter(data.price),
        },
        {
          title: 'Вал.(У)',
          key: 'price',
          dataIndex: 'price',
          width: 150,
          render: (price) => (price ? priceFormatter(price) : 0),
        },
      ],
    },
    {
      title: 'Единицы',
      children: [
        {
          title: 'Кол-во',
          key: 'count',
          dataIndex: 'count',
          width: 80,
        },
        {
          title: 'Ед. изм.',
          dataIndex: 'service',
          key: 'service',
          render: (service) => (service ? service.units : ''),
          width: 150,
        },
      ],
    },
    {
      title: 'НДС',
      children: [
        {
          title: 'Ставка',
          dataIndex: 'vat_rate_str',
          key: 'vat_rate_str',
          width: 150,
        },
        {
          title: 'Валюта(У)',
          key: 'sum_vat',
          dataIndex: 'sum_vat',
          width: 150,
        },
      ],
    },
    {
      title: 'Всего',
      children: [
        {
          title: 'Всего',
          key: 'sum_currency',
          width: 150,
          render: (data) =>
            data?.in_currency
              ? priceFormatter(+data?.sum_currency?.sum_with_vat || 0)
              : priceFormatter(data?.sum_with_vat || 0),
        },
        {
          title: 'Всего(У)',
          key: 'sum',
          dataIndex: 'sum_with_vat',
          width: 150,
          render: (sum) => (sum ? priceFormatter(sum) : 0),
        },
      ],
    },
  ];

  const searchService = async (keyword) => {
    setSearchLoading(true);
    try {
      const url = `${restApiHost}/refbook/service/?search=${keyword}&active=true`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setServices(json.data);
      }
    } catch (e) {
      setSearchLoading(false);
      console.log('search service err', e);
      message.error('Ошибка запроса для поиска услуги');
    } finally {
      setSearchLoading(false);
    }
  };

  const saveCost = async (body) => {
    try {
      const url = `${restApiHost}/shipping/customer_bill/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      json && setCosts([...costs, json]);
      setVisiblePreCostModal(false);
      costForm.resetFields();
      message.success('Операция прошла успешно');
    } catch (e) {
      console.log('save error', e);
      message.error('');
    }
  };

  const edit = async (id, body) => {
    try {
      const url = `${restApiHost}/shipping/customer_bill/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      const newCosts = [...costs];
      const objIndex = newCosts.findIndex((obj) => obj.id == id);
      newCosts[objIndex] = { ...json };
      setCosts(newCosts);
      setVisiblePreCostModalEdit(false);
      costFormEdit.resetFields();
      refresh(preTaskId);
      message.success('Редактирование прошло успешно!');
    } catch (e) {
      console.log('edit err', e);
    }
  };

  const copyCosts = async (task) => {
    try {
      const url = `${restApiHost}/shipping/task/copy_upfrontcost_to_customerbill/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ task }),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      await refresh(task);
      message.success('Успешно скоприровано!');
    } catch (e) {
      console.log('copy costs err', e);
      message.error('Ошибка запроса на копирование');
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const createBill = async (body) => {
    setLoadingBill(true);
    try {
      const url = `${restApiHost}/shipping/task/create_invoice/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setCreatedBill(json);
      setLoadingBill(false);
      message.success('Счет успешно создан!');
    } catch (e) {
      setLoadingBill(false);
      console.log('create bill err', e);
      message.error('Ошибка запроса на создание счета');
    }
  };

  /*const calculateSum = (form) => {
    let sum;
    const count = +form.getFieldValue('count') || 1;
    const price = !inCurrency
      ? +form.getFieldValue('price')
      : +form.getFieldValue('price_currency') || 0;
    const vat_rate = +form.getFieldValue('vat_rate') || null;
    const currency = +form.getFieldValue('currency_rates') || 1;

    if (vat_rate || vat_rate == 0) {
      const priceWithCurrency = price * currency;
      const ndsCalc = priceWithCurrency * count * (vat_rate / 100);
      sum = priceWithCurrency * count + ndsCalc;

      form.setFieldsValue({
        sum: sum.toFixed(2),
      });
    } else {
      const priceWithCurrency = price * currency;
      sum = priceWithCurrency * count;
      form.setFieldsValue({
        sum: sum.toFixed(2),
      });
    }
  };*/

  return (
    <>
      <div className={'top-table-actions'}>
        <Tooltip placement={'topLeft'} title={'Добавить'}>
          <Button
            type="primary"
            size={'small'}
            icon={<PlusOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => setVisiblePreCostModal(true)}
          />
        </Tooltip>
        <Tooltip placement={'topLeft'} title={'Копировать с пред. расходов'}>
          <Button
            type="primary"
            size={'small'}
            icon={<CopyOutlined />}
            style={{ marginRight: 10 }}
            onClick={() => copyCosts(preTaskId)}
          />
        </Tooltip>
        <div>
          <Tooltip placement={'topLeft'} title={'Сформировать счет'}>
            <Button
              onClick={() => {
                setVisibleBillModal(true);
                let body = {
                  task: preTaskId,
                  transport_cargo_ids:
                    taskData?.transport_cargo_task?.length > 0
                      ? taskData.transport_cargo_task
                          .map((item) => item.id)
                          .join(',')
                      : '',
                  cost_ids: selectedRowKeys.join(','),
                };
                createBill(body);
              }}
              type="primary"
              disabled={!hasSelected}
              size={'small'}
              icon={<FilePdfOutlined />}
            />
          </Tooltip>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Выбрано ${selectedRowKeys.length} услуг` : ''}
          </span>
        </div>
        <div style={{ textAlign: 'right', marginLeft: 'auto' }}>
          <h4>Итоговая сумма</h4>
          <b>{priceFormatter(totalSum)}</b>
        </div>
      </div>

      <Modal
        width={'auto'}
        visible={visibleBillModal}
        title="Формирование счета"
        onOk={() => {
          setVisibleBillModal(false);
          setCreatedBill(null);
        }}
        onCancel={() => {
          setVisibleBillModal(false);
          setCreatedBill(null);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisibleBillModal(false);
              setCreatedBill(null);
            }}
          >
            Закрыть
          </Button>,
        ]}
      >
        <Spin spinning={loadingBill}>
          {createdBill ? (
            <BillTemplate
              task={preTaskId}
              loading={loadingBill}
              data={createdBill}
            />
          ) : (
            <h1>Нет данных</h1>
          )}
        </Spin>
      </Modal>

      <Modal
        visible={visiblePreCostModalEdit}
        title="Редактирование"
        onOk={() => {
          setVisiblePreCostModalEdit(false);
          setInCurrency(false);
          setDisableBtn(true);
          costFormEdit.resetFields();
        }}
        onCancel={() => {
          setVisiblePreCostModalEdit(false);
          setInCurrency(false);
          setDisableBtn(true);
          costFormEdit.resetFields();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisiblePreCostModalEdit(false);
              setInCurrency(false);
              setDisableBtn(true);
              costFormEdit.resetFields();
            }}
          >
            Закрыть
          </Button>,
          <Button
            disabled={disableBtn}
            key="submit"
            type="primary"
            onClick={() => {
              costFormEdit
                .validateFields()
                .then((values) => {
                  let body = {
                    ...values,
                    in_currency: inCurrency,
                    service: JSON.parse(values.service).id,
                    task: preTaskId,
                  };
                  currEditCost && edit(currEditCost.id, body);
                  setInCurrency(false);
                })
                .catch(() => setDisableBtn(true));
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form
          layout={'vertical'}
          form={costFormEdit}
          onValuesChange={() => {
            setTimeout(() => {
              costFormEdit
                .validateFields()
                .then(() => setDisableBtn(false))
                .catch(() => setDisableBtn(true));
            }, 0);
          }}
        >
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item name={'operation'} label={'Операция'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  // defaultValue={record && record.operation ? record.operation.id : ''}
                  placeholder="Поиск операции"
                >
                  {operations && operations.length > 0 ? (
                    <>
                      {operations.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </>
                  ) : (
                    <Option disabled value="Загрузка">
                      Загрузка
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name={'service'} label={'Поиск услуги'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  loading={searchLoading}
                  onSearch={(value) => value.length > 0 && searchService(value)}
                  placeholder="Найти услугу"
                >
                  {services.length > 0 ? (
                    <>
                      {services.map((item) => {
                        return (
                          <Option key={item.id} value={JSON.stringify(item)}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </>
                  ) : null}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                rules={[{ required: true, message: 'Обязательное поле!' }]}
                name={'transport'}
                label={'Транспорт'}
              >
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  placeholder={'Выберите транспорт с заявки'}
                >
                  {transportList?.length > 0 ? (
                    transportList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item?.transport?.transport_unit?.type_transport}
                        </Option>
                      );
                    })
                  ) : (
                    <Option disabled>Нет доступных вариантов</Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col xs={24} lg={12}>
                            <Form.Item name={'customer'} label={'Поставщик'}>
                                <Select
                                    showSearch
                                    placeholder="Поиск клиента"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                        0
                                    }
                                >
                                    {customers.length > 0 &&
                                    customers.map((customer) => {
                                        return (
                                            <Option
                                                value={customer.id}
                                            >
                                                {customer?.short_name || customer?.legal_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col> */}
            <Col xs={24} lg={6}>
              <Form.Item
                valuePropName="checked"
                name={'in_currency'}
                label={'В валюте'}
              >
                <Checkbox
                  onChange={(e) => {
                    if (!e.target.checked) {
                      costFormEdit.setFieldsValue({
                        currency: 'rub',
                        currency_rates: '1',
                      });
                    }
                    setInCurrency(e.target.checked);
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={6}>
              <Form.Item name={'currency'} label={'Валюта'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  disabled={!inCurrency}
                >
                  <Option value={'rub'}>Рубли</Option>
                  <Option value={'dol'}>Доллар</Option>
                  <Option value={'eur'}>Евро</Option>
                  <Option value={'cny'}>Юань</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name={'currency_rates'} label={'Курс'}>
                <Input disabled={!inCurrency} />
              </Form.Item>
            </Col>
            {inCurrency ? (
              <Col xs={24} lg={6}>
                <Form.Item name={'price_currency'} label={'Цена за ед.'}>
                  <Input placeholder={'Введите цену за ед.'} />
                </Form.Item>
              </Col>
            ) : (
              <Col xs={24} lg={6}>
                <Form.Item name={'price'} label={'Цена за ед.'}>
                  <Input placeholder={'Введите цену за ед.'} />
                </Form.Item>
              </Col>
            )}

            {/*<Col xs={24} lg={6}>*/}
            {/*    <Form.Item name={'count'} label={'Количество'}>*/}
            {/*        <InputNumber  placeholder={'Количество'}*/}
            {/*        />*/}
            {/*    </Form.Item>*/}
            {/*</Col>*/}
            <Col xs={24} lg={6}>
              <Form.Item name={'vat_rate'} label={'НДС'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  placeholder={'НДС'}
                >
                  <Option value={null}>Без НДС</Option>
                  <Option value={0}>0%</Option>
                  <Option value={20}>20%</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        visible={visiblePreCostModal}
        title="Добавить"
        onOk={() => {
          setVisiblePreCostModal(false);
          setInCurrency(false);
          setDisableBtn(true);
          costForm.resetFields();
        }}
        onCancel={() => {
          setVisiblePreCostModal(false);
          setInCurrency(false);
          setDisableBtn(true);
          costForm.resetFields();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisiblePreCostModal(false);
              setInCurrency(false);
              setDisableBtn(true);
              costForm.resetFields();
            }}
          >
            Закрыть
          </Button>,
          <Button
            disabled={disableBtn}
            key="submit"
            type="primary"
            onClick={() => {
              costForm
                .validateFields()
                .then((values) => {
                  let body = {
                    ...values,
                    in_currency: inCurrency,
                    service: JSON.parse(values.service).id,
                    task: preTaskId,
                  };
                  saveCost(body);
                  setInCurrency(false);
                })
                .catch(() => setDisableBtn(true));
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form
          form={costForm}
          layout={'vertical'}
          onChange={() =>
            costForm
              .validateFields()
              .then(() => setDisableBtn(false))
              .catch(() => setDisableBtn(true))
          }
        >
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item name={'operation'} label={'Операция'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  // defaultValue={record && record.operation ? record.operation.id : ''}
                  placeholder="Поиск операции"
                >
                  {operations && operations.length > 0 ? (
                    <>
                      {operations.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </>
                  ) : (
                    <Option disabled value="Загрузка">
                      Загрузка
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name={'service'} label={'Поиск услуги'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  loading={searchLoading}
                  onSearch={(value) => value.length > 0 && searchService(value)}
                  placeholder="Найти услугу"
                >
                  {services.length > 0 ? (
                    <>
                      {services.map((item) => {
                        return (
                          <Option key={item.id} value={JSON.stringify(item)}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </>
                  ) : null}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                rules={[{ required: true, message: 'Обязательное поле!' }]}
                name={'transport'}
                label={'Транспорт'}
              >
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  placeholder={'Выберите транспорт с заявки'}
                >
                  {transportList?.length > 0 ? (
                    transportList.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item?.transport?.transport_unit?.type_transport}
                        </Option>
                      );
                    })
                  ) : (
                    <Option disabled>Нет доступных вариантов</Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col xs={24} lg={12}>
                            <Form.Item name={'customer'} label={'Поставщик'}>
                                <Select
                                    showSearch
                                    placeholder="Поиск клиента"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                        0
                                    }
                                >
                                    {customers.length > 0 &&
                                    customers.map((customer) => {
                                        return (
                                            <Option
                                                value={customer.id}
                                            >
                                                {customer?.short_name || customer?.legal_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col> */}
            <Col xs={24} lg={6}>
              <Form.Item
                valuePropName="checked"
                name={'in_currency'}
                label={'В валюте'}
              >
                <Checkbox
                  onChange={(e) => {
                    if (!e.target.checked) {
                      costFormEdit.setFieldsValue({
                        currency: 'rub',
                        currency_rates: '1',
                      });
                    }
                    setInCurrency(e.target.checked);
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={6}>
              <Form.Item name={'currency'} label={'Валюта'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  defaultValue={'rub'}
                  disabled={!inCurrency}
                >
                  <Option value={'rub'}>Рубли</Option>
                  <Option value={'dol'}>Доллар</Option>
                  <Option value={'eur'}>Евро</Option>
                  <Option value={'cny'}>Юань</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name={'currency_rates'} label={'Курс'}>
                <Input disabled={!inCurrency} />
              </Form.Item>
            </Col>
            {inCurrency ? (
              <Col xs={24} lg={6}>
                <Form.Item name={'price_currency'} label={'Цена за ед.'}>
                  <Input placeholder={'Введите цену за ед.'} />
                </Form.Item>
              </Col>
            ) : (
              <Col xs={24} lg={6}>
                <Form.Item name={'price'} label={'Цена за ед.'}>
                  <Input placeholder={'Введите цену за ед.'} />
                </Form.Item>
              </Col>
            )}
            {/*<Col xs={24} lg={6}>*/}
            {/*    <Form.Item name={'count'} label={'Количество'}>*/}
            {/*        <InputNumber min={1}*/}
            {/*                     defaultValue={1}*/}
            {/*                     placeholder={'Количество'}*/}
            {/*        />*/}
            {/*    </Form.Item>*/}
            {/*</Col>*/}
            <Col xs={24} lg={6}>
              <Form.Item name={'vat_rate'} label={'НДС'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  placeholder={'НДС'}
                >
                  <Option value={null}>Без НДС</Option>
                  <Option value={0}>0%</Option>
                  <Option value={20}>20%</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Table
        rowClassName={(record, index) =>
          index % 2 === 0
            ? 'black-text table-row-color'
            : 'black-text table-row-white'
        }
        rowKey={(record) => record.id}
        rowSelection={rowSelection}
        scroll={{ x: 1000 }}
        showHeader
        bordered={true}
        size={'small'}
        dataSource={costs}
        columns={columns}
        onRow={(costs) => {
          return {
            onDoubleClick: () => {
              costs && costs.service && searchService(costs.service.name);
              costs?.in_currency && setInCurrency(true);
              setCurrEditCost(costs);
              setVisiblePreCostModalEdit(true);
              costFormEdit.setFieldsValue({
                ...costs,
                transport: costs?.transport?.id,
                in_currency: costs?.in_currency,
                customer: costs?.customer?.id,
                operation: costs?.operation?.id,
                service: costs?.service && JSON.stringify(costs.service),
              });
            },
          };
        }}
        pagination={{ position: ['none'] }}
      />
    </>
  );
};

export default CustomerBill;
