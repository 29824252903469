import React, { useCallback } from 'react';
import { Card, Col, Divider, Dropdown, Row, Space, Tag } from 'antd';
import PropTypes from 'prop-types';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';

const ContractListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>
              {props.contract?.code_contract || '-'}
            </Col>
            <Col className={'secondText'}>
              {getStatusFromServer(props.contract?.status)}
            </Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.contract)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.contract]);

  const getStatusFromServer = (status) => {
    switch (status) {
      case 'new':
        return <Tag color={'cyan'}>Новый</Tag>;
      case 'rec':
        return <Tag color={'purple'}>Отправлен на согласование</Tag>;
      case 'ret':
        return <Tag color={'purple'}>Повторное согласование</Tag>;
      case 'con':
        return <Tag color={'orange'}>Получена копия</Tag>;
      case 'sig':
        return <Tag color={'green'}>Подписан</Tag>;
      case 'arc':
        return <Tag color={'#A9A9A9'}>Архив</Tag>;
      default:
        return <Tag color={'magenta'}>Неизвестный статус</Tag>;
    }
  };

  const expired = (record) => {
    return (
      (record?.status === 'rec' && record.status_time > 7) ||
      (record.status === 'ret' && record.status_time > 3)
    );
  };

  return (
    <>
      <Card
        className={`mainCard`}
        style={{ background: '#ffffff' }}
        bodyStyle={{
          padding: '12px',
          background: expired(props.contract) ? 'rgba(255,73,108,0.16)' : '',
          borderRadius: '10px',
        }}
      >
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[0, 15]}>
          <Col xs={24}>
            <div className={'secondText'}>{'Наша компания'}</div>
            <div className={'valueText'}>
              {props.contract?.signer_manager?.customer?.legal_name || '-'}
            </div>
          </Col>

          <Col xs={24}>
            <div className={'secondText'}>{'Клиент'}</div>
            <div className={'valueText'}>
              {props.contract?.customer?.legal_name ||
                props.contract?.customer?.short_name ||
                props.contract?.customer?.email ||
                '-'}
            </div>
          </Col>

          <Col>
            <div className={'secondText'}>{'Тип компании'}</div>
            <div className={'valueText'}>
              {props.contract?.customer?.category?.length > 0
                ? props.contract?.customer?.category?.map((item, index) => {
                    return <Tag key={index}>{item?.category_str}</Tag>;
                  })
                : '-'}
            </div>
          </Col>

          <Col xs={24}>
            <div className={'secondText'}>{'Тип договора'}</div>
            <div className={'valueText'}>{props.contract?.type_str || '-'}</div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

ContractListItem.propTypes = {
  contract: PropTypes.object,
  menuActions: PropTypes.func,
};

export default ContractListItem;
