import PropTypes from 'prop-types';
import { Button, Form, message, Modal } from 'antd';
import React, { useCallback, useContext, useEffect } from 'react';
import { ErrorBoundary } from '../../../../../../../ErrorBoundary';
import ManagerModalForm from './ManagerModalForm';
import {
  addShippingTaskManagers,
  editShippingTaskManager,
} from '../../../../../../../../logic/task/TaskApiFunctions';
import { userInformations } from '../../../../../../../../contex';

const ManagerModal = (props) => {
  const { userInfo } = useContext(userInformations);
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.editingManager) {
      handleSetFormFieldsValue(props.editingManager);
    }
  }, [props.editingManager]);

  const handleSetFormFieldsValue = (editingManager) => {
    form.setFieldsValue({
      group: editingManager?.group,
      manager: editingManager?.manager?.id,
    });
  };

  const handleCloseModal = useCallback(() => {
    props.closeModal && props.closeModal();
    form.resetFields();
  }, [props.closeModal]);

  const handleCreateOrEditManager = useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        let body = {
          task: props.taskData?.id,
          manager: values.manager,
          group: values.group,
        };
        const res = props.editingManager?.id
          ? await editShippingTaskManager(
              props.editingManager?.id,
              body,
              userInfo,
            )
          : await addShippingTaskManagers(body, userInfo);

        if (res) {
          message.success(
            props.editingWaypoint?.id
              ? 'Менеджер успешно отредактирован'
              : 'Менеджер успешно добавлен',
          );
          handleCloseModal();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [form, props.editingManager]);

  return (
    <>
      <Modal
        visible={props.visible}
        destroyOnClose={true}
        zIndex={199}
        title={
          props.editingManager?.id
            ? 'Редактировать менеджера'
            : 'Добавить менеджера'
        }
        onOk={() => {
          handleCloseModal();
        }}
        onCancel={() => {
          handleCloseModal();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={async () => {
              await handleCreateOrEditManager();
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <ErrorBoundary>
          <ManagerModalForm editingManager={props.editingManager} form={form} />
        </ErrorBoundary>
      </Modal>
    </>
  );
};

export default ManagerModal;

ManagerModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  taskData: PropTypes.object.isRequired,
  editingManager: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
};
