const fetchUrl = async (url) => {
  if (!url) return;
  try {
    const data = await fetch(`${url}`);
    return data;
  } catch (err) {
    console.log(err);
  }
};

async function getBlobFromUrl(fileUrl) {
  try {
    const data = await fetchUrl(fileUrl);
    if (data) {
      const contentType = data.headers.get('Content-Type');
      let blob = await data.blob();
      if (contentType) {
        blob = new Blob([blob], { type: contentType });
      }
      return blob;
    }
  } catch (err) {
    throw new Error(err);
  }
}

export default getBlobFromUrl;
