import dayjs from 'dayjs';

export function getTodayClass(
  day,
  useForDayViewCalendar,
  dayNow = dayjs().format('DD-MM-YY'),
) {
  return day === dayNow
    ? 'calendar-mouth-day-today'
    : useForDayViewCalendar
    ? ''
    : 'calendar-mouth-day-title';
}
