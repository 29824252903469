import { Resizable } from 'react-resizable';
import { useCallback, useEffect, useRef, useState } from 'react';
import useStateRef from 'react-usestateref';
import './ResizableTableColumnHeader.css';

const ResizableTableColumnHeader = (props) => {
  const {
    onResize,
    width,
    isChildren,
    disableResize,
    uniqueIndex,
    isGroupItem,
    ...restProps
  } = props;
  const [, setIsResize, isResizeRef] = useStateRef(false);
  const [, setCopyThRefPositionAndText, copyThRefPositionAndTextRef] =
    useStateRef(null);
  const thRef = useRef(null);
  const [copyWidth, setCopyWidth] = useState(width);

  if (disableResize || !onResize) {
    return <th {...restProps} />;
  }

  useEffect(() => {
    setCopyWidth(width);
  }, [width]);

  const resetVariables = useCallback(() => {
    setCopyThRefPositionAndText(null);
  }, [setCopyThRefPositionAndText]);

  const handleSetIsResize = useCallback(
    (isResize) => {
      if (isResize && thRef.current) {
        setCopyThRefPositionAndText({
          right: thRef.current.getBoundingClientRect().right,
          left: thRef.current.getBoundingClientRect().left,
          top: thRef.current.getBoundingClientRect().top,
          textContent:
            props.children?.length > 1 && props.children[1]
              ? props.children[1]
              : thRef.current?.textContent,
        });
      }
      if (!isResize) {
        onResize(null, { size: copyWidth, uniqueIndex, isGroupItem });
        resetVariables();
      }
      setIsResize(isResize);
    },
    [thRef, copyThRefPositionAndTextRef, copyWidth, setIsResize],
  );

  return (
    <>
      <Resizable
        width={copyWidth}
        height={0}
        handle={
          <span
            style={{
              position: 'absolute',
              right: '-5px',
              bottom: '0',
              zIndex: 1,
              width: '10px',
              height: '100%',
              color: '#ffffff',
              cursor: 'col-resize',
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        }
        onResize={(e, { size }) => {
          setCopyWidth(size.width);
        }}
        onResizeStart={() => {
          handleSetIsResize(true);
        }}
        onResizeStop={() => {
          resetVariables();
          handleSetIsResize(false);
        }}
        draggableOpts={{ enableUserSelectHack: true }}
      >
        {isResizeRef.current ? (
          <th ref={thRef} {...restProps}>
            <div
              className={'resizable-column'}
              style={{
                width: `${copyWidth}px`,
                position: 'fixed',
                left: `${copyThRefPositionAndTextRef.current?.left}px`,
                right: `${copyThRefPositionAndTextRef.current?.right}px`,
                top: `${copyThRefPositionAndTextRef.current?.top}px`,
              }}
            />
            {!isChildren
              ? copyThRefPositionAndTextRef.current?.textContent
              : props.children?.length > 1 && props.children[1]}
          </th>
        ) : (
          <th ref={thRef} {...restProps} />
        )}
      </Resizable>
    </>
  );
};

export default ResizableTableColumnHeader;
