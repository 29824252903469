import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { message } from 'antd';

export default async function getSectionsForCurrentGroup(
  groupId,
  branchId,
  //userInfo = null,
) {
  try {
    const url = `${restApiHost}/accounts/group_section_visibility/?branch=${branchId}&group=${groupId}&ordering=index`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      false,
    );

    if (json.data) {
      return json?.data;
    }
  } catch (e) {
    console.log('get group sections err', e);
    message.error('Ошибка запроса на получение разделов групп филиала');
  }
}
