import PropTypes from 'prop-types';
import { Button, Space, Tooltip } from 'antd';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import React, { useCallback, useContext, useState } from 'react';
import { ErrorBoundary } from '../../../../../../ErrorBoundary';
import ManagersTable from './ManagersTable';
import { getShippingTaskManagersList } from '../../../../../../../logic/task/TaskApiFunctions';
import { userInformations } from '../../../../../../../contex';
import ManagerModal from './modal/ManagerModal';
import useAsyncEffect from 'use-async-effect';
import { FilterOutlined } from '@ant-design/icons';
import useStateRef from 'react-usestateref';
import useCheckMobileScreen from '../../../../../../../hooks/useCheckMobileScreen';

const ManagersTab = (props) => {
  const { userInfo } = useContext(userInformations);
  const [visibleModal, setVisibleModal] = useState(false);
  const [managersDataList, setManagersDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setVisibleFilters, visibleFiltersRef] = useStateRef(false);
  const [, setTableFilters, tableFiltersRef] = useStateRef(null);
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    await handleGetManagerList();
  }, []);

  useAsyncEffect(async () => {
    if (!visibleFiltersRef.current && !isMobile) {
      await handleGetManagerList(null);
    }
  }, [visibleFiltersRef.current, isMobile]);

  const handleGetManagerList = useCallback(
    async (params = null) => {
      try {
        setLoading(true);
        setTableFilters(params);
        const res = await getShippingTaskManagersList(
          props.taskData?.id,
          params,
          userInfo,
        );
        setManagersDataList(res ?? []);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [setManagersDataList, setLoading, setTableFilters],
  );

  const handleAddManager = useCallback(() => {
    setVisibleModal(true);
  }, [setVisibleModal]);

  const handleCloseModal = useCallback(async () => {
    setVisibleModal(false);
    await handleGetManagerList(tableFiltersRef.current);
  }, [setVisibleModal, tableFiltersRef.current]);

  return (
    <>
      <div className={'top-table-actions'}>
        <Space>
          <Tooltip placement={'topLeft'} title={'Добавить менеджера'}>
            <Button
              size={'small'}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                handleAddManager();
              }}
            />
          </Tooltip>
          <Tooltip placement={'topLeft'} title={'Отобразить фильтры'}>
            <Button
              size={'small'}
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => {
                setVisibleFilters(!visibleFiltersRef.current);
              }}
            />
          </Tooltip>
        </Space>
      </div>
      <ErrorBoundary>
        <div style={{ position: 'relative' }}>
          <ManagersTable
            managersDataList={managersDataList}
            taskData={props.taskData}
            loading={loading}
            choices={props.choices}
            tableFilters={tableFiltersRef.current}
            visibleFilters={visibleFiltersRef.current}
            setVisibleFilters={(val) => {
              setVisibleFilters(val);
            }}
            handleUpdateList={async (tableFilters) => {
              await handleGetManagerList(tableFilters);
            }}
          />
        </div>
      </ErrorBoundary>

      <ErrorBoundary>
        <ManagerModal
          taskData={props.taskData}
          visible={visibleModal}
          choices={props.choices}
          closeModal={async () => await handleCloseModal()}
        />
      </ErrorBoundary>
    </>
  );
};

export default ManagersTab;

ManagersTab.propTypes = {
  taskData: PropTypes.object.isRequired,
  choices: PropTypes.array,
};
