import { restApiHost } from '../../../../utils/appVariables';
import { call } from '../../../../apiUtils/call';
import { message } from 'antd';
import { convertObjFilterToUrl } from '../../../../utils/getFilters';

export async function getCalendarEventsTasks(
  params,
  userInfo,
  staffId,
  firstAndLastDayOfMonth,
) {
  let filters = `&active=true`;

  if (params) {
    filters = convertObjFilterToUrl(params, null);
  }
  if (staffId) {
    filters += `&members__staff_id=${staffId}`;
  } else {
    filters += `&members__staff_id=${userInfo.current?.id}`;
  }

  if (
    firstAndLastDayOfMonth?.firstDay !== null &&
    firstAndLastDayOfMonth?.lastDay !== null
  ) {
    filters += `&end__gte=${firstAndLastDayOfMonth.firstDay}`;
    filters += `&end__lte=${firstAndLastDayOfMonth.lastDay}`;
  }

  try {
    const url = `${restApiHost}/calendar/events/?size=0${filters}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    console.log(e);
    message.error('Ошибка получения задач');
  }
}

export async function getCalendarEventById(eventId, userInfo) {
  try {
    const url = `${restApiHost}/calendar/events/${eventId}/?active=true`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json?.data) {
      return json.data;
    }
  } catch (e) {
    console.log(e);
    message.error('Ошибка получения задачи');
  }
}

export async function createEvent(body, userInfo) {
  try {
    const url = `${restApiHost}/calendar/events/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo?.current?.haveViewBranch,
    );

    if (json) {
      message.success('Задача успешно создана!');
      return json;
    }
  } catch (e) {
    console.log('create calendar task err', e);
    message.error('Ошибка запроса на создания задачи');
  }
}

export async function editEvent(id, body, userInfo) {
  try {
    const url = `${restApiHost}/calendar/events/${id}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo?.current?.haveViewBranch,
    );

    if (json) {
      message.success('Задача успешно отредактирована!');
      return json?.data;
    }
  } catch (e) {
    console.log('edit calendar task err', e);
    message.error('Ошибка запроса на редактирование задачи');
  }
}

export async function deleteEvent(eventId, userInfo) {
  try {
    const url = `${restApiHost}/calendar/events/${eventId}/`;
    const json = await call(
      url,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;

    message.success('Успешно удалено');

    return json;
  } catch (e) {
    console.log('delete event err', e);
    message.error('Ошибка запроса на удаление задачи');
  }
}

export async function editEventTags(id, body, userInfo) {
  try {
    const url = `${restApiHost}/calendar/event_tags/${id}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo?.current?.haveViewBranch,
    );

    if (json) {
      message.success('Тэг задачи успешно отредактирован!');
      return json;
    }
  } catch (e) {
    console.log('edit event tags err', e);
    message.error('Ошибка запроса на редактирование тэга задачи');
  }
}

export async function createTagsOnEvent(body, userInfo) {
  try {
    const url = `${restApiHost}/calendar/event_tags/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo?.current?.haveViewBranch,
    );

    if (json) {
      message.success('Тэг задачи успешно добавлен!');
      return json;
    }
  } catch (e) {
    console.log('create tags event err', e);
    message.error('Ошибка запроса на добавление тэга задачи');
  }
}

export async function deleteTagOnEvent(tagId, userInfo) {
  if (!tagId) return;
  try {
    const url = `${restApiHost}/calendar/event_tags/${tagId}`;
    const json = await call(
      url,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo?.current?.haveViewBranch,
    );

    if (!json) return;

    message.success('Тэг задачи успешно удален!');
    return json;
  } catch (e) {
    console.log('create tags event err', e);
    message.error('Ошибка запроса на добавление тэга задачи');
  }
}

export async function createEventMembers(body, userInfo) {
  try {
    const url = `${restApiHost}/calendar/event_members/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo?.current?.haveViewBranch,
    );

    if (json) {
      message.success('Участники задачи успешно добавлен!');
      return json;
    }
  } catch (e) {
    console.log('edit event members err', e);
    message.error('Ошибка запроса на добавление участника задачи');
  }
}

export async function editEventMembers(id, body, userInfo) {
  try {
    const url = `${restApiHost}/calendar/event_members/${id}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo?.current?.haveViewBranch,
    );

    if (json) {
      message.success('Участники задачи успешно отредактирован!');
      return json;
    }
  } catch (e) {
    console.log('edit event tags err', e);
    message.error('Ошибка запроса на редактирование участников задачи');
  }
}

export async function deleteEventMembers(memberId, userInfo) {
  try {
    const url = `${restApiHost}/calendar/event_members/${memberId}/`;
    const json = await call(
      url,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo?.current?.haveViewBranch,
    );

    if (json) {
      message.success('Участник задача успешно удален!');
      return json;
    }
  } catch (e) {
    console.log('delete event member err', e);
    message.error('Ошибка запроса на удаление участника задачи');
  }
}

export async function createTask(body, userInfo) {
  try {
    const url = `${restApiHost}/calendar/tasks/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo?.current?.haveViewBranch,
    );

    if (json) {
      message.success('Задача успешно создана!');
      return json;
    }
  } catch (e) {
    console.log('create calendar task err', e);
    message.error('Ошибка запроса на создания задачи');
  }
}

export async function editTask(id, body, userInfo) {
  try {
    const url = `${restApiHost}/calendar/tasks/${id}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo?.current?.haveViewBranch,
    );

    if (json) {
      message.success('Задача успешно отредактирована!');
      return json;
    }
  } catch (e) {
    console.log('edit calendar task err', e);
    message.error('Ошибка запроса на редактирование задачи');
  }
}
