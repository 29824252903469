import React, { useContext, useEffect, useState } from 'react';
import { Form, Modal, Button, Row, Col, Select, message } from 'antd';
import { restApiHost } from '../../utils/appVariables';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const { Option, OptGroup } = Select;

const ChainWithTaskModal = ({
  open,
  handleClose,
  email,
  handleShowBodyEmail,
}) => {
  const [form] = Form.useForm();
  const [loading] = useState(false);
  const [preTasks, setPreTasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    getPreTasks();
    getTasks();
  }, []);

  const getTasks = async () => {
    try {
      const url = `${restApiHost}/shipping/tasks/?page=1`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        setTasks(json.data);
      }
    } catch (e) {
      console.log('tasks get err', e);
    }
  };

  const searchTasks = async (value) => {
    try {
      const url = `${restApiHost}/shipping/task/?search=${value}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        setTasks(json.data);
      }
    } catch (e) {
      console.log('search tasks err', e);
    }
  };

  const getPreTasks = async () => {
    try {
      const url = `${restApiHost}/leads/pre_tasks/?page=1`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        setPreTasks(json.data);
      }
    } catch (e) {
      console.log('tasks get err', e);
    }
  };

  const searchPreTasks = async (value) => {
    try {
      const url = `${restApiHost}/leads/pre_task/?search=${value}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.pre_tasks) {
        setPreTasks(json.pre_tasks);
      }
    } catch (e) {
      console.log('search tasks err', e);
    }
  };

  const handleSearchTasks = (value) => {
    if (value.length > 0) {
      searchPreTasks(value);
      searchTasks(value);
    } else {
      getPreTasks();
      getTasks();
    }
  };

  const taskEdit = async (body, url) => {
    try {
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Сделка успешно привязана');
      handleClose();
      handleShowBodyEmail(email);
      form.resetFields();
    } catch (e) {
      console.log('edit task err', e);
      message.error('Ошибка запроса на привязку письма');
    }
  };

  return (
    <Modal
      visible={open}
      title={'Привязать сделку'}
      onCancel={() => {
        handleClose();
        form.resetFields();
      }}
      footer={[
        <Button
          key="close"
          onClick={() => {
            handleClose();
            form.resetFields();
          }}
        >
          Закрыть
        </Button>,
        <Button
          key="save"
          type="primary"
          disabled={loading}
          onClick={() => {
            form.validateFields().then((values) => {
              const { task } = values;
              const parsedTask = task ? JSON.parse(task) : null;
              if (parsedTask) {
                const url = parsedTask.pre_task_number
                  ? `${restApiHost}/leads/pre_tasks/${parsedTask.id}/`
                  : `${restApiHost}/shipping/tasks/${parsedTask.id}/`;
                taskEdit({ related_message: email.msg_id || email.id }, url);
              } else {
                message.error('Не выбрана заявка');
              }
            });
          }}
          loading={loading}
        >
          Привязать
        </Button>,
      ]}
    >
      <Form form={form} className={'reset-margin-form'}>
        <Row gutter={15} className={'color-disabled'}>
          <Col lg={24}>
            <Form.Item
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              name={'task'}
            >
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                showSearch
                onSearch={handleSearchTasks}
                placeholder="Выберите заявку"
                defaultValue={[]}
                filterOption={false}
                onChange={(value) => console.log(value)}
                style={{
                  width: '100%',
                }}
              >
                {preTasks?.length > 0 && (
                  <>
                    <OptGroup label="Пред. заявки">
                      {preTasks.map((item) => {
                        return (
                          <Option key={item.id} value={JSON.stringify(item)}>
                            {item.pre_task_number} | {item?.lead?.company_name}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  </>
                )}
                {tasks?.length > 0 && (
                  <>
                    <OptGroup label="Заявки">
                      {tasks.map((item) => {
                        return (
                          <Option key={item.id} value={JSON.stringify(item)}>
                            {item.task_number} |{' '}
                            {item?.customer?.short_name ||
                              item?.customer?.legal_name}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  </>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChainWithTaskModal;
