import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { message } from 'antd';

export async function getListStrategicArchReason(userInfo) {
  try {
    const url = `${restApiHost}/refbook/strategic_arch_reason/?active=true`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );
    if (!json) return;
    return json;
  } catch (e) {
    console.log('call getListStrategicArchReason error', e);
    message.error(
      'Ошибка запроса на получение списка причин попадания в стратегиский архив',
    );
  }
}
