import moment from 'moment';

function filtersTasksByTimePeriod(tasks, timeStart, timeEnd) {
  if (!tasks) return [];
  const tasksOnThisPeriod = tasks?.filter(
    (item) =>
      moment(item.end).format('HH:mm') >= timeStart &&
      moment(item.end).format('HH:mm') <= timeEnd &&
      !item?.lead &&
      !item?.customer &&
      !item?.group,
  );
  return tasksOnThisPeriod.sort(
    (a, b) => b.priority - a.priority || new Date(a.end) - new Date(b.end),
  );
}

export function filtersTasksByTimePeriodCallLeadGroup(
  tasks,
  timeStart,
  timeEnd,
) {
  if (!tasks) return [];
  const tasksOnThisPeriod = tasks?.filter(
    (item) =>
      (item?.lead || item?.customer) &&
      moment(item.start).format('HH:mm') >= timeStart &&
      moment(item.start).format('HH:mm') <= timeEnd,
  );
  return tasksOnThisPeriod.sort(
    (a, b) => b.priority - a.priority || new Date(a.end) - new Date(b.end),
  );
}

export function isCreatorTask(task, currentUserId) {
  for (const member of task.members) {
    if (member.group === 'cre' && member?.staff_id === currentUserId) {
      return true;
    }
  }
  return false;
}

export function filtersTasksByTimePeriodAndGroupToPersonallyCommon(
  tasks,
  timeStart,
  timeEnd,
  currentUserId,
) {
  if (!tasks) return [];
  const tasksOnThisPeriod = tasks
    ?.filter(
      (item) =>
        moment(item.end).format('HH:mm') >= timeStart &&
        moment(item.end).format('HH:mm') <= timeEnd &&
        !item?.lead &&
        !item?.customer &&
        item?.group !== null,
    )
    .sort(
      (a, b) => b.priority - a.priority || new Date(a.end) - new Date(b.end),
    );

  const tasksToGroup = [];
  const classicTasks = [];
  for (const task of tasksOnThisPeriod) {
    if (task?.members?.length > 0) {
      if (isCreatorTask(task, currentUserId)) {
        tasksToGroup.push(task);
      } else {
        classicTasks.push(task);
      }
    }
  }
  let outputGroupTask = [];
  if (tasksToGroup?.length > 0) {
    outputGroupTask = _.mapValues(_.groupBy(tasksToGroup, (i) => i?.group));
  }
  return classicTasks.concat(Object.entries(outputGroupTask));
}

export function mergedClassicAndGroupPersonallyCommonTask(
  tasks,
  timeStart,
  timeEnd,
  selectedStaffId,
  userInfoId,
) {
  if (!tasks) return [];
  const currentUserId = selectedStaffId ? selectedStaffId : userInfoId;
  const classicTasks = filtersTasksByTimePeriod(tasks, timeStart, timeEnd);
  const groupTaskToPersonallyCommon =
    filtersTasksByTimePeriodAndGroupToPersonallyCommon(
      tasks,
      timeStart,
      timeEnd,
      currentUserId,
    );
  return classicTasks.concat(groupTaskToPersonallyCommon);
}
