import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';

export async function getStaffList(userInfo, getAll, branchId) {
  let urlParams = new URLSearchParams();
  if (getAll) {
    urlParams.append('size', '0');
  }
  if (branchId) {
    urlParams.append('branch', `${branchId}`);
  }
  try {
    const url = `${restApiHost}/accounts/staff_users/?${urlParams}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );
    if (!json) return;
    return json;
  } catch (e) {
    console.log('fetch profile info error', e);
    message.error('Ошибка запроса на получение сотрудников');
  }
}

export async function searchStaffList(keyword, userInfo, getAll, branchId) {
  let urlParams = new URLSearchParams();
  if (keyword) {
    urlParams.append('search', keyword);
  }
  if (getAll) {
    urlParams.append('size', '0');
  }
  if (branchId) {
    urlParams.append('branch', `${branchId}`);
  }

  try {
    const url = `${restApiHost}/accounts/staff_users/?${urlParams}`;
    console.log(url);
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );
    if (!json) return;
    return json;
  } catch (e) {
    console.log('fetch profile info error', e);
    message.error('Ошибка запроса на поиск сотрудников');
  }
}

export async function getStaffById(staffId, userInfo) {
  try {
    const url = `${restApiHost}/accounts/staff_users/${staffId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );
    if (!json) return;
    return json;
  } catch (e) {
    console.log('fetch profile info error', e);
    message.error('Ошибка запроса на получение сотрудника по id');
  }
}

export async function getStaffByBranchId(branchId, userInfo) {
  if (branchId != null) {
    try {
      const url = `${restApiHost}/accounts/staff_users/?branch=${branchId}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (!json) return;
      return json;
    } catch (e) {
      console.log('fetch profile info error', e);
      message.error('Ошибка запроса на получение сотрудников по id филиала');
    }
  }
}
