import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Card, Col, Divider, Row } from 'antd';

const SectionsListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Col>
          <Col className={'mainText'}>{props.section.sectionsName || '-'}</Col>
        </Col>
      </>
    );
  }, [props.section]);

  const renderCheckbox = (item) => {
    console.log(item);
    const x = `item.render(props.section)`;
    return eval(x);
  };

  return (
    <>
      <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[0, 15]}>
          {props.fields?.map((item, index) => {
            return (
              <Col key={index} xs={24}>
                <div className={'secondText'}>{`${item?.title}`}</div>
                <div className={'valueText'}>{renderCheckbox(item)}</div>
              </Col>
            );
          })}
        </Row>
      </Card>
    </>
  );
};

SectionsListItem.propTypes = {
  section: PropTypes.object,
  fields: PropTypes.arrayOf(PropTypes.object),
};

export default SectionsListItem;
