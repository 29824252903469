import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import BriefInformationAboutTaskPopoverTitle from '../briefInformationAboutTaskPopover/BriefInformationAboutTaskPopoverTitle';
import BriefInformationAboutTaskPopoverContent from '../briefInformationAboutTaskPopover/BriefInformationAboutTaskPopoverContent';
import '../briefInformationAboutTaskPopover/briefInformationAboutTaskPopover.css';

const BriefInformationAboutTaskPopover = (props) => {
  const [visiblePopover, setVisiblePopover] = useState(false);

  return (
    <>
      <Popover
        zIndex={1100}
        onVisibleChange={(visiblePopover) => {
          setVisiblePopover(visiblePopover);
        }}
        visible={visiblePopover}
        title={
          <BriefInformationAboutTaskPopoverTitle
            task={props.task}
            handleEditTask={(task) => {
              setVisiblePopover(false);
              props.handleEditTask(task);
            }}
          />
        }
        content={
          <BriefInformationAboutTaskPopoverContent
            task={props.task}
            handleEditTask={(task) => {
              setVisiblePopover(false);
              props.handleEditTask(task);
            }}
          />
        }
        placement="right"
        trigger="hover"
      >
        {props.children}
      </Popover>
    </>
  );
};
BriefInformationAboutTaskPopover.propTypes = {
  task: PropTypes.object.isRequired,
  handleEditTask: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
};

export default React.memo(BriefInformationAboutTaskPopover);
