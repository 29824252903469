import PropTypes from 'prop-types';
import { Button, Form, message, Modal, Popover, Spin, Steps } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import BasicInformationBranchStep from './BranchesMainModalSteps/BasicInformationBranchStep';
import SettingDisplaySectionsBranchStep from './BranchesMainModalSteps/SettingDisplaySectionsBranchStep';
import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { userInformations } from '../../../contex';
import { ErrorBoundary } from '../../ErrorBoundary';

const { Step } = Steps;

const getStatusProgressDot = (status) => {
  switch (status) {
    case 'process':
      return 'в процессе';
    case 'wait':
      return 'в ожидании';
    case 'finish':
      return 'завершен';
    default:
      return status ? status : 'Неизвестный статус';
  }
};

const progressDotPopover = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        Шаг {index + 1}, статус: {getStatusProgressDot(status)}
      </span>
    }
  >
    <span style={{ fontSize: '13px !important' }}>{dot}</span>
  </Popover>
);

const BranchesMainModal = (props) => {
  const { userInfo } = useContext(userInformations);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [basicInformationForm] = Form.useForm();
  const [createdBranchId, setCreatedBranchId] = useState(null);

  useEffect(() => {
    if (props.editingBranchItem) {
      basicInformationForm.setFieldsValue({
        ...props.editingBranchItem,
        city: props.editingBranchItem?.city?.id
          ? props.editingBranchItem.city.id
          : null,
      });
      setCreatedBranchId(props.editingBranchItem?.id);
    }
  }, [props.editingBranchItem]);

  const mainSteps = [
    {
      title: 'Основная информация',
      content: () => {
        return (
          <>
            <ErrorBoundary>
              <BasicInformationBranchStep
                form={basicInformationForm}
                editingBranchItem={props.editingBranchItem}
              />
            </ErrorBoundary>
          </>
        );
      },
    },
    {
      title: 'Настройка отображения разделов',
      content: () => {
        return (
          <>
            <ErrorBoundary>
              <SettingDisplaySectionsBranchStep
                branchId={createdBranchId}
                setLoading={(val) => setLoading(val)}
              />
            </ErrorBoundary>
          </>
        );
      },
      disabled: !createdBranchId,
    },
  ];

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const nextStep = () => {
    if (currentStep === 0 && !props.editingBranchItem && !createdBranchId) {
      basicInformationForm.validateFields().then(async (values) => {
        const body = { ...values };
        await createBranch(body);
      });
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const onCloseModal = () => {
    setCurrentStep(0);
    basicInformationForm.resetFields();
    setCreatedBranchId(null);
    props.closeModal();
  };

  const createBranch = async (body) => {
    try {
      const url = `${restApiHost}/accounts/branches/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        false,
      );

      if (!json?.data) return;

      setCreatedBranchId(json?.data?.id);
      setCurrentStep(currentStep + 1);
      message.success('Филиал успешно создан!');
      await handleRefreshBranch(json?.data?.id);
    } catch (e) {
      console.log('create branch err', e);
      message.error('Ошибка запроса для создания филиала');
    }
  };

  const editBranch = async (body, branchId) => {
    try {
      const url = `${restApiHost}/accounts/branches/${branchId}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json?.data) return;

      setCreatedBranchId(json?.data?.id);
      message.success('Филиал успешно отредатирован!');
      await handleRefreshBranch(branchId);
    } catch (e) {
      console.log('create branch err', e);
      message.error('Ошибка запроса для создания филиала');
    }
  };

  const getBranchFromId = async (branchId) => {
    setLoading(true);
    try {
      const url = `${restApiHost}/accounts/branches/${branchId}/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json) {
        return json;
      }
    } catch (e) {
      console.log('create branch err', e);
      message.error('Ошибка запроса на получение филиала');
    } finally {
      setLoading(false);
    }
  };

  const handleEditBranch = async () => {
    basicInformationForm.validateFields().then(async (values) => {
      const body = { ...values };
      await editBranch(body, createdBranchId);
    });
  };

  const handleRefreshBranch = async (branchId) => {
    const branchData = await getBranchFromId(branchId);
    if (branchData?.data) {
      basicInformationForm.setFieldsValue({
        ...branchData.data,
        city: branchData.data?.city?.id ? branchData.data.city.id : null,
      });
    }
  };

  return (
    <Modal
      style={{ zIndex: 100 }}
      visible={props.visibleModal}
      width={1000}
      title={
        props.editingBranchItem ? 'Редактирование филиала' : 'Создание филила'
      }
      onOk={() => {}}
      onCancel={() => {
        onCloseModal();
      }}
      footer={[
        <div
          key={'steps-action'}
          className="steps-action"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prevStep()}>
              Назад
            </Button>
          )}
          {currentStep === 0 && createdBranchId && (
            <Button
              style={{ marginRight: '10px' }}
              onClick={async () => {
                await handleEditBranch();
              }}
            >
              Сохранить
            </Button>
          )}
          {currentStep < mainSteps.length - 1 && (
            <Button type="primary" onClick={() => nextStep()}>
              Далее
            </Button>
          )}
          {currentStep === mainSteps.length - 1 && (
            <Button
              type="primary"
              onClick={() => {
                onCloseModal();
              }}
            >
              Закрыть
            </Button>
          )}
        </div>,
      ]}
    >
      <Spin spinning={loading}>
        <>
          <Steps
            size="small"
            progressDot={progressDotPopover}
            current={currentStep}
            onChange={(current) => setCurrentStep(current)}
          >
            {mainSteps.map((item) => (
              <Step //status={getStatus(idx, errors)}
                key={item?.title}
                title={item?.title}
                disabled={item?.disabled}
              />
            ))}
          </Steps>
          <div className="steps-content" style={{ marginTop: '10px' }}>
            {mainSteps[currentStep]?.content() ? (
              mainSteps[currentStep]?.content()
            ) : (
              <></>
            )}
          </div>
        </>
      </Spin>
    </Modal>
  );
};

export default BranchesMainModal;

BranchesMainModal.propTypes = {
  visibleModal: PropTypes.bool,
  editingBranchItem: PropTypes.any,
  closeModal: PropTypes.func,
  createBranch: PropTypes.func,
};
