import React, { useState, useContext, useCallback } from 'react';
import {
  Table,
  Modal,
  Steps,
  Form,
  Button,
  message,
  Tooltip,
  Space,
  Dropdown,
  Checkbox,
  Menu,
  Popconfirm,
} from 'antd';
import { restApiHost } from '../../../utils/appVariables';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import AddTransport from '../../tasks/transportation/new/AddTransport';
import AddCargoFormTask from '../../tasks/transportation/AddCargoFormTask';
import { userInformations } from '../../../contex';
import { call } from '../../../apiUtils/call';
import { ErrorBoundary } from '../../ErrorBoundary';
import {
  deletePreTaskCargo,
  getPreTaskTransportCargo,
} from '../../../logic/preTasks/PreTaskApiFunctions';
import { dateStrRevers } from '../../../utils/dateStrRevers';
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import TransportationEdit from './TransportationEdit';
import useAsyncEffect from 'use-async-effect';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../../mobile/scrollingLoadedList/ScrollingLoadedList';
import TransportationListItem from '../../mobile/preTasks/modal/TransportationListItem';

const { Step } = Steps;

const Transportation = ({
  task,
  waypoints,
  taskEdit,
  createdCargo,
  transportListOptions,
  onSearchTransportOptions,
  totalTransportation,
}) => {
  const [addCargoForm] = Form.useForm();
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [currentAddStep, setCurrentAddStep] = useState(0);
  const [disableNextBtn, setDisableNextBtn] = useState(true);
  const [disableSelect, setDisableSelect] = useState(false);
  const { userInfo } = useContext(userInformations);
  const [loading, setLoading] = useState(false);
  const [transportationList, setTransportationList] = useState([]);
  const [editCargo, setEditCargo] = useState(null);
  const [visibleEditTransportation, setVisibleEditTransportation] =
    useState(false);
  const [cargos] = useState([]);
  const [disableTransportStep, setDisableTransportStep] = useState(false);

  //transport
  const [createdTransport, setCreatedTransport] = useState(null);
  const [transportValues, setTransportValues] = useState(null);
  const [cargoDetailsValues, setCargoDetailsValues] = useState(null);

  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    await callGetTransportation(task);
  }, []);

  const handleSearchTransportOptions = useCallback(
    (keyword) => {
      onSearchTransportOptions(keyword);
    },
    [onSearchTransportOptions],
  );

  const addModalSteps = [
    {
      title: 'Транспорт',
      disable: disableTransportStep,
      content: () => {
        return (
          <>
            <ErrorBoundary>
              <AddTransport
                data={transportValues}
                handleFormChange={handleFormTransportChange}
                waypoints={waypoints}
                options={transportListOptions}
                onSearchTransportOptions={(keyword) => {
                  handleSearchTransportOptions(keyword);
                }}
                disableSelect={disableSelect}
              />
            </ErrorBoundary>
          </>
        );
      },
    },
    {
      title: 'Груз',
      content: () => {
        return (
          <ErrorBoundary>
            <AddCargoFormTask
              form={addCargoForm}
              createdCargo={createdCargo}
              cargoDetailsValues={cargoDetailsValues}
              handleChangeForm={handleChangeCargoForm}
              taskEdit={taskEdit}
              task={task}
            />
          </ErrorBoundary>
        );
      },
    },
  ];

  const callGetTransportation = useCallback(
    async (taskId) => {
      setLoading(true);
      const res = await getPreTaskTransportCargo(taskId, userInfo);
      if (res?.data) {
        setTransportationList(res.data);
      }
      setLoading(false);
    },
    [getPreTaskTransportCargo, setTransportationList, setLoading],
  );

  const handleOpenModalAddTransport = useCallback(() => {
    if (totalTransportation !== 0) {
      message.warning(
        'Перевозка уже добавлена. Перевозка может быть только одна',
      );
    } else {
      setVisibleAddModal(true);
    }
  }, [totalTransportation, setVisibleAddModal]);

  const resetVariables = useCallback(() => {
    setDisableTransportStep(false);
    setCreatedTransport(null);
    setCurrentAddStep(0);
    setDisableNextBtn(true);
    setTransportValues(null);
    setDisableSelect(false);
  }, [
    setCreatedTransport,
    setCurrentAddStep,
    setDisableNextBtn,
    setTransportValues,
    setDisableSelect,
  ]);

  const handleCloseModal = useCallback(async () => {
    setVisibleAddModal(false);
    resetVariables();
    await callGetTransportation(task);
  }, [setVisibleAddModal, task, callGetTransportation]);

  const addTransportToTask = useCallback(
    async (body) => {
      try {
        const url = `${restApiHost}/leads/pre_transport_cargo_task/`;
        const json = await call(
          url,
          {
            method: 'POST',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          },
          userInfo.current.haveViewBranch,
        );
        if (!json?.data) return;

        await handleCloseModal();

        message.success('Перевозка успешно добавлена к заявке!');
      } catch (e) {
        console.log(e);
      }
    },
    [handleCloseModal],
  );

  const addTransport = useCallback(
    async (body) => {
      try {
        const url = `${restApiHost}/leads/pre_transport/`;
        const json = await call(
          url,
          {
            method: 'POST',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          },
          userInfo.current.haveViewBranch,
        );

        if (!json?.data) return;
        setCreatedTransport(json?.data);
        setCurrentAddStep(currentAddStep + 1);
        setDisableSelect(true);
        message.success('Транспорт успешно добавлен!');
      } catch (e) {
        console.log('add transport err', e);
        message.error('Ошибка запроса на добавление транспорта');
      }
    },
    [setCreatedTransport, setCurrentAddStep, setDisableSelect],
  );

  const handleChangeAddStep = useCallback(
    (current) => {
      setCurrentAddStep(current);
    },
    [setCurrentAddStep],
  );

  const handleChangeCargoForm = useCallback(() => {
    setDisableTransportStep(true);
    setTimeout(() => {
      addCargoForm
        .validateFields()
        .then((values) => {
          setDisableNextBtn(false);
          setCargoDetailsValues(values);
        })
        .catch(() => setDisableNextBtn(true));
    }, 0);
  }, [
    addCargoForm,
    setDisableNextBtn,
    setCargoDetailsValues,
    setDisableNextBtn,
  ]);

  const handleFormTransportChange = useCallback(
    (form) => {
      form
        .validateFields()
        .then((values) => {
          setDisableNextBtn(false);
          setTransportValues({ ...transportValues, ...values });
        })
        .catch((e) => {
          setDisableNextBtn(true);
          console.log(e);
        });
    },
    [setDisableNextBtn, setTransportValues, transportValues],
  );

  const handleDeleteCargo = useCallback(
    async (id) => {
      await deletePreTaskCargo(id, userInfo);
      await callGetTransportation(task);
    },
    [deletePreTaskCargo, task],
  );

  const menuGruz = (row) => {
    return (
      <Menu style={{ width: 160 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => {
            handleEdit(row);
          }}
        >
          Редактировать
        </Menu.Item>

        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={async () => await handleDeleteCargo(row.id)}
          onCancel={() => console.log('Delete item')}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const columnsGruz = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (row) => (
        <Space size="middle">
          <Dropdown overlay={menuGruz(row)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Транспортная единица',
      className: 'test-test',
      children: [
        {
          title: 'Тип',
          key: 'type_transport',
          dataIndex: 'transport',
          width: 220,
          render: (transport) => transport?.transport_unit?.type_full,
        },
        {
          title: 'Номер',
          key: 'number_container',
          dataIndex: 'transport',
          width: 150,
          render: (transport) => transport?.number_container,
        },
      ],
    },
    {
      title: 'Груз',
      children: [
        {
          title: 'Наименование',
          key: 'cargo_name',
          width: 180,
          dataIndex: 'task',
          ellipsis: {
            showTitle: true,
          },
          render: (task) =>
            task?.cargo?.name ? (
              <Tooltip title={task.cargo.name} placement={'topLeft'}>
                {task.cargo.name}
              </Tooltip>
            ) : (
              ''
            ),
        },
        {
          title: 'Код',
          key: 'code',
          width: 100,
          dataIndex: 'task',
          render: (task) =>
            task?.cargo?.code ? (
              <Tooltip title={task.cargo.code} placement={'topLeft'}>
                {task.cargo.code}
              </Tooltip>
            ) : (
              ''
            ),
        },
        {
          title: 'Охрана',
          dataIndex: 'cargo_security',
          key: 'code',
          width: 100,
          render: (cargo_security) =>
            cargo_security ? (
              <Checkbox disabled={true} checked={true} />
            ) : (
              <Checkbox disabled={true} checked={false} />
            ),
        },
      ],
    },
    {
      title: 'Упаковка',
      width: 180,
      ellipsis: {
        showTitle: false,
      },
      render: (data) => data?.cargo_type_packing?.name,
    },
    {
      title: 'Мест',
      width: 100,
      render: (data) => data?.cargo_places,
    },
    {
      title: 'Вес(кг)',
      width: 100,
      render: (data) => data?.cargo_weight,
    },
  ];

  function handleEdit(record) {
    setEditCargo({
      ...record,
      transport: {
        ...record.transport,
        date_plan: record?.transport?.date_plan
          ? `${dateStrRevers(record.transport.date_plan.split('T')[0])}T${
              record.transport.date_plan.split('T')[1]
            }`
          : null,
      },
    });
    setVisibleEditTransportation(true);
  }

  return (
    <>
      <div className={'top-table-actions'}>
        <Tooltip placement={'topLeft'} title={'Добавить'}>
          <Button
            size={'small'}
            icon={<PlusOutlined />}
            onClick={() => handleOpenModalAddTransport()}
            type="primary"
          />
        </Tooltip>
      </div>

      <Modal
        visible={visibleAddModal}
        title="Добавление перевозок"
        onOk={() => handleCloseModal()}
        onCancel={() => handleCloseModal()}
        footer={[
          <Button key="back" onClick={() => handleCloseModal()}>
            Закрыть
          </Button>,
          <>
            {currentAddStep === 0 && !createdTransport ? (
              <Button
                key="submit"
                type="primary"
                disabled={disableNextBtn}
                onClick={() => {
                  transportValues && addTransport(transportValues);
                }}
              >
                Продолжить
              </Button>
            ) : null}
          </>,
          <>
            {currentAddStep === 1 && createdTransport ? (
              <Button
                disabled={disableTransportStep}
                key="submit"
                type="primary"
                onClick={() => {
                  setCurrentAddStep(currentAddStep - 1);
                }}
              >
                Назад
              </Button>
            ) : null}
          </>,
          <>
            {currentAddStep === 1 && createdTransport ? (
              <Button
                key="submit"
                type="primary"
                disabled={disableNextBtn}
                onClick={async () => {
                  let body = {
                    ...cargoDetailsValues,
                    task,
                    transport: createdTransport.id,
                  };
                  await addTransportToTask(body);
                  await handleCloseModal();
                }}
              >
                Сохранить
              </Button>
            ) : null}
          </>,
        ]}
      >
        <Steps
          style={{ marginBottom: '20px' }}
          current={currentAddStep}
          onChange={handleChangeAddStep}
        >
          {addModalSteps.map((step, index) => (
            <Step
              key={index}
              disabled={
                (step.title === 'Груз' && !createdTransport) || step?.disable
              }
              title={step.title}
            />
          ))}
        </Steps>
        {addModalSteps[currentAddStep]?.content()}
      </Modal>

      <ErrorBoundary>
        <TransportationEdit
          cargos={cargos}
          taskEdit={taskEdit}
          refreshData={async () => await callGetTransportation(task)}
          waypoints={waypoints}
          options={transportListOptions}
          onSearchTransportOptions={(keyword) => {
            handleSearchTransportOptions(keyword);
          }}
          task={task}
          cargo={editCargo}
          createdCargo={createdCargo}
          visible={visibleEditTransportation}
          handleChangeVisible={(visible) =>
            setVisibleEditTransportation(visible)
          }
        />
      </ErrorBoundary>

      {!isMobile ? (
        <Table
          scroll={{ x: 850 }}
          loading={loading}
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'black-text table-row-color'
              : 'black-text table-row-white'
          }
          size="small"
          bordered={true}
          pagination={{ position: ['none'] }}
          dataSource={transportationList}
          columns={columnsGruz}
          rowKey={(row) => row.id}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleEdit(record);
              },
            };
          }}
        />
      ) : (
        <div style={{ paddingTop: '5px' }}>
          <ErrorBoundary>
            <ScrollingLoadedList
              dataList={transportationList}
              totalData={transportationList?.length}
              loadMoreItems={async () => {
                //handleUpdate(page + 1, filters, false,true)
              }}
              renderListItem={(item) => (
                <TransportationListItem
                  transport={item}
                  menuActions={(transport) => menuGruz(transport)}
                />
              )}
            />
          </ErrorBoundary>
        </div>
      )}
    </>
  );
};

export default Transportation;
