import React, { useCallback, useContext, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { restApiHost } from '../utils/appVariables';
import StaffListToolbar from '../components/staff/StaffListToolbar';
import StaffTable from '../components/staff/StaffTable';
import { message } from 'antd';
import { userInformations } from '../contex';
import { call } from '../apiUtils/call';
import { convertObjFilterToUrl } from '../utils/getFilters';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import formationDocumentTitle from '../utils/formationDocumentTitle';
import useAsyncEffect from 'use-async-effect';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

const Staff = () => {
  const [staffList, setStaffList] = useState([]);
  const [loadingStaffList, setLoadingStaffList] = useState(false);
  const { userInfo } = useContext(userInformations);
  const [filters, setFilters] = useState(null);
  const [page, setPage] = useState(1);
  const [visibleFilters, setVisibleFilters] = useState(false);
  const [visibleTableSetting, setVisibleTableSetting] = useState(false);
  const [totalStaffList, setTotalStaffList] = useState(0);
  const [branchList, setBranchList] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );
  const isMobile = useCheckMobileScreen();
  const scrollingLoadedListRef = useRef(null);

  useAsyncEffect(async () => {
    await handleUpdate(1, null, false);
  }, []);

  const getStaffList = async (page = 1, params, me, concatData = false) => {
    setLoadingStaffList(true);
    let urlParams;

    if (params) {
      urlParams = convertObjFilterToUrl(params, null);
    }
    try {
      const url = urlParams
        ? `${restApiHost}/accounts/staff_users/?page=${page}${urlParams}`
        : `${restApiHost}/accounts/staff_users/?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
        true,
      );

      if (!json) return;

      concatData
        ? setStaffList((prevState) => prevState.concat(json?.data))
        : setStaffList(json?.data);
      json?.count !== null &&
        json?.count !== undefined &&
        setTotalStaffList(json.count);
      setLoadingStaffList(false);
    } catch (e) {
      console.log('fetch profile info error', e);
      setLoadingStaffList(false);
    }
  };

  // const getDepartments = async () => {
  //     try {
  //         const url = `${restApiHost}/accounts/departments/`
  //         const res = await fetch(url, {
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 'Authorization': `Token ${localStorage.getItem('token')}`
  //             },
  //         })
  //         const json = await res.json()
  //         setDepartments(json.results)
  //     }catch (e) {
  //         console.log('get departments error')
  //     }
  // }

  const handleUpdate = async (page = 1, params, me, concatData) => {
    setPage(page);
    setFilters(params);
    await getStaffList(page, params, me, concatData);
  };

  const getBranchList = useCallback(async () => {
    if (!userInfo.current?.haveViewBranch) {
      setBranchList([]);
      return;
    }
    try {
      const url = `${restApiHost}/accounts/branches/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;
      setBranchList(json?.data ? json.data : []);
    } catch (e) {
      console.log('get branch list err', e);
      message.error('Ошибка запроса на получение списка филиалов');
    }
  }, [userInfo.current?.haveViewBranch]);

  const handleScrollToTopMobileList = useCallback(() => {
    if (scrollingLoadedListRef.current?.scrollTop) {
      scrollingLoadedListRef.current.scrollTop = 0;
    }
  }, [scrollingLoadedListRef]);

  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle('Сотрудники | КвикТранс', notificationCount)}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ErrorBoundary>
            <StaffListToolbar
              handleUpdate={async (page, params) => {
                isMobile && handleScrollToTopMobileList();
                await handleUpdate(isMobile ? 1 : page, params);
              }}
              tableFilters={filters}
              page={page}
              loading={loadingStaffList}
              data={staffList}
              totalStaff={totalStaffList}
              activeTab={activeTab}
              handleScrollToTopMobileList={handleScrollToTopMobileList}
              changeVisibleFilters={async () => {
                setVisibleFilters(!visibleFilters);
                if (visibleFilters) {
                  await handleUpdate(1, null, false, false);
                }
              }}
              changeVisibleTableSetting={() =>
                setVisibleTableSetting(!visibleTableSetting)
              }
              branchList={branchList}
              handleGetBranchList={() => getBranchList()}
            />
          </ErrorBoundary>
          <Box>
            <ErrorBoundary>
              <StaffTable
                tableFilters={filters}
                totalStaff={totalStaffList}
                handleUpdate={handleUpdate}
                loading={loadingStaffList}
                page={page}
                setLoading={(val) => setLoadingStaffList(val)}
                visibleFilters={visibleFilters}
                setVisibleFilters={(visible) => {
                  setVisibleFilters(visible);
                }}
                scrollingLoadedListRef={scrollingLoadedListRef}
                handleScrollToTopMobileList={handleScrollToTopMobileList}
                visibleTableSetting={visibleTableSetting}
                activeTab={activeTab}
                setActiveTab={(tabVal) => setActiveTab(tabVal)}
                onCloseDrawerTableSetting={() => setVisibleTableSetting(false)}
                data={staffList}
                branchList={branchList}
                handleGetBranchList={async () => await getBranchList()}
              />
            </ErrorBoundary>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Staff;
