import { Radio } from 'antd';
import PropTypes from 'prop-types';

const MainTabSwitcher = (props) => {
  return (
    <>
      <Radio.Group
        className={'SwitchersWrapper'}
        value={props.value}
        size="medium"
        onChange={(e) => props.changeValue(e.target.value)}
      >
        <Radio.Button
          style={{ textAlign: 'center' }}
          className={'MainTabSwitcher-RadioBtn MainTabSwitcher-RadioBtn-Right'}
          value="day"
        >
          День
        </Radio.Button>
        <Radio.Button
          style={{ textAlign: 'center' }}
          className={'MainTabSwitcher-RadioBtn'}
          value="week"
        >
          Неделя
        </Radio.Button>
        <Radio.Button
          style={{ textAlign: 'center' }}
          className={'MainTabSwitcher-RadioBtn MainTabSwitcher-RadioBtn-Left'}
          value="month"
        >
          Месяц
        </Radio.Button>
        {/* <Radio.Button className={'MainTabSwitcher-RadioBtn-Left'} value="kanban">Канбан</Radio.Button>*/}
      </Radio.Group>
    </>
  );
};

MainTabSwitcher.propTypes = {
  value: PropTypes.string,
  changeValue: PropTypes.func,
};
export default MainTabSwitcher;
