import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form,
  Input,
  Radio,
  Rate,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { CheckOutlined, FireOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import {
  getListTags,
  searchListTags,
} from '../../../../../../logic/refbook/calendarTags/CalendarTagsApiFunctions';
import { userInformations } from '../../../../../../contex';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import useCheckMobileScreen from '../../../../../../hooks/useCheckMobileScreen';
const { Option } = Select;

const EditForm = (props) => {
  const statusesTask = [
    {
      id: 1,
      name: 'Поставлена',
      color: '#3d66ee',
      value: 'ass',
      option: <>{'Поставлена'}</>,
    },
    {
      id: 2,
      name: 'Выполнена',
      color: '#4eee3d',
      value: 'don',
      option: <>{'Выполнена'}</>,
    },
    {
      id: 3,
      name: 'Не выполнена',
      color: '#ff0000',
      value: 'unc',
      option: <>{'Не выполнена'}</>,
    },
  ];
  const { userInfo } = useContext(userInformations);
  const [taskTags, setTaskTags] = useState([]);
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    await callGetTagsList();
  }, []);

  const callGetTagsList = async () => {
    const tags = await getListTags(userInfo);
    setTaskTags(tags?.data ? tags.data : []);
  };

  const callSearchTags = async (keyword) => {
    if (!keyword) return;
    const tags = await searchListTags(keyword, userInfo);
    setTaskTags(tags?.data ? tags.data : []);
  };
  return (
    <>
      <Col style={{ color: 'white' }}>
        <Row justify={'space-between'}>
          <Col span={isMobile ? '' : 22} style={{ fontSize: '16px' }}>
            <Form.Item
              label={'Название'}
              name={'name'}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <Input placeholder={'Название'} />
            </Form.Item>
          </Col>
          {isMobile ? (
            <></>
          ) : (
            <>
              <Col span={2}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Tooltip title="Сохранить">
                    <Button
                      onClick={() => {
                        props.callCreateEvent();
                      }}
                      shape="circle"
                      icon={<CheckOutlined />}
                    />
                  </Tooltip>
                </div>
              </Col>
            </>
          )}
        </Row>
        <Row className={'gutter-top'}>
          <Col span={isMobile ? '' : 22}>
            <Form.Item label={'Тэги задачи'} name={'tags'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                disabled={props.isCallToLeadOrCustomer}
                placeholder={'Тэги задачи'}
                onSelect={async (value) => {
                  if (props.editingItem) {
                    props.onCreateEventTag(value);
                  }
                  await callGetTagsList();
                }}
                onDeselect={async (value) => {
                  if (props.editingItem) {
                    props.onDeleteEventTag(value);
                  }
                  await callGetTagsList();
                }}
                allowClear={false}
                onSearch={async (val) => {
                  if (val?.length > 0) {
                    await callSearchTags(val);
                  } else {
                    await callGetTagsList();
                  }
                }}
                filterOption={false}
                showSearch
                mode={'multiple'}
                maxTagCount={'responsive'}
              >
                {taskTags.length > 0 ? (
                  <>
                    {taskTags.map((item, index) => (
                      <Option key={index} value={item?.id}>
                        {item?.name}
                      </Option>
                    ))}
                  </>
                ) : (
                  <Option disabled value={null}>
                    Нет доступных вариантов
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row className={'gutter-top'}>
          <Col span={12} style={isMobile ? { fontSize: 17 } : { fontSize: 16 }}>
            <span style={isMobile ? { fontWeight: 600 } : {}}>От кого:</span>
            <p style={isMobile ? { margin: '10px 0 0 ' } : { margin: 0 }}>
              {props.editingItem
                ? props.form.getFieldsValue(['memberCreator'])?.memberCreator ||
                  'Система'
                : userInfo.current.full_name}
            </p>
          </Col>
          <Col span={12}>
            <div className={'EditForm-priority-field'}>
              {isMobile ? (
                <>
                  {' '}
                  <span
                    style={isMobile ? { fontWeight: 600, fontSize: 17 } : {}}
                  >
                    Приоритет
                  </span>
                </>
              ) : (
                <></>
              )}
              <Form.Item name={'priority'}>
                <Rate
                  character={<FireOutlined />}
                  allowHalf={false}
                  count={3}
                  style={{
                    fontSize: 25,
                  }}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row className={'gutter-top'}>
          <Col span={5}>
            {isMobile ? (
              <>
                <Radio.Group
                  className={'EditForm-task-status-switcher'}
                  name={'status'}
                  size="small"
                  defaultValue={'ass'}
                >
                  {statusesTask.length > 0 ? (
                    <>
                      {statusesTask.map((item, index) => (
                        <Radio.Button
                          key={index}
                          style={{ textAlign: 'center' }}
                          className={
                            'MainTabSwitcher-RadioBtn MainTabSwitcher-RadioBtn-Right'
                          }
                          value={item?.value}
                        >
                          {item?.option}
                        </Radio.Button>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Radio.Group>
              </>
            ) : (
              <Form.Item
                label={'Статус'}
                name={'status'}
                className={'EditForm-task-status'}
              >
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  placeholder={'Статус'}
                  allowClear={false}
                  defaultValue={'ass'}
                >
                  {statusesTask.length > 0 ? (
                    <>
                      {statusesTask.map((item, index) => (
                        <Option
                          key={index}
                          value={item?.value}
                          style={{ backgroundColor: item?.color }}
                        >
                          <Tooltip title={item?.name}>
                            <div
                              className={'task-status-option'}
                              style={{
                                backgroundColor: item?.color,
                                color:
                                  item?.value !== 'my' ? '#ffffff' : 'black',
                              }}
                            >
                              {item?.option}
                            </div>
                          </Tooltip>
                        </Option>
                      ))}
                    </>
                  ) : (
                    <Option disabled>Нет доступных вариантов</Option>
                  )}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>

        {!props.editingItem?.chat ? (
          <Row className={'Chat-CreateChatButton-wrapper-row'}>
            <Button
              className={'Chat-CreateChatButton'}
              onClick={() => {
                props.createChat();
              }}
              disabled={!props.editingItem}
              type="default"
              shape="round"
              icon={<PlusOutlined />}
              size={'middle'}
            >
              Создать чат
            </Button>
          </Row>
        ) : (
          ''
        )}
      </Col>
    </>
  );
};

EditForm.propTypes = {
  form: PropTypes.any,
  editingItem: PropTypes.object,
  onCreateEventTag: PropTypes.func,
  onDeleteEventTag: PropTypes.func,
  setEditFields: PropTypes.func,
  createChat: PropTypes.func.isRequired,
  disableBtnCreateChat: PropTypes.bool,
  chatIsCreated: PropTypes.bool.isRequired,
};

export default EditForm;
