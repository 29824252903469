import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Checkbox,
  ConfigProvider,
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { restApiHost } from '../../utils/appVariables';
import ContainerOutlined from '@ant-design/icons/lib/icons/ContainerOutlined';
import PreApplicationModal from '../pre-applications/PreApplicationModal';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import CreateCustomerModal from '../CreateCustomer/CreateCustomerModal';
import MenuUnfoldOutlined from '@ant-design/icons/lib/icons/MenuUnfoldOutlined';
import MenuFoldOutlined from '@ant-design/icons/lib/icons/MenuFoldOutlined';
import { getFiltersTable } from '../../utils/getFilters';
import { dateStrRevers } from '../../utils/dateStrRevers';
import { useNavigate } from 'react-router-dom';
import FilterByStaff from './filters/FilterByStaff';
import moment from 'moment';
import 'moment/locale/ru';
import locale from 'antd/es/locale/ru_RU';
import { userInformations } from '../../contex';
import SendKpOrPresentationModal from '../sendKpOrPresentation/SendKpOrPresentationModal';
import { call } from '../../apiUtils/call';
import FilterDatePicker from './filters/FilterDatePicker';
import SearchDataFilter from './filters/SearchData';
import { setPaginationSettings } from '../../localStorageUtils/paginationSettings';
import convertToSelectOptions from './filters/filtersUtils/convertToSelectOptions';
import FilterSelect from './filters/FilterSelect';
import { ErrorBoundary } from '../ErrorBoundary';
import SettingTableColumnsDrawer from '../settingTable/SettingTableColumnsDrawer';
import {
  prepareColumnsForResizing,
  sortingColumnsTable,
} from '../settingTable/sortingColumnsTable';
import { callGetProfileInfo } from '../account/callGetProfileInfo';
import getCallResult from '../../logic/refbook/callResult/CallResultApiFunction';
import prepareObjectForFilter from './filters/filtersUtils/prepareObjectForFilter';
import LeadModal from './modal/mainModal/LeadModal';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import { getChoicesLead } from '../../logic/lead/LeadApiFunctions';
import useAsyncEffect from 'use-async-effect';
import searchCities from '../../logic/refbook/cities/CitiesApiFunction';
import useStateRef from 'react-usestateref';
import ResizableTableColumnHeader from '../table/ResizableTableColumnHeader';
import changeWidthStyleMainTable from '../table/changeWidthStyleMainTable';
import filterActiveColumns from '../table/utils';
import { editTableColumn } from '../../logic/profileInfo/mainTable/MainTableApiFunctions';
import { getProfileInfo } from '../../logic/account/AccountsApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../mobile/scrollingLoadedList/ScrollingLoadedList';
import LeadListItem from '../mobile/leads/list/LeadListItem';
import { userInfoHaveViewAllBranch } from '../../logic/account/utils';
import UniversalFiltersModal from '../mobile/filtersModal/UniversalFiltersModal';

const LeedsTable = ({
  modalLead,
  loading,
  leads,
  handleUpdate,
  totalLeads,
  page,
  setPage,
  currStatusFilter,
  setLoading,
  setPreTaskLead,
  visibleFilters,
  visibleTableSetting,
  onCloseDrawerTableSetting,
  setVisibleFilters,
  scrollingLoadedListRef,
  handleScrollToTopMobileList,
}) => {
  const tableName = 'leads';
  const navigate = useNavigate();
  const [visibleModalEditLead, setVisibleModalEditLead] = useState(false);
  const [currentLead, setCurrentLead] = useState(null);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [loadingStaffList, setLoadingStaffList] = useState(false);
  const [staffList, setStaffList] = useState(null);
  const savingSum = useRef(false);
  const savingSumWereEditing = useRef(false);
  const [sumsWereEdited, setSumsWereEdited] = useState(false);
  const [visitSumsClient, setVisitSumsClient] = useState(false);
  const [disableBtnAtSaveSums, setDisableBtnAtSaveSums] = useState(false);
  const [loadingLead, setLoadingLead] = useState(false);
  const [visibleCustomerModal, setVisibleCustomerModal] = useState(false);
  const [callResultOptions, setCallResultOptions] = useState([]);
  const [choices, setChoices] = useState([]);
  const [cities, setCities] = useState([]);
  //hide action btns
  const [expanded, setExpanded] = useState(false);

  // update task info
  const [taskData, setTaskData] = useState(null);

  // pre-applications
  const [visibleTasksModal, setVisibleTasksModal] = useState(false);
  const [preTasksList] = useState([]);
  const [filters, setFilters, filtersRef] = useStateRef(null);

  const { userInfo } = useContext(userInformations);

  const [
    ,
    setColumnFiltersFromTableComponent,
    columnFiltersFromTableComponentRef,
  ] = useStateRef([]);

  const [baseColumns, setBaseColumns, baseColumnsRef] = useStateRef([]);

  const demoAccessIsUsed = useTypedSelector(
    (state) => state.demoAccess.demoAccessIsUsed,
  );

  const mainTableRef = useRef(null);
  const isMobile = useCheckMobileScreen();

  const ourBranch = useMemo(() => {
    const branchId = userInfo.current?.haveViewBranch
      ? parseInt(localStorage.getItem('branchId'))
      : userInfo.current?.branch?.id;
    return branchId === 1;
  }, [userInfo]);

  const franchiseeOptions = [
    {
      title: 'Франчайзи',
      id: true,
    },
    {
      title: 'Не франчайзи',
      id: false,
    },
  ];

  const colorsLead = [
    {
      title: 'Синий',
      id: 'blue',
    },
    {
      title: 'Красный',
      id: 'red',
    },
    {
      title: 'Жёлтый',
      id: 'yellow',
    },
  ];

  const callResultsOptions = useMemo(() => {
    if (callResultOptions?.length > 0) {
      return callResultOptions.map((item) => {
        return {
          title: item?.result,
          id: item?.id,
        };
      });
    }
  }, [callResultOptions]);

  useEffect(() => {
    if (modalLead) {
      openModalLead(modalLead);
    }
  }, [modalLead]);

  useAsyncEffect(async () => {
    if (visibleFilters) {
      await callGetChoices();
      await searchRefbookCities();
      await callGetCallResult();
      await getStaffList();
    }
  }, [visibleFilters]);

  useEffect(() => {
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
  }, [visibleFilters, staffList, cities]);

  useEffect(() => {
    if (currStatusFilter) {
      const newFilters = {
        ...currStatusFilter?.filter,
        filter: {
          ...currStatusFilter?.filter,
          search: currStatusFilter?.filter?.search,
        },
      };
      setFilters(newFilters);
    } else {
      setFilters(null);
      setColumnFiltersFromTableComponent([]);
    }
  }, [currStatusFilter]);

  const openModalLead = (leadData) => {
    setCurrentLead(leadData);
    setVisibleModalEditLead(true);
  };

  const deleteLead = async (id) => {
    try {
      const url = `${restApiHost}/leads/list/${id}/`;
      const json = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json) {
        message.success('Лид успешно удален!');
        if (leads.length === 1 && page !== 1) {
          isMobile && handleScrollToTopMobileList();
          await handleUpdate(page - 1, filtersRef.current, false, false);
        } else {
          isMobile && handleScrollToTopMobileList();
          await handleUpdate(page, filtersRef.current, false, false);
        }
      }
    } catch (e) {
      console.log('lead delete error', e);
      message.error('Ошибка удаления лида');
    }
  };

  const callGetCallResult = useCallback(async () => {
    const res = await getCallResult('lead', userInfo);
    if (res) {
      setCallResultOptions(res);
    }
  }, [setCallResultOptions]);

  const getLead = async (id) => {
    setLoadingLead(true);
    try {
      const url = `${restApiHost}/leads/list/${id}/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (!json) return;
      setCurrentLead(json?.data);
      setLoadingLead(false);
    } catch (e) {
      console.log('get lead err', e);
    } finally {
      setLoadingLead(false);
    }
  };

  const closeVisibleModal = () => {
    setVisibleTasksModal(false);
  };

  const rework = async (id) => {
    try {
      const url = `${restApiHost}/leads/status/re_work/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ lead: id }),
        },
        userInfo.current.haveViewBranch,
      );
      if (json) {
        message.success('Операция прошла успешно');
        isMobile && handleScrollToTopMobileList();
        await handleUpdate(
          isMobile ? 1 : page,
          filtersRef.current,
          false,
          isMobile,
        );
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса');
    }
  };

  const handleGetPreTaskForSendKp = async (lead) => {
    setLoading(true);
    const hide = message.loading('Поиск пред.заявок лида', 0);
    //await getLead(leadId)
    setCurrentLead(lead);
    setVisibleTasksModal(true);
    hide();
    setLoading(false);
  };

  const getMenuItemFromStatus = (status, data) => {
    switch (status) {
      case 'clo':
      case 'arc':
        return (
          <Menu.Item
            disabled={demoAccessIsUsed}
            icon={<ContainerOutlined />}
            onClick={() => {
              rework(data?.id);
            }}
          >
            В работу
          </Menu.Item>
        );
      default:
        return null;
    }
  };

  function menu(lead) {
    return (
      <Menu style={{ width: 'auto' }}>
        <Menu.Item
          disabled={lead?.status === 'clo'}
          icon={<EditOutlined />}
          onClick={() => {
            openModalLead(lead);
          }}
        >
          Редактировать
        </Menu.Item>

        <Menu.Item
          disabled={lead?.status === 'clo' || demoAccessIsUsed}
          icon={<FormOutlined />}
          onClick={async () => {
            setCurrentLead(lead);
            setVisibleCustomerModal(true);
          }}
        >
          В контрагента
        </Menu.Item>
        <Menu.Item
          disabled={lead?.status === 'clo'}
          icon={<ContainerOutlined />}
          onClick={() => {
            setVisibleDrawer(true);
            setCurrentLead(lead);
            setPreTaskLead(lead);
          }}
        >
          Оформить пред. заявку
        </Menu.Item>

        {getMenuItemFromStatus(lead.status, lead)}

        <Menu.Item
          disabled={lead?.status === 'clo' || demoAccessIsUsed}
          icon={<FilePdfOutlined />}
          onClick={async () => {
            await handleGetPreTaskForSendKp(lead);
          }}
        >
          Отправить КП/Презентацию
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={() => deleteLead(lead?.id)}
          onCancel={() => console.log('Delete item')}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item disabled={demoAccessIsUsed} icon={<DeleteOutlined />}>
            Удалить
          </Menu.Item>
        </Popconfirm>
      </Menu>
    );
  }

  const handleResize = useCallback(
    (index) =>
      async (e, { size }) => {
        const copyColumns = [...baseColumnsRef.current];

        setBaseColumns((columns) => {
          const nextColumns = [...columns];
          const children = nextColumns[index]?.children
            ? // eslint-disable-next-line no-unsafe-optional-chaining
              [...nextColumns[index]?.children]
            : null;
          if (children && children[0]?.width) {
            children[0].width = size;
          }
          nextColumns[index] = {
            ...nextColumns[index],
            width: size,
            children: children ? [...children] : [],
          };
          return nextColumns;
        });

        const databaseColumnsId = copyColumns[index]?.idInDatabase;
        if (!databaseColumnsId) return;

        await editTableColumn(databaseColumnsId, { width: size }, userInfo);
        const res = await getProfileInfo(userInfo);
        if (res) {
          userInfo.current = res;
          const haveViewBranch = userInfoHaveViewAllBranch(res);
          if (haveViewBranch) {
            userInfo.current['haveViewBranch'] = true;
          }
        }
      },
    [baseColumnsRef.current],
  );

  const columns = [
    {
      title: '',
      name: 'Действия',
      key: 'action',
      width: 50,
      align: 'center',
      uniqueIndex: 'action',
      index: 1,
      ellipsis: {
        showTitle: false,
      },
      render: (lead) => (
        <Space size="middle">
          <Dropdown overlay={menu(lead)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 80,
      uniqueIndex: 'id',
      index: 2,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'ID'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={columnFiltersFromTableComponentRef.current?.id}
              columnKey={'id'}
            />
          ),
          visible: visibleFilters,
          key: 'id',
          dataIndex: 'id',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    ourBranch
      ? {
          title: 'Франчайзи',
          key: 'franchise',
          dataIndex: 'franchise',
          width: 120,
          uniqueIndex: 'franchise',
          index: 3,
          ellipsis: {
            showTitle: false,
          },
          render: (franchise) => (
            <Checkbox
              checked={franchise}
              onClick={() => {
                return false;
              }}
            />
          ),
          children: [
            {
              title: (
                <FilterSelect
                  confirm={(filtersTable) =>
                    handleTableChange({ current: 1 }, filtersTable)
                  }
                  loading={loadingStaffList}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current?.franchise
                  }
                  columnKey={'franchise'}
                  useSingleSelect={true}
                  dataList={convertToSelectOptions(
                    franchiseeOptions,
                    'item?.title',
                  )}
                />
              ),
              visible: visibleFilters,
              key: 'franchise',
              dataIndex: 'franchise',
              width: 120,
              ellipsis: {
                showTitle: false,
              },
              render: (franchise) => (
                <Checkbox
                  checked={franchise}
                  onClick={() => {
                    return false;
                  }}
                />
              ),
            },
          ],
        }
      : '',
    {
      title: 'Цвет',
      key: 'color',
      dataIndex: 'color_str',
      width: 150,
      /* if (choices?.color) {
         dataList = Object.entries(choices.color).map((item) => {
           return {
             title: item[1],
             value: item[0],
           };
         });
       }*/
      uniqueIndex: 'color',
      index: 4,
      ellipsis: {
        showTitle: false,
      },
      render: (color) =>
        color && (
          <div
            style={{
              width: '15px',
              height: '15px',
              background: `rgb(${color})`,
            }}
          ></div>
        ),
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={columnFiltersFromTableComponentRef.current?.color}
              columnKey={'color'}
              dataList={convertToSelectOptions(colorsLead, 'item?.title')}
            />
          ),
          key: 'color',
          dataIndex: 'color_str',
          width: 150,
          visible: visibleFilters,
          ellipsis: {
            showTitle: false,
          },
          render: (color) =>
            color && (
              <div
                style={{
                  width: '15px',
                  height: '15px',
                  background: `rgb(${color})`,
                }}
              ></div>
            ),
        },
      ],
    },
    {
      title: 'Юр. имя',
      key: 'company_name__search',
      dataIndex: 'company_name',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'company_name',
      index: 5,
      render: (company) =>
        company && (
          <Tooltip placement="leftBottom" title={company}>
            <div className={'leed-comment'}>{company}</div>
          </Tooltip>
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Юр. имя'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.company_name__search
              }
              columnKey={'company_name__search'}
            />
          ),
          visible: visibleFilters,
          key: 'company_name__search',
          dataIndex: 'company_name',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (company) =>
            company && (
              <Tooltip placement="leftBottom" title={company}>
                <div className={'leed-comment'}>{company}</div>
              </Tooltip>
            ),
        },
      ],
    },
    {
      title: 'ИНН',
      key: 'okpo__search',
      dataIndex: 'okpo',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'okpo',
      index: 6,
      render: (okpo) =>
        okpo && (
          <Tooltip placement="leftBottom" title={okpo}>
            <div className={'leed-comment'}>{okpo}</div>
          </Tooltip>
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'ИНН'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.okpo__search
              }
              columnKey={'okpo__search'}
            />
          ),
          key: 'okpo__search',
          dataIndex: 'okpo',
          width: 150,
          visible: visibleFilters,
          ellipsis: {
            showTitle: false,
          },
          render: (company) =>
            company && (
              <Tooltip placement="leftBottom" title={company}>
                <div className={'leed-comment'}>{company}</div>
              </Tooltip>
            ),
        },
      ],
    },
    {
      title: 'ABC',
      key: 'abc',
      width: 80,
      dataIndex: 'abc_str',
      uniqueIndex: 'abc',
      index: 7,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={columnFiltersFromTableComponentRef.current?.abc}
              columnKey={'abc'}
              dataList={convertToSelectOptions(
                prepareObjectForFilter(choices?.abc),
                'item?.title',
              )}
            />
          ),
          key: 'abc',
          width: 120,
          dataIndex: 'abc_str',
          visible: visibleFilters,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'XYZ',
      key: 'xyz',
      width: 80,
      dataIndex: 'xyz_str',
      uniqueIndex: 'xyz',
      index: 8,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={columnFiltersFromTableComponentRef.current?.xyz}
              columnKey={'xyz'}
              dataList={convertToSelectOptions(
                prepareObjectForFilter(choices?.xyz),
                'item?.title',
              )}
            />
          ),
          key: 'xyz',
          width: 120,
          dataIndex: 'xyz_str',
          visible: visibleFilters,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'Email',
      key: 'email__search',
      width: 250,
      dataIndex: 'email',
      uniqueIndex: 'email',
      index: 9,
      ellipsis: {
        showTitle: false,
      },
      render: (email) =>
        email && (
          <Tooltip placement="leftBottom" title={email}>
            <div className={'leed-comment'}>{email}</div>
          </Tooltip>
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Email'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.email__search
              }
              columnKey={'email__search'}
            />
          ),
          visible: visibleFilters,
          key: 'email__search',
          width: 250,
          dataIndex: 'email',
          ellipsis: {
            showTitle: false,
          },
          render: (email) =>
            email && (
              <Tooltip placement="leftBottom" title={email}>
                <div className={'leed-comment'}>{email}</div>
              </Tooltip>
            ),
        },
      ],
    },
    {
      title: 'Телефон',
      key: 'phone_number__search',
      width: 150,
      dataIndex: 'phone_number',
      uniqueIndex: 'phone_number',
      index: 10,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Телефон'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.phone_number__search
              }
              columnKey={'phone_number__search'}
            />
          ),
          visible: visibleFilters,
          key: 'phone_number__search',
          width: 150,
          dataIndex: 'phone_number',
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'Сайт',
      key: 'website__search',
      dataIndex: 'website',
      width: 230,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'website',
      index: 11,
      render: (website) =>
        website ? (
          <a href={website} target={'_blank'} rel="noreferrer">
            {website}
          </a>
        ) : null,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Сайт'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.website__search
              }
              columnKey={'website__search'}
            />
          ),
          visible: visibleFilters,
          key: 'website__search',
          dataIndex: 'website',
          width: 230,
          ellipsis: {
            showTitle: false,
          },
          render: (website) =>
            website ? (
              <a href={website} target={'_blank'} rel="noreferrer">
                {website}
              </a>
            ) : null,
        },
      ],
    },
    {
      title: 'Статус',
      key: 'status',
      dataIndex: 'status_str',
      width: 230,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'status',
      index: 12,
      render: (status) => {
        return <Tooltip title={status}>{status}</Tooltip>;
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={columnFiltersFromTableComponentRef.current?.status}
              columnKey={'status'}
              dataList={convertToSelectOptions(
                prepareObjectForFilter(choices?.status),
                'item?.title',
              )}
            />
          ),
          key: 'status',
          dataIndex: 'status_str',
          visible: visibleFilters,
          width: 230,
          ellipsis: {
            showTitle: false,
          },
          render: (status) => {
            return <Tooltip title={status}>{status}</Tooltip>;
          },
        },
      ],
    },
    {
      title: 'Привлек',
      key: 'prv',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      dataIndex: 'prv_manager',
      uniqueIndex: 'prv',
      index: 13,
      render: (prv_manager) => {
        return (
          <Tooltip placement={'topLeft'} title={prv_manager}>
            {prv_manager}
          </Tooltip>
        );
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponentRef.current}
              useAdditionalFilterCheckboxOnNull={true}
              columnKey={'prv'}
              selectedKeys={columnFiltersFromTableComponentRef.current?.prv}
              dataList={convertToSelectOptions(
                staffList,
                'item?.full_name || item?.email',
              )}
              useApiSearch={true}
              onSearch={async (e) => {
                if (e) {
                  await searchStaff(e);
                } else {
                  await getStaffList();
                }
              }}
            />
          ),
          visible: visibleFilters,
          key: 'prv',
          width: 300,
          ellipsis: {
            showTitle: false,
          },
          dataIndex: 'prv_manager',
          render: (prv_manager) => {
            return (
              <Tooltip placement={'topLeft'} title={prv_manager}>
                {prv_manager}
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      title: 'Ведет',
      key: 'vdt',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      dataIndex: 'vdt_manager',
      uniqueIndex: 'vdt',
      index: 14,
      render: (vdt_manager) => {
        return (
          <Tooltip placement={'topLeft'} title={vdt_manager}>
            {vdt_manager}
          </Tooltip>
        );
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponentRef.current}
              useAdditionalFilterCheckboxOnNull={true}
              columnKey={'vdt'}
              selectedKeys={columnFiltersFromTableComponentRef.current?.vdt}
              dataList={convertToSelectOptions(
                staffList,
                'item?.full_name || item?.email',
              )}
              useApiSearch={true}
              onSearch={async (e) => {
                if (e) {
                  await searchStaff(e);
                } else {
                  await getStaffList();
                }
              }}
            />
          ),
          visible: visibleFilters,
          key: 'vdt',
          width: 300,
          ellipsis: {
            showTitle: false,
          },
          dataIndex: 'vdt_manager',
          render: (vdt_manager) => {
            return (
              <Tooltip placement={'topLeft'} title={vdt_manager}>
                {vdt_manager}
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      title: 'Ответственный',
      key: 'otv',
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      dataIndex: 'otv_manager',
      uniqueIndex: 'otv',
      index: 15,
      render: (otv_manager) => {
        return (
          <Tooltip placement={'topLeft'} title={otv_manager}>
            {otv_manager}
          </Tooltip>
        );
      },
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              tableFilters={columnFiltersFromTableComponentRef.current}
              useAdditionalFilterCheckboxOnNull={true}
              columnKey={'otv'}
              selectedKeys={columnFiltersFromTableComponentRef.current?.otv}
              dataList={convertToSelectOptions(
                staffList,
                'item?.full_name || item?.email',
              )}
              useApiSearch={true}
              onSearch={async (e) => {
                if (e) {
                  await searchStaff(e);
                } else {
                  await getStaffList();
                }
              }}
            />
          ),
          visible: visibleFilters,
          key: 'otv',
          width: 300,
          ellipsis: {
            showTitle: false,
          },
          dataIndex: 'otv_manager',
          render: (otv_manager) => {
            return (
              <Tooltip placement={'topLeft'} title={otv_manager}>
                {otv_manager}
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      title: 'Город',
      key: 'city',
      dataIndex: 'city',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'city',
      index: 16,
      render: (city) => (
        <Tooltip placement={'topLeft'} title={city?.name || ''}>
          {city?.name || ''}
        </Tooltip>
      ),
      children: [
        {
          title: (
            <FilterByStaff
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              viewSearch={true}
              visibleSelect={false}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={columnFiltersFromTableComponentRef.current?.city}
              columnKey={'city'}
              useAdditionalFilter={false}
              dataList={convertToSelectOptions(cities, 'item?.name')}
              onSearch={async (e) => {
                if (e) {
                  searchRefbookCities(e);
                }
              }}
            />
          ),
          visible: visibleFilters,
          key: 'city',
          ellipsis: {
            showTitle: false,
          },
          dataIndex: 'city',
          width: 200,
          render: (city) => (
            <Tooltip placement={'topLeft'} title={city?.name || ''}>
              {city?.name || ''}
            </Tooltip>
          ),
        },
      ],
    },
    {
      title: 'Итог звонка',
      key: 'call_result',
      dataIndex: 'call_result',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'call_result',
      index: 17,
      render: (call_result) =>
        call_result?.result ? (
          <Tooltip placement={'topLeft'} title={call_result?.result}>
            {call_result?.result}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.call_result
              }
              columnKey={'call_result'}
              dataList={convertToSelectOptions(
                callResultsOptions,
                'item?.title',
              )}
            />
          ),
          key: 'call_result',
          dataIndex: 'call_result',
          width: 200,
          ellipsis: {
            showTitle: false,
          },
          visible: visibleFilters,
          render: (call_result) =>
            call_result?.result ? (
              <Tooltip placement={'topLeft'} title={call_result?.result}>
                {call_result?.result}
              </Tooltip>
            ) : null,
        },
      ],
    },
    {
      title: 'Источник',
      key: 'source_select',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'source_select',
      index: 18,
      render: (data) =>
        data?.source_select == 'oth' ? (
          <Tooltip placement="leftBottom" title={data?.source_other}>
            {data?.source_other}
          </Tooltip>
        ) : data?.source_select ? (
          <Tooltip placement="leftBottom" title={data?.source_str}>
            {data?.source_str}
          </Tooltip>
        ) : null,
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.source_select
              }
              columnKey={'source_select'}
              dataList={convertToSelectOptions(
                prepareObjectForFilter(choices?.source),
                'item?.title',
              )}
            />
          ),
          key: 'source_select',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          visible: visibleFilters,
          render: (data) =>
            data?.source_select === 'oth' ? (
              <Tooltip placement="leftBottom" title={data?.source_other}>
                {data?.source_other}
              </Tooltip>
            ) : data?.source_select ? (
              <Tooltip placement="leftBottom" title={data?.source_str}>
                {data?.source_str}
              </Tooltip>
            ) : null,
        },
      ],
    },
    {
      title: 'Дата создания',
      key: 'date_create__periodDate',
      dataIndex: 'date_create',
      width: 150,
      uniqueIndex: 'date_create',
      index: 19,
      ellipsis: {
        showTitle: false,
      },
      render: (date) => (date ? dateStrRevers(date.split('T')[0]) : null),
      children: [
        {
          title: (
            <FilterDatePicker
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.date_create__periodDate
              }
              columnKey={'date_create__periodDate'}
            />
          ),
          key: 'date_create__periodDate',
          dataIndex: 'date_create',
          width: 150,
          visible: visibleFilters,
          ellipsis: {
            showTitle: false,
          },
          render: (date) => (date ? dateStrRevers(date.split('T')[0]) : null),
        },
      ],
    },
    {
      title: 'Послед. общение',
      key: 'date_recent_request__periodDate',
      width: 200,
      dataIndex: 'date_recent_request',
      uniqueIndex: 'date_recent_request',
      index: 20,
      ellipsis: {
        showTitle: false,
      },
      render: (date_create) =>
        date_create ? dateStrRevers(date_create.split('T')[0]) : null,
      children: [
        {
          title: (
            <FilterDatePicker
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.date_recent_request__periodDate
              }
              columnKey={'date_recent_request__periodDate'}
            />
          ),
          key: 'date_recent_request__periodDate',
          width: 200,
          dataIndex: 'date_recent_request',
          visible: visibleFilters,
          ellipsis: {
            showTitle: false,
          },
        },
      ],
    },
    {
      title: 'След. общение',
      key: 'date_next_request__periodDate',
      width: 200,
      dataIndex: 'date_next_request',
      uniqueIndex: 'date_next_request',
      index: 21,
      ellipsis: {
        showTitle: false,
      },
      render: (date_next_request) =>
        date_next_request ? (
          moment(date_next_request).format('DD-MM-YYYY HH:mm')
        ) : (
          <></>
        ),
      children: [
        {
          title: (
            <FilterDatePicker
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.date_next_request__periodDate
              }
              columnKey={'date_next_request__periodDate'}
            />
          ),
          key: 'date_next_request__periodDate',
          width: 200,
          dataIndex: 'date_next_request',
          visible: visibleFilters,
          ellipsis: {
            showTitle: false,
          },
          render: (date_next_request) =>
            date_next_request ? (
              moment(date_next_request).format('DD-MM-YYYY HH:mm')
            ) : (
              <></>
            ),
        },
      ],
    },
    {
      title: 'Комментарий',
      key: 'comment__search',
      dataIndex: 'comment',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      uniqueIndex: 'comment',
      index: 22,
      render: (comment) => (
        <Tooltip placement="leftBottom" title={comment}>
          <div className={'leed-comment'}>{comment}</div>
        </Tooltip>
      ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Комментарий'}
              confirm={(filtersTable) =>
                handleTableChange({ current: 1 }, filtersTable)
              }
              loading={loadingStaffList}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.comment__search
              }
              columnKey={'comment__search'}
            />
          ),
          visible: visibleFilters,
          key: 'comment__search',
          dataIndex: 'comment',
          width: 200,
          ellipsis: {
            showTitle: false,
          },
          render: (comment) => (
            <Tooltip placement="leftBottom" title={comment}>
              <div className={'leed-comment'}>{comment}</div>
            </Tooltip>
          ),
        },
      ],
    },
  ];

  const updateUserInfo = async (userInfoForCallApi) => {
    setLoading(true);
    const profileInfo = await callGetProfileInfo(userInfoForCallApi);
    const haveViewBranch =
      profileInfo?.data?.permissions.some(
        (o) => o.codename === 'switch_branch',
      ) &&
      profileInfo?.data?.permissions.some((o) => o.codename === 'view_branch');
    if (haveViewBranch) {
      profileInfo['haveViewBranch'] = true;
    }
    userInfo.current = profileInfo;
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
    setLoading(false);
  };

  const filteringColumnsChildrenPillarByVisible = useMemo(() => {
    const copyColumns = [...baseColumns];
    return filterActiveColumns(copyColumns, expanded);
  }, [baseColumns, expanded]);

  const mainTableWidth = useMemo(() => {
    const copyColumns = [...baseColumns];
    let filteredColumns = filterActiveColumns(copyColumns, expanded);
    return filteredColumns.reduce((n, { width }) => n + width, 0);
  }, [baseColumns, expanded]);

  const getMainTableWidth = useCallback(() => {
    return mainTableWidth;
  }, [mainTableWidth]);

  useEffect(() => {
    if (mainTableRef.current.children[0]) {
      changeWidthStyleMainTable(mainTableRef, mainTableWidth);
    }
  }, [mainTableWidth]);

  const searchStaff = useCallback(
    async (keyword) => {
      try {
        setLoadingStaffList(true);
        const url = `${restApiHost}/accounts/staff_users/?search=${keyword}`;
        const json = await call(
          url,
          {
            method: 'GET',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          },
          userInfo.current.haveViewBranch,
        );

        if (json?.data) {
          setStaffList(json?.data);
        }
      } catch (e) {
        console.log(e);
        message.error('Ошибка запроса! Не удалось найти сотрудника');
      } finally {
        setLoadingStaffList(false);
      }
    },
    [setLoadingStaffList, setStaffList],
  );

  const getStaffList = useCallback(async () => {
    try {
      setLoadingStaffList(true);
      const url = `${restApiHost}/accounts/staff_users`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setStaffList(json?.data);
      }
    } catch (e) {
      console.log('fetch profile info error', e);
    } finally {
      setLoadingStaffList(false);
    }
  }, [setLoadingStaffList, setStaffList]);

  const collapseColumn = () => {
    setExpanded(false);
  };

  const expandColumn = () => {
    setExpanded(true);
  };

  const handleTableChange = (pagination, filtersTable, sorter) => {
    const { page, filters } = getFiltersTable(pagination, filtersTable, sorter);
    setColumnFiltersFromTableComponent(filtersTable);
    setPage(page);
    let newFilters = { ...filters };
    if (currStatusFilter?.filter?.search) {
      newFilters = {
        ...filters,
        filter: { ...filters.filter, search: currStatusFilter.filter.search },
      };
    }
    setFilters(newFilters);
    handleUpdate(page, newFilters);
  };

  const handleSendKpApplication = async (application) => {
    setLoading(true);
    const hide = message.loading('Поиск лида', 0);
    if (application?.lead) {
      await getLead(application.lead.id);
    }
    setVisibleTasksModal(true);
    hide();
    setLoading(false);
  };

  const callGetChoices = useCallback(async () => {
    const res = await getChoicesLead(userInfo);
    if (res) {
      setChoices(res);
    }
  }, [setChoices]);

  const searchRefbookCities = useCallback(
    async (keyword = null) => {
      const res = await searchCities(keyword, userInfo);
      if (res) {
        setCities(res);
      }
    },
    [setCities],
  );

  const setSavingSum = () => {
    if (visitSumsClient) {
      setSumsWereEdited(false);
      columnFiltersFromTableComponentRef.current &&
        setColumnFiltersFromTableComponent([]);
      handleUpdate(page, filtersRef.current, true, isMobile);
      setTaskData(null);
      setVisibleDrawer(false);
      setDisableBtnAtSaveSums(false);
      setVisitSumsClient(false);
      savingSum.current = false;
    }
  };
  const setVisitSums = () => {
    setVisitSumsClient(true);
  };
  const saveSumsWhenSwitching = () => {
    savingSumWereEditing.current = true;
    setDisableBtnAtSaveSums(true);
  };
  const endSavingSumsWhenSwitching = () => {
    setSumsWereEdited(false);
    setDisableBtnAtSaveSums(false);
    setVisitSumsClient(false);
    savingSumWereEditing.current = false;
  };

  return (
    <>
      {!isMobile && (
        <div
          style={{
            position: 'sticky',
            top: '-25px',
            zIndex: 1,
          }}
        >
          {expanded ? (
            <Tooltip title={'Показать действия'}>
              <MenuUnfoldOutlined
                style={{
                  zIndex: '1',
                  position: 'absolute',
                  left: '-23px',
                  top: '0px',
                  color: 'var(--primary-color)',
                  backgroundColor: 'var(--accent-color)',
                  borderRadius: '4px',
                  padding: '5px',
                }}
                onClick={() => collapseColumn()}
              />
            </Tooltip>
          ) : (
            <Tooltip title={'Скрыть действия'}>
              <MenuFoldOutlined
                style={{
                  zIndex: '1',
                  position: 'absolute',
                  left: '-23px',
                  top: '0px',
                  color: 'var(--primary-color)',
                  backgroundColor: 'var(--accent-color)',
                  borderRadius: '4px',
                  padding: '5px',
                }}
                onClick={() => expandColumn()}
              />
            </Tooltip>
          )}
        </div>
      )}
      <ConfigProvider locale={locale}>
        <div ref={mainTableRef}>
          {!isMobile ? (
            <Table
              style={{
                position: 'relative',
                zIndex: '0',
              }}
              scroll={{ x: getMainTableWidth() }}
              rowClassName={(record, index) => {
                let className = ['black-text'];
                record?.status === 'arc' && className.push('table-row-archive');
                if (record?.color) {
                  className.push(`table-row-${record?.color}`);
                } else {
                  className = [];
                  if (index % 2 === 0) {
                    className.push('black-text table-row-color');
                  } else {
                    className.push('black-text table-row-white');
                  }
                }
                if (className.length > 0) {
                  return className.join(' ');
                }
              }}
              onRow={(record) => {
                return {
                  onDoubleClick: () => {
                    openModalLead(record);
                  },
                };
              }}
              onChange={(val) => {
                handleTableChange(
                  { current: val?.current },
                  columnFiltersFromTableComponentRef.current,
                );
              }}
              components={{
                header: {
                  cell: ResizableTableColumnHeader,
                },
              }}
              sticky={true}
              bordered={true}
              //columns={baseColumns}
              columns={filteringColumnsChildrenPillarByVisible}
              dataSource={leads}
              loading={loading}
              size="small"
              pagination={setPaginationSettings(totalLeads, page)}
            />
          ) : (
            <>
              <ErrorBoundary>
                <ScrollingLoadedList
                  dataList={leads}
                  totalData={totalLeads}
                  loadMoreItems={async () => {
                    handleUpdate(page + 1, filters, false, true);
                  }}
                  scrollingLoadedListRef={scrollingLoadedListRef}
                  renderListItem={(item) => (
                    <LeadListItem
                      lead={item}
                      menuActions={(lead) => menu(lead)}
                    />
                  )}
                />
              </ErrorBoundary>
            </>
          )}
        </div>
      </ConfigProvider>

      <ErrorBoundary>
        <UniversalFiltersModal
          visibleModal={visibleFilters && isMobile}
          handleClose={() => {
            setVisibleFilters(false);
          }}
          resetFilters={() => {
            setVisibleFilters(false);
            handleUpdate(1, null, false, false);
          }}
          columnsList={columns}
        />
      </ErrorBoundary>

      {/*edit leed modal*/}

      <ErrorBoundary>
        <LeadModal
          visibleModal={visibleModalEditLead}
          editingLead={currentLead}
          onCloseModal={() => {
            setVisibleModalEditLead(false);
            setCurrentLead(null);
            isMobile && handleScrollToTopMobileList();
            handleUpdate(isMobile ? 1 : page, filtersRef.current, false, false);
          }}
          setCurrentLeadData={(leadData) => {
            setCurrentLead(leadData);
          }}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <PreApplicationModal
          visible={visibleDrawer}
          updateData={(data) => setTaskData(data)}
          handleUpdate={() => {
            columnFiltersFromTableComponentRef.current &&
              setColumnFiltersFromTableComponent([]);
            isMobile && handleScrollToTopMobileList();
            handleUpdate(page, filtersRef.current, true, false);
          }}
          handleOk={() => {
            setTaskData(null);
            setVisibleDrawer(false);
            columnFiltersFromTableComponentRef.current &&
              setColumnFiltersFromTableComponent([]);
            isMobile && handleScrollToTopMobileList();
            handleUpdate(page, filtersRef.current, true, false);
          }}
          handleCancel={() => {
            if (visitSumsClient && sumsWereEdited) {
              savingSum.current = true;
              setDisableBtnAtSaveSums(true);
            } else {
              columnFiltersFromTableComponentRef.current &&
                setColumnFiltersFromTableComponent([]);
              isMobile && handleScrollToTopMobileList();
              handleUpdate(page, filtersRef.current, true, false);
              setTaskData(null);
              setVisibleDrawer(false);
            }
          }}
          handleOpenSendKpModal={async (application) => {
            await handleSendKpApplication(application);
          }}
          loading={false}
          lead={currentLead}
          data={taskData}
          savingSum={savingSum.current}
          savingSumWereEditing={savingSumWereEditing.current}
          setSavingSum={setSavingSum}
          setVisitSums={setVisitSums}
          saveSumsWhenSwitching={() => saveSumsWhenSwitching()}
          changeSumsWereEdited={(sumEditing) => setSumsWereEdited(sumEditing)}
          sumsWereEdited={sumsWereEdited}
          endSavingSumsWhenSwitching={() => endSavingSumsWhenSwitching()}
          disableBtnAtSaveSums={disableBtnAtSaveSums}
          renderInOutPagePreTask={false}
        />
      </ErrorBoundary>

      {/* create customer modal */}
      <ErrorBoundary>
        <CreateCustomerModal
          customers={[]}
          lead={currentLead}
          visible={visibleCustomerModal}
          handleOk={() => {
            setVisibleCustomerModal(false);
            columnFiltersFromTableComponentRef.current &&
              setColumnFiltersFromTableComponent([]);
            isMobile && handleScrollToTopMobileList();
            handleUpdate(isMobile ? 1 : page, filtersRef.current, true, false);
          }}
          handleCancel={() => {
            setVisibleCustomerModal(false);
            columnFiltersFromTableComponentRef.current &&
              setColumnFiltersFromTableComponent([]);
            isMobile && handleScrollToTopMobileList();
            handleUpdate(isMobile ? 1 : page, filtersRef.current, true, false);
          }}
          loading={false}
          handleRedirect={(state) => {
            navigate('/all_customers', { state });
          }}
        />
      </ErrorBoundary>

      {/* Отправить КП/Презентацию*/}
      <ErrorBoundary>
        <SendKpOrPresentationModal
          visibleModal={visibleTasksModal}
          preTask={preTasksList}
          closeVisibleModal={closeVisibleModal}
          currentLead={currentLead}
          sendFromLeadPage={true}
          staffList={staffList}
          page={page}
          updateModelData={getLead}
          handleUpdate={() => {
            isMobile && handleScrollToTopMobileList();
            handleUpdate(
              isMobile ? 1 : page,
              filtersRef.current,
              false,
              isMobile,
            );
          }}
          loading={loadingLead}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <SettingTableColumnsDrawer
          visible={visibleTableSetting}
          loading={false}
          columns={baseColumns}
          onCloseDrawer={async () => {
            onCloseDrawerTableSetting();
            await updateUserInfo(userInfo);
          }}
        />
      </ErrorBoundary>
    </>
  );
};

export default LeedsTable;
