import React, { useCallback, useContext, useRef, useState } from 'react';
import { Col, Form, Input, message, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import PreApplicationModalFormFieldsSums from './PreApplicationModalFormFieldsSums';
import { ErrorBoundary } from '../../ErrorBoundary';
import { getShortTemplate } from '../utils/getShortNameTemplate';
import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { userInformations } from '../../../contex';
import useAsyncEffect from 'use-async-effect';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const { Option, OptGroup } = Select;

const BasicInformationPreApplicationModalForm = (props) => {
  const [template, setTemplate] = useState(1);
  const [staff, setStaff] = useState([]);
  const [taskTemplateList, setTaskTemplateList] = useState([]);
  const [ourCustomers, setOurCustomers] = useState([]);
  const { userInfo } = useContext(userInformations);
  const [loadingStaffList, setLoadingStaffList] = useState(false);
  let identifiedFieldClientType = useRef('');
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    await getOurCustomers();
    await getTaskTemplateList();
    await getStaffList();
  }, []);

  useAsyncEffect(async () => {
    if (props.otvPreTask?.manager?.full_name) {
      await searchStaff(props.otvPreTask.manager.full_name);
    }
  }, [props.otvPreTask]);

  useAsyncEffect(async () => {
    if (props.vdtPreTask?.manager?.full_name) {
      await searchStaff(props.vdtPreTask.manager.full_name);
    }
  }, [props.vdtPreTask]);

  const handleChangeTaskTemplate = useCallback(
    async (value) => {
      setTemplate(value);
      await changeTaskTemplate(props.data?.id || props.createdTask?.id, value);

      const taskInfo = props.data || props.createdTask;

      props.mainForm.setFieldsValue({
        number_task: `${getShortTemplate(taskInfo?.template?.name)}-${
          props.data?.id || props.createdTask?.id
        }`,
      });
    },
    [setTemplate, props.data, props.createdTask, getShortTemplate],
  );

  const makeLeadOrCustomerTask = useCallback(
    async (body) => {
      props.setLoading(true);

      try {
        const url =
          identifiedFieldClientType.current !== '' &&
          !props.customer &&
          !props.leadInPreTask
            ? identifiedFieldClientType.current === 'customer'
              ? `${restApiHost}/customers/pre_tasks/`
              : `${restApiHost}/leads/pre_tasks/`
            : props.customer
            ? `${restApiHost}/customers/pre_tasks/`
            : `${restApiHost}/leads/pre_tasks/`;
        const json = await call(
          url,
          {
            method: 'POST',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          },
          userInfo.current.haveViewBranch,
        );

        if (!json?.data) return;

        props.setCreatedTask(json.data);
        props.refreshTaskInfo(json?.data?.id);
        props.setCurrentTab(1);
      } catch (e) {
        console.log('create pre task err', e);
        message.error('Ошибка создания предварительной заявки');
      } finally {
        props.setLoading(false);
      }
    },
    [
      identifiedFieldClientType,
      props.customer,
      props.leadInPreTask,
      props.setCreatedTask,
      props.refreshTaskInfo,
      props.setCurrentTab,
      props.setLoading,
    ],
  );

  const formValuesChange = useCallback(() => {
    if (
      identifiedFieldClientType.current !== '' &&
      !props.customer &&
      !props.leadInPreTask
    ) {
      setTimeout(() => {
        props.mainForm.validateFields().then((values) => {
          identifiedFieldClientType.current === 'customer'
            ? makeLeadOrCustomerTask({
                customer: values.lead,
                our_customer: values.our_customer,
              })
            : makeLeadOrCustomerTask({
                lead: values.lead,
                our_customer: values.our_customer,
              });
        });
      }, 0);
    } else {
      console.log('props.mainForm.validateFields()');
      setTimeout(() => {
        props.mainForm.validateFields().then((values) => {
          props.customer
            ? makeLeadOrCustomerTask({
                customer: values.lead,
                our_customer: values.our_customer,
              })
            : makeLeadOrCustomerTask({
                lead: values.lead,
                our_customer: values.our_customer,
              });
        });
      }, 0);
    }
  }, [
    props.createdTask,
    identifiedFieldClientType.current,
    props.customer,
    props.leadInPreTask,
    props.mainForm,
    makeLeadOrCustomerTask,
  ]);

  const identificationTypeClient = async (option) => {
    if (option['data-value']) {
      const client = JSON.parse(option['data-value']);
      identifiedFieldClientType.current =
        client?.item_type === 'lead' ? 'lead' : 'customer';
    }
    await checkFormValue();
  };

  const checkFormValue = useCallback(async () => {
    console.log('checkFormValue');
    if (!(props.data?.id || props.createdTask?.id)) {
      await formValuesChange();
      return;
    }
    await props.mainForm.validateFields();
  }, [
    props.customer,
    props.leadInPreTask,
    props.createdTask,
    props.data,
    props.mainForm,
    formValuesChange,
  ]);

  const changeTaskTemplate = useCallback(
    async (task, template) => {
      props.setLoading(true);
      try {
        const url = `${restApiHost}/leads/change_template/`;
        const json = await call(
          url,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ task, template }),
          },
          userInfo.current.haveViewBranch,
        );

        if (!json) return;

        message.success('Шаблон заявки успешно изменен!');
        props.refreshTaskInfo(task);
      } catch (e) {
        console.log('change template error', e);
        message.error('Ошибка смены шаблона заявки!');
      } finally {
        props.setLoading(true);
      }
    },
    [props.setLoading, props.refreshTaskInfo],
  );

  const getStaffList = useCallback(async () => {
    setLoadingStaffList(true);
    try {
      const url = `${restApiHost}/accounts/staff_users/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setStaff(json?.data);
      }
    } catch (e) {
      console.log('fetch profile info error', e);
    } finally {
      setLoadingStaffList(false);
    }
  }, [setLoadingStaffList, setStaff]);

  const searchStaff = useCallback(async (keyword) => {
    setLoadingStaffList(true);
    try {
      const url = `${restApiHost}/accounts/staff_users/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setStaff(json?.data);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса! Не удалось найти сотрудника');
    } finally {
      setLoadingStaffList(false);
    }
  }, []);

  const getTaskTemplateList = useCallback(async () => {
    try {
      const url = `${restApiHost}/refbook/task_template/?active=true`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setTaskTemplateList(json?.data);
      }
    } catch (e) {
      console.log('error get task template list', e);
      message.error('Ошибка запроса! Не удалось получить список шаблонов');
    }
  }, [setTaskTemplateList]);

  const getOurCustomers = useCallback(
    async (keyword) => {
      const body = {};

      if (keyword) {
        body['keyword'] = keyword;
      }
      try {
        const url = `${restApiHost}/customers/search_our_customer/`;
        const json = await call(
          url,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(body),
          },
          userInfo.current.haveViewBranch,
        );

        if (json?.data) {
          setOurCustomers(json.data);
        }
      } catch (e) {
        console.log(e);
        message.error('Ошибка получения списка компаний');
      }
    },
    [setOurCustomers],
  );

  const handleChangeCustomer = useCallback(
    async (value, data) => {
      if (!props.customer) {
        if (data?.id || (props.createdTask?.id && value)) {
          props.taskEdit({ lead: value }, data?.id || props.createdTask?.id);
        }
      } else {
        props.setSelectedCustomer(value);
        let currCustomer = {};
        if (data['data-value']) {
          currCustomer = JSON.parse(data['data-value']);
        }
        if (currCustomer?.contact_person) {
          props.mainForm.setFieldsValue({
            contact: currCustomer.contact_person,
          });
        }

        if (data?.id || (props.createdTask?.id && value)) {
          await props.taskEdit(
            { customer: value },
            data?.id || props.createdTask?.id,
          );
        }
      }
    },
    [props.customer, props.mainForm, props.createdTask, props.taskEdit],
  );

  const renderCustomersForSelect = useCallback(() => {
    if (props.leadInPreTask) {
      return (
        <>
          {props.leads?.length > 0 ? (
            <>
              {props.leads.map((itemLead) => {
                return (
                  <Option
                    value={itemLead?.id}
                    key={itemLead?.id}
                    data-value={JSON.stringify(itemLead)}
                  >
                    {itemLead?.company_name || itemLead?.email || 'Не указано'}
                  </Option>
                );
              })}
            </>
          ) : (
            <Option value={'no'} disabled>
              Нет доступных вариантов
            </Option>
          )}
        </>
      );
    } else if (props.customer) {
      return (
        <>
          {props.customers?.length > 0 && (
            <>
              {props.customers.map((itemCustomer) => {
                return (
                  <Option
                    data-value={JSON.stringify(itemCustomer)}
                    key={itemCustomer.id}
                    value={itemCustomer.id}
                  >
                    {itemCustomer?.company_name ||
                      itemCustomer?.short_name ||
                      itemCustomer?.legal_name ||
                      itemCustomer?.email ||
                      'Не указано'}
                  </Option>
                );
              })}
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {props.leadsAndCustomers?.length > 0 && (
            <>
              {props.leadsAndCustomers.map((item) => {
                return (
                  <Option
                    data-value={JSON.stringify(item)}
                    key={item.id}
                    value={item.id}
                  >
                    {item?.company_name ||
                      item?.short_name ||
                      item?.legal_name ||
                      item?.email ||
                      'Не указано'}
                  </Option>
                );
              })}
            </>
          )}
        </>
      );
    }
  }, [
    props.leadInPreTask,
    props.leads,
    props.customer,
    props.customers,
    props.leadsAndCustomers,
  ]);

  const editTaskManagers = useCallback(async (body, taskId, vdtGroupId) => {
    props.setLoading(true);
    try {
      const url = `${restApiHost}/shipping/task_manager/${vdtGroupId}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Информация по заявке успешно обновлена');

      props.refreshTaskInfo(taskId);
    } catch (e) {
      console.log('edit task err', e);
      message.error(
        'Ошибка запроса на редактирование групп менеджеров пред. заявки',
      );
    } finally {
      props.setLoading(false);
    }
  }, []);

  const getOptionsForSelectOtv = useCallback(
    (isCustomerPreTask) => {
      return isCustomerPreTask ? props.customerManagersOtvForSelect : staff;
    },
    [staff, props.customerManagersOtvForSelect],
  );

  return (
    <>
      <Form
        form={props.mainForm}
        onValuesChange={checkFormValue}
        layout="vertical"
      >
        <Row gutter={5}>
          <Col lg={16} xs={24}>
            <Row gutter={5} className={'reset-margin-form'}>
              <Col lg={8} md={8} xs={24}>
                <Form.Item label={'Номер заявки'} name="pre_task_number">
                  <Input disabled placeholder={'Номер пред. заявки'} />
                </Form.Item>
              </Col>
              <Col lg={8} md={8} xs={24}>
                <Form.Item name={'date_create'} label={'от'}>
                  <Input disabled placeholder={'Дата'} />
                </Form.Item>
              </Col>
              <Col lg={8} md={8} xs={24}>
                <Form.Item name={'status'} label="Статус">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col lg={8} md={8} xs={24}>
                <Form.Item
                  name={'our_customer'}
                  label="Наша компания"
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    placeholder={
                      ourCustomers?.length <= 0
                        ? 'Начните поиск'
                        : 'Выберите компанию'
                    }
                    showSearch
                    filterOption={false}
                    //disabled={data?.our_customer || props.createdTask?.our_customer}
                    onSearch={(value) =>
                      value?.length > 0
                        ? getOurCustomers(value)
                        : getOurCustomers()
                    }
                    onChange={(value) => {
                      if (value && props.createdTask) {
                        props.taskEdit(
                          { our_customer: value },
                          props.data?.id || props.createdTask?.id,
                        );
                      }
                    }}
                  >
                    <>
                      {ourCustomers?.length > 0 && (
                        <>
                          {ourCustomers.map((item) => {
                            return (
                              <Option key={item.id} value={item.id}>
                                {item?.short_name ||
                                  item?.legal_name ||
                                  item?.email ||
                                  'Не указаны данные'}
                              </Option>
                            );
                          })}
                        </>
                      )}
                    </>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={8} xs={24}>
                <Form.Item
                  name={'lead'}
                  label="Клиент"
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    disabled={props.disableClientSelect}
                    showSearch
                    placeholder={'Начните поиск'}
                    filterOption={false}
                    onSearch={(keyword) =>
                      props.handleSearchListCustomers(keyword)
                    }
                    onChange={async (value, option) => {
                      if (value && option) {
                        await identificationTypeClient(option);
                        await handleChangeCustomer(
                          value,
                          props.data || props.createdTask,
                        );
                      }
                    }}
                    //onChange={handleChangeCustomer}
                  >
                    {renderCustomersForSelect()}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={8} xs={24}>
                <Form.Item name={'contact'} label="Контакт">
                  <Input disabled placeholder={'Контактное лицо'} />
                </Form.Item>
              </Col>
              <Col lg={8} md={8} xs={24}>
                <Form.Item name={'template'} label={'Шаблон'}>
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    disabled={!props.createdTask}
                    value={template}
                    showSearch
                    placeholder="Шаблон"
                    optionFilterProp="children"
                    onChange={async (value) => {
                      await handleChangeTaskTemplate(value);
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {taskTemplateList && taskTemplateList?.length > 0 ? (
                      taskTemplateList.map((item) => {
                        return (
                          <Option
                            value={item.id}
                            key={item.id}
                            data-value={item.name}
                          >
                            {item.name}
                          </Option>
                        );
                      })
                    ) : (
                      <Option value="Не найдено...">Не найдено...</Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={8} xs={24}>
                <Form.Item label="Ведет">
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    showSearch
                    disabled={!props.createdTask}
                    value={
                      props.vdtPreTask?.manager?.id
                        ? props.vdtPreTask?.manager?.id
                        : null
                    }
                    // value={currUser ? JSON.stringify(currUser) : null}
                    placeholder="Начните поиск"
                    optionFilterProp="children"
                    loading={loadingStaffList}
                    onChange={async (value) => {
                      if (value) {
                        await editTaskManagers(
                          { manager: value, group: 'vdt' },
                          props.data?.id || props.createdTask?.id,
                          props.vdtPreTask?.id,
                        );
                        await getStaffList();
                      }
                    }}
                    onInput={async (e) => {
                      if (e.target.value.length > 0) {
                        await searchStaff(e.target.value);
                      } else {
                        await getStaffList();
                      }
                    }}
                  >
                    {staff && staff.length > 0 ? (
                      <>
                        {staff.map((item) => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.full_name ? item.full_name : item.email}
                            </Option>
                          );
                        })}
                      </>
                    ) : (
                      <OptGroup
                        label={
                          loadingStaffList
                            ? 'Поиск сотрудников'
                            : 'Cотрудник не найден'
                        }
                      >
                        {loadingStaffList
                          ? 'Поиск сотрудников'
                          : 'Cотрудник не найден'}
                      </OptGroup>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={8} xs={24}>
                <Form.Item label="Ответственный">
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    showSearch
                    disabled={!props.createdTask}
                    value={
                      props.otvPreTask?.manager?.id
                        ? props.otvPreTask.manager.id
                        : null
                    }
                    // value={currUser ? JSON.stringify(currUser) : null}
                    placeholder="Начните поиск"
                    optionFilterProp="children"
                    filterOption={props.selectedCustomer !== null}
                    loading={loadingStaffList}
                    notFoundContent={'Сотрудник не найден'}
                    onChange={async (value) => {
                      if (value) {
                        await editTaskManagers(
                          { manager: value, group: 'otv' },
                          props.data?.id || props.createdTask?.id,
                          props.otvPreTask?.id,
                        );
                        await getStaffList();
                      }
                    }}
                    onInput={async (e) => {
                      if (!props.selectedCustomer) {
                        if (e.target.value.length > 0) {
                          await searchStaff(e.target.value);
                        } else {
                          await getStaffList();
                        }
                      }
                    }}
                  >
                    {getOptionsForSelectOtv(props.selectedCustomer) ? (
                      <>
                        {getOptionsForSelectOtv(props.selectedCustomer)?.map(
                          (item) => {
                            return (
                              <Option key={item.id} value={item.id}>
                                {item.full_name ? item.full_name : item.email}
                              </Option>
                            );
                          },
                        )}
                      </>
                    ) : (
                      <OptGroup
                        label={
                          loadingStaffList
                            ? 'Поиск сотрудников'
                            : 'Cотрудник не найден'
                        }
                      >
                        {loadingStaffList
                          ? 'Поиск сотрудников'
                          : 'Cотрудник не найден'}
                      </OptGroup>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <ErrorBoundary>
            <PreApplicationModalFormFieldsSums
              totalSumsClient={props.totalSumsClient}
              totalSumsUpfront={props.totalSumsUpfront}
              totalDelta={props.totalDelta}
            />
          </ErrorBoundary>
        </Row>
      </Form>
    </>
  );
};

BasicInformationPreApplicationModalForm.propTypes = {
  mainForm: PropTypes.any,
  data: PropTypes.object,
  customer: PropTypes.object,
  createdTask: PropTypes.object,
  selectedCustomer: PropTypes.object,
  otvPreTask: PropTypes.object,
  leadInPreTask: PropTypes.bool,
  leads: PropTypes.arrayOf(PropTypes.object),
  totalSumsClient: PropTypes.object,
  totalSumsUpfront: PropTypes.object,
  totalDelta: PropTypes.object,
  vdtPreTask: PropTypes.object,
  customers: PropTypes.arrayOf(PropTypes.object),
  customerManagersOtvForSelect: PropTypes.arrayOf(PropTypes.object),
  leadsAndCustomers: PropTypes.arrayOf(PropTypes.object),
  disableClientSelect: PropTypes.bool,
  setSelectedCustomer: PropTypes.func,
  handleSearchListCustomers: PropTypes.func,
  setLoading: PropTypes.func,
  taskEdit: PropTypes.func,
  refreshTaskInfo: PropTypes.func,
  setCreatedTask: PropTypes.func,
  setCurrentTab: PropTypes.func,
};

export default BasicInformationPreApplicationModalForm;
