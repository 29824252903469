import { dateStrRevers } from '../../../../../../../utils/dateStrRevers';
import {
  Checkbox,
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import { ErrorBoundary } from '../../../../../../ErrorBoundary';
import TransportationModal from './modal/TransportationModal';
import { userInformations } from '../../../../../../../contex';
import { deleteShippingTaskCargo } from '../../../../../../../logic/task/TaskApiFunctions';
import useCheckMobileScreen from '../../../../../../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../../../../../../mobile/scrollingLoadedList/ScrollingLoadedList';
import TransportationListItem from '../../../../../../mobile/task/modal/TransportationListItem';
import useStateRef from 'react-usestateref';
import {
  changeColumnSizeInColumnsGroup,
  changeSizeColumn,
  convertingColumnsWithGroupsIntoRegularOnesForUseInMobileFilters,
  prepareColumnsForResizing,
  sortingColumnsTable,
} from '../../../../../../settingTable/sortingColumnsTable';
import { editTableColumn } from '../../../../../../../logic/profileInfo/mainTable/MainTableApiFunctions';
import { getProfileInfo } from '../../../../../../../logic/account/AccountsApiFunctions';
import { userInfoHaveViewAllBranch } from '../../../../../../../logic/account/utils';
import filterActiveColumns from '../../../../../../table/utils';
import ResizableTableColumnHeader from '../../../../../../table/ResizableTableColumnHeader';
import SearchDataFilter from '../../../../../../leeds/filters/SearchData';
import { getFiltersTable } from '../../../../../../../utils/getFilters';
import FilterSelect from '../../../../../../leeds/filters/FilterSelect';
import RangeNumberFilters from '../../../../../../leeds/filters/RangeNumberFilters';
import UniversalFiltersModal from '../../../../../../mobile/filtersModal/UniversalFiltersModal';

const TransportationTable = (props) => {
  const tableName = 'tasks.transport_cargo_tasks';
  const { userInfo } = useContext(userInformations);
  const [editingTransport, setEditingTransport] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const isMobile = useCheckMobileScreen();
  const [baseColumns, setBaseColumns, baseColumnsRef] = useStateRef([]);
  const [
    ,
    setColumnFiltersFromTableComponent,
    columnFiltersFromTableComponentRef,
  ] = useStateRef([]);

  useEffect(() => {
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      props.visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
  }, [props.visibleFilters, props.taskData]);

  useEffect(() => {
    if (!props.tableFilters) {
      setColumnFiltersFromTableComponent([]);
    }
  }, [props.tableFilters]);

  const handleEditTransport = useCallback(
    (transportRow) => {
      setEditingTransport({
        ...transportRow,
        cargo: props.taskData?.cargo?.id,
        transport: {
          ...transportRow?.transport,
          date_plan: transportRow?.transport?.date_plan
            ? `${dateStrRevers(
                transportRow.transport.date_plan.split('T')[0],
              )}T${transportRow.transport.date_plan.split('T')[1]}`
            : null,
        },
        transportId: transportRow?.transport?.id,
      });
      setVisibleModal(true);
    },
    [setEditingTransport, props.taskData?.cargo, setVisibleModal],
  );

  const handleCloseModal = useCallback(() => {
    setVisibleModal(false);
    setEditingTransport(null);
    props.handleUpdateList(props.tableFilters);
  }, [setVisibleModal, setEditingTransport, props.tableFilters]);

  const handleDeleteTransportation = useCallback(
    async (transportationId) => {
      const res = await deleteShippingTaskCargo(transportationId, userInfo);
      if (res) {
        message.success('Перевозка успешно удалена');
        props.handleUpdateList(props.tableFilters);
      }
    },
    [props.handleUpdateList, props.tableFilters],
  );

  const handleResize = useCallback(
    (index) =>
      async (e, { size, uniqueIndex, isGroupItem }) => {
        let databaseColumnsId = null;
        const copyColumns = [...baseColumnsRef.current];

        if (!isGroupItem) {
          setBaseColumns((columns) => {
            return changeSizeColumn(columns, index, size);
          });

          databaseColumnsId = copyColumns[index]?.idInDatabase;
        } else {
          setBaseColumns((columns) => {
            const { nextColumns, idInDatabase } =
              changeColumnSizeInColumnsGroup(columns, index, uniqueIndex, size);
            databaseColumnsId = idInDatabase;
            return nextColumns;
          });
        }

        if (!databaseColumnsId) return;

        await editTableColumn(databaseColumnsId, { width: size }, userInfo);
        const res = await getProfileInfo(userInfo);
        if (res) {
          userInfo.current = res;
          const haveViewBranch = userInfoHaveViewAllBranch(res);
          if (haveViewBranch) {
            userInfo.current['haveViewBranch'] = true;
          }
        }
      },
    [baseColumnsRef.current],
  );

  const filteringColumnsChildrenPillarByVisible = useMemo(() => {
    const copyColumns = [...baseColumns];
    return filterActiveColumns(copyColumns);
  }, [baseColumns]);

  const menu = (row) => {
    return (
      <Menu style={{ width: 160 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => handleEditTransport(row)}
        >
          Редактировать
        </Menu.Item>

        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={async () => await handleDeleteTransportation(row?.id)}
          onCancel={() => console.log('Delete item')}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const handleTableChange = (filtersTable) => {
    const { filters } = getFiltersTable({ current: 1 }, filtersTable);
    setColumnFiltersFromTableComponent(filtersTable);
    props.handleUpdateList(filters);
  };

  const columns = [
    {
      title: '',
      key: 'action',
      align: 'center',
      width: 50,
      uniqueIndex: 'action',
      index: 0,
      render: (row) => (
        <Space size="middle">
          <Dropdown overlay={menu(row)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Транспортная единица',
      uniqueIndex: 'transportationGroup',
      isGroup: true,
      columnId: 'active',
      disableResize: true,
      index: 1,
      children: [
        {
          title: 'Тип',
          key: 'type_transport',
          dataIndex: 'transport',
          width: 150,
          uniqueIndex: 'type_transport',
          groupIndex: 'transportationGroup',
          index: 4,
          render: (transport) => transport?.transport_unit?.type_transport,
          children: [
            {
              title: (
                <SearchDataFilter
                  inputPlaceholder={'Тип'}
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.transport__transport_unit__type_transport__icontains
                  }
                  columnKey={
                    'transport__transport_unit__type_transport__icontains'
                  }
                />
              ),
              key: 'type_transport',
              dataIndex: 'transport',
              width: 150,
              uniqueIndex: 'type_transport',
              groupIndex: 'transportationGroup',
              index: 4,
              visible: props.visibleFilters,
              render: (transport) => transport?.transport_unit?.type_transport,
            },
          ],
        },
        {
          title: 'Номер контейнера',
          key: 'number_container',
          dataIndex: 'transport',
          width: 150,
          uniqueIndex: 'number_container',
          groupIndex: 'transportationGroup',
          index: 6,
          render: (transport) => transport?.number_container ?? '-',
          children: [
            {
              title: (
                <SearchDataFilter
                  inputPlaceholder={'Номер контейнера'}
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.transport__number_container__icontains
                  }
                  columnKey={'transport__number_container__icontains'}
                />
              ),
              key: 'number_container',
              dataIndex: 'transport',
              width: 150,
              uniqueIndex: 'number_container',
              groupIndex: 'transportationGroup',
              index: 6,
              visible: props.visibleFilters,
              render: (transport) => transport?.number_container ?? '-',
            },
          ],
        },
        {
          title: 'Платформа',
          key: 'platform',
          dataIndex: 'transport',
          width: 150,
          uniqueIndex: 'platform',
          groupIndex: 'transportationGroup',
          index: 8,
          render: (transport) => transport?.platform ?? '-',
          children: [
            {
              title: (
                <SearchDataFilter
                  inputPlaceholder={'Платформа'}
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.transport__platform__icontains
                  }
                  columnKey={'transport__platform__icontains'}
                />
              ),
              key: 'platform',
              dataIndex: 'transport',
              width: 150,
              uniqueIndex: 'platform',
              groupIndex: 'transportationGroup',
              index: 8,
              visible: props.visibleFilters,
              render: (transport) => transport?.platform ?? '-',
            },
          ],
        },
        {
          title: 'Номер платформы',
          key: 'platform_number',
          dataIndex: 'transport',
          width: 150,
          uniqueIndex: 'platform_number',
          groupIndex: 'transportationGroup',
          index: 10,
          render: (transport) => transport?.platform_number ?? '-',
          children: [
            {
              title: (
                <SearchDataFilter
                  inputPlaceholder={'Номер платформы'}
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.transport__platform_number__icontains
                  }
                  columnKey={'transport__platform_number__icontains'}
                />
              ),
              key: 'platform_number',
              visible: props.visibleFilters,
              dataIndex: 'transport',
              width: 150,
              uniqueIndex: 'platform_number',
              groupIndex: 'transportationGroup',
              index: 10,
              render: (transport) => transport?.platform_number ?? '-',
            },
          ],
        },
      ],
    },
    {
      title: 'Груз',
      uniqueIndex: 'cargoGroup',
      isGroup: true,
      columnId: 'active',
      disableResize: true,
      index: 2,
      children: [
        {
          title: 'Наименование',
          key: 'cargo_name',
          width: 180,
          uniqueIndex: 'cargo_name',
          groupIndex: 'cargoGroup',
          index: 12,
          ellipsis: {
            showTitle: false,
          },
          render: () =>
            props.taskData?.cargo ? (
              <Tooltip title={props.taskData?.cargo.name} placement={'topLeft'}>
                {props.taskData?.cargo?.name ?? '-'}
              </Tooltip>
            ) : null,
          children: [
            {
              title: (
                <SearchDataFilter
                  inputPlaceholder={'Наименование'}
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.task__cargo__name__icontains
                  }
                  columnKey={'task__cargo__name__icontains'}
                />
              ),
              key: 'cargo_name',
              width: 180,
              uniqueIndex: 'cargo_name',
              groupIndex: 'cargoGroup',
              index: 12,
              ellipsis: {
                showTitle: false,
              },
              visible: props.visibleFilters,
              render: () =>
                props.taskData?.cargo ? (
                  <Tooltip
                    title={props.taskData?.cargo.name}
                    placement={'topLeft'}
                  >
                    {props.taskData?.cargo?.name ?? '-'}
                  </Tooltip>
                ) : null,
            },
          ],
        },
        {
          title: 'Код',
          key: 'code',
          width: 100,
          uniqueIndex: 'code',
          groupIndex: 'cargoGroup',
          index: 14,
          render: () => props.taskData?.cargo?.code,
          children: [
            {
              title: (
                <SearchDataFilter
                  inputPlaceholder={'Код'}
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.task__cargo__code__icontains
                  }
                  columnKey={'task__cargo__code__icontains'}
                />
              ),
              key: 'code',
              width: 100,
              uniqueIndex: 'code',
              groupIndex: 'cargoGroup',
              index: 14,
              visible: props.visibleFilters,
              render: () => props.taskData?.cargo?.code,
            },
          ],
        },
        {
          title: 'Охрана',
          key: 'cargo_security',
          width: 100,
          uniqueIndex: 'cargo_security',
          groupIndex: 'cargoGroup',
          index: 16,
          render: (data) =>
            data?.cargo_security ? (
              <Checkbox disabled={true} checked={data.cargo_security} />
            ) : (
              <Checkbox disabled={true} checked={false} />
            ),
          children: [
            {
              title: (
                <FilterSelect
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current?.cargo_security
                  }
                  columnKey={'cargo_security'}
                  useSingleSelect={true}
                  dataList={[
                    { title: 'Да', value: true },
                    { title: 'Нет', value: false },
                  ]}
                />
              ),
              key: 'cargo_security',
              width: 100,
              uniqueIndex: 'cargo_security',
              groupIndex: 'cargoGroup',
              index: 16,
              visible: props.visibleFilters,
              render: (data) =>
                data?.cargo_security ? (
                  <Checkbox disabled={true} checked={data.cargo_security} />
                ) : (
                  <Checkbox disabled={true} checked={false} />
                ),
            },
          ],
        },
      ],
    },
    {
      title: 'Упаковка',
      width: 180,
      key: 'cargo_type_packing',
      uniqueIndex: 'cargo_type_packing',
      index: 18,
      ellipsis: {
        showTitle: false,
      },
      render: (data) => data?.cargo_type_packing?.name,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Упаковка'}
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.cargo_type_packing__name__icontains
              }
              columnKey={'cargo_type_packing__name__icontains'}
            />
          ),
          width: 180,
          key: 'cargo_type_packing',
          uniqueIndex: 'cargo_type_packing',
          index: 18,
          visible: props.visibleFilters,
          ellipsis: {
            showTitle: false,
          },
          render: (data) => data?.cargo_type_packing?.name,
        },
      ],
    },
    {
      title: 'Мест',
      key: 'cargo_places',
      width: 100,
      uniqueIndex: 'cargo_places',
      index: 20,
      render: (data) => data?.cargo_places,
      children: [
        {
          title: (
            <RangeNumberFilters
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.cargo_places__range
              }
              columnKey={'cargo_places__range'}
            />
          ),
          key: 'cargo_places',
          width: 100,
          uniqueIndex: 'cargo_places',
          index: 20,
          visible: props.visibleFilters,
          render: (data) => data?.cargo_places,
        },
      ],
    },
    {
      title: 'Вес(кг)',
      width: 100,
      key: 'cargo_weight',
      uniqueIndex: 'cargo_weight',
      index: 22,
      render: (data) => data?.cargo_weight,
      children: [
        {
          title: (
            <RangeNumberFilters
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.cargo_weight__range
              }
              columnKey={'cargo_weight__range'}
            />
          ),
          width: 100,
          key: 'cargo_weight',
          uniqueIndex: 'cargo_weight',
          index: 22,
          visible: props.visibleFilters,
          render: (data) => data?.cargo_weight,
        },
      ],
    },
  ];

  return (
    <>
      {!isMobile ? (
        <Table
          scroll={{ x: 850 }}
          showHeader
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'black-text table-row-color'
              : 'black-text table-row-white'
          }
          size="small"
          pagination={{
            position: ['none'],
            size: 'middle',
            showSizeChanger: false,
          }}
          loading={props.loading}
          bordered={true}
          dataSource={props.transportationDataList}
          columns={filteringColumnsChildrenPillarByVisible}
          rowKey={(row) => row.id}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleEditTransport(record);
              },
            };
          }}
          components={{
            header: {
              cell: ResizableTableColumnHeader,
            },
          }}
        />
      ) : (
        <>
          <div style={{ paddingTop: '5px' }}>
            <ErrorBoundary>
              <ScrollingLoadedList
                dataList={props.transportationDataList}
                totalData={props.transportationDataList?.length}
                loadMoreItems={async () => {
                  //handleUpdate(page + 1, filters, false,true)
                }}
                renderListItem={(item) => (
                  <TransportationListItem
                    transport={item}
                    menuActions={(manager) => menu(manager)}
                  />
                )}
              />
            </ErrorBoundary>
          </div>
        </>
      )}
      <ErrorBoundary>
        <TransportationModal
          visible={visibleModal}
          taskData={props.taskData}
          editingTransport={editingTransport}
          updateValueEditingTransport={(value) => setEditingTransport(value)}
          closeModal={() => {
            handleCloseModal();
          }}
          taskEdit={props.taskEdit}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <UniversalFiltersModal
          visibleModal={props.visibleFilters && isMobile}
          handleClose={() => {
            props.setVisibleFilters(false);
          }}
          resetFilters={async () => {
            props.setVisibleFilters(false);
            await props.handleUpdateList(null);
          }}
          columnsList={convertingColumnsWithGroupsIntoRegularOnesForUseInMobileFilters(
            columns,
          )}
        />
      </ErrorBoundary>
    </>
  );
};

export default TransportationTable;

TransportationTable.propTypes = {
  transportationDataList: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  taskData: PropTypes.object.isRequired,
  visibleFilters: PropTypes.bool,
  handleUpdateList: PropTypes.func.isRequired,
  tableFilters: PropTypes.any,
  taskEdit: PropTypes.func,
};
