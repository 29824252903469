import {
  Alert,
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { userInformations } from '../../contex';
import useAsyncEffect from 'use-async-effect';
import {
  getContactsCustomer,
  getCustomerCustomerBanks,
  getCustomerCustomerForeignBanks,
} from '../../logic/customer/CustomerApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { ErrorBoundary } from '../ErrorBoundary';
import ScrollingLoadedList from '../mobile/scrollingLoadedList/ScrollingLoadedList';
import CustomersBankListItem from '../mobile/customers/modal/CustomersBankListItem';

const { TabPane } = Tabs;
const { Option } = Select;

const CustomerBanks = (props) => {
  const [visibleBankModal, setVisibleBankModal] = useState(false);
  const [visibleBankModalEdit, setVisibleBankModalEdit] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [currEditBank, setCurrEditBank] = useState(null);
  const [bankForeignData, setBankForeigData] = useState([]);
  const [bankFormEdit] = Form.useForm();
  const [bankForeignEdit] = Form.useForm();
  const [currEditBankForiegn, setCurrEditBankForiegn] = useState(null);
  const [visibleBankForeignEdit, setVisibleBankForeignEdit] = useState(false);
  const [disableSaveBank, setDisableSaveBank] = useState(true);
  const [selectedBank, setSelectedBank] = useState(null);
  const [bankList, setBankList] = useState([]);
  const [loadingBankList, setLoadingBankList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankForm] = Form.useForm();
  const [bankType, setBankType] = useState('ru');
  const [currentTab, setCurrentTab] = useState('1');
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    currentTab === '1' &&
      props.customerId &&
      (await handleGetCustomerBanks(props.customerId));
    currentTab === '2' &&
      props.customerId &&
      (await handleGetCustomerBanksForeign(props.customerId));
  }, [props.customerId, currentTab]);

  const handleGetCustomerBanks = useCallback(
    async (customerId) => {
      setLoading(true);
      const res = await getCustomerCustomerBanks(customerId, userInfo);
      if (res?.data) {
        setBankData(res.data);
      }
      setLoading(false);
    },
    [setBankData, getCustomerCustomerBanks],
  );

  const handleGetCustomerBanksForeign = useCallback(
    async (customerId) => {
      setLoading(true);
      const res = await getCustomerCustomerForeignBanks(customerId, userInfo);
      if (res?.data) {
        setBankForeigData(res.data);
      }
      setLoading(false);
    },
    [setBankForeigData, getContactsCustomer],
  );

  const editBankDetails = async (id, body, foreign) => {
    try {
      const url = foreign
        ? `${restApiHost}/customers/bank_details_foreign/${id}/`
        : `${restApiHost}/customers/bank_details/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      props.customerId && foreign
        ? await handleGetCustomerBanksForeign(props.customerId)
        : await handleGetCustomerBanks(props.customerId);

      setVisibleBankForeignEdit(false);
      setVisibleBankModalEdit(false);
      bankForeignEdit.resetFields();
      bankFormEdit.resetFields();
      message.success('Счет успешно отредактирован!');
    } catch (e) {
      console.log('add bank details err', e);
      message.error('Ошибка запроса на редактирование счета');
    }
  };

  const deleteBank = async (id, body, foreign) => {
    try {
      const url = foreign
        ? `${restApiHost}/customers/bank_details_foreign/${id}/`
        : `${restApiHost}/customers/bank_details/${id}/`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      props.customerId && foreign
        ? await handleGetCustomerBanksForeign(props.customerId)
        : await handleGetCustomerBanks(props.customerId);

      message.success('Операция прошла успешно!');
    } catch (e) {
      console.log(e);
      message.error('Ошибка удаления');
    }
  };

  const getBankList = async (value) => {
    setLoadingBankList(true);
    try {
      const url = value
        ? `${restApiHost}/customers/banks/?search=${value}`
        : `${restApiHost}/customers/banks/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;
      setBankList(json?.data ? json.data : []);
    } catch (e) {
      console.log('get bank list err', e);
      message.error('Ошибка запроса на получение списка банков РФ');
    } finally {
      setLoadingBankList(false);
    }
  };

  const addBankDetails = async (type, body) => {
    try {
      const url =
        type === 'ru'
          ? `${restApiHost}/customers/bank_details/`
          : `${restApiHost}/customers/bank_details_foreign/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      props.customerId && type !== 'ru'
        ? await handleGetCustomerBanksForeign(props.customerId)
        : await handleGetCustomerBanks(props.customerId);

      setVisibleBankModal(false);
      bankForm.resetFields();
      message.success('Счет успешно добавлен!');
    } catch (e) {
      console.log('add bank details err', e);
      message.error('Ошибка запроса на добавление счета');
    }
  };

  const menuBank = (bank) => {
    return (
      <Menu style={{ width: 190 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={async () => {
            setCurrEditBank(bank);
            bankFormEdit.setFieldsValue({
              ...bank,
              bank_details: bank.bank.id,
              ...bank.bank,
            });
            await getBankList(bank?.bank?.name);
            setVisibleBankModalEdit(true);
          }}
        >
          Редактировать
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={() => deleteBank(bank.id, { customer: bank.customer })}
          onCancel={() => console.log('Delete item')}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const menuBankForeign = (bank) => {
    return (
      <Menu style={{ width: 190 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => {
            setCurrEditBankForiegn(bank);
            bankForeignEdit.setFieldsValue({
              ...bank,
            });
            setVisibleBankForeignEdit(true);
          }}
        >
          Редактировать
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={() =>
            deleteBank(bank.id, { customer: bank.customer }, 'ua')
          }
          onCancel={() => console.log('Delete item')}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const bankColumns = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (bank) => (
        <Space size="middle">
          <Dropdown overlay={menuBank(bank)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Расчетный счет',
      key: 'account',
      dataIndex: 'account',
    },
    {
      title: 'Банк',
      render: (data) => (data && data.bank ? data.bank?.name : '-'),
    },
    {
      title: 'Использовать в договоре',
      editable: true,
      render: (data) =>
        data?.contract ? (
          <Checkbox
            checked={true}
            onChange={async (e) => {
              await editBankDetails(data?.id, {
                contract: e.target.checked,
              });
            }}
          />
        ) : (
          <Checkbox
            checked={false}
            onChange={async (e) => {
              await editBankDetails(data?.id, {
                contract: e.target.checked,
              });
            }}
          />
        ),
    },
    {
      title: 'Корреспондентский счет',
      render: (data) =>
        data && data.bank ? data.bank.correspondent_account : '-',
    },
    {
      title: 'БИК',
      render: (data) => (data && data.bank ? data.bank.bank_code : '-'),
    },
    {
      title: 'Адрес',
      render: (data) => (data && data.bank ? data.bank.address : '-'),
    },
  ];

  const bankForeignColumns = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (bank) => (
        <Space size="middle">
          <Dropdown overlay={menuBankForeign(bank)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Расчетный счет',
      key: 'account',
      dataIndex: 'account',
    },
    {
      title: 'Банк',
      key: 'bank',
      dataIndex: 'bank',
    },
    {
      title: 'Использовать в договоре',
      width: 200,
      render: (bank) =>
        bank?.contract ? (
          <Checkbox
            checked={true}
            onChange={async (e) => {
              await editBankDetails(
                bank?.id,
                {
                  contract: e.target.checked,
                },
                'en',
              );
            }}
          />
        ) : (
          <Checkbox
            checked={false}
            onChange={async (e) => {
              await editBankDetails(
                bank?.id,
                {
                  contract: e.target.checked,
                },
                'en',
              );
            }}
          />
        ),
    },
    {
      title: 'Получатель',
      key: 'recipient',
      dataIndex: 'recipient',
    },
    {
      title: 'Корреспондентский счет',
      key: 'correspondent_account',
      dataIndex: 'correspondent_account',
    },
  ];

  return (
    <>
      {props.customerId ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            style={{ marginBottom: '10px', width: '150px' }}
            onClick={() => {
              setVisibleBankModal(true);
            }}
          >
            Добавить счет
          </Button>
          <Tabs
            activeKey={currentTab}
            onChange={(activeKey) => setCurrentTab(activeKey)}
          >
            <TabPane tab="Счет РФ" key="1">
              {!isMobile ? (
                <Table
                  dataSource={bankData}
                  columns={bankColumns}
                  loading={loading}
                  rowClassName={(record, index) =>
                    index % 2 === 0
                      ? 'black-text table-row-color'
                      : 'black-text table-row-white'
                  }
                  bordered={true}
                  size="small"
                  pagination={{ size: 'middle', showSizeChanger: false }}
                  onRow={(record) => {
                    return {
                      onDoubleClick: async () => {
                        setCurrEditBank(record);
                        bankFormEdit.setFieldsValue({
                          ...record,
                          bank_details: record.bank.id,
                          ...record.bank,
                        });
                        await getBankList(record?.bank?.name);
                        setVisibleBankModalEdit(true);
                      },
                    };
                  }}
                />
              ) : (
                <>
                  <div style={{ paddingTop: '5px' }}>
                    <ErrorBoundary>
                      <ScrollingLoadedList
                        dataList={bankData}
                        totalData={bankData?.length}
                        loadMoreItems={async () => {
                          //handleUpdate(page + 1, filters, false,true)
                        }}
                        renderListItem={(item) => (
                          <CustomersBankListItem
                            bank={item}
                            menuActions={(leadContact) => menuBank(leadContact)}
                          />
                        )}
                      />
                    </ErrorBoundary>
                  </div>
                </>
              )}
            </TabPane>
            <TabPane tab="Иностранный счет" key="2">
              {!isMobile ? (
                <Table
                  dataSource={bankForeignData}
                  columns={bankForeignColumns}
                  loading={loading}
                  rowClassName={(record, index) =>
                    index % 2 === 0
                      ? 'black-text table-row-color'
                      : 'black-text table-row-white'
                  }
                  size="small"
                  bordered={true}
                  pagination={{ size: 'middle', showSizeChanger: false }}
                  onRow={(record) => {
                    return {
                      onDoubleClick: () => {
                        setCurrEditBankForiegn(record);
                        bankForeignEdit.setFieldsValue({
                          ...record,
                        });
                        setVisibleBankForeignEdit(true);
                      },
                    };
                  }}
                />
              ) : (
                <>
                  <div style={{ paddingTop: '5px' }}>
                    <ErrorBoundary>
                      <ScrollingLoadedList
                        dataList={bankForeignData}
                        totalData={bankForeignData?.length}
                        loadMoreItems={async () => {
                          //handleUpdate(page + 1, filters, false,true)
                        }}
                        renderListItem={(item) => (
                          <CustomersBankListItem
                            bank={item}
                            isForeign={true}
                            menuActions={(leadContact) =>
                              menuBankForeign(leadContact)
                            }
                          />
                        )}
                      />
                    </ErrorBoundary>
                  </div>
                </>
              )}
            </TabPane>
          </Tabs>
          <Modal
            visible={visibleBankForeignEdit}
            title="Редактировать счет"
            onCancel={() => {
              setVisibleBankForeignEdit(false);
              setDisableSaveBank(true);
              bankForeignEdit.resetFields();
            }}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setVisibleBankForeignEdit(false);
                  setDisableSaveBank(true);
                  bankForeignEdit.resetFields();
                }}
              >
                Закрыть
              </Button>,
              <Button
                key="submit"
                type="primary"
                disabled={disableSaveBank}
                onClick={() => {
                  bankForeignEdit.validateFields().then(async (values) => {
                    await editBankDetails(
                      currEditBankForiegn.id,
                      {
                        ...values,
                        contract: values.contract,
                        customer: props.customerId,
                      },
                      'en',
                    );
                  });
                }}
              >
                Сохранить
              </Button>,
            ]}
          >
            <Form
              onValuesChange={() => {
                setTimeout(() => {
                  bankForeignEdit
                    .validateFields()
                    .then(() => setDisableSaveBank(false))
                    .catch(() => setDisableSaveBank(true));
                }, 0);
              }}
              form={bankForeignEdit}
              layout={'vertical'}
            >
              <Row gutter={16}>
                <Col lg={18} xs={24}>
                  <Form.Item
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                    label={'Расчетный счет'}
                    name={'account'}
                  >
                    <Input placeholder={'Введите номер счета'} />
                  </Form.Item>
                </Col>
                <Col lg={24} xs={24}>
                  <Form.Item
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                    label={'Получатель'}
                    name={'recipient'}
                  >
                    <Input placeholder={'Получатель'} />
                  </Form.Item>
                </Col>
                <Col lg={24} xs={24}>
                  <Form.Item
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                    label={'Банк'}
                    name={'bank'}
                  >
                    <Input placeholder={'Банк'} />
                  </Form.Item>
                </Col>
                <Col lg={24} xs={24}>
                  <Form.Item
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                    label={'Кор. счет'}
                    name={'correspondent_account'}
                  >
                    <Input placeholder={'Корреспондентский счет'} />
                  </Form.Item>
                </Col>
                <Col lg={24} xs={24}>
                  <Form.Item
                    label={'Использовать в договоре'}
                    name={'contract'}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Modal
            visible={visibleBankModalEdit}
            title="Редактировать счет"
            onCancel={() => {
              setVisibleBankModalEdit(false);
              bankFormEdit.resetFields();
              setDisableSaveBank(true);
            }}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setVisibleBankModalEdit(false);
                  bankFormEdit.resetFields();
                  setDisableSaveBank(true);
                }}
              >
                Закрыть
              </Button>,
              <Button
                key="submit"
                type="primary"
                disabled={disableSaveBank}
                onClick={() => {
                  bankFormEdit.validateFields().then(async (values) => {
                    await editBankDetails(currEditBank?.id, {
                      ...selectedBank,
                      contract: values.contract,
                      account: values?.account,
                      customer: props.customerId,
                      bank: selectedBank?.id,
                    });
                  });
                }}
              >
                Редактировать
              </Button>,
            ]}
          >
            <Form
              onValuesChange={() => {
                setTimeout(() => {
                  bankFormEdit
                    .validateFields()
                    .then(() => setDisableSaveBank(false))
                    .catch(() => setDisableSaveBank(true));
                }, 0);
              }}
              form={bankFormEdit}
              layout={'vertical'}
            >
              <Row gutter={16}>
                <Col lg={18} xs={24}>
                  <Form.Item
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                    label={'Расчетный счет'}
                    name={'account'}
                  >
                    <Input placeholder={'Введите номер счета'} />
                  </Form.Item>
                </Col>
                <Col lg={24} xs={24}>
                  <Form.Item
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                    label={'Банк'}
                    name={'bank_details'}
                  >
                    <Select
                      getPopupContainer={(trigger) =>
                        isMobile ? trigger.parentNode : document.body
                      }
                      filterOption={false}
                      showSearch
                      loading={loadingBankList}
                      onSearch={async (value) => {
                        if (value.length > 0) {
                          await getBankList(value);
                        } else {
                          await getBankList();
                        }
                      }}
                      onChange={(value, data) => {
                        let bank = JSON.parse(data['data-value']);
                        setSelectedBank(bank);
                        bankFormEdit.setFieldsValue({
                          correspondent_account: bank.correspondent_account,
                          bank_code: bank.bank_code,
                          address: bank.address,
                        });
                      }}
                      placeholder={'Поиск банка'}
                    >
                      {bankList && bankList.length > 0 ? (
                        <>
                          {bankList.map((item, index) => {
                            return (
                              <Option
                                key={index}
                                data-value={JSON.stringify(item)}
                                value={item.id}
                              >
                                {item?.name}
                              </Option>
                            );
                          })}
                        </>
                      ) : (
                        <Option disabled>Нет доступных вариантов</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                  <Form.Item label={'Кор. счет'} name={'correspondent_account'}>
                    <Input disabled placeholder={'Корреспондентский счет'} />
                  </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                  <Form.Item label={'БИК'} name={'bank_code'}>
                    <Input disabled placeholder={'БИК'} />
                  </Form.Item>
                </Col>
                <Col lg={24} xs={24}>
                  <Form.Item label={'Адрес'} name={'address'}>
                    <Input disabled placeholder={'Адрес'} />
                  </Form.Item>
                </Col>
                <Col lg={24} xs={24}>
                  <Form.Item
                    label={'Использовать в договоре'}
                    name={'contract'}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Modal
            visible={visibleBankModal}
            title="Добавить счет"
            onCancel={() => {
              setVisibleBankModal(false);
              setDisableSaveBank(true);
              bankForm.resetFields();
            }}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setVisibleBankModal(false);
                  setDisableSaveBank(true);
                  bankForm.resetFields();
                }}
              >
                Закрыть
              </Button>,
              <Button
                key="submit"
                type="primary"
                disabled={disableSaveBank}
                onClick={() => {
                  bankForm.validateFields().then(async (values) => {
                    let body;
                    if (bankType === 'ru') {
                      body = {
                        ...selectedBank,
                        account: values.account,
                        contract: values.contract,
                        customer: props.customerId,
                        bank: selectedBank.id,
                      };
                    } else {
                      body = {
                        ...values,
                        contract: values.contract,
                        customer: props.customerId,
                      };
                    }
                    await addBankDetails(bankType, body);
                  });
                }}
              >
                Добавить
              </Button>,
            ]}
          >
            <Form
              onValuesChange={() => {
                setTimeout(() => {
                  bankForm
                    .validateFields()
                    .then(() => setDisableSaveBank(false))
                    .catch(() => setDisableSaveBank(true));
                }, 0);
              }}
              form={bankForm}
              layout={'vertical'}
            >
              <Row gutter={16}>
                <Col lg={18} xs={24}>
                  <Form.Item
                    rules={[{ required: true, message: 'Обязательное поле' }]}
                    label={'Расчетный счет'}
                    name={'account'}
                  >
                    <Input placeholder={'Введите номер счета'} />
                  </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                  <Form.Item label={'Курс'}>
                    <Select
                      getPopupContainer={(trigger) =>
                        isMobile ? trigger.parentNode : document.body
                      }
                      defaultValue={bankType}
                      onChange={(value) => setBankType(value)}
                    >
                      <Option value={'ru'}>Руб.</Option>
                      <Option value={'usd'}>USD</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {bankType === 'ru' ? (
                  <>
                    <Col lg={24} xs={24}>
                      <Form.Item
                        rules={[
                          { required: true, message: 'Обязательное поле' },
                        ]}
                        label={'Банк'}
                        name={'bank_details'}
                      >
                        <Select
                          getPopupContainer={(trigger) =>
                            isMobile ? trigger.parentNode : document.body
                          }
                          filterOption={false}
                          showSearch
                          loading={loadingBankList}
                          onSearch={async (value) => {
                            if (value.length > 0) {
                              await getBankList(value);
                            } else {
                              await getBankList();
                            }
                          }}
                          onChange={(value, data) => {
                            let bank = JSON.parse(data['data-value']);
                            setSelectedBank(bank);
                            bankForm.setFieldsValue({
                              correspondent_account: bank.correspondent_account,
                              bank_code: bank.bank_code,
                              address: bank.address,
                            });
                          }}
                          placeholder={'Поиск по БИК'}
                        >
                          {bankList && bankList.length > 0 ? (
                            <>
                              {bankList.map((item, index) => {
                                return (
                                  <Option
                                    key={index}
                                    data-value={JSON.stringify(item)}
                                    value={item.id}
                                  >
                                    {item?.name}
                                  </Option>
                                );
                              })}
                            </>
                          ) : (
                            <Option disabled>Нет доступных вариантов</Option>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item
                        label={'Кор. счет'}
                        name={'correspondent_account'}
                      >
                        <Input
                          disabled
                          placeholder={'Корреспондентский счет'}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item label={'БИК'} name={'bank_code'}>
                        <Input disabled placeholder={'БИК'} />
                      </Form.Item>
                    </Col>
                    <Col lg={24} xs={24}>
                      <Form.Item label={'Адрес'} name={'address'}>
                        <Input disabled placeholder={'Адрес'} />
                      </Form.Item>
                    </Col>
                    <Col lg={24} xs={24}>
                      <Form.Item
                        label={'Использовать в договоре'}
                        name={'contract'}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col lg={24} xs={24}>
                      <Form.Item
                        rules={[
                          { required: true, message: 'Обязательное поле' },
                        ]}
                        label={'Получатель'}
                        name={'recipient'}
                      >
                        <Input placeholder={'Получатель'} />
                      </Form.Item>
                    </Col>
                    <Col lg={24} xs={24}>
                      <Form.Item
                        rules={[
                          { required: true, message: 'Обязательное поле' },
                        ]}
                        label={'Банк'}
                        name={'bank'}
                      >
                        <Input placeholder={'Банк'} />
                      </Form.Item>
                    </Col>
                    <Col lg={24} xs={24}>
                      <Form.Item
                        rules={[
                          { required: true, message: 'Обязательное поле' },
                        ]}
                        label={'Кор. счет'}
                        name={'correspondent_account'}
                      >
                        <Input placeholder={'Корреспондентский счет'} />
                      </Form.Item>
                    </Col>
                    <Col lg={24} xs={24}>
                      <Form.Item
                        label={'Использовать в договоре'}
                        name={'contract'}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          </Modal>
        </div>
      ) : (
        <Alert
          style={{ margin: '20px 0' }}
          message="Раздел на данном этапе недоступен"
          description="Чтобы добавить банковские реквизиты к контрагенту, сначала, вам необходимо нажать на кнопку создания клиента с уже заполненными полями информации"
          type="info"
          showIcon
        />
      )}
    </>
  );
};

CustomerBanks.propTypes = {
  customerId: PropTypes.number,
};

export default CustomerBanks;
