import PropTypes from 'prop-types';
import { Col, Form, Input, message, Row, Select, Skeleton } from 'antd';
import {
  getContactsCustomer,
  getCustomerContractsList,
  searchCustomerInList,
} from '../../../../../logic/customer/CustomerApiFunctions';
import React, { useCallback, useContext, useState } from 'react';
import { userInformations } from '../../../../../contex';
import {
  changeShippingTaskTemplate,
  createShippingTask,
} from '../../../../../logic/task/TaskApiFunctions';
import useCheckMobileScreen from '../../../../../hooks/useCheckMobileScreen';
import getTaskTemplateRefbookList from '../../../../../logic/refbook/task/RefbookTaskApiFunctions';
import useAsyncEffect from 'use-async-effect';
import { priceFormatter } from '../../../../../utils/priceFormatter';
import { getCustomerById } from '../../../../customer/customerApiFunctions/getCustomerById';

const { Option } = Select;

const BasicInformationForm = (props) => {
  const [mainForm] = Form.useForm();
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();
  const [ourCustomers, setOurCustomers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [contactPersons, setContactPersons] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [taskTemplateList, setTaskTemplateList] = useState([]);
  const [loading, setLoading] = useState(false);

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      await handleGetOurCustomers();
      await handleGetCustomers();
      await handleGetTaskTemplateList();
      if (!props.taskData) return;

      await handleSetFormFieldsValue(props.taskData);
    } finally {
      setLoading(false);
    }
  }, [props.taskData]);

  const makeTask = useCallback(
    async (body) => {
      const json = await createShippingTask(body, userInfo);

      if (!json) return;

      message.success('Заявка успешно создана!');
      props.refreshTaskInfo && props.refreshTaskInfo(json?.id);
    },
    [props.refreshTaskInfo],
  );

  const formValuesChange = useCallback(() => {
    if (props.taskData) return;

    setTimeout(() => {
      mainForm.validateFields().then(async (values) => {
        let body = { ...values };
        await makeTask(body);
      });
    }, 0);
  }, [props.taskData, mainForm]);

  const handleGetOurCustomers = useCallback(
    async (keyword = null) => {
      const res = await searchCustomerInList(userInfo, 'our', keyword);
      if (res?.data) {
        setOurCustomers(res.data);
      }
    },
    [setOurCustomers],
  );

  const handleGetCustomers = useCallback(
    async (keyword = null) => {
      const res = await searchCustomerInList(userInfo, null, keyword);
      if (res?.data) {
        setCustomers(res.data);
      }
    },
    [setCustomers],
  );

  const handleGetCustomerContactsPersons = useCallback(
    async (customerId) => {
      const res = await getContactsCustomer(customerId, userInfo);
      if (res?.data) {
        setContactPersons(res.data);
        return res.data;
      }
    },
    [setContactPersons],
  );

  const handleGetCustomerContracts = useCallback(
    async (customerId) => {
      const res = await getCustomerContractsList(customerId, userInfo);
      if (res) {
        setContracts(res);
      }
    },
    [setContracts],
  );

  const handleChangeCustomerInForm = useCallback(
    async (data, value) => {
      let customer = JSON.parse(data['data-value']);
      const contactPersons = await getContactsCustomer(customer?.id, userInfo);
      setContactPersons(
        contactPersons?.data?.length ? contactPersons.data : [],
      );
      mainForm.setFieldsValue({
        contract: null,
        base: '',
        contact: null,
      });
      if (value && props.taskData?.id) {
        props.taskEdit({ customer: value }, props.taskData.id);
      }
      await handleGetCustomerContactsPersons(value);
      await handleGetCustomerContracts(value);
    },
    [
      setContactPersons,
      mainForm,
      props.taskEdit,
      handleGetCustomerContactsPersons,
      handleGetCustomerContracts,
    ],
  );

  const handleChangeTaskTemplate = useCallback(
    async (template) => {
      const body = {
        task: props.taskData?.id,
        template,
      };
      const res = await changeShippingTaskTemplate(
        props.taskData?.id,
        body,
        userInfo,
      );

      if (!res) return;
      message.success('Шаблон заявки успешно изменен!');
      props.refreshTaskInfo(props.taskData?.id);
    },
    [props.refreshTaskInfo],
  );

  const handleGetTaskTemplateList = useCallback(async () => {
    const res = await getTaskTemplateRefbookList(userInfo);
    if (!res) return;
    setTaskTemplateList(res);
  }, [setTaskTemplateList]);

  const handleSetFormFieldsValue = useCallback(
    async (data) => {
      mainForm.setFieldsValue({
        cargo: data?.cargo?.id || null,
        status: data?.status || null,
        customer: data?.customer?.id || null,
        our_customer: data?.our_customer || null,
        date_create: data?.date_create
          ? new Intl.DateTimeFormat('ru-RU').format(new Date(data.date_create))
          : new Intl.DateTimeFormat('ru-RU').format(Date.now()),
        app: data && data.app ? data.app : '',
        phone: data ? data.phone_number : '',
        contract: data.contract || null,
        contact: data?.contact || null,
        task_number: data?.task_number || null,
        template: data?.template?.id || null,
        upfront_costs_sum: data?.upfront_costs_sum
          ? priceFormatter(data.upfront_costs_sum)
          : 0,
        delta: data?.delta ? priceFormatter(data.delta) : 0,
        price: data?.price ? priceFormatter(data.price) : 0,
      });

      if (props.taskData?.customer?.id) {
        await handleGetCustomerById(props.taskData?.customer?.id);
        const contactPersons = await handleGetCustomerContactsPersons(
          props.taskData?.customer?.id,
        );
        setContactPersons(contactPersons?.length ? contactPersons : []);
        await handleGetCustomerContracts(props.taskData?.customer?.id);
      }
    },
    [props.taskData],
  );

  const handleGetCustomerById = useCallback(
    async (customerId) => {
      if (!customerId) return;
      const res = await getCustomerById(customerId, userInfo);
      if (res) {
        setCustomers([res]);
      }
    },
    [setCustomers],
  );

  return (
    <>
      <Form
        form={mainForm}
        onValuesChange={formValuesChange}
        layout={'vertical'}
      >
        <Row gutter={5}>
          <Col lg={18} xs={24}>
            <Row gutter={5} className={'reset-margin-form'}>
              <Col lg={8} md={8} xs={24}>
                <Form.Item label={'Номер заявки'} name="task_number">
                  {loading ? (
                    <Skeleton.Input active={true} />
                  ) : (
                    <Input disabled placeholder={'Номер заявки'} />
                  )}
                </Form.Item>
              </Col>
              <Col lg={8} md={8} xs={24}>
                <Form.Item name={'date_create'} label={'от'}>
                  {loading ? (
                    <Skeleton.Input active={true} />
                  ) : (
                    <Input disabled placeholder={'Дата'} />
                  )}
                </Form.Item>
              </Col>
              <Col lg={8} md={8} xs={24}>
                <Form.Item initialValue={'cre'} name={'status'} label="Статус">
                  {loading ? (
                    <Skeleton.Input active={true} />
                  ) : (
                    <Select
                      getPopupContainer={(trigger) =>
                        isMobile ? trigger.parentNode : document.body
                      }
                      placeholder={'Состояние заявки'}
                      onChange={(value) => {
                        props.taskData?.id &&
                          props.taskEdit({ status: value }, props.taskData.id);
                      }}
                    >
                      <Option value={'cre'}>Новая</Option>
                      <Option value={'log'}>Ожидает логиста</Option>
                      <Option value={'pay'}>На оплате</Option>
                      <Option value={'wor'}>В работе</Option>
                      <Option value={'car'}>Ожидает вагон</Option>
                      <Option value={'way'}>В пути</Option>
                      <Option value={'suc'}>Исполнена</Option>
                      <Option value={'clo'}>Закрыта</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col lg={8} md={8} xs={24}>
                <Form.Item
                  disabled={props.taskData?.our_customer}
                  name={'our_customer'}
                  label="Наша компания"
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                  {loading ? (
                    <Skeleton.Input active={true} />
                  ) : (
                    <Select
                      getPopupContainer={(trigger) =>
                        isMobile ? trigger.parentNode : document.body
                      }
                      placeholder={'Выберите компанию'}
                      showSearch
                      filterOption={false}
                      onSearch={(value) =>
                        value?.length > 0
                          ? handleGetOurCustomers(value)
                          : handleGetOurCustomers()
                      }
                      onChange={(value) => {
                        if (value && props.taskData?.id) {
                          props.taskEdit(
                            { our_customer: value },
                            props.taskData.id,
                          );
                        }
                      }}
                    >
                      {ourCustomers?.length > 0 && (
                        <>
                          {ourCustomers.map((item) => {
                            return (
                              <Option key={item.id} value={item.id}>
                                {item?.short_name ||
                                  item?.legal_name ||
                                  item?.email ||
                                  'Не указаны данные'}
                              </Option>
                            );
                          })}
                        </>
                      )}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col lg={8} md={8} xs={24}>
                <Form.Item
                  name={'customer'}
                  label="Клиент"
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                >
                  {loading ? (
                    <Skeleton.Input active={true} size={'default'} />
                  ) : (
                    <Select
                      getPopupContainer={(trigger) =>
                        isMobile ? trigger.parentNode : document.body
                      }
                      showSearch
                      placeholder="Поиск клиента"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSearch={(value) =>
                        value?.length > 0
                          ? handleGetCustomers(value)
                          : handleGetCustomers()
                      }
                      onChange={async (value, data) => {
                        await handleChangeCustomerInForm(data, value);
                      }}
                    >
                      {customers?.length > 0 &&
                        customers.map((customer) => {
                          return (
                            <Option
                              value={customer.id}
                              data-value={JSON.stringify(customer)}
                              key={customer.id}
                            >
                              {customer?.short_name ||
                                customer?.legal_name ||
                                customer?.email ||
                                'Данные не указаны'}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col lg={8} md={8} xs={24}>
                <Form.Item name={'contact'} label="Контакт">
                  {loading ? (
                    <Skeleton.Input active={true} />
                  ) : (
                    <Select
                      getPopupContainer={(trigger) =>
                        isMobile ? trigger.parentNode : document.body
                      }
                      placeholder={'Контактное лицо'}
                      showSearch
                      onChange={(value) => {
                        props.taskData?.id &&
                          props.taskEdit({ contact: value }, props.taskData.id);
                      }}
                    >
                      {contactPersons?.length > 0 ? (
                        <>
                          {contactPersons.map((item) => {
                            return (
                              <Option
                                key={item.id}
                                value={
                                  item.full_name ? item.full_name : item.email
                                }
                              >
                                {item.full_name ? item.full_name : item.email}
                              </Option>
                            );
                          })}
                        </>
                      ) : (
                        <Option value={'none'} disabled>
                          Нет доступных вариантов
                        </Option>
                      )}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item name={'template'} label={'Шаблон'}>
                  {loading ? (
                    <Skeleton.Input active={true} />
                  ) : (
                    <Select
                      getPopupContainer={(trigger) =>
                        isMobile ? trigger.parentNode : document.body
                      }
                      // disabled={!createdTask}
                      // value={template}
                      showSearch
                      placeholder="Шаблон"
                      optionFilterProp="children"
                      onChange={async (value) => {
                        // setTemplate(value);
                        props.taskData?.id &&
                          (await handleChangeTaskTemplate(value));
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {taskTemplateList?.length > 0 ? (
                        taskTemplateList.map((item) => {
                          return (
                            <Option
                              value={item.id}
                              data-value={item.name}
                              key={item.id}
                            >
                              {item.name}
                            </Option>
                          );
                        })
                      ) : (
                        <Option disabled={true} value="Не найдено...">
                          Не найдено...
                        </Option>
                      )}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  rules={[{ required: true, message: 'Обязательное поле' }]}
                  label="Договор"
                  name={'contract'}
                >
                  {loading ? (
                    <Skeleton.Input active={true} />
                  ) : (
                    <Select
                      getPopupContainer={(trigger) =>
                        isMobile ? trigger.parentNode : document.body
                      }
                      showSearch
                      placeholder="Договор"
                      // disabled={!currClient}
                      optionFilterProp="children"
                      onChange={(value) => {
                        mainForm.setFieldsValue({ base: '' });
                        props.taskData?.id &&
                          props.taskEdit(
                            { contract: value },
                            props.taskData.id,
                          );
                      }}
                    >
                      {Array.isArray(contracts) &&
                        contracts?.filter(
                          (item) =>
                            item.status === 'sig' || item.status === 'con',
                        )?.length > 0 &&
                        contracts
                          .filter(
                            (item) =>
                              item.status === 'sig' || item.status === 'con',
                          )
                          .map((contract) => {
                            return (
                              <Option
                                value={contract.id}
                                data-value={JSON.stringify(contract)}
                                key={contract.id}
                              >
                                Договор №{contract.code_contract}
                              </Option>
                            );
                          })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={6} xs={24}>
            <Row gutter={5} className={'reset-margin-form'}>
              <Col lg={24} md={8} xs={24}>
                <Form.Item initialValue={0} name={'price'} label="Клиенту">
                  {loading ? (
                    <Skeleton.Input active={true} />
                  ) : (
                    <Input disabled />
                  )}
                </Form.Item>
              </Col>
              <Col lg={24} md={8} xs={24}>
                <Form.Item
                  initialValue={0}
                  name={'upfront_costs_sum'}
                  label="Расходы"
                >
                  {loading ? (
                    <Skeleton.Input active={true} />
                  ) : (
                    <Input disabled />
                  )}
                </Form.Item>
              </Col>
              <Col lg={24} md={8} xs={24}>
                <Form.Item initialValue={0} name={'delta'} label="Дельта">
                  {loading ? (
                    <Skeleton.Input active={true} />
                  ) : (
                    <Input disabled />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default BasicInformationForm;

BasicInformationForm.propTypes = {
  taskData: PropTypes.object.isRequired,
  refreshTaskInfo: PropTypes.func.isRequired,
  taskEdit: PropTypes.func.isRequired,
};
