import PropTypes from 'prop-types';
import SortableList from './SortableList';
import SortableItem, { DragHandle } from './SortableItem';
import { Checkbox, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';

const DragAndDropUserSections = (props) => {
  const [copyUserSectionsList, setCopyUserSectionsList] = useState([]);

  useEffect(() => {
    if (props.userSectionsList) {
      setCopyUserSectionsList(props.userSectionsList);
    }
  }, [props.userSectionsList]);

  const handleChangeVisibleSection = (itemSectionId, checkboxValue) => {
    const indexOfItemInArray = copyUserSectionsList.findIndex(
      (item) => item.section_id === itemSectionId,
    );

    let userSectionsListForUpdate = [...copyUserSectionsList];

    if (!userSectionsListForUpdate?.length) return;
    if (indexOfItemInArray > -1) {
      userSectionsListForUpdate[indexOfItemInArray] = {
        ...copyUserSectionsList[indexOfItemInArray],
        value: checkboxValue,
      };

      props.onChangeList(userSectionsListForUpdate);
    }
  };

  return (
    <>
      <SortableList
        items={copyUserSectionsList}
        renderItem={(item) => (
          <SortableItem index={item?.index}>
            <Row gutter={10} key={item.section_id}>
              <Col>
                <Checkbox
                  checked={item?.value}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    handleChangeVisibleSection(
                      item?.section_id,
                      e.target.checked,
                    );
                  }}
                >
                  <Col style={{ color: '#333333' }}>{item?.section}</Col>
                </Checkbox>
              </Col>
            </Row>
            <DragHandle />
          </SortableItem>
        )}
        onChange={(list) => {
          props.onChangeList(list);
        }}
      />
    </>
  );
};

DragAndDropUserSections.propTypes = {
  userSectionsList: PropTypes.arrayOf(PropTypes.object),
  onChangeList: PropTypes.func,
};

export default DragAndDropUserSections;
