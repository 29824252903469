import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

const LeadCallGroupTask = (props) => {
  return (
    <Tooltip placement={'left'} title={`Кол-во ${props?.countTasks}`}>
      <div
        onClick={() => props.onClick()}
        onDoubleClick={(e) => e.stopPropagation()}
        className={'task-item-day-month'}
        style={{
          width: '100%',
          backgroundColor: '#3d66eeee',
          marginLeft: props.useForDayViewCalendar ? '10px' : '',
          maxWidth: props.useForDayViewCalendar ? '200px' : '',
        }}
      >
        <div
          className={'task-item-day-month-name'}
          style={{ color: '#ffffff' }}
        >
          {props.title}
        </div>
      </div>
    </Tooltip>
  );
};
LeadCallGroupTask.propTypes = {
  title: PropTypes.string,
  countTasks: PropTypes.number,
  onClick: PropTypes.func,
};

export default LeadCallGroupTask;
