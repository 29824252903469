import { Button, Row } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { getMonthName } from '../calendarUtils/getMonthName';

const MonthSwitcher = (props) => {
  const nextMonth = (index) => {
    index++;
    props.changeMonthIndex(index, 'next');
  };
  const prevMonth = (index) => {
    index--;
    props.changeMonthIndex(index, 'prev');
  };

  return (
    <>
      <Row style={{ minWidth: '115px' }}>
        <Button
          onClick={() => prevMonth(props.currentMonthIndex)}
          type={'text'}
          size={'small'}
          icon={<DoubleLeftOutlined />}
        />

        <div style={{ textAlign: 'center', fontWeight: '600', width: '100px' }}>
          {getMonthName(props.currentMonthIndex, true) +
            ' ' +
            props.currentYear}
        </div>

        <Button
          onClick={() => nextMonth(props.currentMonthIndex)}
          type={'text'}
          size={'small'}
          icon={<DoubleRightOutlined />}
        />
      </Row>
    </>
  );
};

MonthSwitcher.propTypes = {
  currentMonthIndex: PropTypes.any.isRequired,
  currentYear: PropTypes.number.isRequired,
  changeMonthIndex: PropTypes.func.isRequired,
};

export default MonthSwitcher;
