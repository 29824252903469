import React from 'react';

const NotUserRole = () => {
  return (
    <div>
      <div className="centerText">
        <h1 className="text">У вас нет прав доступа.</h1>
      </div>
    </div>
  );
};

export default NotUserRole;
