import PropTypes from 'prop-types';
import TaskItemDayOfMonth from '../month/tasks/TaskItemDayOfMonth';
import DropdownViewAllTasks from '../month/dayOfMonth/dropdownViewAllTasks/DropdownViewAllTasks';
import detectArray from '../../../utils/detectArray';

const CellTime = (props) => {
  const getCountHiddenTasks = (taskLength) => {
    if (taskLength <= 0) return 0;
    if (props.useForDayViewCalendar) {
      return taskLength >= 7
        ? props.tasksCellCallLead?.length > 0
          ? taskLength - 6
          : taskLength - 7
        : 0;
    } else {
      return displayTasks() ? taskLength - 1 : taskLength;
    }
  };

  const displayTasks = () => {
    return props.tasksCellCallLead?.length <= 0;
  };

  const allowTaskOutput = (index) => {
    if (props.useForDayViewCalendar) {
      return props.tasksCellCallLead?.length > 0 ? index <= 5 : index <= 6;
    } else {
      return index === 0 && displayTasks();
    }
  };

  return (
    <>
      <div
        className={
          props.useForDayViewCalendar ? 'cell-time cell-time-flex' : 'cell-time'
        }
        onDoubleClick={() => props.handleCreateTask(props.day, props.time)}
        style={props.index === 17 ? { borderBottom: 'none' } : {}}
      >
        {props.displayTimeInCell ? (
          <div className={'cell-time-title'}>{props.time}</div>
        ) : (
          <></>
        )}

        {props.tasksCellCallLead?.length > 0 ? (
          <>
            <DropdownViewAllTasks
              day={props.day}
              tasks={props.tasksCellCallLead}
              isWeekDay={true}
              groupTitle={'ЗВОНКИ'}
              useForDayViewCalendar={props.useForDayViewCalendar}
              useForCallGroupLead={true}
              timePeriodTitle={props.timePeriodTitle}
              goToCompetitionDate={(day) => {
                props.goToCompetitionDate(day);
              }}
              handleEditTask={(task) => props.handleEditTask(task)}
            />
          </>
        ) : (
          <></>
        )}

        {props.tasksCell?.length > 0 &&
          props.tasksCell.map((task, index) => {
            if (allowTaskOutput(index)) {
              if (!detectArray(task)) {
                return (
                  <TaskItemDayOfMonth
                    key={index}
                    task={task}
                    useForDayViewCalendar={props.useForDayViewCalendar}
                    handleEditTask={(task) => props.handleEditTask(task)}
                  />
                );
              } else if (detectArray(task)) {
                return (
                  <DropdownViewAllTasks
                    key={index}
                    day={props.day}
                    tasks={task[1] ? task[1] : []}
                    isWeekDay={true}
                    groupTitle={'ПЕРСОНАЛЬНЫЕ ЗАДАЧИ'}
                    useForDayViewCalendar={props.useForDayViewCalendar}
                    useForCallGroupLead={true}
                    timePeriodTitle={props.timePeriodTitle}
                    goToCompetitionDate={(day) => {
                      props.goToCompetitionDate(day);
                    }}
                    handleEditTask={(task) => props.handleEditTask(task)}
                  />
                );
              }
            }
          })}

        {props.tasksCell?.length >= 1 &&
          getCountHiddenTasks(props.tasksCell?.length) > 0 && (
            <DropdownViewAllTasks
              day={props.day}
              tasks={props.tasksCell}
              countHiddenTasks={getCountHiddenTasks(props.tasksCell?.length)}
              timePeriodTitle={props.timePeriodTitle}
              isWeekDay={true}
              goToCompetitionDate={(day) => {
                props.goToCompetitionDate(day);
              }}
              useForDayViewCalendar={props.useForDayViewCalendar}
              handleEditTask={(task) => props.handleEditTask(task)}
            />
          )}
      </div>
    </>
  );
};
CellTime.propTypes = {
  day: PropTypes.any,
  time: PropTypes.any,
  index: PropTypes.number,
  tasksCell: PropTypes.any,
  tasksCellCallLead: PropTypes.any,
  displayTimeInCell: PropTypes.bool,
  useForDayViewCalendar: PropTypes.bool,
  timePeriodTitle: PropTypes.string,
  handleEditTask: PropTypes.func,
  handleCreateTask: PropTypes.func,
  goToCompetitionDate: PropTypes.func,
};

export default CellTime;
