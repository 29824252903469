import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Form,
  Modal,
  Row,
  Col,
  Collapse,
  Select,
  Upload,
  Input,
  message,
} from 'antd';
import { restApiHost } from '../../utils/appVariables';
import { InboxOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/ru';
import CreationOfKpModal from '../sendKpOrPresentation/CreationOfKpModal';
import { convertObjFilterToUrl } from '../../utils/getFilters';
import { call } from '../../apiUtils/call';
import { userInformations } from '../../contex';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import * as ClassicEditor from '../../ckeditor';
import MultipleSelectUseSearch from '../UiComponents/selects/MultipleSelectUseSearch';
import { ErrorBoundary } from '../ErrorBoundary';

const { Panel } = Collapse;
const { Option } = Select;
const { Dragger } = Upload;

const hash = require('object-hash');

const ResendEmailModal = ({
  open,
  profileInfo,
  handleClose,
  listEmails,
  selectedSendObj,
}) => {
  const [form] = Form.useForm();
  const [contacts, setContacts] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loadingSend, setLoadingSend] = useState(false);
  const { userInfo } = useContext(userInformations);
  const [emailHtml, setEmailHtml] = useState('');

  /*attachKp*/
  const [loadingPreTasks, setLoadingPreTasks] = useState(false);
  const [preTasks, setPreTasks] = useState([]);
  const [totalPreTasks, setTotalPreTasks] = useState(0);
  const [visibleAttachKpModal, setVisibleAttachKpModal] = useState(false);
  const [preTaskTablePage, setPreTaskTablePage] = useState(1);
  const [filtersTablePreTasks, setFiltersTablePreTasks] = useState(null);
  const [fileHashes, setFileHashes] = useState([]);

  useEffect(() => {
    if (profileInfo) {
      if (profileInfo?.signature) {
        setEmailHtml(profileInfo.signature);
      } else {
        const html = `<br><br><p style="margin:0;font-size:14px"><span style="font-size:16px;"><strong>ООО "Квиктранс"</strong></span></p>
                <p style="margin:0;font-size:14px;mso-line-height-alt:16.8px">&nbsp;</p>
                <p style="margin:0;font-size:14px">
                    <span style="font-size:13px;">С уважением, к Вам и Вашему бизнесу ${
                      profileInfo?.full_name || ''
                    }</span><br>
                    <span style="font-size:13px;">${
                      profileInfo?.groups?.length > 0
                        ? profileInfo.groups[0].name
                        : ''
                    } ООО «Квиктранс»</span><br>
                    <span style="font-size:13px;">426032, г. Ижевск, ул. Карла Маркса, дом 1, литер Д, оф.414</span><br>
                    <span style="font-size:13px;">Тел: ${
                      profileInfo?.phone_number || ''
                    }</span><br>
                    <span style="font-size:13px;">E-mail: ${
                      profileInfo?.email || ''
                    }</span><br>
                    <span style="font-size:13px;">www.quicklytrans.ru</span><br>
                </p>`;
        setEmailHtml(html);
      }
    }
  }, [profileInfo]);

  useEffect(() => {
    if (selectedSendObj) {
      form.setFieldsValue({
        subject: selectedSendObj.subject,
      });
      if (selectedSendObj?.body) {
        const date = selectedSendObj?.date
          ? `${moment(selectedSendObj?.date).format('L')}, ${moment(
              selectedSendObj?.date,
            ).format('LT')}`
          : `${moment().format('L')}, ${moment().format('LT')}`;
        const name = `"${
          selectedSendObj?.contact_person || selectedSendObj?.from_email
        }" &#60;${selectedSendObj.from_email}&#62;`;
        let html = ``;
        if (profileInfo) {
          if (profileInfo?.signature) {
            html += profileInfo.signature;
          } else {
            html += `<br><br><p style="margin:0;font-size:14px"><span style="font-size:16px;"><strong>ООО "Квиктранс"</strong></span></p>
                        <p style="margin:0;font-size:14px;mso-line-height-alt:16.8px">&nbsp;</p>
                        <p style="margin:0;font-size:14px">
                            <span style="font-size:13px;">С уважением, к Вам и Вашему бизнесу ${
                              profileInfo?.full_name || ''
                            }</span><br>
                            <span style="font-size:13px;">${
                              profileInfo?.groups?.length > 0
                                ? profileInfo.groups[0].name
                                : ''
                            } ООО «Квиктранс»</span><br>
                            <span style="font-size:13px;">426032, г. Ижевск, ул. Карла Маркса, дом 1, литер Д, оф.414</span><br>
                            <span style="font-size:13px;">Тел: ${
                              profileInfo?.phone_number || ''
                            }</span><br>
                            <span style="font-size:13px;">E-mail: ${
                              profileInfo?.email || ''
                            }</span><br>
                            <span style="font-size:13px;">www.quicklytrans.ru</span><br>
                        </p>`;
          }
        }
        html += `<br><br>${date}, ${name}:<blockquote>${selectedSendObj.body}</blockquote>`;

        setEmailHtml(html);
      }
    } else {
      form.resetFields();
    }
  }, [selectedSendObj, listEmails, profileInfo]);

  const searchContacts = async (keyword) => {
    try {
      const url = `${restApiHost}/mail/search_contacts/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ keyword }),
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setContacts(json.data);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на поиск контактов');
    }
  };

  const handleSearchContacts = async (keyword) => {
    if (keyword.length > 0) {
      await searchContacts(keyword);
    }
  };

  const props = {
    beforeUpload: () => false,
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        setFileList(fileList);
      }
    },
    multiple: true,
    defaultFileList: fileList,
    fileList,
  };

  const sendEmail = async (values) => {
    setLoadingSend(true);
    try {
      const formData = new FormData();
      for (let key in values) {
        if (
          (key === 'email' && values[key]) ||
          (key === 'cc' && values[key]) ||
          (key === 'bcc' && values[key])
        ) {
          formData.append(key, values[key].join(','));
        } else {
          values[key] && formData.append(key, `${values[key]}`);
        }
      }
      formData.append('footer', 'true');
      formData.append('text_content', emailHtml);
      selectedSendObj?.id ||
        (selectedSendObj?.msg_id &&
          formData.append(
            'in_reply_to',
            `${selectedSendObj?.id || selectedSendObj?.msg_id}`,
          ));

      if (fileList.length > 0) {
        fileList.map((file) => {
          if (file.originFileObj) {
            formData.append('files', file.originFileObj, file.name);
          }
        });
      }

      const url = `${restApiHost}/mail/send/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: formData,
        },
        userInfo.current?.haveViewBranch,
      );

      if (!json) return;

      const attachedFileRemains = checkFileIsStillAttached();
      if (attachedFileRemains) {
        await putDownThatTheCPWasSent();
        setFileHashes([]);
      }
      message.success('Письмо успешно отправлено!');
      setFileList([]);
      handleClose();
    } catch (e) {
      console.log(e);
      message.error('Ошибка отправки письма');
    } finally {
      setLoadingSend(false);
    }
  };

  const putDownThatTheCPWasSent = async () => {
    try {
      const url = `${restApiHost}/leads/statistic/cp_sended`;
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 500 || res.status === 400 || res.status === 404) {
        message.error('Ошибка запроса на подтверждение прикрепления КП');
        return;
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на поиск контактов');
    }
  };

  const getPreTaskList = async (page = 1, params, me) => {
    let urlParams;

    if (params) {
      urlParams = convertObjFilterToUrl(params, null);
    }

    try {
      const url =
        urlParams && me
          ? `${restApiHost}/leads/pre_tasks/?filter=me&page=${page}${urlParams}`
          : urlParams && !me
          ? `${restApiHost}/leads/pre_tasks/?page=${page}${urlParams}`
          : !urlParams && me
          ? `${restApiHost}/leads/pre_tasks/?filter=me&page=${page}`
          : `${restApiHost}/leads/pre_tasks/?page=${page}`;

      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      return {
        preTask: json?.data ? json.data : [],
        total: json.count,
      };
    } catch (e) {
      console.log('fetch applications error', e);
    }
  };

  const handleUpdate = async (page = 1, params, me) => {
    setLoadingPreTasks(true);
    setPreTaskTablePage(page);
    setFiltersTablePreTasks(params);
    const preTasks = await getPreTaskList(page, params, me);
    setPreTasks(preTasks?.preTask);
    setTotalPreTasks(preTasks?.total);
    setLoadingPreTasks(false);
  };

  const createHashInFile = async (file) => {
    try {
      const hashFile = await hash(file, {
        algorithm: 'md5',
        encoding: 'base64',
      });
      if (hashFile) {
        return hashFile;
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка при создании hash файла');
    }
  };
  const checkFileIsStillAttached = () => {
    let attachment = false;
    if (fileList?.length > 0 && fileHashes?.length > 0) {
      for (const file of fileList) {
        for (const fileHash of fileHashes) {
          if (file?.hash === fileHash) {
            attachment = true;
            break;
          }
        }
      }
    }
    return attachment;
  };
  const setFileToFileList = async (file) => {
    if (file) {
      const fileHash = await createHashInFile(file);
      const fileListCopy = fileList;
      const copyFileHashes = fileHashes;
      if (fileHash) {
        copyFileHashes.push(fileHash);
        setFileHashes(copyFileHashes);
      }
      fileListCopy.push({
        originFileObj: file,
        name: file?.name,
        hash: fileHash ? fileHash : null,
      });
      setFileList(fileListCopy);
    }
    message.success('PDF прикреплен.');
  };
  const openModalAttachKP = async () => {
    setLoadingPreTasks(true);
    setVisibleAttachKpModal(true);
    const preTasks = await getPreTaskList();
    setPreTasks(preTasks?.preTask);
    setTotalPreTasks(preTasks?.total);
    setLoadingPreTasks(false);
  };
  const closeModalAttachKp = () => {
    setVisibleAttachKpModal(false);
  };
  return (
    <Modal
      visible={open}
      title={'Переслать письмо'}
      width={1000}
      onCancel={() => {
        form.resetFields();
        setEmailHtml('');
        handleClose();
      }}
      footer={[
        <Button
          key="close"
          onClick={() => {
            form.resetFields();
            setEmailHtml('');
            handleClose();
          }}
        >
          Закрыть
        </Button>,
        <Button
          key="back"
          type="primary"
          disabled={loadingSend}
          onClick={() => {
            form.validateFields().then((values) => {
              values && sendEmail(values);
            });
          }}
          loading={loadingSend}
        >
          Отправить
        </Button>,
      ]}
    >
      <Form form={form} className={'reset-margin-form'}>
        <Row gutter={15} className={'color-disabled'}>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              name={'email'}
            >
              <MultipleSelectUseSearch
                onSearch={handleSearchContacts}
                placeholder="Кому"
                style={{
                  width: '100%',
                }}
                options={contacts.map((contact) => {
                  return (
                    <Option key={contact.email} value={contact.email}>
                      {contact.name} {`<${contact.email}>`}
                    </Option>
                  );
                })}
              />
            </Form.Item>
          </Col>

          <Col lg={24} md={24} xs={24}>
            <Form.Item name={'cc'}>
              <ErrorBoundary>
                <MultipleSelectUseSearch
                  onSearch={handleSearchContacts}
                  placeholder="Копия"
                  style={{
                    width: '100%',
                  }}
                  options={contacts.map((contact) => {
                    return (
                      <Option
                        key={contact.email}
                        value={`${contact.name} <${contact.email}>`}
                      >
                        {contact.name} {`<${contact.email}>`}
                      </Option>
                    );
                  })}
                />
              </ErrorBoundary>
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item name={'bcc'}>
              <ErrorBoundary>
                <MultipleSelectUseSearch
                  onSearch={handleSearchContacts}
                  placeholder="Скрытая копия"
                  style={{
                    width: '100%',
                  }}
                  options={contacts.map((contact) => {
                    return (
                      <Option
                        key={contact.email}
                        value={`${contact.name} <${contact.email}>`}
                      >
                        {contact.name} {`<${contact.email}>`}
                      </Option>
                    );
                  })}
                />
              </ErrorBoundary>
            </Form.Item>
          </Col>

          <Col lg={24} md={24} xs={24}>
            <Form.Item
              name={'subject'}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <Input placeholder={'Тема'} />
            </Form.Item>
          </Col>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              marginTop: '5px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                paddingRight: '7.5px',
              }}
            >
              <Button
                key="back"
                type="primary"
                disabled={loadingSend}
                onClick={async () => {
                  await openModalAttachKP();
                }}
                loading={loadingSend}
              >
                Прикрепить КП
              </Button>
            </div>
          </div>
          <Col lg={24} md={24} xs={24} style={{ margin: '10px 0 30px 0' }}>
            <Collapse defaultActiveKey={['1']} ghost>
              <Panel header="Загрузка файлов" key="1">
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Кликните или перенесите файл для загрузки
                  </p>
                </Dragger>
              </Panel>
            </Collapse>
          </Col>
          <Col lg={24} md={24} xs={24}>
            {!visibleAttachKpModal ? (
              <div className="email-editor">
                <CKEditor
                  editor={ClassicEditor}
                  data={emailHtml}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    if (data) {
                      setEmailHtml(data);
                    }
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Form>
      <ErrorBoundary>
        <CreationOfKpModal
          visibleModal={visibleAttachKpModal}
          preTasks={preTasks}
          loading={loadingPreTasks}
          totalPreTask={totalPreTasks}
          pageTable={preTaskTablePage}
          tableFilters={filtersTablePreTasks}
          setFileToFileList={(file) => setFileToFileList(file)}
          closeVisibleModal={closeModalAttachKp}
          updatePreTask={(page, params, me) => handleUpdate(page, params, me)}
        />
      </ErrorBoundary>
    </Modal>
  );
};

export default ResendEmailModal;
