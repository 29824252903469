import React, { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from '@material-ui/core';
import { restApiHost } from '../utils/appVariables';
import MuiAlert from '@material-ui/lab/Alert';
import ResetPassword from '../components/resetPassword/ResetPassword';
import { ErrorBoundary } from '../components/ErrorBoundary';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Login = () => {
  const navigate = useNavigate();
  const [errorLogin, setErrorLogin] = useState(false);
  const [successLogin, setSuccessLogin] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [resetPasswordForm, setResetPasswordForm] = useState(false);
  const [successRestorePassword, setSuccessRestorePassword] = useState(false);
  const [successTextRestorePassword, setSuccessTextRestorePassword] =
    useState(false);

  const handleLogin = async (loginData) => {
    setLoadingResponse(true);
    try {
      const url = `${restApiHost}/accounts/login/`;
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });
      const json = await res.json();
      if (res.status !== 201 && res.status !== 200) {
        if (json?.data) {
          setErrorText(`${json.data}`);
          setErrorLogin(true);
        } else {
          setErrorText('Неизвестный ответ от сервера');
          setErrorLogin(true);
        }
        return;
      }

      if (json?.data) {
        localStorage.setItem('token', json.data);
        handleRedirectToDashboard();
      }
    } catch (e) {
      setErrorText('Ошибка запроса к серверу, попробуйте позднее');
      setErrorLogin(false);
      console.log(e);
    } finally {
      setLoadingResponse(false);
    }
  };

  const handleRedirectToDashboard = useCallback(() => {
    console.log('handleRedirectToDashboard');
    navigate('/dashboard');
  }, [navigate]);

  const handleCloseSnack = () => {
    setErrorLogin(false);
    setSuccessLogin(false);
    setSuccessRestorePassword(false);
  };
  const backToLoginForm = (
    successRestorePassword,
    successTextRestorePassword,
  ) => {
    setSuccessRestorePassword(successRestorePassword);
    setSuccessTextRestorePassword(successTextRestorePassword);
    setResetPasswordForm(false);
  };
  return (
    <>
      <Helmet>
        <title>Авторизация | КвикТранс</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'calc(100vh - 64px)',
          justifyContent: 'center',
        }}
      >
        {!resetPasswordForm ? (
          <Container maxWidth="sm">
            {successRestorePassword ? (
              <Box sx={{ py: 2 }}>
                <Alert onClose={handleCloseSnack} severity="success">
                  {successTextRestorePassword}
                </Alert>
              </Box>
            ) : null}
            {successLogin ? (
              <Box sx={{ py: 2 }}>
                <Alert onClose={handleCloseSnack} severity="success">
                  Вы успешно авторизовались!
                </Alert>
              </Box>
            ) : null}

            {errorLogin ? (
              <Box sx={{ py: 2 }}>
                <Alert onClose={handleCloseSnack} severity="error">
                  {errorText}
                </Alert>
              </Box>
            ) : null}

            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Email должен быть валиден')
                  .max(255)
                  .required('Пожалуйста, введите email'),
                password: Yup.string()
                  .max(255)
                  .required('Пожалуйста, введите пароль'),
              })}
              onSubmit={async (values) => {
                await handleLogin(values);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                //isSubmitting,
                touched,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 3 }}>
                    <Typography color="textPrimary" variant="h2">
                      Авторизация
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      CRM система КвикТранс
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Пароль"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <p
                    onClick={() => {
                      setResetPasswordForm(true);
                    }}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    Забыли пароль?
                  </p>
                  <Box sx={{ py: 2 }}>
                    <Button
                      disabled={loadingResponse}
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {loadingResponse ? 'Загрузка...' : 'Войти'}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        ) : (
          <ErrorBoundary>
            <ResetPassword backToLoginForm={backToLoginForm} />
          </ErrorBoundary>
        )}
      </Box>
    </>
  );
};

export default Login;
