import { Card, List, Typography } from 'antd';
import { call } from '../../../apiUtils/call';
import { restApiHost } from '../../../utils/appVariables';
import { useContext, useEffect, useState } from 'react';
import { userInformations } from '../../../contex';
// ts(2865)
import { Session as SessionType } from '../../../types';
import SessionItem from './SessionItem';
import { Container, Grid } from '@material-ui/core';
import { ErrorBoundary } from '../../ErrorBoundary';

interface AccountSessionsProps {
  loading: boolean;
}

// @ts-expect-error: Should have generic type
const loadSessions = (userInfo) => {
  try {
    return call(
      `${restApiHost}/accounts/sessions/`,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo?.current?.haveViewBranch,
      null,
    );
  } catch (e) {
    console.log(e);
  }
};

const { Text } = Typography;

export default function AccountSecurity({ loading }: AccountSessionsProps) {
  // @ts-expect-error: Should have generic type
  const { userInfo } = useContext(userInformations);
  const [sessions, setSessions] = useState<SessionType[] | undefined>(
    undefined,
  );

  const refreshSessions = async () => {
    loadSessions(userInfo)?.then((data) => {
      setSessions(data.data);
    });
  };

  useEffect(() => {
    refreshSessions();
  }, []);

  const renderSession = (session: SessionType) => {
    return <SessionItem session={session} refreshSessions={refreshSessions} />;
  };

  return (
    <Container maxWidth="lg">
      <Grid>
        <Grid>
          <ErrorBoundary>
            <Card style={{ borderRadius: '10px', overflow: 'hidden' }}>
              <Text strong style={{ fontSize: 24 }}>
                Сессии
              </Text>
              <List
                loading={Object.is(sessions, undefined) || loading}
                dataSource={sessions}
                renderItem={renderSession}
                locale={{ emptyText: 'Нет сессий.' }}
              />
            </Card>
          </ErrorBoundary>
        </Grid>
      </Grid>
    </Container>
  );
}
