import React from 'react';
import { Timeline, Pagination, Empty, Spin } from 'antd';
import moment from 'moment';

const TaskHistoryTable = ({ data, loading, task, total, getHistory, page }) => {
  const handleTableChange = (current) => {
    getHistory(task, current);
  };

  const getColorByChange = (type) => {
    switch (type) {
      case 'change':
        return 'blue';
      case 'create':
        return 'green';
      case 'delete':
        return 'red';
      default:
        return 'grey';
    }
  };

  const parseDate = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm');
  };

  return (
    <>
      <Spin spinning={loading}>
        {data?.length > 0 ? (
          <div
            style={{ overflowY: 'auto', maxHeight: '600px', padding: '20px' }}
          >
            <Timeline>
              {data?.map((historyItem, index) => (
                <Timeline.Item
                  key={index}
                  className={index !== data?.length - 1 ? 'antd-arrow' : ''}
                  color={getColorByChange(historyItem[0])}
                >
                  {historyItem[0] === 'change' ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p style={{ margin: 0, fontWeight: 600 }}>
                        {historyItem[1]}
                      </p>
                      <p style={{ margin: 0, fontWeight: 600 }}>
                        Новое значение: {historyItem[3]?.toString()}
                      </p>
                      <p style={{ margin: 0, fontWeight: 600 }}>
                        Старое значение: {historyItem[2]?.toString()}
                      </p>
                      <p style={{ margin: 0, opacity: 60 }}>
                        {parseDate(historyItem[4])}
                      </p>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p style={{ margin: 0, fontWeight: 600 }}>
                        {historyItem[1]}
                      </p>
                      <p style={{ margin: 0, opacity: 60 }}>
                        {parseDate(historyItem[2])}
                      </p>
                    </div>
                  )}
                </Timeline.Item>
              ))}
            </Timeline>
            {total > 10 ? (
              <Pagination
                showSizeChanger={false}
                total={total}
                pageSize={10}
                current={page}
                onChange={handleTableChange}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <>
            <Empty description={<b>Нет истории изменений</b>} />
          </>
        )}
      </Spin>
    </>
  );
};

export default TaskHistoryTable;
