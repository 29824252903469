import PropTypes from 'prop-types';
import { Steps } from 'antd';

const { Step } = Steps;

const StepsDrawer = (props) => {
  return (
    <div style={{ width: '100%' }}>
      <Steps onChange={props.onChangeStep} current={props.currentStep}>
        <Step title="Раздел" description={'Выбор раздела'} />
        <Step
          disabled={props.currentStep === 0}
          title="Права"
          description={'Редактирование прав'}
        />
      </Steps>
    </div>
  );
};

StepsDrawer.propTypes = {
  currentStep: PropTypes.number,
  onChangeStep: PropTypes.func,
};

export default StepsDrawer;
