import React from 'react';
import PropTypes from 'prop-types';
import { ControlOutlined } from '@ant-design/icons';
import { Button, Dropdown, Switch, Space, Divider } from 'antd';
import styled from 'styled-components';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const Label = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
  grid-gap: 5px;
`;
const menu = (props) => {
  return (
    <>
      <Space
        direction="vertical"
        style={{
          width: 200,
          padding: 15,
          backgroundColor: 'white',
          borderRadius: 8,
        }}
      >
        <Label>
          Уведомления чата
          <Switch
            checked={props.useChatNotifications}
            onChange={(value) => {
              props.handleChangeUseChatNotifications(value);
            }}
          />
        </Label>
        <Divider style={{ margin: '10px 0' }} />
        <Label>
          Звук уведомлений
          <Switch
            checked={props.useSound}
            onChange={(value) => {
              props.handleSound(value);
            }}
          />
        </Label>
      </Space>
    </>
  );
};
const SoundButtons = (props) => {
  const isMobile = useCheckMobileScreen();

  return (
    <Dropdown
      overlay={menu(props)}
      placement="bottomLeft"
      trigger={[isMobile ? 'click' : 'hover']}
      arrow
    >
      <Button
        shape="default"
        size={props.isMobile ? 'small' : 'middle'}
        icon={<ControlOutlined />}
      />
    </Dropdown>
  );
};

SoundButtons.propTypes = {
  useSound: PropTypes.bool,
  useChatNotifications: PropTypes.bool,
  handleSound: PropTypes.func.isRequired,
  handleChangeUseChatNotifications: PropTypes.func.isRequired,
};

export default SoundButtons;
