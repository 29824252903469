import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';

const SendCodeAndPassword = ({
  backToStepOne,
  handleCloseSnack,
  loadingRestorePassword,
  restorePassword,
  errorRestorePassword,
  successSendCode,
  successTextSendCode,
  errorTextRestorePassword,
}) => {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <>
      {successSendCode ? (
        <Box sx={{ py: 2 }}>
          <Alert onClose={handleCloseSnack} severity="success">
            {successTextSendCode}
          </Alert>
        </Box>
      ) : null}

      {errorRestorePassword ? (
        <Box sx={{ py: 2 }}>
          <Alert onClose={handleCloseSnack} severity="error">
            {errorTextRestorePassword}
          </Alert>
        </Box>
      ) : null}

      <Formik
        initialValues={{
          code: '',
          password: '',
          password_repeat: '',
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().required('Пожалуйста, введите код'),
          password: Yup.string()
            .min(6, 'Пароль слишком короткий')
            .required('Пожалуйста, введите пароль'),
          password_repeat: Yup.string()
            .required('Пожалуйста, повторите пароль')
            .oneOf([Yup.ref('password'), null], 'Пароли не совпадают'),
        })}
        onSubmit={(values) => {
          restorePassword(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          //isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <Typography color="textPrimary" variant="h2">
                Восстановление пароля
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                CRM система КвикТранс
              </Typography>
            </Box>
            <TextField
              error={Boolean(touched.code && errors.code)}
              fullWidth
              helperText={touched.code && errors.code}
              label="Код"
              margin="normal"
              name="code"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.code}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Пароль"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password_repeat && errors.password_repeat)}
              fullWidth
              helperText={touched.password_repeat && errors.password_repeat}
              label="Повтор пароль"
              margin="normal"
              name="password_repeat"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.repeat}
              variant="outlined"
            />
            <p
              onClick={() => {
                backToStepOne();
              }}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              Не пришел код?
            </p>
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {loadingRestorePassword ? 'Заугрузка...' : 'Сохранить'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SendCodeAndPassword;
