import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { restApiHost } from '../../utils/appVariables';
import { Card } from '@material-ui/core';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';

const TasksStatTable = (props) => {
  const [applications, setApplications] = useState([]);
  const [, setLoading] = useState(false);
  const { userInfo } = useContext(userInformations);

  useEffect(async () => {
    getApplications();
  }, []);

  const getApplications = async () => {
    setLoading(true);
    try {
      const url = `${restApiHost}/leads/pre_tasks/?filter=me`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setApplications(json.data);
        setLoading(false);
      }
    } catch (e) {
      console.log('fetch applications error', e);
      setLoading(false);
    }
  };

  /*  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: 'Лид',
      key: 'lead',
      dataIndex: 'lead',
      width: 150,
      render: (lead) => (lead && lead.company_name ? lead.company_name : '-'),
    },
    { title: 'Комментарий', key: 'comment', dataIndex: 'comment', width: 300 },
    {
      title: 'Полный маршрут',
      key: 'full_route',
      dataIndex: 'waypoint',
      width: 600,
      render: (pre_waypoint) => {
        return (
          <>
            {pre_waypoint && pre_waypoint.length > 0
              ? getFullAddrStr(pre_waypoint)
              : 'Не указано'}
          </>
        );
      },
    },
  ];*/

  const renderPreApplications = (data) => {
    return data.map((item) => {
      const date = item.date_create ? new Date(item.date_create) : null;
      return (
        <div key={item.id} className={'pre-tasks__item'}>
          <div className="pre-tasks__block">
            <div className="pre-tasks__icon">
              {item.lead?.company_name ? item.lead.company_name[0] : null}
            </div>
            <div className="pre-tasks__company-name">
              {item.lead?.company_name || 'Не указано'}
            </div>
            <div className="pre-tasks__text">
              {item.template?.name ? (
                <Tooltip title={item.template.name} placement={'topLeft'}>
                  {item.template.name}
                </Tooltip>
              ) : (
                'Не указано'
              )}
            </div>
            <div className="pre-tasks__text">
              {item.route ? (
                <Tooltip title={item.route} placement={'topLeft'}>
                  {item.route}
                </Tooltip>
              ) : (
                'Без маршрута'
              )}
            </div>
            <div className="pre-tasks__text">
              {date
                ? new Intl.DateTimeFormat('ru-RU').format(date)
                : 'Не указано'}
            </div>
            <div className="pre-tasks__price">{item.price}</div>
          </div>
        </div>
      );
    });
  };

  return (
    <Card {...props}>
      <div className="leads-header">
        <span>Предварительные заявки</span>
      </div>
      <div className="pre-tasks">
        {applications && applications.length > 0
          ? renderPreApplications(applications)
          : null}
      </div>
    </Card>
  );
};

export default TasksStatTable;
