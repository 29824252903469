import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, Empty, message, Row, Spin } from 'antd';
import EmailBody from './new/EmailBody';
import EmailListItem from './new/EmailListItem';
import { restApiHost } from '../../utils/appVariables';
import { Container } from '@material-ui/core';
import { InboxOutlined, SendOutlined } from '@ant-design/icons';
import { ErrorBoundary } from '../ErrorBoundary';
import { call } from '../../apiUtils/call';
import { userInformations } from '../../contex';

const EmailsForClients = ({ customer, lead }) => {
  const { userInfo } = useContext(userInformations);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [currentPageEmails, setCurrentPageEmails] = useState(1);
  const [totalEmails, setTotalEmails] = useState(0);
  const [emails, setEmails] = useState([]);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingEmails, setLoadingEmails] = useState(false);
  const [profileInfo, setProfileInfo] = useState(null);

  const [activeFolder, setActiveFolder] = useState('inbox');

  const selectedStaff = useMemo(() => {
    if (profileInfo?.id) {
      return profileInfo.id;
    } else {
      return null;
    }
  }, [profileInfo]);

  const disabledCanDoActions = useMemo(() => {
    if (selectedStaff) {
      if (profileInfo?.id !== selectedStaff) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [profileInfo, selectedStaff]);

  useEffect(() => {
    const getProfileInfo = async () => {
      try {
        const url = `${restApiHost}/accounts/profile/`;
        const res = await fetch(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        });
        const json = await res.json();

        if (json.detail == 'Недопустимый токен.') {
          localStorage.removeItem('token');
          window.location.href = '/';
          return;
        }

        if (json?.data) {
          setProfileInfo(json.data);
        }
      } catch (e) {
        console.log('fetch profile info error', e);
      }
    };

    localStorage.getItem('token') && getProfileInfo();
  }, []);

  useEffect(() => {
    getMessagesFromFolder(activeFolder);
  }, [lead, customer]);

  const getMessagesFromFolder = async (
    key,
    more = false,
    flagged = false,
    staff,
  ) => {
    setLoadingEmails(true);
    let page = more ? currentPageEmails : 1;

    if (more) {
      page += 1;
      setCurrentPageEmails(page);
    }

    try {
      const url = `${restApiHost}/mail/message/list/?folder=${key}${
        flagged ? '&flagged=true' : ''
      }${lead ? `&from_lead=${lead}` : ''}${
        customer ? `&from_customer=${customer}` : ''
      }&page=${page}${staff ? `&staff_user=${staff}` : ''}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current?.haveViewBranch,
      );

      if (!json) return;

      const newEmails =
        key === 'inbox' || key === 'Sent' || key === 'Spam'
          ? json?.data
          : json?.data;
      if (more) {
        setEmails([...emails, ...newEmails]);
      } else {
        setEmails(newEmails);
      }
      setTotalEmails(json?.count);
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на получение писем');
    } finally {
      setLoadingEmails(false);
    }
  };

  const replacer = (text) => {
    return text.replace('<', '').replace('>', '');
  };

  const formatBodyMessage = (body) => {
    if (body?.length > 0) {
      const regexp = /<([\w.!#$%&’*+/=?^_`{|}~-]+@[\w-]+(?:\.[\w-]+)+)>/g;
      // const regexp2 = /<((https?:\/\/)|(\/)|(..\/))(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g
      const regexp3 =
        /<mailto:([\w.!#$%&’*+/=?^_`{|}~-]+@[\w-]+(?:\.[\w-]+)+)>/g;
      const bodyReplaceEmailBrackets = body
        .replace(regexp, replacer)
        .replace(regexp3, replacer);
      // const addBodySpaces = bodyReplaceEmailBrackets.replace(/>+/g, replacer2);
      // const newLineBodyFormat = addBodySpaces.replace(/\n/g, "<br />");
      return bodyReplaceEmailBrackets;
    } else {
      return '';
    }
  };

  const getMessage = async (email) => {
    setLoadingEmail(true);
    try {
      const url = `${restApiHost}/mail/message/body/?folder=${email.folder}${
        email.uid ? `&uid=${email.uid}` : ''
      }${
        selectedStaff ? `&staff_user=${selectedStaff}` : ''
      }&msg_id=${encodeURIComponent(email.msg_id)}&from_email=${
        email.from_email
      }&date=${encodeURIComponent(email.date)}`;
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.status == 403) {
        message.warning(
          'У вас недостаточно прав для взаимодействия с сообщениями',
        );
        setLoadingEmail(false);
        return;
      }
      const json = await res.json();

      if (json.status === 'error') {
        message.error(json.text);
        setLoadingEmail(false);
        return;
      } else {
        const body = {
          ...json,
          body: formatBodyMessage(json.body),
        };
        setSelectedEmail(body);
        setLoadingEmail(false);
      }
    } catch (e) {
      console.log(e);
      setLoadingEmail(false);
      message.error(
        'Ошибка запроса на получение детальной информации по сообщению',
      );
    }
  };

  const changeMessageSeen = async (email, seen) => {
    try {
      const url = `${restApiHost}/mail/message/seen/`;
      const body = {
        folder: activeFolder,
        msg_id: email.msg_id,
        date: email.date,
        seen: seen.toString(),
      };
      await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const changedEmailIdx = emails.findIndex(
        (item) => item.msg_id === email.msg_id,
      );
      if (changedEmailIdx >= 0) {
        const newEmails = [...emails];
        newEmails[changedEmailIdx].seen = seen;
        setEmails(newEmails);
        selectedEmail &&
          (selectedEmail.id === email.id ||
            selectedEmail.msg_id === email.msg_id) &&
          setSelectedEmail({ ...selectedEmail, ...newEmails[changedEmailIdx] });
      }
    } catch (e) {
      console.log('change message seen error', e);
    }
  };

  const handleShowBodyEmail = async (email) => {
    const changedEmailIdx = emails.findIndex(
      (item) => item.msg_id === email.msg_id,
    );
    if (changedEmailIdx >= 0) {
      const newEmails = [...emails];
      newEmails[changedEmailIdx].seen = true;
      setEmails(newEmails);
      selectedEmail &&
        (selectedEmail.id === email.id ||
          selectedEmail.msg_id === email.msg_id) &&
        setSelectedEmail({ ...selectedEmail, ...newEmails[changedEmailIdx] });
    }
    await getMessage(email);
  };

  const deleteMessage = async (email) => {
    try {
      const url = `${restApiHost}/mail/message/delete/`;
      const body = {
        folder: activeFolder,
        msg_id: email.msg_id,
        date: email.date,
      };
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (res.ok) {
        return Promise.resolve('ok');
      } else {
        return Promise.reject('Ошибка удаления');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteMessageInBody = async (email) => {
    setLoadingEmails(true);
    deleteMessage(email)
      .then(() => {
        message.success(`Сообщение успешно удалено`);
        setSelectedEmail(null);
        setCurrentPageEmails(1);
        getMessagesFromFolder(activeFolder, false, false, selectedStaff);
      })
      .catch((e) => message.error(`Ошибка удаления: ${e}`))
      .finally(() => setLoadingEmails(false));
  };

  const handleChangeFolder = async (key) => {
    if (activeFolder === key) {
      setSelectedEmail(null);
    } else {
      setActiveFolder(key);
      setCurrentPageEmails(1);
      setSelectedEmail(null);
      await getMessagesFromFolder(key, false, false, selectedStaff);
    }
  };

  return (
    <Container
      maxWidth={false}
      style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}
    >
      <div className="email-container">
        <Row gutter={16}>
          <Col lg={5} md={6} xs={24}>
            <div className="email-menu">
              <div className={'email-menu__list'}>
                <div
                  className={`email-menu__list-item${
                    activeFolder === 'inbox' ? ' active' : ''
                  }`}
                  onClick={() => handleChangeFolder('inbox')}
                >
                  <div>
                    <InboxOutlined /> Входящие
                  </div>
                </div>
                <div
                  className={`email-menu__list-item${
                    activeFolder === 'Sent' ? ' active' : ''
                  }`}
                  onClick={() => handleChangeFolder('Sent')}
                >
                  <div>
                    <SendOutlined /> Отправленные
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={19} md={18} xs={24}>
            {!selectedEmail ? (
              <Spin tip="Загрузка..." spinning={loadingEmails}>
                <div className={'email-messages'}>
                  {emails?.length > 0 ? (
                    <>
                      {emails?.map((email, index) => (
                        <ErrorBoundary key={index}>
                          <EmailListItem
                            email={email}
                            key={email.id || email.msg_id}
                            activeFolder={activeFolder}
                            showBodyEmail={handleShowBodyEmail}
                            selectedStaff={disabledCanDoActions}
                            taskType={true}
                          />
                        </ErrorBoundary>
                      ))}
                      {emails?.length !== totalEmails && (
                        <Button
                          style={{ marginTop: 10 }}
                          onClick={() =>
                            getMessagesFromFolder(
                              'inbox',
                              true,
                              false,
                              selectedStaff,
                            )
                          }
                        >
                          Еще письма
                        </Button>
                      )}
                    </>
                  ) : (
                    <Empty description={<b>Нет писем</b>} />
                  )}
                </div>
              </Spin>
            ) : (
              <ErrorBoundary>
                <EmailBody
                  back={() => {
                    setSelectedEmail(null);
                    setCurrentPageEmails(1);
                  }}
                  selectedStaff={disabledCanDoActions}
                  profileInfo={profileInfo}
                  changeMessageSeen={changeMessageSeen}
                  handleShowBodyEmail={handleShowBodyEmail}
                  loading={loadingEmail}
                  activeFolder={activeFolder}
                  getMessagesFromFolder={() =>
                    getMessagesFromFolder(
                      activeFolder,
                      false,
                      false,
                      selectedStaff,
                    )
                  }
                  handleDeleteMessageInBody={handleDeleteMessageInBody}
                  email={selectedEmail}
                />
              </ErrorBoundary>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default EmailsForClients;
