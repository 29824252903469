import PropTypes from 'prop-types';
import locale from 'antd/lib/locale/ru_RU';
import {
  Button,
  Col,
  ConfigProvider,
  Dropdown,
  Menu,
  Row,
  Space,
  Table,
} from 'antd';
import { setPaginationSettings } from '../../../../../localStorageUtils/paginationSettings';
import React, { useState } from 'react';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { EditOutlined } from '@ant-design/icons';
import SettingSectionsDrawer from './SettingSectionsDrawer';
import { ErrorBoundary } from '../../../../ErrorBoundary';
import MultipleSettingSectionsDrawer from './MultipleSettingSectionsDrawer';

const BranchesSettingSectionsTable = (props) => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visibleMultipleDrawer, setVisibleMultipleDrawer] = useState(false);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [selectedGroupsInTable, setSelectedGroupsInTable] = useState([]);

  const menu = (group) => {
    return (
      <Menu style={{ width: 310 }}>
        <Menu.Item
          key={'edit'}
          icon={<EditOutlined />}
          onClick={async () => {
            if (group?.id) {
              openSettingSectionsDrawer(group?.id);
            }
          }}
        >
          Редактирование отображаемых разделов
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: '',
      key: '1',
      width: 10,
      align: 'center',
      render: (group) => (
        <Space size="middle">
          <Dropdown overlay={menu(group)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Группа',
      width: 200,
      key: '3',
      dataIndex: 'name',
      align: 'center',
    },
  ];
  const openSettingSectionsDrawer = (groupId) => {
    setCurrentGroupId(groupId);
    setVisibleDrawer(true);
  };

  const closeSettingSectionsDrawer = () => {
    setVisibleDrawer(false);
    setCurrentGroupId(null);
  };

  const handleChangePage = (page, branchId) => {
    props.handleUpdateGroup(page, branchId);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedGroupsInTable(selectedRows);
    },
  };

  return (
    <>
      <Row style={{ height: '40px' }}>
        {selectedGroupsInTable && selectedGroupsInTable.length >= 2 ? (
          <Row justify={'start'} style={{ padding: '0 10px 10px 10px' }}>
            <Col>
              <Button onClick={() => setVisibleMultipleDrawer(true)}>
                Применить к выбранным
              </Button>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Row>

      <div>
        <ConfigProvider locale={locale}>
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0
                ? 'black-text table-row-color'
                : 'black-text table-row-white'
            }
            onRow={(record) => {
              return {
                onDoubleClick: async () => {
                  if (record?.id) {
                    openSettingSectionsDrawer(record?.id);
                  }
                },
              };
            }}
            sticky={true}
            bordered={true}
            onChange={(val) => {
              handleChangePage(val?.current, props.branchId);
            }}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            dataSource={props.groups}
            columns={columns}
            loading={props.loading}
            size="small"
            rowKey={(record) => record.id}
            pagination={setPaginationSettings(
              props.groupsTotal,
              props.page,
              ['bottomRight'],
              false,
              1,
              'middle',
              15,
              null,
              false,
            )}
          />
        </ConfigProvider>
      </div>
      <ErrorBoundary>
        <SettingSectionsDrawer
          branchId={props.branchId}
          groupId={currentGroupId}
          visible={visibleDrawer}
          closeSettingSectionsDrawer={() => closeSettingSectionsDrawer()}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <MultipleSettingSectionsDrawer
          visible={visibleMultipleDrawer}
          groups={selectedGroupsInTable}
          branchId={props.branchId}
          closeDrawer={() => setVisibleMultipleDrawer(false)}
        />
      </ErrorBoundary>
    </>
  );
};

BranchesSettingSectionsTable.props = {
  branchId: PropTypes.number,
  groups: PropTypes.any,
  page: PropTypes.number,
  groupsTotal: PropTypes.number,
  handleUpdateGroup: PropTypes.func,
  setLoading: PropTypes.func,
};

export default BranchesSettingSectionsTable;
