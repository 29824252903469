import {
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { restApiHost } from '../../utils/appVariables';
import { Option } from 'antd/es/mentions';
import { call } from '../../apiUtils/call';
import { userInformations } from '../../contex';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const ShoulderForm = ({
  shoulder,
  taskId,
  savingSum,
  setSavingSum,
  sumsWereEdited,
  endSavingSumsWhenSwitching,
  savingSumWereEditing,
  setLoadingModalSpiner,
  refresh,
  editCustomSum,
  changeSumsClientAtInputSum,
}) => {
  const [shoulderForm] = Form.useForm();
  const { userInfo } = useContext(userInformations);
  const [formIsEdit, setFormIsEdit] = useState(false);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    shoulderForm.setFieldsValue({
      price: shoulder.price ? shoulder.price : null,
      currency: shoulder.currency ? shoulder.currency : 'rub',
      vat_rate: shoulder.vat_rate !== null ? shoulder.vat_rate : null,
    });
  }, [shoulder]);

  useEffect(() => {
    if ((savingSum || savingSumWereEditing) && sumsWereEdited && formIsEdit) {
      shoulderForm
        .validateFields()
        .then(async (values) => {
          let body = {
            ...values,
            operation_out: shoulder?.operationOut
              ? shoulder.operationOut
              : null,
            operation_in: shoulder?.operationIn ? shoulder.operationIn : null,
            inSum: shoulder?.inSum ? shoulder.inSum : null,
            sumsId: shoulder?.sumsId ? shoulder?.sumsId : null,
            task: taskId,
          };
          await createOrEditOrDeleteSum(body);
          setFormIsEdit(false);
        })
        .catch((err) => {
          if (err?.errorFields.length > 0) {
            message.error('Валюта для суммы не выбрана');
          }
          if (!savingSumWereEditing) {
            setSavingSum();
          } else {
            endSavingSumsWhenSwitching();
          }
          console.log(err);
        });
    }
  }, [savingSum, savingSumWereEditing, sumsWereEdited, formIsEdit]);

  const createOrEditOrDeleteSum = async (body) => {
    if (body.price !== null && body.price !== '0') {
      if (!shoulder.inSum) {
        await createSum(body);
        if (!savingSumWereEditing) {
          setSavingSum();
        } else {
          endSavingSumsWhenSwitching();
          taskId && refresh(taskId);
        }
      } else {
        await editeSum(body);
        if (!savingSumWereEditing) {
          setSavingSum();
        } else {
          endSavingSumsWhenSwitching();
          taskId && refresh(taskId);
        }
      }
    } else {
      if (body.price === '0') {
        await deleteSum(body);
        if (!savingSumWereEditing) {
          setSavingSum();
        } else {
          endSavingSumsWhenSwitching();
          taskId && refresh(taskId);
        }
      }
    }
  };

  const createSum = async (body) => {
    setLoadingModalSpiner(true);
    try {
      const url = `${restApiHost}/leads/pre_summaries/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;
    } catch (e) {
      console.log('add cargo err', e);
      message.error('Ошибка выполнения операции!');
    } finally {
      setLoadingModalSpiner(false);
    }
  };

  const editeSum = async (body) => {
    setLoadingModalSpiner(true);
    try {
      const url = `${restApiHost}/leads/pre_summaries/${body.sumsId}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Сумма успешно обновлена');
    } catch (e) {
      console.log('add cargo err', e);
      message.error('Ошибка выполнения операции!');
    } finally {
      setLoadingModalSpiner(false);
    }
  };
  const deleteSum = async (body) => {
    setLoadingModalSpiner(true);
    try {
      const url = `${restApiHost}/leads/pre_summaries/${body.sumsId}/`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      message.success('Сумма успешно удалена');
    } catch (e) {
      console.log('add cargo err', e);
      message.error('Ошибка выполнения операции!');
    } finally {
      setLoadingModalSpiner(false);
    }
  };
  const menu = () => {
    return (
      <Menu style={{ width: 100 }}>
        <Popconfirm
          title="Вы действительно хотите удалить сумму?"
          onConfirm={async () => {
            if (shoulder?.sumsId) {
              await deleteSum({ sumsId: shoulder.sumsId });
              taskId && refresh(taskId);
            } else {
              message.warning('Сумма не сохранена');
            }
          }}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };
  const menuCustomShoulder = () => {
    return (
      <Menu style={{ width: 140 }}>
        {shoulder.isCustom ? (
          <Menu.Item
            icon={<EditOutlined />}
            onClick={() => {
              shoulder && editCustomSum(shoulder);
            }}
          >
            Редактировать
          </Menu.Item>
        ) : (
          <></>
        )}
        <Popconfirm
          title="Вы действительно хотите удалить сумму?"
          onConfirm={async () => {
            if (shoulder?.sumsId) {
              await deleteSum({ sumsId: shoulder.sumsId });
              taskId && refresh(taskId);
            } else {
              message.warning('Сумма не сохранена');
            }
          }}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  return (
    <div className={'shoulderForm'} style={{ marginTop: '5px' }}>
      <Form form={shoulderForm} layout={'vertical'}>
        <Row gutter={8} style={{ display: 'flex', alignItems: 'flex-end' }}>
          {!isMobile && (
            <Col>
              <Space size="middle">
                <Dropdown
                  overlay={shoulder.isCustom ? menuCustomShoulder() : menu()}
                  trigger={['hover']}
                >
                  <EllipsisOutlined
                    style={{ cursor: 'pointer', fontSize: '24px' }}
                  />
                </Dropdown>
              </Space>
            </Col>
          )}
          <Col sm={9} xs={24} md={8} lg={6}>
            {isMobile ? (
              <Row>
                <Col xs={16}>
                  <label style={{ fontWeight: 'bold' }}>
                    {!shoulder?.isCustom
                      ? `Сумма за операцию (${shoulder?.type_transport})`
                      : `Сумма за операцию`}
                    <br />
                    {shoulder.shoulderName ? shoulder.shoulderName : ''}
                  </label>
                </Col>
                <Col xs={8}>
                  <Row justify={'end'}>
                    <Space size="middle">
                      <Dropdown
                        overlay={
                          shoulder.isCustom ? menuCustomShoulder() : menu()
                        }
                        trigger={['hover']}
                      >
                        <EllipsisOutlined
                          style={{ cursor: 'pointer', fontSize: '24px' }}
                        />
                      </Dropdown>
                    </Space>
                  </Row>
                </Col>
              </Row>
            ) : (
              <label style={{ fontWeight: 'bold' }}>
                {!shoulder?.isCustom
                  ? `Сумма за операцию (${shoulder?.type_transport})`
                  : `Сумма за операцию`}
                <br />
                {shoulder.shoulderName ? shoulder.shoulderName : ''}
              </label>
            )}
            <Form.Item
              name={'price'}
              style={{ whiteSpace: 'pre-wrap', margin: '0px', padding: '0px' }}
            >
              <Input
                onChange={() => {
                  const values = shoulderForm.getFieldsValue();
                  let body = {
                    ...values,
                    index: shoulder?.index,
                  };
                  setFormIsEdit(true);
                  changeSumsClientAtInputSum(body);
                }}
                placeholder="Сумма"
              />
            </Form.Item>
          </Col>
          <Col sm={9} xs={24} md={5} lg={3}>
            <Form.Item
              name={'currency'}
              rules={[{ required: true, whitespace: true, message: ' ' }]}
              label={'Валюта'}
              style={{ margin: '0px', padding: '0px' }}
            >
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                placeholder={'Не выбрано'}
                onChange={() => {
                  shoulderForm.validateFields().then(async (values) => {
                    let body = {
                      ...values,
                      index: shoulder?.index,
                    };
                    setFormIsEdit(true);
                    changeSumsClientAtInputSum(body);
                  });
                }}
              >
                <Option value={'rub'}>Рубли</Option>
                <Option value={'dol'}>Доллар</Option>
                <Option value={'eur'}>Евро</Option>
                <Option value={'cny'}>Юань</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col sm={4} xs={24} md={4} lg={2}>
            <Form.Item
              name={'vat_rate'}
              label={'НДС'}
              style={{ margin: '0px', padding: '0px' }}
            >
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                onChange={() =>
                  shoulderForm.validateFields().then(async (values) => {
                    let body = {
                      ...values,
                      index: shoulder?.index,
                    };
                    setFormIsEdit(true);
                    changeSumsClientAtInputSum(body);
                  })
                }
              >
                <Option value={0}>0%</Option>
                <Option value={20}>20%</Option>
                <Option value={null}>Без НДС</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default React.memo(ShoulderForm);
