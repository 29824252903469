import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { message, Row, Col, Button, Select } from 'antd';
import { restApiHost } from '../utils/appVariables';
import SendMessageModal from '../components/email/SendMessageModal';
import {
  EditOutlined,
  ReloadOutlined,
  InboxOutlined,
  SendOutlined,
  DeleteFilled,
  BookOutlined,
  CloseSquareOutlined,
} from '@ant-design/icons';
import EmailList from '../components/email/new/EmailList';
import EmailBody from '../components/email/new/EmailBody';
import { getNoun } from '../utils/getNoun';
import { userInformations } from '../contex';
import { call } from '../apiUtils/call';
import { ErrorBoundary } from '../components/ErrorBoundary';
import dayjs from 'dayjs';
import formationDocumentTitle from '../utils/formationDocumentTitle';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

const { Option } = Select;

const Email = () => {
  const [folders, setFolders] = useState(null);
  const [activeFolder, setActiveFolder] = useState('inbox');

  //send email modal
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
  const [loadingSend] = useState(false);

  //emails
  const [emails, setEmails] = useState([]);
  const [currentPageEmails, setCurrentPageEmails] = useState(1);
  const [totalEmails, setTotalEmails] = useState(0);
  const [loadingEmails, setLoadingEmails] = useState(false);
  const [activeAll, setActiveAll] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);

  //body email
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [loadingEmail, setLoadingEmail] = useState(false);

  const [profileInfo, setProfileInfo] = useState(null);

  const [flagged, setFlagged] = useState(false);

  const [staffList, setStaffList] = useState([]);
  const [, setLoadingStaffList] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const { userInfo } = useContext(userInformations);
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );
  const isMobile = useCheckMobileScreen();

  const canViewStaffEmails = useMemo(() => {
    if (profileInfo?.groups?.length > 0) {
      const isDirector = profileInfo.groups.filter(
        (item) => item.id === 1 || item.id === 2,
      );
      if (isDirector?.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [profileInfo, selectedStaff]);

  const disabledCanDoActions = useMemo(() => {
    if (selectedStaff) {
      if (profileInfo?.id !== selectedStaff) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [profileInfo, selectedStaff]);

  const listEmailsForResponse = useMemo(() => {
    if (selectedEmails?.length > 0) {
      const filteredArr = selectedEmails.map((item) => item.from_email);
      const uniqueEmails = [...new Set(filteredArr)];
      return uniqueEmails;
    } else {
      return [];
    }
  }, [selectedEmails]);

  useEffect(() => {
    const getProfileInfo = async () => {
      try {
        const url = `${restApiHost}/accounts/profile/`;
        const json = await call(
          url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          },
          userInfo.current.haveViewBranch,
        );

        if (!json?.data) return;

        if (json?.detail === 'Недопустимый токен.') {
          localStorage.removeItem('token');
          window.location.href = '/';
          return;
        }
        setProfileInfo(json.data);
      } catch (e) {
        console.log('fetch profile info error', e);
      }
    };

    localStorage.getItem('token') && getProfileInfo();
  }, []);

  useEffect(() => {
    if (activeAll) {
      setSelectedEmails(emails);
    } else {
      setSelectedEmails([]);
    }
  }, [activeAll]);

  useEffect(() => {
    getFolders();
    getMessagesFromFolder('inbox');
    getStaffList();
  }, []);

  const searchStaff = async (keyword) => {
    try {
      const url = `${restApiHost}/accounts/staff_users/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setStaffList(json?.data.filter((item) => item.auth_pass_mail === true));
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса! Не удалось найти лида');
    }
  };

  const getStaffList = async (page = 1) => {
    setLoadingStaffList(true);
    try {
      const url = `${restApiHost}/accounts/staff_users/?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setStaffList(json?.data.filter((item) => item.auth_pass_mail === true));
    } catch (e) {
      console.log('fetch profile info error', e);
      setLoadingStaffList(false);
    } finally {
      setLoadingStaffList(false);
    }
  };

  const getFolders = async (staff_user = null) => {
    try {
      const url = `${restApiHost}/mail/folders/${
        staff_user ? `?staff_user=${staff_user}` : ''
      }`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json) {
        setFolders(json);
      }
    } catch (e) {
      console.log(e);
      // message.error('Ошибка запроса для получения писем');
    }
  };

  const getMessagesFromFolder = async (
    key,
    more = false,
    flagged = false,
    staff,
  ) => {
    setLoadingEmails(true);
    let page = more ? currentPageEmails : 1;

    if (more) {
      page += 1;
      setCurrentPageEmails(page);
    }

    try {
      const url =
        key === 'inbox' || key === 'Sent' || key === 'Spam'
          ? `${restApiHost}/mail/message/list/?folder=${key}${
              flagged ? '&flagged=true' : ''
            }&page=${page}${staff ? `&staff_user=${staff}` : ''}`
          : `${restApiHost}/mail/messages_from_folder/?folder=${key}${
              flagged ? '&flagged=true' : ''
            }&page=${page}${staff ? `&staff_user=${staff}` : ''}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) {
        setEmails([]);
        return;
      }
      if (more) {
        const newEmails =
          key === 'inbox' ||
          key === 'Sent' ||
          key === 'Spam' ||
          (key === 'deleted' && json?.data)
            ? json.data
            : [];
        setEmails([...emails, ...newEmails]);
      } else {
        setEmails(json?.data ? json.data : []);
      }
      setTotalEmails(json.count);
      await getFolders(staff);
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на получение писем');
    } finally {
      setLoadingEmails(false);
    }
  };

  const deleteMessage = async (email) => {
    try {
      const url =
        activeFolder === 'inbox' ||
        activeFolder === 'Sent' ||
        activeFolder === 'Spam'
          ? `${restApiHost}/mail/message/delete/`
          : `${restApiHost}/mail/delete/`;
      const body =
        activeFolder === 'inbox' ||
        activeFolder === 'Sent' ||
        activeFolder === 'Spam'
          ? { folder: activeFolder, msg_id: email.msg_id, date: email.date }
          : { message: email.id };
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (json) {
        return Promise.resolve('ok');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const deleteAllEmails = async () => {
    try {
      const url = `${restApiHost}/mail/delete/`;
      const body = { message: 'empty_trash' };
      const response = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (response) {
        return Promise.resolve('ok');
      } else {
        return Promise.reject('Ошибка удаления');
      }
    } catch (e) {
      return Promise.reject(e.message);
    }
  };

  const deleteAllEmailsFromDeletedFolder = () => {
    setLoadingEmails(true);
    deleteAllEmails()
      .then(() => {
        message.success(`Все письма с папки успешно удалены!`);
        setSelectedEmails([]);
        setActiveAll(false);
        getMessagesFromFolder(activeFolder, false, flagged, selectedStaff);
        getFolders();
      })
      .catch((e) => message.error(`Ошибка удаления: ${e}`))
      .finally(() => setLoadingEmails(false));
  };

  const handleChangeFolder = async (key) => {
    setSelectedEmails([]);
    if (activeFolder === key) {
      setSelectedEmail(null);
    } else {
      setActiveFolder(key);
      setFlagged(false);
      setCurrentPageEmails(1);
      setActiveAll(false);
      setSelectedEmail(null);
      await getMessagesFromFolder(key, false, false, selectedStaff);
    }
  };

  const replacer = (text) => {
    return text.replace('<', '').replace('>', '');
  };

  const formatBodyMessage = (body) => {
    if (body?.length > 0) {
      const regexp = /<([\w.!#$%&’*+/=?^_`{|}~-]+@[\w-]+(?:\.[\w-]+)+)>/g;
      // const regexp2 = /<((https?:\/\/)|(\/)|(..\/))(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g
      const regexp3 =
        /<mailto:([\w.!#$%&’*+/=?^_`{|}~-]+@[\w-]+(?:\.[\w-]+)+)>/g;
      const bodyReplaceEmailBrackets = body
        .replace(regexp, replacer)
        .replace(regexp3, replacer);
      // const addBodySpaces = bodyReplaceEmailBrackets.replace(/>+/g, replacer2);
      // const newLineBodyFormat = addBodySpaces.replace(/\n/g, "<br />");
      return bodyReplaceEmailBrackets;
    } else {
      return '';
    }
  };

  const getMessage = async (email) => {
    setLoadingEmail(true);
    try {
      const url =
        activeFolder === 'inbox' ||
        activeFolder === 'Sent' ||
        activeFolder === 'Spam'
          ? `${restApiHost}/mail/message/body/?folder=${email.folder}${
              email.uid ? `&uid=${email.uid}` : ''
            }${
              selectedStaff ? `&staff_user=${selectedStaff}` : ''
            }&msg_id=${encodeURIComponent(email.msg_id)}&from_email=${
              email.from_email
            }&date=${dayjs(email.date).format('YYYY-MM-DD')}`
          : `${restApiHost}/mail/messages/${email.id}/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      const body = {
        ...json,
        body: formatBodyMessage(json.body),
      };
      setSelectedEmail(body);
      setLoadingEmail(false);
    } catch (e) {
      console.log(e);
      setLoadingEmail(false);
      message.error(
        'Ошибка запроса на получение детальной информации по сообщению',
      );
    }
  };

  const sendMessage = async (values) => {
    console.log(values);
  };

  const handleSearchContacts = (keyword) => {
    console.log(keyword);
  };

  const handleShowBodyEmail = async (email) => {
    // if (activeFolder !== 'drafts') {
    //     !email?.seen && await changeMessageSeen(email, true);
    // }
    const changedEmailIdx =
      activeFolder === 'inbox' || activeFolder === 'Sent'
        ? emails.findIndex((item) => item.msg_id === email.msg_id)
        : emails.findIndex((item) => item.id === email.id);
    if (changedEmailIdx >= 0) {
      const newEmails = [...emails];
      newEmails[changedEmailIdx].seen = true;
      setEmails(newEmails);
      selectedEmail &&
        (selectedEmail.id === email.id ||
          selectedEmail.msg_id === email.msg_id) &&
        setSelectedEmail({ ...selectedEmail, ...newEmails[changedEmailIdx] });
    }
    await getMessage(email);
  };

  const handleDeleteMessages = async () => {
    setLoadingEmails(true);
    Promise.all(
      selectedEmails.map(async (email) => {
        await deleteMessage(email);
      }),
    )
      .then(() => {
        message.success(
          `Удалено ${selectedEmails.length} ${getNoun(
            selectedEmails.length,
            'письмо',
            'письма',
            'писем',
          )}`,
        );
        setSelectedEmails([]);
        setActiveAll(false);
        getMessagesFromFolder(activeFolder, false, flagged, selectedStaff);
        getFolders();
      })
      .catch((e) => message.error(`Ошибка удаления: ${e}`))
      .finally(() => setLoadingEmails(false));
  };

  const handleDeleteMessageInBody = async (email) => {
    setLoadingEmails(true);
    deleteMessage(email)
      .then(() => {
        message.success(`Сообщение успешно удалено`);
        setActiveAll(false);
        setSelectedEmail(null);
        setSelectedEmails([]);
        setCurrentPageEmails(1);
        getMessagesFromFolder(activeFolder, false, flagged, selectedStaff);
        getFolders();
      })
      .catch((e) => message.error(`Ошибка удаления: ${e}`))
      .finally(() => setLoadingEmails(false));
  };

  const changeMessageSeen = async (email, seen) => {
    try {
      const url =
        activeFolder === 'inbox' || activeFolder === 'Sent'
          ? `${restApiHost}/mail/message/seen/`
          : `${restApiHost}/mail/message_seen/`;
      const body =
        activeFolder === 'inbox' || activeFolder === 'Sent'
          ? {
              folder: activeFolder,
              msg_id: email.msg_id,
              date: email.date,
              seen: seen.toString(),
            }
          : { message: email.id, seen: seen.toString() };
      await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      const changedEmailIdx =
        activeFolder === 'inbox' || activeFolder === 'Sent'
          ? emails.findIndex((item) => item.msg_id === email.msg_id)
          : emails.findIndex((item) => item.id === email.id);
      if (changedEmailIdx >= 0) {
        const newEmails = [...emails];
        newEmails[changedEmailIdx].seen = seen;
        setEmails(newEmails);
        selectedEmail &&
          (selectedEmail.id === email.id ||
            selectedEmail.msg_id === email.msg_id) &&
          setSelectedEmail({ ...selectedEmail, ...newEmails[changedEmailIdx] });
      }
    } catch (e) {
      console.log('change message seen error', e);
    }
  };

  const changeMessageFlag = async (email) => {
    try {
      const url = `${restApiHost}/mail/message/flagged/`;
      const body = {
        folder: activeFolder,
        msg_id: email.msg_id || email.id,
        date: email.date,
        flagged: `${!email.flagged}`,
      };
      await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      const changedEmailIdx =
        activeFolder === 'inbox' || activeFolder === 'Sent'
          ? emails.findIndex((item) => item.msg_id === email.msg_id)
          : emails.findIndex((item) => item.id === email.id);
      if (changedEmailIdx >= 0) {
        const newEmails = [...emails];
        newEmails[changedEmailIdx].flagged = !email.flagged;
        setEmails(newEmails);
        selectedEmail &&
          (selectedEmail.id === email.id ||
            selectedEmail.msg_id === email.msg_id) &&
          setSelectedEmail({ ...selectedEmail, ...newEmails[changedEmailIdx] });
      }
    } catch (e) {
      console.log('change message flag error', e);
    }
  };

  const spamMessage = async (email) => {
    try {
      const url = `${restApiHost}/mail/message/to_spam/`;
      const body = {
        folder: activeFolder,
        msg_id: email.msg_id || email.id,
        date: email.date,
      };
      await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );
    } catch (e) {
      console.log('change message flag error', e);
    }
  };

  const handleFlaggedMessages = async () => {
    setLoadingEmails(true);
    Promise.all(
      selectedEmails.map(async (email) => {
        await changeMessageFlag(email, email.flag);
      }),
    )
      .then(() => {
        message.success(
          `Помечено важным ${selectedEmails.length} ${getNoun(
            selectedEmails.length,
            'письмо',
            'письма',
            'писем',
          )}`,
        );
        setSelectedEmails([]);
        setActiveAll(false);
        getMessagesFromFolder(activeFolder, false, flagged, selectedStaff);
        // getFolders();
      })
      .catch((e) => message.error(`Ошибка запроса для отметки важным: ${e}`))
      .finally(() => setLoadingEmails(false));
  };

  const handleSpamMessages = async () => {
    setLoadingEmails(true);
    Promise.all(
      selectedEmails.map(async (email) => {
        await spamMessage(email);
      }),
    )
      .then(() => {
        message.success(
          `Помечено как спам ${selectedEmails.length} ${getNoun(
            selectedEmails.length,
            'письмо',
            'письма',
            'писем',
          )}`,
        );
        setSelectedEmails([]);
        setActiveAll(false);
        getMessagesFromFolder(activeFolder, false, flagged, selectedStaff);
        // getFolders();
      })
      .catch((e) =>
        message.error(`Ошибка запроса для отметки писем как СПАМ: ${e}`),
      )
      .finally(() => setLoadingEmails(false));
  };

  // const handleChangeStaffEmail = async id => {
  //     try {
  //         const url = `${restApiHost}/mail/message/list/?staff_user=${id}`;
  //         const res = await fetch(url, {
  //             headers: {
  //                 Authorization: `Token ${localStorage.getItem('token')}`,
  //                 'Content-Type': 'application/json',
  //             },
  //         })
  //         if (res.status == 403) {
  //             message.warning('У вас недостаточно прав для взаимодействия почтой')
  //             return
  //         }
  //         const json = await res.json()

  //     } catch (e) {
  //         console.log('change viewed staff err', e);
  //     }
  // }

  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle('Почта | Квик Транс', notificationCount)}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container
          maxWidth={false}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <div className="email-container">
            <Row gutter={16}>
              <Col lg={5} md={6} xs={24}>
                <div className="email-menu">
                  {canViewStaffEmails && (
                    <Select
                      getPopupContainer={(trigger) =>
                        isMobile ? trigger.parentNode : document.body
                      }
                      onChange={(value) => {
                        if (value) {
                          setSelectedStaff(value);
                          getMessagesFromFolder(
                            activeFolder,
                            false,
                            flagged,
                            value,
                          );
                        } else {
                          setSelectedStaff(null);
                          getMessagesFromFolder(activeFolder, false, flagged);
                        }
                      }}
                      showSearch
                      allowClear
                      onSearch={(value) => {
                        if (value.length > 0) {
                          searchStaff(value);
                        } else {
                          getStaffList();
                        }
                      }}
                      filterOption={false}
                      style={{ width: '100%' }}
                      placeholder="Выберите сотрудника"
                    >
                      {staffList?.length > 0 &&
                        staffList.map((item) => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item?.full_name || item.email}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                  <div className="send-message-container">
                    <Button
                      onClick={() => setOpenSendEmailModal(true)}
                      style={{ marginRight: 10, borderRadius: 5, fontSize: 13 }}
                      type="primary"
                      disabled={selectedStaff}
                      icon={<EditOutlined />}
                    >
                      Написать
                    </Button>
                    <Button
                      onClick={() => {
                        setCurrentPageEmails(1);
                        getMessagesFromFolder(
                          activeFolder,
                          false,
                          flagged,
                          selectedStaff,
                        );
                      }}
                      style={{ borderRadius: 5, fontSize: 13 }}
                      type="primary"
                      icon={<ReloadOutlined />}
                    />
                  </div>

                  <div
                    className={'email-menu__list'}
                    title={folders?.inbox?.count || 0}
                  >
                    <div
                      className={`email-menu__list-item${
                        activeFolder === 'inbox' ? ' active' : ''
                      }`}
                      onClick={() => handleChangeFolder('inbox')}
                    >
                      <div>
                        <InboxOutlined /> Входящие
                      </div>
                      {folders?.inbox?.count && (
                        <span>{folders.inbox.count}</span>
                      )}
                    </div>
                    <div
                      className={`email-menu__list-item${
                        activeFolder === 'Sent' ? ' active' : ''
                      }`}
                      onClick={() => handleChangeFolder('Sent')}
                      title={folders?.sent?.count || 0}
                    >
                      <div>
                        <SendOutlined /> Отправленные
                      </div>
                      {folders?.sent?.count && (
                        <span>{folders.sent.count}</span>
                      )}
                    </div>
                    {!selectedStaff && (
                      <>
                        <div
                          className={`email-menu__list-item${
                            activeFolder === 'drafts' ? ' active' : ''
                          }`}
                          onClick={() => handleChangeFolder('drafts')}
                          title={folders?.drafts?.count || 0}
                        >
                          <div>
                            <BookOutlined /> Черновики
                          </div>
                          {folders?.drafts?.count && (
                            <span>{folders.drafts.count}</span>
                          )}
                        </div>
                        <div
                          className={`email-menu__list-item${
                            activeFolder === 'deleted' ? ' active' : ''
                          }`}
                          onClick={() => handleChangeFolder('deleted')}
                          title={folders?.deleted?.count || 0}
                        >
                          <div>
                            <DeleteFilled /> Удаленные
                          </div>
                          {folders?.deleted?.count && (
                            <span>{folders.deleted.count}</span>
                          )}
                        </div>
                        <div
                          className={`email-menu__list-item${
                            activeFolder === 'Spam' ? ' active' : ''
                          }`}
                          onClick={() => handleChangeFolder('Spam')}
                        >
                          <div>
                            <CloseSquareOutlined /> Спам
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={19} md={18} xs={24}>
                {!selectedEmail ? (
                  <ErrorBoundary>
                    <EmailList
                      selectedEmails={selectedEmails}
                      setSelectedEmails={setSelectedEmails}
                      setActiveAll={setActiveAll}
                      activeAll={activeAll}
                      showBodyEmail={handleShowBodyEmail}
                      emails={emails}
                      total={totalEmails}
                      selectedStaff={disabledCanDoActions}
                      getMessagesFromFolder={(value = true, value2 = false) =>
                        getMessagesFromFolder(
                          activeFolder,
                          value,
                          value2,
                          selectedStaff,
                        )
                      }
                      loading={loadingEmails}
                      handleDeleteMessages={handleDeleteMessages}
                      deleteAllEmailsFromDeletedFolder={
                        deleteAllEmailsFromDeletedFolder
                      }
                      activeFolder={activeFolder}
                      handleShowSendEmailModal={() =>
                        setOpenSendEmailModal(true)
                      }
                      handleFlaggedMessages={handleFlaggedMessages}
                      changeMessageFlag={changeMessageFlag}
                      setFlagged={setFlagged}
                      flagged={flagged}
                      profileInfo={profileInfo}
                      handleSpamMessages={handleSpamMessages}
                    />
                  </ErrorBoundary>
                ) : (
                  <ErrorBoundary>
                    <EmailBody
                      back={() => {
                        setActiveAll(false);
                        setSelectedEmail(null);
                        setSelectedEmails([]);
                        setCurrentPageEmails(1);
                      }}
                      selectedStaff={disabledCanDoActions}
                      profileInfo={profileInfo}
                      changeMessageSeen={changeMessageSeen}
                      handleShowBodyEmail={handleShowBodyEmail}
                      loading={loadingEmail}
                      activeFolder={activeFolder}
                      getMessagesFromFolder={() =>
                        getMessagesFromFolder(
                          activeFolder,
                          false,
                          flagged,
                          selectedStaff,
                        )
                      }
                      handleDeleteMessageInBody={handleDeleteMessageInBody}
                      email={selectedEmail}
                    />
                  </ErrorBoundary>
                )}
              </Col>
            </Row>
          </div>
        </Container>

        {/*send email modal*/}
        <ErrorBoundary>
          <SendMessageModal
            open={openSendEmailModal}
            loading={loadingSend}
            handleClose={() => {
              setOpenSendEmailModal(false);
              // setSelectedSendObj(null)
            }}
            profileInfo={profileInfo}
            listEmails={listEmailsForResponse}
            onSearchList={handleSearchContacts}
            onSubmit={sendMessage}
          />
        </ErrorBoundary>
      </Box>
    </>
  );
};

export default Email;
