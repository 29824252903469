import { Popover } from 'antd';
import React from 'react';

const getStatusDot = (status) => {
  switch (status) {
    case 'process':
      return 'в процессе';
    case 'wait':
      return 'в ожидании';
    case 'finish':
      return 'завершен';
    default:
      return status;
  }
};

const CustomDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        Шаг {index + 1}, статус: {getStatusDot(status)}
      </span>
    }
  >
    <span style={{ fontSize: '13px !important' }}>{dot}</span>
  </Popover>
);

export default CustomDot;
