import PropTypes from 'prop-types';
import { ConfigProvider, Drawer, Empty } from 'antd';
import { ErrorBoundary } from '../ErrorBoundary';
import NotificationList from './NotificationList';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import ru from 'antd/lib/locale/ru_RU';

const NotificationDrawer = (props) => {
  const navigate = useNavigate();

  const handleClose = () => {
    props.onClose();
  };

  const redirectToCalendar = useCallback((eventId) => {
    if (!eventId) return;
    const state = {
      eventId: eventId,
    };
    navigate('/calendar', { state });
  }, []);

  return (
    <>
      <Drawer
        destroyOnClose={true}
        title={
          props.useForChatNotification ? 'Уведомления чатов' : 'Уведомления'
        }
        width={'450px'}
        placement={'right'}
        size={'large'}
        onClose={handleClose}
        visible={props.visible}
        key={'right'}
        className={'notification-drawer-wrapper'}
      >
        {props.notificationList?.length > 0 ? (
          <ErrorBoundary>
            <NotificationList
              notificationList={props.notificationList}
              loading={props.loading}
              useForChatNotification={props.useForChatNotification}
              loadMoreNotification={() => props.loadMoreNotification()}
              redirectToCalendar={(eventId) => {
                handleClose();
                redirectToCalendar(eventId);
              }}
              removeNotificationFormList={(notificationId) => {
                props.removeNotificationFormList(notificationId);
              }}
              removeAllNotificationsFromList={() => {
                props.removeAllNotificationsFromList();
              }}
              handleGetNotificationList={() =>
                props.handleGetNotificationList()
              }
            />
          </ErrorBoundary>
        ) : (
          <ConfigProvider locale={ru}>
            <Empty description={'Непрочитанные уведомления отсутствуют'} />
          </ConfigProvider>
        )}
      </Drawer>
    </>
  );
};

NotificationDrawer.propTypes = {
  visible: PropTypes.bool,
  notificationList: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  useForChatNotification: PropTypes.bool,
  onClose: PropTypes.func,
  loadMoreNotification: PropTypes.func,
  handleGetNotificationList: PropTypes.func,
  removeNotificationFormList: PropTypes.func,
  removeAllNotificationsFromList: PropTypes.func,
};

export default NotificationDrawer;
