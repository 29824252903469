export function replaceCpTemplatePlaceholdersToValue(
  htmlTemplateCp,
  placeholdersValueBook,
) {
  if (!htmlTemplateCp) {
    return ``;
  }
  let copyHtmlTemplate = htmlTemplateCp;
  for (const key in placeholdersValueBook) {
    if (Object.prototype.hasOwnProperty.call(placeholdersValueBook, key)) {
      let regInsertKey = `{{` + key + `}}`;
      const regex = new RegExp(regInsertKey, 'g');
      copyHtmlTemplate = copyHtmlTemplate.replaceAll(
        regex,
        placeholdersValueBook[key]?.value,
      );
    }
  }
  return copyHtmlTemplate;
}
