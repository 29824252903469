import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Row, Col, Form, Input, Modal, Select, Button, message } from 'antd';
import PhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import { restApiHost } from '../../utils/appVariables';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import { createLeadContact } from '../../logic/lead/LeadApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const { Option } = Select;

const ContactLeedModal = ({
  lead,
  contact,
  contacts,
  visible,
  onClose,
  onChangeContacts,
}) => {
  const [form] = Form.useForm();
  //phone number
  const [countryCode, setCountryCode] = useState('ru');
  const phoneNumberRef = useRef(null);
  const { userInfo } = useContext(userInformations);
  const demoAccessIsUsed = useTypedSelector(
    (state) => state.demoAccess.demoAccessIsUsed,
  );
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (contact && visible) {
      form.setFieldsValue({ ...contact });
    }

    if (!visible) {
      form.resetFields();
    }
  }, [contact, visible]);

  const handleCloseModal = () => {
    form.resetFields();
    onClose && onClose();
  };

  const handleChangePhone = (value, data, event) => {
    if (event.type === 'click') {
      setCountryCode(data.countryCode);
      phoneNumberRef?.current?.numberInputRef?.focus();
      setCountryCode('ru');
    }
  };

  const handleCreateContact = useCallback(
    async (body) => {
      const res = await createLeadContact(body, userInfo);
      if (res?.data) {
        message.success('Контактное лицо успешно добавлено!');
        onChangeContacts([...contacts, { ...res.data }]);
        onClose && onClose();
      }
    },
    [createLeadContact, onChangeContacts, onClose],
  );

  const editContact = async (id, body) => {
    try {
      const url = `${restApiHost}/leads/contact_persons/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        message.success('Контактное лицо успешно добавлено!');
        const newContacts = [...contacts];
        const editIdx = newContacts.findIndex(
          (item) =>
            item.email === contact.email &&
            item.phone_number === contact.phone_number,
        );
        if (editIdx >= 0) {
          if (onChangeContacts) {
            newContacts[editIdx] = { ...json?.data };
            onChangeContacts(newContacts);
            form.resetFields();
            onClose && onClose();
          }
        }
        onClose && onClose();
      }
    } catch (e) {
      message.error('Ошибка запроса на добавление контактного лица');
      console.log('add contact error', e);
    }
  };

  const submit = () => {
    if (lead) {
      if (contact) {
        form.validateFields().then(async (values) => {
          await editContact(contact?.id, { ...values, lead: lead.id });
        });
      } else {
        form.validateFields().then(async (values) => {
          await handleCreateContact({ ...values, lead: lead.id });
        });
      }
    } else {
      if (contact) {
        const newContacts = [...contacts];
        const editIdx = newContacts.findIndex(
          (item) =>
            item.email === contact.email &&
            item.phone_number === contact.phone_number,
        );
        if (editIdx >= 0) {
          if (onChangeContacts) {
            form.validateFields().then((values) => {
              newContacts[editIdx] = { ...newContacts[editIdx], ...values };
              onChangeContacts(newContacts);
              form.resetFields();
              onClose && onClose();
            });
          }
        }
      } else {
        if (onChangeContacts) {
          form.validateFields().then((values) => {
            console.log(values);
            onChangeContacts([...contacts, { ...values }]);
            form.resetFields();
            onClose && onClose();
          });
        }
      }
    }
  };

  return (
    <Modal
      title={contact ? 'Редактирование контакта' : 'Добавление контакта'}
      visible={visible}
      onCancel={handleCloseModal}
      footer={[
        <Button
          key={'actionBtn'}
          disabled={demoAccessIsUsed}
          type={'primary'}
          onClick={submit}
        >
          {contact ? 'Редактировать' : 'Добавить'}
        </Button>,
      ]}
    >
      <Form form={form} layout={'vertical'}>
        <Row gutter={15}>
          <Col lg={12} md={12} xs={24}>
            <Form.Item
              label={'ФИО'}
              name={'full_name'}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <Input placeholder={'Введите ФИО'} />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} xs={24}>
            <Form.Item label={'Должность'} name={'position'}>
              <Input placeholder={'Введите должность в компании'} />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} xs={24}>
            <Form.Item name={'sex'} label={'Пол'}>
              <Select
                placeholder={'Выберите пол'}
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
              >
                <Option value={'m'}>Мужской</Option>
                <Option value={'f'}>Женский</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={6} md={9} xs={24}>
            <Form.Item label={'Доб. номер'} name={'add_tel_number'}>
              <Input placeholder={'Введите добавочный номер'} />
            </Form.Item>
          </Col>
          <Col lg={12} md={9} xs={24}>
            <Form.Item
              label="Телефон (контактное лицо)"
              name={'phone_number'}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
            >
              <PhoneInput
                ref={phoneNumberRef}
                localization={ru}
                value={countryCode}
                country={'ru'}
                onlyCountries={[
                  'ru',
                  'us',
                  'fr',
                  'by',
                  'az',
                  'ge',
                  'ua',
                  'kz',
                  'am',
                  'kg',
                  'md',
                  'tr',
                  'uz',
                  'tj',
                ]}
                onChange={handleChangePhone}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} xs={24}>
            <Form.Item label={'Почта'} name={'email'}>
              <Input placeholder={'Введите email'} />
            </Form.Item>
          </Col>

          <Col lg={12} md={12} xs={24}>
            <Form.Item label={'Дата рождения'} name={'birthday'}>
              <input className={'ant-input'} type={'date'} max="9999-12-31" />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item label={'Комментарий'} name={'comment'}>
              <Input placeholder={'Введите комментарий'} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ContactLeedModal;
