import { Drawer } from 'antd';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { userInformations } from '../../../../../contex';
import StepsDrawer from './steps/StepsDrawer';
import ListSections from './steps/listSections/ListSections';
import TableEditPermissions from './steps/tableEditPermissions/TableEditPermissions';

const EditPermissionsDrawer = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentSection, setCurrentSection] = useState(null);
  const { userInfo } = useContext(userInformations);

  const closeDrawer = () => {
    clearVariables();
    props.closeDrawer();
  };

  const clearVariables = () => {
    setCurrentStep(0);
    setCurrentSection(null);
  };
  const getListSections = () => {
    if (userInfo.current?.sections) {
      return userInfo.current?.sections.sort((a, b) => a.index - b.index);
    }
    return [];
  };

  const handleSelectedSection = async (section) => {
    setCurrentStep(1);
    setCurrentSection(section);
  };

  const renderContent = (step) => {
    switch (step) {
      case 0:
        return (
          <ListSections
            sectionsList={getListSections()}
            onSelectedSection={(section) => handleSelectedSection(section)}
          />
        );
      case 1:
        return (
          <TableEditPermissions
            currentSection={currentSection ? currentSection : null}
            selectedGroups={props.selectedGroups}
            handleUpdateGroupList={() => props.handleUpdateGroupList()}
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <>
      <Drawer
        className={'global-style-drawer'}
        title={`Настройка прав: ${props.selectedGroups
          ?.map((group) => group.name)
          .join(', ')}`}
        placement="right"
        onClose={() => closeDrawer()}
        visible={props.visible}
      >
        <StepsDrawer
          currentStep={currentStep}
          onChangeStep={(step) => setCurrentStep(step)}
        />

        {renderContent(currentStep)}
      </Drawer>
    </>
  );
};

EditPermissionsDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  selectedGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleUpdateGroupList: PropTypes.func.isRequired,
};

export default EditPermissionsDrawer;
