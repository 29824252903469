export function getMonthName(monthIndex, fullName = false) {
  const monthArr = [
    'янв',
    'фев',
    'мар',
    'апр',
    'май',
    'июн',
    'июл',
    'авг',
    'сент',
    'окт',
    'ноя',
    'дек',
  ];
  const monthArrFullName = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];
  if (fullName) {
    return monthArrFullName[monthIndex];
  }
  return monthArr[monthIndex];
}
