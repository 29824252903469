import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  message,
} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import Dragger from 'antd/es/upload/Dragger';
import { getDocxContractFileFormUrl } from '../contracts/getDocxContractFileFormUrl';
import getBlobFromUrl from '../contracts/genereteContracts/getBlobFromUrl';
import { ErrorBoundary } from '../ErrorBoundary';
import SendMessageModal from '../email/SendMessageModal';
import { userInformations } from '../../contex';
import AddAttachmentModal from './attachments/AddAttachmentModal';
import { createAttachmentFunction } from '../contracts/attachments/createAttachmentFunction';
import { prepareAttachmentTypeForSelect } from '../contracts/attachments/prepareAttachmentTypeForSelect';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const { Option, OptGroup } = Select;

const CreateContractModal = (props) => {
  const [contractTypeForPurveyors, setContractTypeForPurveyors] =
    useState(false);
  const [contractStatus, setContractStatus] = useState(null);
  const mainModalRef = useRef(null);
  const [draggingFile, setDraggingFile] = useState(false);
  const [contractFileList, setContractFileList] = useState([]);
  const [recipientContract, setRecipientContract] = useState(null);
  const { userInfo } = useContext(userInformations);
  const contractStatusesWhichCannotDragFiles = ['con', 'sig', 'arc'];
  const [visibleAttachmentsModal, setVisibleAttachmentsModal] = useState(false);
  const [addedFilesToAttachments, setAddedFilesToAttachments] = useState([]);
  const [fileForCreateAttachment, setFileForCreateAttachment] = useState([]);
  const isMobile = useCheckMobileScreen();

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDraggingFile(true);
    }
  };
  const handleDragOut = () => {
    setDraggingFile(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingFile(false);
    if (e.dataTransfer?.files && e.dataTransfer?.files?.length > 0) {
      if (
        contractStatusesWhichCannotDragFiles.includes(contractStatus) &&
        props.contract
      ) {
        const files = Object.entries(e.dataTransfer?.files).map((fileArr) => {
          return { originFileObj: fileArr[1], name: fileArr[1]?.name };
        });
        setFileForCreateAttachment(files);
        setVisibleAttachmentsModal(true);
      } else {
        if (e.dataTransfer.files.length > 1) {
          message.warning(
            `Перетянуто более 1 файла. Выбран файл: ${e.dataTransfer.files[0]?.name}`,
          );
        }
        const file = [
          {
            originFileObj: e.dataTransfer.files[0],
            name: e.dataTransfer.files[0]?.name,
          },
        ];
        props.changeDraggerFileList(file);
      }

      e.dataTransfer.clearData();
    }
  };

  useEffect(() => {
    const element = mainModalRef.current;
    if (element) {
      element.addEventListener('dragenter', handleDragIn);
      element.addEventListener('dragleave', handleDragOut);
      element.addEventListener('dragover', handleDrag);
      element.addEventListener('drop', handleDrop);
      return () => {
        element.removeEventListener('dragenter', handleDragIn);
        element.removeEventListener('dragleave', handleDragOut);
        element.removeEventListener('dragover', handleDrag);
        element.removeEventListener('drop', handleDrop);
      };
    }
  }, [props.visible, contractStatus]);

  useEffect(() => {
    if (!props.visible) {
      setRecipientContract(null);
      setAddedFilesToAttachments([]);
      setFileForCreateAttachment([]);
    }
  }, [props.visible]);

  useEffect(() => {
    const status = props.form.getFieldValue('status');
    setContractStatus(status ? status : null);
  }, [props.visible]);

  const propsDragger = {
    beforeUpload: () => false,
    maxCount: 1,
    async onChange({ fileList }) {
      props.changeDraggerFileList(fileList);
    },
    multiple: false,
    fileList: props.draggerFileList,
  };

  const propsDraggerAttachment = {
    beforeUpload: () => false,
    async onChange({ file }) {
      if (file.status === 'removed') {
        await message.warning(
          'Удаление вложения договора, можно произвести в карточке контрагента в разделе "вложения"',
          2,
        );
        return;
      }
      const fileObject = { originFileObj: file, name: file?.name };
      setFileForCreateAttachment([file?.originFileObj ? file : fileObject]);
      setVisibleAttachmentsModal(true);
    },
    multiple: true,
    fileList: addedFilesToAttachments,
  };

  useEffect(() => {
    if (props.contractType) {
      changeFiledContractType(props.contractType);
    }
  }, [props.contractType]);

  useEffect(() => {
    if (!props.visible) {
      setContractTypeForPurveyors(false);
    }
  }, [props.visible]);

  const changeFiledContractType = (contractType) => {
    const typeForPurveyors = ['aup', 'tep', 'vap'];
    if (typeForPurveyors.includes(contractType)) {
      setContractTypeForPurveyors(true);
      return;
    }
    setContractTypeForPurveyors(false);
  };

  const onChangeContractStatus = async (contractStatus) => {
    if (contractStatusesWhichCannotDragFiles.includes(contractStatus)) {
      props.changeDraggerFileList([]);
    }
    setContractStatus(contractStatus);
    if (
      contractStatus === 'sig' &&
      props.contract &&
      !props.contract?.has_original
    ) {
      await message.info('Прикрепите оригинал договора', 4);
    }
  };

  async function prepareFiles(contractNumber, filesUrl) {
    const fileList = [];
    if (filesUrl.docxUrl) {
      const filenameDocx = contractNumber
        ? `Договор ${contractNumber}.docx`
        : `Договор.docx`;
      const docxFile = await getDocxContractFileFormUrl(
        filesUrl.docxUrl,
        filenameDocx,
      );
      if (docxFile) {
        fileList.push({ originFileObj: docxFile, name: docxFile?.name });
      }
    }
    if (filesUrl.pdfUrl) {
      const pdfFileName = contractNumber
        ? `Договор ${contractNumber}.pdf`
        : `Договор.pdf`;
      const blobPDF = await getBlobFromUrl(filesUrl.pdfUrl);
      const filePDF = new File([blobPDF], pdfFileName, {
        type: 'application/pdf',
      });
      fileList.push({ originFileObj: filePDF, name: filePDF?.name });
    }
    return fileList;
  }

  const openModalSendEmailModalAndPrepareFilesContract = async () => {
    try {
      const contractNumber = props.form.getFieldValue('code_contract');
      const fileList = await prepareFiles(contractNumber, props.filesUrl);
      setContractFileList(fileList);
      props.setVisibleSendEmailModal(true);
    } catch (e) {
      console.log(e);
      message.error('Ошибка конвертации файлов договора');
    }
  };

  const handleCloseSendEmailModal = () => {
    setContractFileList([]);
    props.setVisibleSendEmailModal(false);
    if (props.handleUpdateAfterCloseModalSend) {
      props.handleUpdateDataContract();
    }
  };

  /*const handleClear = () => {
        setRecipientContract(null)
        setAddedFilesToAttachments([])
        setFileForCreateAttachment([])
        props.onCancel()
    }*/

  const handleCloseAddAttachmentModal = () => {
    setFileForCreateAttachment([]);
    setVisibleAttachmentsModal(false);
  };

  async function addAttachment(body, userInfo) {
    const successfulAdd = await createAttachmentFunction(body, userInfo);
    if (successfulAdd) {
      const addedFiles = body?.file?.length > 0 ? body.file : [];
      setAddedFilesToAttachments((prevState) => prevState.concat(addedFiles));
      setVisibleAttachmentsModal(false);
      setFileForCreateAttachment([]);
    }
  }

  return (
    <>
      <Modal
        className={'CustomerTable-create-dgv-modal'}
        visible={props.visible}
        width={1000}
        //bodyStyle={{zIndex: 99, position: 'relative'}}
        zIndex={99}
        bodyStyle={{ minHeight: '100%' }}
        title="Оформление договора"
        onCancel={() => {
          props.onCancel();
        }}
        footer={[
          <Button
            key={'preview'}
            type={'primary'}
            onClick={props.previewContract}
          >
            Предпросмотр
          </Button>,
          <Button
            key={'download'}
            disabled={props.disabled || props.loading}
            onClick={props.downloadDoc}
          >
            Скачать .docx
          </Button>,
          <>
            {!contractTypeForPurveyors ? (
              <Button
                key="submit"
                type="primary"
                loading={props.loading}
                disabled={props.disabled || props.loading}
                onClick={async () =>
                  await openModalSendEmailModalAndPrepareFilesContract()
                }
              >
                Отправить на согласование
              </Button>
            ) : (
              <></>
            )}
          </>,
          <Button
            key="back"
            onClick={() => {
              props.saveContract();
            }}
            disabled={props.loading}
          >
            Сохранить
          </Button>,
        ]}
      >
        <div ref={mainModalRef} style={{ width: '100%', height: '100%' }}>
          {draggingFile ? (
            <div
              className={'ant-upload'}
              style={{
                width: '100%',
                height: '377px',
                textAlign: 'center',
                border: '3px dashed #d9d9d9',
                borderRadius: '2px',
                backgroundColor: '#fafafa',
                fontSize: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pointerEvents: 'none',
              }}
            >
              <div>
                {!contractStatusesWhichCannotDragFiles.includes(contractStatus)
                  ? 'Перенесите файл договора'
                  : 'Перенесите файл вложения'}
              </div>
            </div>
          ) : (
            <>
              <Spin spinning={props.loading}>
                <Form
                  form={props.form}
                  onValuesChange={props.formOnValuesChange}
                  layout="vertical"
                >
                  <Row gutter={16}>
                    <Col xs={24} lg={6}>
                      <Form.Item
                        name={'type'}
                        label={'Тип договора'}
                        rules={[
                          { required: true, message: 'Обязательное поле!' },
                        ]}
                      >
                        <Select
                          getPopupContainer={(trigger) =>
                            isMobile ? trigger.parentNode : document.body
                          }
                          placeholder={'Тип договора'}
                          onChange={(val) => changeFiledContractType(val)}
                        >
                          {props.typeChoices?.contract_type
                            ? Object.entries(
                                props.typeChoices.contract_type,
                              ).map((item) => {
                                return (
                                  <Option key={item[0]} value={item[0]}>
                                    {item[1]}
                                  </Option>
                                );
                              })
                            : null}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      lg={6}
                      style={props.uploadedDoc ? { display: 'none' } : {}}
                    >
                      <Form.Item
                        label={'Номер договора'}
                        name={'code_contract'}
                        rules={[
                          { required: true, message: 'Обязательно поле!' },
                        ]}
                      >
                        <Input
                          disabled={props.disableDateBeginAndNumberAndStatus}
                          placeholder={'Номер договора'}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      lg={6}
                      style={props.uploadedDoc ? { display: 'none' } : {}}
                    >
                      <Form.Item
                        initialValue={'new'}
                        label={'Состояние'}
                        name={'status'}
                      >
                        <Select
                          getPopupContainer={(trigger) =>
                            isMobile ? trigger.parentNode : document.body
                          }
                          disabled={!props.isAdmin && props.useLocks}
                          onChange={async (val) =>
                            await onChangeContractStatus(val)
                          }
                          defaultValue={'new'}
                          placeholder={'Статус договора'}
                        >
                          {props.typeChoices?.contract_status &&
                          Object.entries(props.typeChoices?.contract_status)
                            ?.length > 0 ? (
                            <>
                              {Object.entries(
                                props.typeChoices.contract_status,
                              ).map((item) => {
                                return (
                                  <Option key={item[0]} value={item[0]}>
                                    {item[1]}
                                  </Option>
                                );
                              })}
                            </>
                          ) : (
                            <Option disabled>Нет доступных вариантов</Option>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={props.uploadedDoc ? 8 : 6}>
                      <Form.Item
                        initialValue={1}
                        label="Условие отсрочки (дней)"
                        name={'postponement_condition'}
                        rules={[
                          { required: true, message: 'Обязательно поле!' },
                        ]}
                      >
                        <InputNumber
                          min={1}
                          defaultValue={1}
                          placeholder="Условие отсрочки"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={props.uploadedDoc ? 8 : 6}>
                      <Form.Item
                        initialValue={new Date().toISOString().split('T')[0]}
                        label={'Дата начала'}
                        name={'date_begin'}
                        rules={[
                          { required: true, message: 'Обязательное поле!' },
                        ]}
                      >
                        <Input
                          disabled={props.disableDateBeginAndNumberAndStatus}
                          className={'ant-input'}
                          type={'date'}
                          max="9999-12-31"
                          min={
                            !props.isAdmin
                              ? new Date().toISOString().split('T')[0]
                              : null
                          }
                          defaultValue={new Date().toISOString().split('T')[0]}
                          onChange={props.onChangeDateBegin}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={props.uploadedDoc ? 8 : 6}>
                      <Form.Item label={'Дата окончания'} name={'date_end'}>
                        <input
                          className={'ant-input'}
                          max="9999-12-31"
                          type={'date'}
                          onChange={props.onChangeDateEnd}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={24} xs={24}>
                      <h4>Подписанты/Получатель</h4>
                    </Col>
                    <Col lg={8} xs={24}>
                      <Form.Item
                        label={'С нашей стороны'}
                        name={'signer_manager'}
                        rules={[
                          { required: true, message: 'Обязательное поле!' },
                        ]}
                      >
                        <Select
                          getPopupContainer={(trigger) =>
                            isMobile ? trigger.parentNode : document.body
                          }
                          onChange={props.onChangeSignerManager}
                          listHeight={122}
                          placeholder={'Выберите из списка или начните поиск'}
                        >
                          {props.signerManagers && (
                            <>
                              {Object.entries(props.signerManagers).map(
                                (item, index) => {
                                  return (
                                    <OptGroup key={index} label={item[0]}>
                                      {item[1].length > 0 ? (
                                        <>
                                          {item[1].map((option) => {
                                            return (
                                              <Option
                                                data-value={JSON.stringify(
                                                  option,
                                                )}
                                                value={option.id}
                                                key={option.id}
                                              >
                                                {option.full_name ||
                                                  option.email}
                                              </Option>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <Option disabled={true} value={'none'}>
                                          Нет доступных вариантов
                                        </Option>
                                      )}
                                    </OptGroup>
                                  );
                                },
                              )}
                            </>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={8} xs={24}>
                      <Form.Item
                        label={'Со стороны клиента'}
                        name={'signer_customer'}
                        rules={[
                          { required: true, message: 'Обязательное поле!' },
                        ]}
                      >
                        <Select
                          getPopupContainer={(trigger) =>
                            isMobile ? trigger.parentNode : document.body
                          }
                          listHeight={122}
                          onChange={props.onChangeSignerCustomer}
                          placeholder={'Выберите из списка или начните поиск'}
                        >
                          {props.signerCustomers && (
                            <>
                              {Object.entries(props.signerCustomers)?.length >
                              0 ? (
                                Object.entries(props.signerCustomers).map(
                                  (item, index) => {
                                    return (
                                      <OptGroup key={index} label={item[0]}>
                                        {item[1].length > 0 ? (
                                          <>
                                            {item[1].map((option) => {
                                              return (
                                                <Option
                                                  data-value={JSON.stringify(
                                                    option,
                                                  )}
                                                  value={option.id}
                                                  key={option.id}
                                                >
                                                  {option.full_name ||
                                                    option.email}
                                                </Option>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <Option
                                            disabled={true}
                                            value={'addContact'}
                                          >
                                            Добавить подписанта
                                          </Option>
                                        )}
                                      </OptGroup>
                                    );
                                  },
                                )
                              ) : (
                                <Option disabled={false} value={'addContact'}>
                                  Добавить подписанта
                                </Option>
                              )}
                            </>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    {!contractTypeForPurveyors ? (
                      <Col lg={8} xs={24}>
                        <Form.Item
                          label={'Получатель'}
                          name={'contact_send'}
                          rules={[
                            {
                              required: !(
                                contractStatus !== 'new' &&
                                contractStatus !== 'rec'
                              ),
                              message: 'Обязательное поле!',
                            },
                          ]}
                        >
                          <Select
                            getPopupContainer={(trigger) =>
                              isMobile ? trigger.parentNode : document.body
                            }
                            listHeight={122}
                            onChange={(val, fullOptionData) => {
                              if (fullOptionData['data-value']) {
                                setRecipientContract(
                                  JSON.parse(fullOptionData['data-value']),
                                );
                              }
                              props.onChangeContactSend();
                            }}
                            placeholder={'Выберите из списка или начните поиск'}
                          >
                            {props.allClients && (
                              <>
                                {Object.entries(props.allClients).map(
                                  (item, index) => {
                                    return (
                                      <OptGroup key={index} label={item[0]}>
                                        {item[1].length > 0 ? (
                                          <>
                                            {item[1].map((option) => {
                                              return (
                                                <Option
                                                  data-value={JSON.stringify(
                                                    option,
                                                  )}
                                                  value={option.id}
                                                  key={option.id}
                                                >
                                                  {option.full_name ||
                                                    option.email}
                                                </Option>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <Option
                                            disabled={true}
                                            value={'none'}
                                          >
                                            Нет доступных вариантов
                                          </Option>
                                        )}
                                      </OptGroup>
                                    );
                                  },
                                )}
                              </>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    ) : (
                      <></>
                    )}
                    {!contractStatusesWhichCannotDragFiles.includes(
                      contractStatus,
                    ) ? (
                      <Col lg={8} xs={24}>
                        <Form.Item label={'Docx договора'}>
                          <Dragger
                            className={'small-dragger'}
                            {...propsDragger}
                          >
                            <p
                              style={{
                                height: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {'Кликните или перенесите файл'}
                            </p>
                          </Dragger>
                        </Form.Item>{' '}
                      </Col>
                    ) : props.contract ? (
                      <>
                        <Col lg={8} xs={24}>
                          <Form.Item label={'Добавить вложение'}>
                            <div>
                              <Dragger
                                className={'small-dragger'}
                                {...propsDraggerAttachment}
                              >
                                <p
                                  style={{
                                    height: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {'Кликните или перенесите файл'}
                                </p>
                              </Dragger>
                            </div>
                          </Form.Item>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Row>
                </Form>
              </Spin>
            </>
          )}
        </div>
      </Modal>
      <ErrorBoundary>
        <SendMessageModal
          open={props.visibleSendEmailModal}
          loading={false}
          handleClose={() => {
            handleCloseSendEmailModal();
          }}
          profileInfo={userInfo.current}
          sendForContract={true}
          contractFiles={contractFileList.length > 0 ? contractFileList : null}
          defaultValueForSelectEmail={
            recipientContract?.email ? [recipientContract?.email] : null
          }
          sendContractForApproval={(valueForm) => {
            props.sendForApproval(valueForm);
          }}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <AddAttachmentModal
          visible={visibleAttachmentsModal}
          creationAttachment={true}
          loading={false}
          typesAttachment={prepareAttachmentTypeForSelect(
            props.typeChoices?.attachment_type,
          )}
          customerId={
            props.contract?.customer?.id ? props.contract?.customer.id : null
          }
          fileList={fileForCreateAttachment}
          currentContract={props.contract}
          createAttachment={async (body) => await addAttachment(body, userInfo)}
          disableSelectContract={true}
          handelCloseModal={() => handleCloseAddAttachmentModal()}
        />
      </ErrorBoundary>
    </>
  );
};

CreateContractModal.propTypes = {
  visible: PropTypes.bool,
  contract: PropTypes.any,
  onCancel: PropTypes.func,
  previewContract: PropTypes.func,
  disabled: PropTypes.bool,
  downloadDoc: PropTypes.func,
  loading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  useLocks: PropTypes.bool,
  filesUrl: PropTypes.shape({
    docxUrl: PropTypes.string,
    pdfUrl: PropTypes.string,
  }),
  draggerFileList: PropTypes.any,
  changeDraggerFileList: PropTypes.func,
  sendForApproval: PropTypes.func,
  saveContract: PropTypes.func,
  form: PropTypes.any,
  contractType: PropTypes.string,
  disableDateBeginAndNumberAndStatus: PropTypes.bool,
  formOnValuesChange: PropTypes.func,
  uploadedDoc: PropTypes.any,
  typeChoices: PropTypes.any,
  handleUpdateAfterCloseModalSend: PropTypes.bool,
  visibleSendEmailModal: PropTypes.bool,
  setVisibleSendEmailModal: PropTypes.func,
  onChangeDateBegin: PropTypes.func,
  onChangeDateEnd: PropTypes.func,
  onChangeSignerManager: PropTypes.func,
  signerManagers: PropTypes.arrayOf(PropTypes.any),
  onChangeSignerCustomer: PropTypes.func,
  signerCustomers: PropTypes.arrayOf(PropTypes.any),
  onChangeContactSend: PropTypes.func,
  allClients: PropTypes.arrayOf(PropTypes.any),
  onChangeAttachment: PropTypes.func,
  attach: PropTypes.any,
  onChangeIsOriginalDocContract: PropTypes.func,
  handleUpdateDataContract: PropTypes.func,
};

export default CreateContractModal;
