import { TodoActionActiveChatIdTypes } from '../todo/activeChatId/activeChatId';

const initialState = {
  chatId: null,
  thereWasTaskCardOpening: false,
};

export const activeChatIdReducer = (
  state = initialState,
  action: TodoActionActiveChatIdTypes,
) => {
  switch (action.type) {
    case 'SET_ACTIVE_CHAT_ID':
      return {
        chatId: action.payload,
        thereWasTaskCardOpening: state.thereWasTaskCardOpening,
      };
    case 'SET_WAS_TASK_CARD_OPENING':
      return { chatId: state.chatId, thereWasTaskCardOpening: action.payload };
    default:
      return state;
  }
};
