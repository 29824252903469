import PropTypes from 'prop-types';
import { Col, Form, Input, Row, Select } from 'antd';
import WaypointRepresentativeForm from '../../../../../../waypointRepresentative/WaypointRepresentativeForm';
import React, { useCallback, useContext, useState } from 'react';
import useCheckMobileScreen from '../../../../../../../../hooks/useCheckMobileScreen';
import { getListOperationWaypoint } from '../../../../../../../../logic/refbook/operationWaypoint/OperationWaypointApiFunctions';
import { userInformations } from '../../../../../../../../contex';
import useAsyncEffect from 'use-async-effect';
import { getListRouteWaypoint } from '../../../../../../../../logic/refbook/operationWaypoint/RouteWaypointApiFunctions';

const { Option } = Select;

const WaypointsModalForm = (props) => {
  const isMobile = useCheckMobileScreen();
  const { userInfo } = useContext(userInformations);
  const [operationsOptionsList, setOperationsOptionsList] = useState([]);
  const [waypointsOptionsList, setWaypointsOptionsList] = useState([]);

  useAsyncEffect(async () => {
    await handleGetOperations();
    await handleGetWaypointsOptions();
  }, []);

  const handleGetOperations = useCallback(
    async (keyword = null) => {
      const res = await getListOperationWaypoint(userInfo, keyword);
      if (res?.data) {
        setOperationsOptionsList(res.data);
      }
    },
    [setOperationsOptionsList],
  );

  const handleGetWaypointsOptions = useCallback(
    async (keyword = null) => {
      const res = await getListRouteWaypoint(userInfo, keyword);
      if (res?.data) {
        setWaypointsOptionsList(res.data);
      }
    },
    [setWaypointsOptionsList],
  );

  return (
    <>
      <Form form={props.form} layout={'vertical'}>
        <Row gutter={16}>
          <Col xs={24} lg={24}>
            <Form.Item name={'operation'} label={'Операция'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                showSearch
                // defaultValue={record && record.operation ? record.operation.id : ''}
                disabled={props.editingWaypoint?.id}
                placeholder="Поиск операции"
              >
                {operationsOptionsList && operationsOptionsList.length > 0 ? (
                  <>
                    {operationsOptionsList.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </>
                ) : (
                  <Option disabled value="Загрузка">
                    Загрузка
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item name={'name'} label={'Название'}>
              <Input
                placeholder={'Название'}
                disabled={props.editingWaypoint?.id}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name={'date'} label={'Дата'}>
              <input
                className={'ant-input'}
                type={'date'}
                max="9999-12-31"
                //onChange={(e) => setDateWayPoint(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name={'load_plan'} label={'Планируемое время погрузки'}>
              <input
                className={'ant-input'}
                type={'time'}
                //onChange={(e) => setDateWayPoint(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item name={'distance'} label={'Расстояние'}>
              <Input placeholder={'Расстояние'} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item name={'waypoint'} label={'Пункт'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                showSearch
                filterOption={false}
                onSearch={(value) => handleGetWaypointsOptions(value)}
                placeholder="Найти пункт"
              >
                {waypointsOptionsList.length > 0 ? (
                  <>
                    {Array.isArray(waypointsOptionsList) &&
                      waypointsOptionsList.map((item) => {
                        return (
                          <Option
                            key={item.id}
                            value={item.id}
                            data-value={JSON.stringify(item)}
                          >
                            {item?.name ?? '-'} {item?.code ?? '-'}
                          </Option>
                        );
                      })}
                  </>
                ) : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <WaypointRepresentativeForm
              contact={props.editingWaypoint?.phone_line}
              representative={props.editingWaypoint?.agent_line}
              visibleModal={props.visibleModal}
              mainForm={props.form}
              onChangeWaypointRepresentative={(waypointRepresentativeValue) => {
                props.onChangeWaypointRepresentative({
                  representative:
                    waypointRepresentativeValue.representative ?? null,
                  contact: waypointRepresentativeValue?.contact ?? null,
                });
              }}
            />
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item name={'comment'} label={'Комментарий'}>
              <Input placeholder={'Комментарий'} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default WaypointsModalForm;

WaypointsModalForm.propTypes = {
  form: PropTypes.any,
  editingWaypoint: PropTypes.object,
  visibleModal: PropTypes.bool,
  onChangeWaypointRepresentative: PropTypes.func,
};
