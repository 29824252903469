import PropTypes from 'prop-types';
import { Card, List } from 'antd';

const PlaceholdersList = (props) => {
  return (
    <List
      grid={{
        gutter: 10,
        column: 4,
      }}
      dataSource={props.placeholders}
      renderItem={(item) => (
        <List.Item>
          <Card hoverable={true} size={'small'} title={item.placeholdersName}>
            {item.translateName}
          </Card>
        </List.Item>
      )}
    />
  );
};

PlaceholdersList.props = {
  placeholders: PropTypes.any,
};
export default PlaceholdersList;
