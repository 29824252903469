import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';
import Budget from '../components/dashboard/Budget';
import Sales from '../components/dashboard/Sales';
import TasksProgress from '../components/dashboard/TasksProgress';
import TotalProfit from '../components/dashboard/TotalProfit';
import TrafficByDevice from '../components/dashboard/TrafficByDevice';
import { restApiHost } from '../utils/appVariables';
import TasksStatTable from '../components/dashboard/TasksStatTable';
import LeedsStatTable from '../components/dashboard/LeedsStatTable';
import { message, Row, Spin } from 'antd';
import { userInformations } from '../contex';
import { call } from '../apiUtils/call';
import PeriodSwitch from '../components/dashboard/periodFilters/PeriodSwitch';
import StaffSwitch from '../components/dashboard/periodFilters/StaffSwitch';
import { ErrorBoundary } from '../components/ErrorBoundary';
import checkGroupsUsers from '../components/dashboard/dasboardUtils/checkGroupsUsers';

import dayjs from 'dayjs';
import formationDocumentTitle from '../utils/formationDocumentTitle';
import { useTypedSelector } from '../store/hooks/useTypedSelector';

const Dashboard = () => {
  const [stat, setStat] = useState({
    leads: { count_all: 0, data: [] },
    pre_tasks: {
      all: 0,
      sum: 0.0,
      create: [],
      dates: [],
      success: [],
    },
    tasks: {
      all: 0,
      sum: 0.0,
      create: [],
      dates: [],
      success: [],
    },
  });
  const [staffList, setStaffList] = useState([]);
  const [currentStaff, setCurrentStaff] = useState('all');
  const [loading, setLoading] = useState(false);
  const [displaySelectChoiceStaff, setDisplaySelectChoiceStaff] =
    useState(false);
  const { userInfo } = useContext(userInformations);
  const [optionsPeriods, setOptionsPeriods] = useState([]);
  const [optionsPeriodsStaff, setOptionsPeriodsStaff] = useState([]);
  const [periodDate, setPeriodDate] = useState(1);
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );

  useEffect(() => {
    const dataNow = new Date();
    setOptionsPeriods([
      {
        title: 'Сегодня',
        value: 1,
      },
      {
        title: 'Неделя',
        value: 7,
      },
      {
        title: 'Месяц',
        value: getDaysInMonth(dataNow.getMonth(), dataNow.getFullYear()),
      },
      {
        title: 'За все время',
        value: 'all',
      },
    ]);
    setOptionsPeriodsStaff([
      {
        title: 'Все',
        value: 'all',
      },
    ]);
  }, []);

  useEffect(async () => {
    setLoading(true);
    if (periodDate) {
      const urlParams = getParamsForUrlGetStat(periodDate, currentStaff);
      await getStat(urlParams);
    }
    setLoading(false);
  }, [periodDate, currentStaff]);

  useEffect(async () => {
    await getStaffList();
  }, []);

  useEffect(() => {
    if (userInfo.current?.groups) {
      const display = checkGroupsUsers(userInfo.current?.groups);
      setDisplaySelectChoiceStaff(display);
    }
  }, [userInfo]);

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const getParamsForUrlGetStat = (periodDate, currentStaff) => {
    if (typeof periodDate === 'object') {
      const startDay = dayjs(periodDate[0]).day();
      const endDay = dayjs(periodDate[1]).day();
      const start = dayjs(periodDate[0])
        .set('day', startDay - 1)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .format('YYYY-MM-DDTHH:ss:mmZ')
        .replaceAll('+', '%2B');
      const end = dayjs(periodDate[1])
        .set('day', endDay - 1)
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .format('YYYY-MM-DDTHH:ss:mmZ')
        .replaceAll('+', '%2B');
      return `?range=${start},${end}${
        currentStaff !== 'all' ? '&staff=' + currentStaff : ''
      }`;
    } else {
      return `?range=${periodDate}${
        currentStaff !== 'all' ? '&staff=' + currentStaff : ''
      }`;
    }
  };

  /*const checkGroupsUsers = (groups) => {
       let include = false
        groups.map((group) => {
            if (group?.id === 1 || group?.id === 2) {
                include = true
            }
        })
        return include
    }*/

  const getStat = async (urlParams) => {
    setLoading(true);
    try {
      const url = `${restApiHost}/leads/statistic/${urlParams}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setStat(json.data);
      }
    } catch (e) {
      console.log('get stat err', e);
      message.error('Ошибка получения статистики');
    } finally {
      setLoading(false);
    }
  };
  const getStaffList = async () => {
    try {
      const url = `${restApiHost}/accounts/staff_users/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setStaffList(json?.data);
      }
    } catch (e) {
      console.log('fetch profile info error', e);
    }
  };
  const searchStaff = async (keyword) => {
    try {
      const url = `${restApiHost}/accounts/staff_users/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setStaffList(json.data);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса! Не удалось найти лида');
    }
  };
  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle('Статистика | Квик Транс', notificationCount)}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
        }}
      >
        <Row
          className={'Dashboard-toolbar'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 30,
          }}
        >
          <ErrorBoundary>
            <PeriodSwitch
              periods={optionsPeriods}
              periodDateProps={periodDate}
              loading={loading}
              changePeriod={(period) => setPeriodDate(period)}
            />
          </ErrorBoundary>
          {displaySelectChoiceStaff ? (
            <ErrorBoundary>
              <StaffSwitch
                periods={optionsPeriodsStaff}
                staffCurrentOptionProps={currentStaff}
                staffListProps={staffList}
                loading={loading}
                changeStaff={(staffId) => {
                  if (staffId) {
                    setCurrentStaff(staffId);
                  }
                }}
                onSearch={(e) => {
                  if (e) {
                    searchStaff(e);
                  } else {
                    getStaffList();
                  }
                }}
                style={{ marginLeft: '50px' }}
              />
            </ErrorBoundary>
          ) : (
            <></>
          )}
        </Row>
        <Spin spinning={loading}>
          <section className={'Dashboard-layout-stats-content'}>
            <div className={'Dashboard-stats-chart-wrapper'}>
              <ErrorBoundary>
                <TrafficByDevice stat={stat} />
              </ErrorBoundary>
            </div>
            <div className={'Dashboard-stats-grid'}>
              <div className={'Dashboard-stats-grid-item'}>
                <ErrorBoundary>
                  <TasksProgress title={'Пред. заявок'} stat={stat} />
                </ErrorBoundary>
              </div>
              <div className={'Dashboard-stats-grid-item'}>
                <ErrorBoundary>
                  <TasksProgress title={'Заявок'} stat={stat} />
                </ErrorBoundary>
              </div>
              <div className={'Dashboard-stats-grid-item'}>
                <ErrorBoundary>
                  <TasksProgress title={'Отправлено КП'} stat={stat} />
                </ErrorBoundary>
              </div>
              <div className={'Dashboard-stats-grid-item'}>
                <ErrorBoundary>
                  <TasksProgress title={'Отправлено презентаций'} stat={stat} />
                </ErrorBoundary>
              </div>
              <div className={'Dashboard-stats-grid-item'}>
                <ErrorBoundary>
                  <Budget stat={stat} />
                </ErrorBoundary>
              </div>
              <div className={'Dashboard-stats-grid-item'}>
                <ErrorBoundary>
                  <TotalProfit sx={{ height: '100%' }} stat={stat} />
                </ErrorBoundary>
              </div>
            </div>
          </section>
          <section className={'Dashboard-layout-stats-content'}>
            <div className="Dashboard-stats-item LeedsStatTable">
              <ErrorBoundary>
                <LeedsStatTable sx={{ height: '100%' }} />
              </ErrorBoundary>
            </div>
            <div className="Dashboard-stats-item Sales">
              <ErrorBoundary>
                <Sales stat={stat} />
              </ErrorBoundary>
            </div>
          </section>
          <section className={'Dashboard-layout-stats-content'}>
            <div className="Dashboard-stats-item TasksStatTable">
              <ErrorBoundary>
                <TasksStatTable />
              </ErrorBoundary>
            </div>
          </section>
        </Spin>
      </Box>
    </>
  );
};

export default Dashboard;
