import React, { useState } from 'react';
import { Tabs } from 'antd';
import { ErrorBoundary } from '../ErrorBoundary';
import ApproachList from './ApproachList';
import StockList from './StockList';
import SendingList from './SendingList';
import DosylList from './DosylList';
import StocksToolbar from './StocksToolbar';

const { TabPane } = Tabs;

const TabsStocks = () => {
  const [approachStocks, setApproachStocks] = useState([
    {
      id: 1,
      title: 'Ижевск',
      data: [
        {
          id: 1,
          status: 'В пути',
          number_container: 'AAABBB11',
          type_container: 'Контейнер 20`, 24 т. (1СС)',
          owner: 'Квиктранс',
          delivery_date: '25-02-2022',
          comment: 'ляляляля',
          stock: 'Ижевск',
        },
      ],
    },
  ]);

  const [stocks, setStocks] = useState([]);
  const [sendingStocks, setSendingStocks] = useState([]);
  const [dosylStocks, setDosylStocks] = useState([]);

  const addApproachStock = (data) => {
    setApproachStocks([...approachStocks, data]);
  };

  const addApproachContainer = (stock, container) => {
    const editItemIndex = approachStocks.findIndex(
      (item) => item.title === stock,
    );
    const newStocks = [...approachStocks];
    if (editItemIndex >= 0) {
      const newData = [...newStocks[editItemIndex].data];
      newStocks[editItemIndex] = {
        ...newStocks[editItemIndex],
        data: [...newData, container],
      };
      setApproachStocks(newStocks);
    }
  };

  const handleChangeApproachStocks = (stock, arr) => {
    const editItemIndex = approachStocks.findIndex(
      (item) => item.title === stock,
    );
    const newStocks = [...approachStocks];

    if (editItem >= 0) {
      newStocks[editItemIndex] = {
        ...newStocks[editItemIndex],
        data: arr,
      };
      setApproachStocks(newStocks);
    }
  };

  const deleteApproachContainer = (stock, idContainer) => {
    const editItemIndex = approachStocks.findIndex(
      (item) => item.title === stock,
    );
    const newStocks = [...approachStocks];

    if (editItemIndex >= 0) {
      let newData = [...newStocks[editItemIndex].data];
      newData = newData.filter((item) => item.id != idContainer);
      newStocks[editItemIndex] = {
        ...newStocks[editItemIndex],
        data: newData,
      };
      setApproachStocks(newStocks);
    }
  };

  const handleChangeSendingStocks = (stock, arr) => {
    const editItemIndex = sendingStocks.findIndex(
      (item) => item.title === stock,
    );
    const newStocks = [...sendingStocks];

    if (editItem >= 0) {
      newStocks[editItemIndex] = {
        ...newStocks[editItemIndex],
        data: arr,
      };
      setSendingStocks(newStocks);
    }
  };

  const addSendingContainer = (stock, container) => {
    const editItemIndex = sendingStocks.findIndex(
      (item) => item.title === stock,
    );
    const newStocks = [...sendingStocks];

    if (editItemIndex >= 0) {
      const newData = [...newStocks[editItemIndex].data];
      newStocks[editItemIndex] = {
        ...newStocks[editItemIndex],
        data: [...newData, container],
      };
      setSendingStocks(newStocks);
    }
  };

  const deleteSendingContainer = (stock, idContainer) => {
    const editItemIndex = sendingStocks.findIndex(
      (item) => item.title === stock,
    );
    const newStocks = [...sendingStocks];

    if (editItemIndex >= 0) {
      let newData = [...newStocks[editItemIndex].data];
      newData = newData.filter((item) => item.id != idContainer);
      newStocks[editItemIndex] = {
        ...newStocks[editItemIndex],
        data: newData,
      };
      setSendingStocks(newStocks);
    }
  };

  const addSendingStock = (data) => {
    setSendingStocks([...sendingStocks, data]);
  };

  const handleChangeDosylStocks = (stock, arr) => {
    const editItemIndex = dosylStocks.findIndex((item) => item.title === stock);
    const newStocks = [...dosylStocks];

    if (editItem >= 0) {
      newStocks[editItemIndex] = {
        ...newStocks[editItemIndex],
        data: arr,
      };
      setDosylStocks(newStocks);
    }
  };

  const addDosylStock = (data) => {
    setDosylStocks([...dosylStocks, data]);
  };

  const addDosylkContainer = (stock, container) => {
    const editItemIndex = dosylStocks.findIndex((item) => item.title === stock);
    const newStocks = [...dosylStocks];

    if (editItemIndex >= 0) {
      const newData = [...newStocks[editItemIndex].data];
      newStocks[editItemIndex] = {
        ...newStocks[editItemIndex],
        data: [...newData, container],
      };
      setDosylStocks(newStocks);
    }
  };

  const deleteDosylContainer = (stock, idContainer) => {
    const editItemIndex = dosylStocks.findIndex((item) => item.title === stock);
    const newStocks = [...dosylStocks];

    if (editItemIndex >= 0) {
      let newData = [...newStocks[editItemIndex].data];
      newData = newData.filter((item) => item.id != idContainer);
      newStocks[editItemIndex] = {
        ...newStocks[editItemIndex],
        data: newData,
      };
      setDosylStocks(newStocks);
    }
  };

  const handleChangeStocks = (stock, arr) => {
    const editItemIndex = stocks.findIndex((item) => item.title === stock);
    const newStocks = [...stocks];

    if (editItem >= 0) {
      newStocks[editItemIndex] = {
        ...newStocks[editItemIndex],
        data: arr,
      };
      setStocks(newStocks);
    }
  };

  const addStock = (data) => {
    setStocks([...stocks, data]);
  };

  const addStockContainer = (stock, container) => {
    const editItemIndex = stocks.findIndex((item) => item.title === stock);
    const newStocks = [...stocks];

    if (editItemIndex >= 0) {
      const newData = [...newStocks[editItemIndex].data];
      newStocks[editItemIndex] = {
        ...newStocks[editItemIndex],
        data: [...newData, container],
      };
      setStocks(newStocks);
    }
  };

  const deleteStockContainer = (stock, idContainer) => {
    const editItemIndex = stocks.findIndex((item) => item.title === stock);
    const newStocks = [...stocks];

    if (editItemIndex >= 0) {
      let newData = [...newStocks[editItemIndex].data];
      newData = newData.filter((item) => item.id != idContainer);
      newStocks[editItemIndex] = {
        ...newStocks[editItemIndex],
        data: newData,
      };
      setStocks(newStocks);
    }
  };

  return (
    <>
      <StocksToolbar
        stocks={approachStocks}
        handleChangeStocks={handleChangeApproachStocks}
        addStock={addApproachStock}
      />
      <Tabs defaultActiveKey="1">
        <TabPane tab="Подход" key="1">
          <ErrorBoundary>
            <ApproachList
              stocks={approachStocks}
              addApproachContainer={addApproachContainer}
              handleChangeStocks={handleChangeApproachStocks}
              deleteContainer={deleteApproachContainer}
              addStockContainer={addStockContainer}
              addStock={addStock}
              anotherStocks={stocks}
            />
          </ErrorBoundary>
        </TabPane>
        <TabPane tab="Сток" key="2">
          <ErrorBoundary>
            <StockList
              stocks={stocks}
              handleChangeStocks={handleChangeStocks}
              deleteContainer={deleteStockContainer}
              addStockContainer={addSendingContainer}
              addStock={addSendingStock}
              anotherStocks={sendingStocks}
            />
          </ErrorBoundary>
        </TabPane>
        <TabPane tab="Отправка" key="3">
          <ErrorBoundary>
            <SendingList
              stocks={sendingStocks}
              handleChangeStocks={handleChangeSendingStocks}
              deleteContainer={deleteSendingContainer}
              addStockContainer={addDosylkContainer}
              addStock={addDosylStock}
              anotherStocks={dosylStocks}
            />
          </ErrorBoundary>
        </TabPane>
        <TabPane tab="Досыл" key="4">
          <ErrorBoundary>
            <DosylList
              stocks={dosylStocks}
              handleChangeStocks={handleChangeDosylStocks}
              deleteContainer={deleteDosylContainer}
              addStockContainer={addApproachContainer}
              addStock={addApproachStock}
              anotherStocks={approachStocks}
            />
          </ErrorBoundary>
        </TabPane>
      </Tabs>
    </>
  );
};

export default TabsStocks;
