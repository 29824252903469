import { Box } from '@material-ui/core';
import MiniCalendar from '../miniCalendar/MiniCalendar';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '../../ErrorBoundary';
import { Col, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { userInformations } from '../../../contex';
import checkGroupsUsers from '../../dashboard/dasboardUtils/checkGroupsUsers';
import {
  getStaffList,
  searchStaffList,
} from '../../../logic/staff/StaffApiFunctions';
import TreeFilters from './filters/TreeFilters';
import TasksSection from '../mobile/TasksSection';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
const { Option } = Select;

const CalendarSidebar = (props) => {
  const [displaySelectChoiceStaff, setDisplaySelectChoiceStaff] =
    useState(true);
  const { userInfo } = useContext(userInformations);
  const [staffList, setStaffList] = useState([]);

  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (userInfo?.current?.groups) {
      const display = checkGroupsUsers(userInfo.current?.groups);
      setDisplaySelectChoiceStaff(display);
    }
  }, [userInfo]);

  useEffect(async () => {
    await callGetStaff();
  }, []);

  async function callGetStaff() {
    const staff = await getStaffList(userInfo);
    setStaffList(staff?.data ? staff.data : []);
  }

  async function callSearchGetStaff(val) {
    if (!val) return;
    const staff = await searchStaffList(val, userInfo);
    setStaffList(staff?.data ? staff.data : []);
  }

  return (
    <>
      <Box className={'Calendar-widget-wrapper'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <ErrorBoundary>
            <MiniCalendar
              tasks={props.tasks}
              currentDate={props.currentDate}
              switchCalendarCurrentDate={(value) =>
                props.switchCalendarCurrentDate(value)
              }
            />
          </ErrorBoundary>
          {displaySelectChoiceStaff ? (
            <Col className={'CalendarSidebar-select-staff-col'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                style={{
                  width: '100%',
                  marginTop: '20px',
                  height: '32px !important',
                }}
                placeholder={'Календарь сотрудника...'}
                onChange={async (value) => {
                  if (value) {
                    props.filterEventByStaff(value);
                  } else {
                    props.filterEventByStaff();
                  }
                }}
                allowClear={true}
                onSearch={async (val) => {
                  if (val?.length > 0) {
                    await callSearchGetStaff(val);
                  } else {
                    await callGetStaff();
                  }
                }}
                filterOption={false}
                showSearch
              >
                {staffList.length > 0 ? (
                  <>
                    {staffList.map((item, index) => (
                      <Option key={index} value={item?.id}>
                        {item?.full_name}
                      </Option>
                    ))}
                  </>
                ) : (
                  <Option disabled value={null}>
                    Нет доступных вариантов
                  </Option>
                )}
              </Select>
            </Col>
          ) : (
            <></>
          )}
        </div>
        {props.isMobile ? (
          <>
            <TasksSection
              handleEditTask={(task) => props.handleEditTask(task)}
              tasks={props.tasks}
              currentDate={props.currentDate}
              openTaskModal={(val) => props.openTaskModal(val)}
            />
          </>
        ) : (
          <>
            <div className={'checkbox-group-calendar-sidebar'}>
              <ErrorBoundary>
                <TreeFilters
                  handleUpdateTask={(filtes) => props.handleUpdateTask(filtes)}
                />
              </ErrorBoundary>
            </div>
          </>
        )}
      </Box>
    </>
  );
};
CalendarSidebar.propTypes = {
  currentDate: PropTypes.any,
  switchCalendarCurrentDate: PropTypes.func,
  filterEventByStaff: PropTypes.func,
  handleUpdateTask: PropTypes.func,
  isMobile: PropTypes.bool,
  tasks: PropTypes.any,
  handleEditTask: PropTypes.func.isRequired,
  openTaskModal: PropTypes.func.isRequired,
};

export default React.memo(CalendarSidebar);
