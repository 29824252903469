import React from 'react';
import { Button, Space } from 'antd';
import { useState } from 'react';
import Logs from './logs/Logs';
import Tables from './tables/Tables';
import Schedules from './schedule/Schedule';
import Permissions from './permissions/Permissions';
import { Log, Permission, Schedule, Table } from '../../types';

const AdminPanel = () => {
  const [schedules, setSchedules] = useState<Schedule[] | undefined>(undefined);
  const [openSchedules, setOpenSchedules] = useState<boolean>(false);

  const [logs, setLogs] = useState<Log[] | undefined>(undefined);
  const [openLogs, setOpenLogs] = useState<boolean>(false);

  const [tables, setTables] = useState<Table[] | undefined>(undefined);
  const [openTables, setOpenTables] = useState<boolean>(false);

  const [permissions, setPermissions] = useState<Permission[] | undefined>(
    undefined,
  );
  const [openPermissions, setOpenPermissions] = useState<boolean>(false);

  const onOpenTables = () => {
    setOpenTables((value) => !value);
  };

  const onOpenLogs = () => {
    setOpenLogs((value) => !value);
  };

  const onOpenPermissions = () => {
    setOpenPermissions((value) => !value);
  };

  const onOpenSchedules = () => {
    setOpenSchedules((value) => !value);
  };

  const onCloseTables = () => {
    setOpenTables((value) => !value);
  };

  const onCloseLogs = () => {
    setOpenLogs((value) => !value);
  };

  const onClosePermissions = () => {
    setOpenPermissions((value) => !value);
  };

  const onCloseSchedules = () => {
    setOpenSchedules((value) => !value);
  };

  return (
    <Space direction="vertical">
      <Space direction="vertical">
        <Button onClick={onOpenLogs}>Логи</Button>
        <Button onClick={onOpenTables}>Таблицы</Button>
        <Button onClick={onOpenSchedules}>Расписание</Button>
        <Button onClick={onOpenPermissions}>Права</Button>
      </Space>

      <Logs
        open={openLogs}
        logs={logs}
        setLogs={setLogs}
        onClose={onCloseLogs}
      />

      <Tables
        open={openTables}
        tables={tables}
        setTables={setTables}
        onClose={onCloseTables}
      />

      <Schedules
        open={openSchedules}
        schedules={schedules}
        setSchedule={setSchedules}
        onClose={onCloseSchedules}
      />

      <Permissions
        open={openPermissions}
        permissions={permissions}
        setPermissions={setPermissions}
        onClose={onClosePermissions}
      />
    </Space>
  );
};

export default AdminPanel;
