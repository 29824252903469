import PropTypes from 'prop-types';
import getListOfTimes from './cellsWithTimeUtils/getListOfTimes';
import { ErrorBoundary } from '../../ErrorBoundary';
import CellTime from './CellTime';
import moment from 'moment';
import {
  filtersTasksByTimePeriodCallLeadGroup,
  mergedClassicAndGroupPersonallyCommonTask,
} from '../month/tasks/tasksUtils/filtersTasksByTimePeriod';
import { useContext } from 'react';
import { userInformations } from '../../../contex';

const CellsWithTime = (props) => {
  const { userInfo } = useContext(userInformations);

  const getStart = (time) => {
    return moment(time, 'HH:mm').format('HH:mm');
  };
  const getEnd = (time) => {
    return moment(time, 'HH:mm').set('minute', 59).format('HH:mm');
  };

  const getStrPeriod = (time) => {
    return `${getStart(time)} - ${getEnd(time)}`;
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        {getListOfTimes().map((time, index) => (
          <ErrorBoundary key={index}>
            <CellTime
              day={props.day}
              time={time}
              index={index}
              tasksCell={mergedClassicAndGroupPersonallyCommonTask(
                props.tasks,
                getStart(time),
                getEnd(time),
                props.selectedStaff,
                userInfo.current?.id,
              )}
              tasksCellCallLead={filtersTasksByTimePeriodCallLeadGroup(
                props.tasks,
                getStart(time),
                getEnd(time),
              )}
              timePeriodTitle={getStrPeriod(time)}
              displayTimeInCell={props.displayTimeInCell}
              useForDayViewCalendar={props.useForDayViewCalendar}
              goToCompetitionDate={(day) => {
                props.goToCompetitionDate(day);
              }}
              handleEditTask={(task) => props.handleEditTask(task)}
              handleCreateTask={(day, time) =>
                props.handleCreateTask(props.day, time)
              }
            />
          </ErrorBoundary>
        ))}
      </div>
    </>
  );
};

CellsWithTime.propTypes = {
  day: PropTypes.any,
  tasks: PropTypes.any,
  displayTimeInCell: PropTypes.bool,
  useForDayViewCalendar: PropTypes.bool,
  selectedStaff: PropTypes.number,
  handleEditTask: PropTypes.func,
  handleCreateTask: PropTypes.func,
  goToCompetitionDate: PropTypes.func,
};

export default CellsWithTime;
