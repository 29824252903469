import { Button, Col, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

const ChangePasswordModal = ({ visible, closeModal, changePassword }) => {
  const [form] = Form.useForm();
  const [oldPassword, SetOldPassword] = useState('');
  const [newPassword, SetNewPassword] = useState('');

  useEffect(() => {
    if (!visible) {
      resetFields();
    }
  }, [visible]);

  const validatePassword = (valueInput) => {
    let valueLength = valueInput.target.value.length;
    if (valueLength >= 8) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    } else {
      return {
        validateStatus: 'error',
        errorMsg:
          valueLength < 8
            ? 'Пароль должен содержать не менее 8-ми символов'
            : '',
      };
    }
  };
  const onOldPasswordChange = (value) => {
    SetOldPassword({
      ...validatePassword(value),
      value,
    });
  };
  const onNewPasswordChange = (value) => {
    SetNewPassword({
      ...validatePassword(value),
      value,
    });
  };

  const resetFields = () => {
    form.resetFields();
    SetOldPassword('');
    SetNewPassword('');
  };

  function handleCloseModal() {
    resetFields();
    closeModal(false);
  }

  return (
    <Modal
      width={400}
      centered
      visible={visible}
      title="Изменение пароля"
      onCancel={() => {
        handleCloseModal();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            handleCloseModal();
          }}
        >
          Закрыть
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form.validateFields().then(async (values) => {
              changePassword(values);
            });
          }}
        >
          Сохранить
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Col gutter={5}>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              name={'prev'}
              label="Текущий пароль"
              rules={[{ required: true, message: 'Обязательное поле' }]}
            >
              <Input
                type={'password'}
                placeholder="Текущий пароль"
                value={oldPassword.value}
                onChange={onOldPasswordChange}
              />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              name={'new'}
              label="Новый пароль"
              rules={[{ required: true, min: 8, message: 'Обязательное поле' }]}
              validateStatus={newPassword.validateStatus}
              help={newPassword.errorMsg}
            >
              <Input
                type={'password'}
                placeholder="Новый пароль"
                value={newPassword.value}
                onChange={onNewPasswordChange}
              />
            </Form.Item>
          </Col>
        </Col>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
