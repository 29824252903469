import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import BranchesToolbar from '../components/branches/BranchesToolbar';
import { userInformations } from '../contex';
import { useLocation } from 'react-router-dom';
import { convertObjFilterToUrl } from '../utils/getFilters';
import { restApiHost } from '../utils/appVariables';
import { call } from '../apiUtils/call';
import { message } from 'antd';
import BranchesTable from '../components/branches/BranchesTable';
import { ErrorBoundary } from '../components/ErrorBoundary';
import formationDocumentTitle from '../utils/formationDocumentTitle';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

const Branches = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const { userInfo } = useContext(userInformations);
  const [filters, setFilters] = useState(null);
  const [page, setPage] = useState(1);
  const [visibleFilters, setVisibleFilters] = useState(false);
  const [visibleTableSetting, setVisibleTableSetting] = useState(false);
  const locationUrl = useLocation();
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );
  const isMobile = useCheckMobileScreen();
  const scrollingLoadedListRef = useRef(null);

  useEffect(async () => {
    await getBranches();
  }, []);

  useEffect(() => {
    if (visibleFilters) {
      setVisibleFilters(false);
    }
  }, [locationUrl]);

  const handleUpdate = async (page = 1, params, me, concatData = false) => {
    setPage(page);
    setFilters(params);
    await getBranches(page, params, me, concatData);
  };

  const getBranches = async (page = 1, params, me, concatData) => {
    setLoading(true);

    let urlParams = ``;

    if (params) {
      urlParams = convertObjFilterToUrl(params, null);
    }
    try {
      const url = urlParams
        ? `${restApiHost}/accounts/branches/?page=${page}${urlParams}`
        : `${restApiHost}/accounts/branches/?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
        true,
      );

      if (!json) return;

      concatData
        ? setData((prevState) => prevState.concat(json?.data))
        : setData(json?.data ? json.data : []);

      setTotal(json.count);
    } catch (e) {
      console.log(e);
      message.error('Ошибка получения филиалов');
    } finally {
      setLoading(false);
    }
  };

  const handleScrollToTopMobileList = useCallback(() => {
    if (scrollingLoadedListRef.current?.scrollTop) {
      scrollingLoadedListRef.current.scrollTop = 0;
    }
  }, [scrollingLoadedListRef]);

  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle('Филиалы | Квик Транс', notificationCount)}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ErrorBoundary>
            <BranchesToolbar
              handleUpdate={async (page, filters) => {
                isMobile && handleScrollToTopMobileList();
                await handleUpdate(isMobile ? 1 : page, filters);
              }}
              tableFilters={filters}
              pageProps={page}
              loading={loading}
              totalBranches={total}
              handleScrollToTopMobileList={handleScrollToTopMobileList}
              changeVisibleFilters={async () => {
                setVisibleFilters(!visibleFilters);
                if (visibleFilters) {
                  await handleUpdate(1, null, false, false);
                }
              }}
              changeVisibleTableSetting={() =>
                setVisibleTableSetting(!visibleTableSetting)
              }
            />
          </ErrorBoundary>
          <Box sx={{ pt: 5 }}>
            <ErrorBoundary>
              <BranchesTable
                branchesList={data}
                tableFilters={filters}
                loading={loading}
                total={total}
                visibleFilters={visibleFilters}
                setVisibleFilters={(visible) => {
                  setVisibleFilters(visible);
                }}
                page={page}
                scrollingLoadedListRef={scrollingLoadedListRef}
                handleScrollToTopMobileList={handleScrollToTopMobileList}
                locationUrl={locationUrl}
                visibleTableSetting={visibleTableSetting}
                onCloseDrawerTableSetting={() => setVisibleTableSetting(false)}
                handleUpdate={handleUpdate}
                setLoading={(load) => setLoading(load)}
              />
            </ErrorBoundary>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Branches;
