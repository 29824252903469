import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Card, Col, Divider, Dropdown, Row, Space } from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';

const TaskListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>{props.task?.task_number || '-'}</Col>
            <Col className={'secondText'}>
              {props.task?.status ? props.getStatusStr(props.task.status) : '-'}
            </Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.task)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.task]);

  return (
    <>
      <>
        <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
          {cardTitle()}
          <Divider style={{ margin: '10px 0' }} />

          <Row gutter={[0, 10]}>
            <Row style={{ width: '100%' }} gutter={[0, 10]}>
              <Col xs={24}>
                <div className={'secondText'}>{'Клиент'}</div>
                <div className={'valueText'}>
                  {props.task?.customer?.legal_name ?? '-'}
                </div>
              </Col>
            </Row>

            <Row gutter={20} justify={'start'}>
              <Col xs={12}>
                <div className={'secondText'}>{'Тип заявки'}</div>
                <div className={'valueText'}>
                  {props.task?.template?.name || '-'}
                </div>
              </Col>
              <Col xs={12}>
                <div className={'secondText'}>{'Ответственный'}</div>
                <div className={'valueText'}>
                  {props.task?.otv_manager || '-'}
                </div>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={8}>
                <div className={'secondText'}>{'Сумма клиенту'}</div>
                <div className={'valueText'}>{props.task?.sum_str || '0'}</div>
              </Col>
              <Col xs={8}>
                <div className={'secondText'}>{'Предв. расходы'}</div>
                <div className={'valueText'}>
                  {props.task?.upfronts_sum || '0'}
                </div>
              </Col>
              <Col xs={8}>
                <div style={{ minHeight: '44px' }} className={'secondText'}>
                  {'Дельта'}
                </div>
                <div className={'valueText'}>
                  {props.task?.delta_str || '0'}
                </div>
              </Col>
            </Row>
          </Row>
        </Card>
      </>
    </>
  );
};

TaskListItem.propTypes = {
  task: PropTypes.object,
  menuActions: PropTypes.func,
  getStatusStr: PropTypes.func.isRequired,
};

export default TaskListItem;
