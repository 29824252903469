import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Form, message, Modal, Spin, Tabs, Tag } from 'antd';
import { restApiHost } from '../../utils/appVariables';
import PreAddServicesTable from './PreAddServicesTable';
import TotalCostApplication from './TotalCostApplication';
import WayPointsTableUpdated from '../applications/WayPointsTableUpdated';
import Transportation from './Transportation/Transportation';
import { priceFormatter } from '../../utils/priceFormatter';
import getLocations from '../../utils/getLocations';
import EmailListItem from '../email/new/EmailListItem';
import EmailBody from '../email/new/EmailBody';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import { ErrorBoundary } from '../ErrorBoundary';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import useAsyncEffect from 'use-async-effect';
import BasicInformationPreApplicationModalForm from './preApplicationModal/BasicInformationPreApplicationModalForm';
import {
  getPreTaskCosts,
  getPreTaskManagers,
  getPreTaskSummaries,
  getPreTaskTransportCargo,
} from '../../logic/preTasks/PreTaskApiFunctions';
import { getCustomerManagers } from '../../logic/customer/CustomerApiFunctions';
import getTransportList from '../../logic/refbook/transportUnits/TransportUnitsApiFunction';

const { TabPane } = Tabs;

const PreApplicationModal = ({
  updateData,
  visible,
  handleOk,
  handleCancel,
  loading,
  data,
  lead,
  //handleUpdate,
  customer,
  isCopy,
  fromPreApplicationPage,
  savingSum,
  setSavingSum,
  setVisitSums,
  disableBtnAtSaveSums,
  handleOpenSendKpModal,
  renderInOutPagePreTask,
  saveSumsWhenSwitching,
  endSavingSumsWhenSwitching,
  changeSumsWereEdited,
  sumsWereEdited,
  savingSumWereEditing,
}) => {
  const [mainForm] = Form.useForm();
  const [createdTask, setCreatedTask] = useState(null);
  const [currentTab, setCurrentTab] = useState(1);
  const [cargos, setCargos] = useState([]);
  const [waypointsOptions, setWaypointsOptions] = useState([]);
  const [transportList, setTransportList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [refreshInfo, setRefreshInfo] = useState(false);
  const [dataUpfrontCosts, setDataUpfrontCosts] = useState([]);
  const [upfrontSum, setUpfrontSum] = useState(0);
  const [transportationData, setTransportationData] = useState(null);
  const [cargo, setCargo] = useState(null);

  const [customers, setCustomers] = useState([]);

  const [locations, setLocations] = useState([]);

  const [leads, setLeads] = useState([]);
  const [leadInPreTask, setLeadInPreTask] = useState(null);
  const [disableClientSelect, setDisableClientSelect] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [selectedEmail, setSelectedEmail] = useState(null);

  const [totalSumsUpfront, setTotalSumsUpfront] = useState({
    totalSumRub: 0,
    totalSumDol: 0,
    totalSumEur: 0,
    totalSumCny: 0,
  });
  const [totalSumsClient, setTotalSumsClient] = useState({
    totalSumRub: 0,
    totalSumDol: 0,
    totalSumEur: 0,
    totalSumCny: 0,
  });
  const [totalDelta, setTotalDelta] = useState({
    totalSumRub: 0,
    totalSumDol: 0,
    totalSumEur: 0,
    totalSumCny: 0,
  });
  const [sumVatRate, setSumVatRate] = useState(20);
  const [dataSums, setDataSums] = useState(null);
  const [visitSumsClient, setVisitSumsClient] = useState(false);
  const [loadingForSpinner, setLoadingForSpinner] = useState(false);
  let identifiedFieldClientType = useRef('');
  const { userInfo } = useContext(userInformations);
  const [leadsAndCustomers, setLeadsAndCustomers] = useState([]);
  const [vdtPreTask, setVdtPreTask] = useState(null);
  const [otvPreTask, setOtvPreTask] = useState(null);
  const [customerManagersOtvForSelect, setCustomerManagersOtvForSelect] =
    useState([]);
  const demoAccessIsUsed = useTypedSelector(
    (state) => state.demoAccess.demoAccessIsUsed,
  );

  useAsyncEffect(async () => {
    if (visible) {
      await getWaypoint();
      await handleGetTransportList(userInfo);
    }
  }, [visible]);

  useAsyncEffect(async () => {
    if (!visible) return;
    if (customer) {
      await getCustomers();
    } else if (lead) {
      await getLeads();
    }
  }, [customer, lead, visible]);

  useEffect(async () => {
    if (customer) {
      setSelectedCustomer(customer?.id);
      if (!fromPreApplicationPage) {
        setDisableClientSelect(true);
      }
      const copyCustomersArr = customers;
      const includedInTheArray = copyCustomersArr.find(
        (item) => item.id === customer.id,
      );
      if (!includedInTheArray) {
        copyCustomersArr.push(customer);
      }
      setCustomers(copyCustomersArr);
      mainForm.setFieldsValue({ lead: customer?.id });
      if (customer?.contact_persons?.length > 0) {
        mainForm.setFieldsValue({
          contact:
            customer?.contact_persons?.[0]?.full_name ||
            customer?.contact_persons?.[0]?.email,
        });
      }

      const customerManagersList = await handleGetCustomerGroupsManagers(
        customer?.id,
      );
      if (customerManagersList) {
        const customerManagersOtv =
          getCustomerManagerGroupOtv(customerManagersList);
        setCustomerManagersOtvForSelect(customerManagersOtv);
      }
    }
  }, [customer]);

  useEffect(() => {
    if (currentTab.toString() === '4') {
      setVisitSums();
      setVisitSumsClient(true);
    } else {
      if (sumsWereEdited) {
        saveSumsWhenSwitching();
      }
    }
  }, [currentTab]);

  useEffect(() => {
    const delta = calculationOfTotalsSumsDelta();
    setTotalDelta(delta);
  }, [totalSumsClient]);

  useEffect(() => {
    const delta = calculationOfTotalsSumsDelta();
    setTotalDelta(delta);
  }, [totalSumsUpfront]);

  useEffect(() => {
    if (!visible) {
      mainForm.resetFields();
      setCurrentTab(1);
      setCreatedTask(null);
      setTransportationData(null);
      setUpfrontSum(0);
      setCargo(null);
      //handleCancel()
      setDataSource([]);
      setDisableClientSelect(false);
      setLoadingForSpinner(false);
      identifiedFieldClientType.current = '';
      /*
            setSelectedCustomer(null)*/
      setSelectedCustomer(null);
      setLeadInPreTask(null);
      if (sumsWereEdited) {
        changeSumsWereEdited(false);
      }
      setTotalSumsClient({
        totalSumRub: 0,
        totalSumDol: 0,
        totalSumEur: 0,
        totalSumCny: 0,
      });
      setTotalDelta({
        totalSumRub: 0,
        totalSumDol: 0,
        totalSumEur: 0,
        totalSumCny: 0,
      });
      setTotalSumsUpfront({
        totalSumRub: 0,
        totalSumDol: 0,
        totalSumEur: 0,
        totalSumCny: 0,
      });
      setVdtPreTask(null);
      setOtvPreTask(null);
      setCustomerManagersOtvForSelect([]);
    }
  }, [visible]);

  useEffect(async () => {
    if (data) {
      setLoadingForSpinner(true);
      setCreatedTask({ id: data.id });

      await handleGetUpfrontConstTask(data?.id);

      setUpfrontSum(data.upfront_costs_sum);
      setSumVatRate(data?.vat_rate);
      if (data?.customer && !customer) {
        //const foundCustomers = await getCustomersById(data?.customer?.id);
        setSelectedCustomer(data?.customer?.id);
        if (!fromPreApplicationPage) {
          setDisableClientSelect(true);
        }
        const copyCustomersArr = customers;
        const includedInTheArray = copyCustomersArr.find(
          (item) => item?.id === data?.customer?.id,
        );
        if (!includedInTheArray) {
          copyCustomersArr.push(customer);
        }
        setCustomers(copyCustomersArr);

        mainForm.setFieldsValue({ lead: data?.customer?.id });
        if (data?.customer?.contact_persons?.length > 0) {
          mainForm.setFieldsValue({
            contact:
              data?.customer?.contact_persons?.[0]?.full_name ||
              data?.customer?.contact_persons?.[0]?.email,
          });
        }
        const customerManagersList = await handleGetCustomerGroupsManagers(
          customer?.id,
        );
        if (customerManagersList) {
          const customerManagersOtv =
            getCustomerManagerGroupOtv(customerManagersList);
          setCustomerManagersOtvForSelect(customerManagersOtv);
        }
      }
      setCreatedTask(data);

      /*if (data.manager) {
                searchStaff(data.manager.full_name)
            }*/
      /* if (data?.our_customer?.legal_name) {
                getOurCustomers(data?.our_customer?.legal_name)
            }*/

      if (data.cargo) {
        searchCargo(data?.cargo?.code || data?.cargo?.name);
      }

      if (data?.lead && !lead) {
        if (!fromPreApplicationPage) {
          setDisableClientSelect(true);
        }
        setLeadInPreTask(data?.lead);
        //await getLeedById(data?.lead?.id)
        const copyLeadsArr = leads;
        const includedInTheArray = copyLeadsArr.find(
          (item) => item?.id === data?.lead?.id,
        );
        if (!includedInTheArray) {
          copyLeadsArr.push(data?.lead);
        }

        setLeads(copyLeadsArr);
      }

      if (data.template?.short === 'ИП' || data.template?.short === 'ОП-Э') {
        const sums = await callGetSummaries(data?.id);
        setDataSums(sums);
        calculationOfTotalsSumsClient(sums);
      }

      if (
        data?.price &&
        data?.template?.short !== 'ИП' &&
        data.template?.short !== 'ОП-Э'
      ) {
        const totalSum = {
          price: data?.price ? data?.price : 0,
          currency: 'rub',
        };
        setDataSums([totalSum]);
        calculationOfTotalsSumsClient([totalSum]);
      }

      const listManagers = await getManagersPreTask(data?.id);
      if (listManagers?.length > 0) {
        const vdtManager = listManagers.find((item) => item?.group === 'vdt');
        if (vdtManager) {
          setVdtPreTask(vdtManager);
          // await callSearchStaff(vdtManager?.manager?.full_name)
        }
        const otvManager = listManagers.find((item) => item?.group === 'otv');
        if (otvManager) {
          setOtvPreTask(otvManager);
          // await callSearchStaff(otvManager?.manager?.full_name)
        }
      }
      mainForm.setFieldsValue({
        status: data?.send_comm_prop ? 'Отправлено КП' : 'Новая',
        cargo: data.cargo?.id,
        pre_task_number: data.pre_task_number,
        lead: data.lead?.id ? data.lead?.id : data?.customer?.id,
        our_customer: data?.our_customer?.id || null,
        date_create: data.date_create
          ? new Intl.DateTimeFormat('ru-RU').format(new Date(data.date_create))
          : new Intl.DateTimeFormat('ru-RU').format(Date.now()),
        contact: data?.lead_contact || data?.customer_contact || 'Не известен',
        // lead: data ? data.company_name : "",
        comment: data ? data.comment : '',
        manager: data?.manager?.id || null,
        template: data?.template?.id || null,
        upfront_costs_sum: data?.upfront_costs_sum
          ? priceFormatter(data.upfront_costs_sum)
          : 0,
        delta: data?.delta ? priceFormatter(data.delta) : 0,
        price: data?.price ? priceFormatter(data.price) : 0,
      });

      /* if (data?.transport_cargo_task && data.transport_cargo_task?.length > 0) {
                setTransportationData(data.transport_cargo_task)
            }*/

      // data && data.cargo && searchCargo(data.cargo.name)
      data && data.cargo && setCargo(data.cargo);

      if (data.waypoint && data.waypoint?.length > 0) {
        setDataSource([...data.waypoint]);
      }
      setLoadingForSpinner(false);
    }
  }, [data]);

  useEffect(() => {
    if (data?.customer && otvPreTask) {
      const copyCustomerManagersOtvForSelect = [
        ...customerManagersOtvForSelect,
      ];
      const managerAlreadyAdded = copyCustomerManagersOtvForSelect.find(
        (item) => item.id === otvPreTask?.manager?.id,
      );
      if (!managerAlreadyAdded) {
        copyCustomerManagersOtvForSelect.push(otvPreTask?.manager);
        setCustomerManagersOtvForSelect(copyCustomerManagersOtvForSelect);
      }
    }
  }, [otvPreTask]);

  useEffect(async () => {
    if (lead) {
      if (!fromPreApplicationPage) {
        setDisableClientSelect(true);
      }
      setLeadInPreTask(lead);
      /*if (lead.manager) {
                searchStaff(lead.manager.full_name)
            }*/

      /* if (lead?.id) {
                await getLeedById(lead?.id)
            }*/
      const copyLeadsArr = [...leads];
      const includedInTheArray = copyLeadsArr.find(
        (item) => item?.id === lead?.id,
      );
      if (!includedInTheArray) {
        copyLeadsArr.push(lead);
      }
      setLeads(copyLeadsArr);

      mainForm.setFieldsValue({
        date_create: new Intl.DateTimeFormat('ru-RU').format(Date.now()),
        contact: lead.contact_person || 'Не известно',
        lead: lead?.id,
        our_customer: null,
        manager: lead.manager?.id || null,
      });
    }
  }, [lead]);

  const getCustomers = async () => {
    try {
      const url = `${restApiHost}/customers/list/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setCustomers(json.data);
      }
    } catch (e) {
      console.log('get customers err', e);
    }
  };

  const getCountTransportOperation = useCallback((operation) => {
    if (operation) {
      return operation?.transport?.transport?.count
        ? operation.transport.transport.count
        : 1;
    }
    return 1;
  }, []);

  const calculationOfTotalsSumsUpfront = useCallback(
    (data) => {
      if (data) {
        let sumDol = 0;
        let sumRub = 0;
        let sumEur = 0;
        let sumCny = 0;
        for (const operation of data) {
          switch (operation?.currency) {
            case 'rub':
              sumRub +=
                operation?.count_services *
                parseFloat(operation?.price_currency) *
                getCountTransportOperation(operation);
              break;
            case 'dol':
              sumDol +=
                operation?.count_services *
                parseFloat(operation?.price_currency) *
                getCountTransportOperation(operation);
              break;
            case 'eur':
              sumEur +=
                operation?.count_services *
                parseFloat(operation?.price_currency) *
                getCountTransportOperation(operation);
              break;
            case 'cny':
              sumCny +=
                operation?.count_services *
                parseFloat(operation?.price_currency) *
                getCountTransportOperation(operation);
              break;
          }
        }
        const sums = {
          totalSumRub: sumRub,
          totalSumDol: sumDol,
          totalSumEur: sumEur,
          totalSumCny: sumCny,
        };
        setTotalSumsUpfront(sums);
      }
    },
    [setTotalSumsUpfront],
  );

  const calculationOfTotalsSumsClient = useCallback(
    (sumsClient) => {
      if (sumsClient) {
        let sumDol = 0;
        let sumRub = 0;
        let sumEur = 0;
        let sumCny = 0;

        for (const sum of sumsClient) {
          switch (sum?.currency) {
            case 'rub':
              sumRub +=
                sum?.price !== '' && sum?.price !== null
                  ? parseFloat(sum?.price)
                  : 0;
              break;
            case 'dol':
              sumDol +=
                sum?.price !== '' && sum?.price !== null
                  ? parseFloat(sum?.price)
                  : 0;
              break;
            case 'eur':
              sumEur +=
                sum?.price !== '' && sum?.price !== null
                  ? parseFloat(sum?.price)
                  : 0;
              break;
            case 'cny':
              sumCny +=
                sum?.price !== '' && sum?.price !== null
                  ? parseFloat(sum?.price)
                  : 0;
              break;
          }
        }
        const sums = {
          totalSumRub: sumRub,
          totalSumDol: sumDol,
          totalSumEur: sumEur,
          totalSumCny: sumCny,
        };
        setTotalSumsClient(sums);
      }
    },
    [setTotalSumsClient],
  );

  const calculationOfTotalsSumsDelta = useCallback(() => {
    const sumRub = totalSumsClient.totalSumRub - totalSumsUpfront.totalSumRub;
    const sumDol = totalSumsClient.totalSumDol - totalSumsUpfront.totalSumDol;
    const sumEur = totalSumsClient.totalSumEur - totalSumsUpfront.totalSumEur;
    const sumCny = totalSumsClient.totalSumCny - totalSumsUpfront.totalSumCny;

    return {
      totalSumRub: sumRub,
      totalSumDol: sumDol,
      totalSumEur: sumEur,
      totalSumCny: sumCny,
    };
  }, [totalSumsClient, totalSumsUpfront]);

  const assignThatSumsEdited = () => {
    changeSumsWereEdited(true);
  };

  const getCustomerManagerGroupOtv = (customerList_managers) => {
    let customerManagersOtv = [];
    if (customerList_managers && customerList_managers?.length > 0) {
      const tempCustomerManagersOtv = customerList_managers.filter(
        (managerGroup) => managerGroup?.group === 'otv',
      );
      if (tempCustomerManagersOtv && tempCustomerManagersOtv?.length > 0) {
        for (const groupManager of tempCustomerManagersOtv) {
          if (groupManager?.manager) {
            customerManagersOtv.push(groupManager?.manager);
          }
        }
      }
    }
    return customerManagersOtv;
  };

  const handleChangeDataSource = (data) => {
    setDataSource(data);
  };

  const handleGetTransportList = useCallback(
    async (keyword) => {
      const res = await getTransportList(userInfo, keyword);
      if (res) {
        setTransportList(res);
      }
    },
    [userInfo, setTransportList],
  );

  const handleSave = async () => {
    if (currentTab.toString() === '1' && data) {
      setCreatedTask(data);
      setCurrentTab(2);
    } else if (currentTab.toString() === '2' && data) {
      setCurrentTab(3);
    } else if (currentTab.toString() === '3' && data) {
      setCurrentTab(4);
    } else if (currentTab.toString() === '4' && data) {
      handleCancel();
    } else if (currentTab.toString() === '5' && data) {
      setCurrentTab(6);
    } else if (currentTab.toString() === '6' && data) {
      setCurrentTab(7);
    } else if (currentTab.toString() === '7' && data) {
      setCurrentTab(8);
    } else {
      handleCancel();
    }
  };

  const getLeads = async () => {
    try {
      const url = `${restApiHost}/leads/list/?page=1`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setLeads(json.data);
      }
    } catch (e) {
      console.log('get leads err', e);
    }
  };

  /*   const callSearchStaff = useCallback(async (keyword) => {
        const res = await searchStaffList(keyword, userInfo)
        if (res?.data) {
            return res.data
        }
    },[searchStaffList,userInfo])*/

  const getWaypoint = async () => {
    try {
      const url = `${restApiHost}/refbook/route_waypoint/?active=true`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setWaypointsOptions(json.data);
      }
    } catch (e) {
      setWaypointsOptions([]);
      console.log('get waypoints error', e);
      message.error('Ошибка запроса для получения списка пунктов!');
    }
  };

  const searchCargo = async (keyword) => {
    try {
      const url = `${restApiHost}/refbook/cargo/?search=${keyword}&active=true`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setCargos(json.data);
      }
    } catch (e) {
      console.log('search cargo err', e);
      message.error('Ошибка поиска грузов');
      setCargos([]);
    }
  };

  const searchLeeds = async (keyword) => {
    try {
      const url = `${restApiHost}/leads/list/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setLeads(json?.data);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса! Не удалось найти лида');
    }
  };

  const searchLeedsOrCustomers = async (keyword) => {
    try {
      const url = `${restApiHost}/customers/search_lead_or_customer/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ keyword }),
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setLeadsAndCustomers(json?.data);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса! Не удалось найти лида');
    }
  };

  const sortDataTable = (data) => {
    const copyData = [...data];
    return copyData.sort((a, b) => (a['number'] > b['number'] ? 1 : -1));
  };

  const taskEdit = async (body, id) => {
    setLoadingForSpinner(true);
    try {
      const url = `${restApiHost}/leads/pre_tasks/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      message.success('Информация по заявке успешно обновлена');

      await refreshTaskInfo(id);
    } catch (e) {
      console.log('edit task err', e);
      message.error('Ошибка запроса на редактирование пред. заявки');
    } finally {
      setLoadingForSpinner(false);
    }
  };

  const refreshTaskInfo = async (id) => {
    setLoadingForSpinner(true);
    if (!savingSum) {
      setRefreshInfo(true);
      try {
        const url = customer
          ? `${restApiHost}/customers/pre_tasks/${id}/`
          : `${restApiHost}/leads/pre_tasks/${id}/`;
        const json = await call(
          url,
          {
            method: 'GET',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          },
          userInfo.current.haveViewBranch,
        );

        if (!json?.data) return;

        updateData && updateData(json.data);
        setRefreshInfo(false);
      } catch (e) {
        console.log('fetch profile info error', e);
        message.error(
          'Ошибка запроса на обновление информации по пред. заявке',
        );
      } finally {
        setLoadingForSpinner(false);
      }
    }
  };

  const callGetSummaries = useCallback(
    async (taskId) => {
      //setLoading(true)
      const res = await getPreTaskSummaries(taskId, userInfo);
      if (res?.data) {
        return res.data;
      }
      //setLoading(false)
    },
    [getPreTaskSummaries],
  );

  const callGetCosts = useCallback(
    async (taskId) => {
      const res = await getPreTaskCosts(taskId, userInfo);
      if (res?.data) {
        return res.data;
      }
    },
    [getPreTaskTransportCargo],
  );

  const handleGetUpfrontConstTask = useCallback(
    async (taskId) => {
      const upfrontConst = await callGetCosts(taskId);
      if (upfrontConst) {
        setDataUpfrontCosts(upfrontConst);
        calculationOfTotalsSumsUpfront(upfrontConst);
      }
    },
    [callGetCosts, setDataUpfrontCosts, calculationOfTotalsSumsUpfront],
  );

  const getLocationsOptions = async () => {
    const locations = await getLocations();
    setLocations(locations);
  };

  const searchCustomers = async (keyword) => {
    try {
      const url = `${restApiHost}/customers/list/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setCustomers(json?.data);
        return json?.data;
      }
    } catch (e) {
      console.log('search clients err', e);
      message.error('Ошибка запроса для поиска клиента');
    }
  };

  const handleSearchListCustomers = useCallback(
    async (keyword) => {
      if (leadInPreTask) {
        keyword?.length > 0 ? await searchLeeds(keyword) : await getLeads();
      } else if (customer) {
        keyword?.length > 0
          ? await searchCustomers(keyword)
          : await getCustomers();
      } else {
        if (keyword?.length > 0) {
          await searchLeedsOrCustomers(keyword);
        }
      }
    },
    [searchLeeds, searchCustomers, searchLeedsOrCustomers],
  );

  const replacer = (text) => {
    return text.replace('<', '').replace('>', '');
  };

  const formatBodyMessage = (body) => {
    if (body?.length > 0) {
      const regexp = /<([\w.!#$%&’*+/=?^_`{|}~-]+@[\w-]+(?:\.[\w-]+)+)>/g;
      const regexp3 =
        /<mailto:([\w.!#$%&’*+/=?^_`{|}~-]+@[\w-]+(?:\.[\w-]+)+)>/g;
      return body.replace(regexp, replacer).replace(regexp3, replacer);
    } else {
      return '';
    }
  };

  const getMessage = async (email) => {
    try {
      const url =
        email?.msg_id || email?.message_id
          ? `${restApiHost}/mail/message/body/?msg_id=${
              email.msg_id || email.message_id
            }&folder=${email.folder}&from_email=${
              email.from_email
            }&date=${encodeURIComponent(email.date)}`
          : `${restApiHost}/mail/messages/${email.id}/`;
      const res = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current?.haveViewBranch,
      );

      if (res.status === 403) {
        message.warning(
          'У вас недостаточно прав для взаимодействия с сообщениями',
        );
        return;
      }
      const json = await res.json();

      if (json.status === 'error') {
        message.error(json.text);
      } else if (json.detail) {
        message.error(json.detail);
      } else {
        const body = {
          ...json,
          body: formatBodyMessage(json.body),
        };
        setSelectedEmail(body);
      }
    } catch (e) {
      console.log(e);
      message.error(
        'Ошибка запроса на получение детальной информации по сообщению',
      );
    }
  };

  const handleShowBodyEmail = async (email) => {
    await getMessage(email);
  };

  const handleUpdateSums = useCallback(
    async (task) => {
      const sums = await callGetSummaries(task);
      if (sums) {
        setDataSums(sums);
      }
    },
    [setDataSums, callGetSummaries],
  );

  const getManagersPreTask = useCallback(
    async (taskId) => {
      if (!taskId) return;
      const res = await getPreTaskManagers(taskId, userInfo);
      if (res?.data && typeof res.data !== 'string') {
        return res.data;
      }
    },
    [getPreTaskManagers, userInfo],
  );

  const handleGetCustomerGroupsManagers = useCallback(
    async (customerId) => {
      const res = await getCustomerManagers(customerId, userInfo);
      if (res?.data) {
        return res.data;
      }
    },
    [getCustomerManagers],
  );

  return (
    <Modal
      width={1200}
      visible={visible}
      zIndex={1000}
      destroyOnClose={true}
      shouldCloseOnOverlayClick={!disableBtnAtSaveSums}
      title={
        <>
          Предварительная заявка{' '}
          {data?.send_comm_prop ? (
            <Tag color={'green'}>Отправлено КП</Tag>
          ) : isCopy ? (
            <Tag color={'orange'}>Копия</Tag>
          ) : (
            <Tag color={'blue'}>Новая</Tag>
          )}
        </>
      }
      onOk={handleOk}
      onCancel={() => {
        if (!disableBtnAtSaveSums) {
          //handleUpdate && handleUpdate()
          handleCancel();
        }
      }}
      footer={[
        <>
          {currentTab !== 4 ? (
            <>
              <Button
                key="back"
                disabled={disableBtnAtSaveSums}
                onClick={() => {
                  //handleUpdate && handleUpdate();
                  handleCancel();
                }}
              >
                Закрыть
              </Button>{' '}
              <></>
              {!renderInOutPagePreTask &&
              createdTask &&
              (!data?.send_comm_prop || !createdTask?.send_comm_prop) ? (
                <Button
                  disabled={disableBtnAtSaveSums || demoAccessIsUsed}
                  onClick={() => {
                    handleCancel();
                    handleOpenSendKpModal(data || createdTask);
                  }}
                >
                  Отправить КП
                </Button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>,
        <>
          {createdTask && currentTab !== 5 && (
            <Button
              disabled={disableBtnAtSaveSums}
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => handleSave()}
            >
              {currentTab.toString() === '4' ? 'Завершить' : 'Далее'}
            </Button>
          )}
        </>,
      ]}
    >
      <Spin spinning={loadingForSpinner}>
        <ErrorBoundary>
          <BasicInformationPreApplicationModalForm
            mainForm={mainForm}
            data={data}
            customer={customer}
            createdTask={createdTask}
            selectedCustomer={selectedCustomer}
            leadInPreTask={leadInPreTask}
            leads={leads}
            totalSumsClient={totalSumsClient}
            totalSumsUpfront={totalSumsUpfront}
            totalDelta={totalDelta}
            vdtPreTask={vdtPreTask}
            otvPreTask={otvPreTask}
            customers={customers}
            customerManagersOtvForSelect={customerManagersOtvForSelect}
            leadsAndCustomers={leadsAndCustomers}
            disableClientSelect={disableClientSelect}
            setSelectedCustomer={(customer) => setSelectedCustomer(customer)}
            handleSearchListCustomers={(keyword) =>
              handleSearchListCustomers(keyword)
            }
            setLoading={(value) => setLoadingForSpinner(value)}
            taskEdit={async (body, taskId) => await taskEdit(body, taskId)}
            refreshTaskInfo={async (taskId) => await refreshTaskInfo(taskId)}
            setCreatedTask={(taskData) => setCreatedTask(taskData)}
            setCurrentTab={(tabNumber) => setCurrentTab(tabNumber)}
          />
        </ErrorBoundary>
        <Tabs
          destroyInactiveTabPane={false}
          activeKey={currentTab.toString()}
          onChange={(key) => setCurrentTab(key)}
        >
          <TabPane tab="Пункты" key="1" disabled={!createdTask}>
            <ErrorBoundary>
              <WayPointsTableUpdated
                taskId={data?.id || createdTask?.id}
                taskTemplete={data?.template || createdTask?.template}
                data={sortDataTable(dataSource)}
                setData={handleChangeDataSource}
                getWaypoint={getWaypoint}
                waypoints={waypointsOptions}
                locationsOptions={locations}
                getLocationsOptions={getLocationsOptions}
              />
            </ErrorBoundary>
          </TabPane>
          <TabPane tab={'Перевозки'} key="2" disabled={!createdTask}>
            <ErrorBoundary>
              <Transportation
                refreshData={async () =>
                  await refreshTaskInfo(data?.id || createdTask?.id)
                }
                waypoints={dataSource}
                transportListOptions={transportList}
                task={data?.id || createdTask?.id}
                cargos={cargos}
                createdCargo={cargo}
                onSearchTransportOptions={async (keyword) => {
                  await handleGetTransportList(keyword);
                }}
                taskEdit={taskEdit}
                totalTransportation={
                  transportationData?.length ? transportationData.length : 0
                }
              />
            </ErrorBoundary>
          </TabPane>
          <TabPane
            tab="Предварительные расходы"
            key="3"
            disabled={!createdTask}
          >
            <ErrorBoundary>
              <PreAddServicesTable
                customers={customers}
                transportList={transportationData}
                data={dataUpfrontCosts}
                upfrontSum={upfrontSum}
                currentTab={currentTab}
                refresh={async (task) => await handleGetUpfrontConstTask(task)}
                preTaskId={data?.id || createdTask?.id}
                preTask={data || createdTask}
              />
            </ErrorBoundary>
          </TabPane>
          <TabPane tab={'Суммы клиенту'} key={'4'} disabled={!createdTask}>
            <ErrorBoundary>
              <TotalCostApplication
                taskId={data?.id || createdTask?.id}
                taskTemplete={data?.template}
                refresh={async (task) => {
                  await handleUpdateSums(task);
                }}
                typeApplication={'pre'}
                waypointsData={dataSource}
                dataSums={dataSums}
                value={data?.price ? data.price : 0}
                valueVatRate={sumVatRate}
                loading={refreshInfo}
                savingSum={savingSum}
                currentTab={currentTab}
                sumsWereEdited={sumsWereEdited}
                setSavingSum={setSavingSum}
                visitSumsClient={visitSumsClient}
                savingSumWereEditing={savingSumWereEditing}
                saveSumsWhenSwitching={() => saveSumsWhenSwitching()}
                setLoadingModalSpiner={(isLoad) => setLoadingForSpinner(isLoad)}
                assignThatSumsEdited={() => assignThatSumsEdited()}
                endSavingSumsWhenSwitching={() => endSavingSumsWhenSwitching()}
                calculationSumsClientAtInputSum={(sumClient) =>
                  calculationOfTotalsSumsClient(sumClient)
                }
              />
            </ErrorBoundary>
          </TabPane>
          <TabPane
            tab="Переписка"
            key="5"
            disabled={!createdTask?.related_message}
          >
            {createdTask?.related_message ? (
              <>
                {!selectedEmail ? (
                  <EmailListItem
                    email={createdTask.related_message}
                    taskType={true}
                    showBodyEmail={handleShowBodyEmail}
                  />
                ) : (
                  <EmailBody
                    back={() => {
                      setSelectedEmail(null);
                    }}
                    loading={false}
                    taskType={true}
                    email={selectedEmail}
                  />
                )}
              </>
            ) : (
              'пусто'
            )}
          </TabPane>
        </Tabs>
      </Spin>
    </Modal>
  );
};

export default PreApplicationModal;
