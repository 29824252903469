import React, { useState } from 'react';
import { Button, Spin, message } from 'antd';
import { rubles } from '../../utils/rubles';
import '../../styles/bill_table.css';
import { restApiHost } from '../../utils/appVariables';
import logo from '../../images/logo_trans.png';
import podpis from '../../images/sign_trans_.png';
import pechat from '../../images/stamp_trans.png';
import html2pdf from 'html2pdf.js/src';

const BillTemplate = ({ loading, data, task }) => {
  const container = React.useRef(null);
  const [uploading, setUploading] = useState(false);

  const chainFileToBill = async (idBill, idFile) => {
    try {
      const url = `${restApiHost}/shipping/customer_invoice/${idBill}/`;
      const res = await fetch(url, {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ document: idFile }),
      });

      if (res.status == 403) {
        message.warning(
          'У вас недостаточно прав для совершения данного действия',
        );
        return;
      }
    } catch (e) {
      console.log('err', e);
    }
  };

  const uploadFile = async (file, name) => {
    try {
      const url = `${restApiHost}/shipping/attachments/`;
      const formData = new FormData();
      formData.append('file', file, name);
      formData.append('name', name);
      formData.append('task', task);
      formData.append('type_attachment', 'bill');

      // console.log(formData)

      const res = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
        body: formData,
      });
      const json = await res.json();

      if (res.status == 403) {
        message.warning(
          'У вас недостаточно прав для взаимодействия с файлами в заявке',
        );
        setUploading(false);
        return;
      }

      if (res.ok) {
        message.success('Файл успешно загружен');
        await chainFileToBill(data.id, json.id);
      } else {
        message.error('Ошибка загрузки файла');
      }
      setUploading(false);
    } catch (e) {
      setUploading(false);
      console.log('file upload err', e);
      message.error('Ошибка запроса на загрузку файла во вложения');
    }
  };

  const pdfDownload = (e) => {
    e.preventDefault();
    setUploading(true);
    const opt = {
      margin: [10, 25, 10, 15],
      filename: `${
        data?.number_invoice ||
        `${new Intl.DateTimeFormat('ru-RU').format(Date.now())}`
      }.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 1.2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf()
      .set({
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      })
      .set(opt)
      .from(container.current)
      .toPdf()
      .output('datauristring')
      .then(async (pdf) => {
        setUploading(true);
        const blob = await (await fetch(pdf)).blob();
        let filename = `${
          data?.number_invoice ||
          `${new Intl.DateTimeFormat('ru-RU').format(Date.now())}`
        }.pdf`;
        const filePDF = new File([blob], filename, { type: 'application/pdf' });
        uploadFile(filePDF, filename);
      })
      .save()
      .then(() => setUploading(false));
  };

  const changePriceText = (text) => {
    const arrPriceText = text.split(' ');
    const newArrPriceText = arrPriceText.map((item, idx) => {
      if (idx === 0) {
        return item.charAt(0).toUpperCase() + item.slice(1);
      }

      return item;
    });

    return newArrPriceText.join(' ');
  };

  const renderTransportUnit = (transport) => {
    let stringTransport = '';

    let transportArr = transport.map((tr_item) => {
      if (tr_item?.cargo_transport?.transport?.number_container) {
        return tr_item.cargo_transport.transport.number_container;
      }
    });

    if (transportArr.length > 0) {
      stringTransport = transportArr.join(', ');
    }

    return stringTransport;
  };

  const renderTransport = (transport) => {
    let stringTransport = '';

    let transportArr = transport.map((tr_item) => {
      if (tr_item?.cargo_transport?.transport?.transport_unit?.type_transport) {
        return tr_item.cargo_transport.transport.transport_unit.type_transport;
      }
    });

    if (transportArr.length > 0) {
      stringTransport = transportArr.join(', ');
    }

    return stringTransport;
  };

  return (
    <Spin spinning={loading || uploading}>
      <Button
        className="k-button"
        disabled={loading || uploading}
        onClick={pdfDownload}
      >
        Скачать счет
      </Button>
      <div
        style={{
          width: '210mm',
          marginLeft: 'auto',
          marginRight: 'auto',
          fontSize: '11pt',
        }}
      >
        <table
          ref={container}
          border={0}
          cellPadding={0}
          cellSpacing={0}
          id="sheet0"
          className="sheet0"
        >
          <colgroup>
            <col className="col0" />
            <col className="col1" />
            <col className="col2" />
            <col className="col3" />
            <col className="col4" />
            <col className="col5" />
            <col className="col6" />
            <col className="col7" />
            <col className="col8" />
            <col className="col9" />
            <col className="col10" />
            <col className="col11" />
            <col className="col12" />
            <col className="col13" />
            <col className="col14" />
            <col className="col15" />
            <col className="col16" />
            <col className="col17" />
            <col className="col18" />
            <col className="col19" />
            <col className="col20" />
            <col className="col21" />
            <col className="col22" />
            <col className="col23" />
            <col className="col24" />
            <col className="col25" />
            <col className="col26" />
            <col className="col27" />
          </colgroup>
          <tbody>
            <tr className="row0">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row1">
              <td
                className="column0 style5 null style5"
                colSpan={3}
                rowSpan={5}
              >
                <img src={logo} style={{ maxWidth: '100%', width: '122px' }} />
              </td>
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row2">
              <td className="column3 style1 null" />
              <td className="column4 style21 s style21" colSpan={22}>
                ООО «Квиктранс»
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row3">
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row4">
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style24 s style24" colSpan={19}>
                Адрес: 426032, Россия, Удмуртская Республика, город Ижевск,
                улица Карла Маркса, дом 1, литера Д, оф. 414
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row5">
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row6">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row7">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style22 s style22" colSpan={14}>
                Образец заполнения платежного поручения
              </td>
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row8">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row9">
              <td className="column0 style6 s style6" colSpan={6}>
                {data?.currency == 'rub' ? '6/7/5018089 0:00' : '1832125571'}
              </td>
              <td className="column6 style23 s style23" colSpan={5}>
                {data?.currency == 'rub' ? '6/2/505951 0:00' : '184101001'}
              </td>
              <td
                className="column11 style28 s style28"
                colSpan={3}
                rowSpan={3}
              >
                Сч.№
              </td>
              <td
                className="column14 style31 s style31"
                colSpan={13}
                rowSpan={3}
              >
                {data?.currency == 'rub'
                  ? '40702810714500025595'
                  : '40702840001500006381'}{' '}
              </td>
              <td className="column27 style1 null" />
            </tr>
            <tr className="row10">
              <td className="column0 style7 s style7" colSpan={11}>
                Получатель
              </td>
              <td className="column27 style1 null" />
            </tr>
            <tr className="row11">
              <td className="column0 style8 s style8" colSpan={11}>
                {data?.currency == 'rub'
                  ? 'ООО «Квиктранс»'
                  : 'OOO "KVIKTRANS"'}
              </td>
              <td className="column27 style1 null" />
            </tr>
            <tr className="row12">
              <td className="column0 style7 s style7" colSpan={11}>
                Банк получателя
              </td>
              <td className="column11 style29 s style29" colSpan={3}>
                БИК
              </td>
              <td className="column14 style32 s style32" colSpan={13}>
                {data?.currency == 'rub' ? '044525999' : ''}
              </td>
              <td className="column27 style1 null" />
            </tr>
            <tr className="row13">
              <td className="column0 style8 s style8" colSpan={11}>
                {data?.currency == 'rub'
                  ? 'ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"'
                  : 'BANK OTKRITIE FINANCIAL CORPORATION (PUBLIC JOINT-STOCK COMPANY)'}
              </td>
              <td className="column11 style29 s style29" colSpan={3}>
                Сч.№
              </td>
              <td className="column14 style32 s style32" colSpan={13}>
                {data?.currency == 'rub'
                  ? '30101810845250000999'
                  : 'JSNMRUMMXXX'}
              </td>
              <td className="column27 style1 null" />
            </tr>
            <tr className="row14">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row15">
              <td className="column0 style9 s style9" colSpan={27}>
                {data?.currency == 'rub' ? 'Счет' : 'INVOICE'} №{' '}
                {data?.number_invoice} от{' '}
                {data?.date_create.split('T')[0]
                  ? new Intl.DateTimeFormat('ru-RU').format(
                      new Date(data?.date_create.split('T')[0]),
                    )
                  : new Intl.DateTimeFormat('ru-RU').format(Date.now())}
              </td>
              <td className="column27 style1 null" />
            </tr>
            <tr className="row16">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row17">
              <td className="column0 style10 s style10" colSpan={2}>
                Плательщик:
              </td>
              <td className="column2 style19 s style19" colSpan={24}>
                {data?.customer?.legal_name}
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row18">
              <td className="column0 style11 s style11" colSpan={2}>
                Адрес:
              </td>
              <td className="column2 style19 s style19" colSpan={24}>
                {data?.customer?.address}
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row19">
              <td className="column0 style11 s style11" colSpan={2}>
                ИНН / КПП:
              </td>
              <td className="column2 style19 s style19" colSpan={24}>
                {data?.customer?.inn} / {data?.customer?.kpp}
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row20">
              <td className="column0 style11 s style11" colSpan={2}>
                Договор:
              </td>
              <td className="column2 style20 s style20" colSpan={24}>
                {data?.customer_contract?.code_contract}{' '}
                {data?.customer_contract?.base_company} от{' '}
                {data?.customer_contract?.date_create.split('T')[0]
                  ? new Intl.DateTimeFormat('ru-RU').format(
                      new Date(
                        data.customer_contract.date_create.split('T')[0],
                      ),
                    )
                  : new Intl.DateTimeFormat('ru-RU').format(Date.now())}
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row21">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row22">
              <td className="column0 style5 null style5" colSpan={27}>
                <img
                  src="data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAqkAAAAECAIAAAAiWTGJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAJUlEQVRYhe3YAQ0AAAgDIN8/9M3hhBik7QAAAAAAAOfF+QPAKwuPHQX9r5QDkAAAAABJRU5ErkJggg=="
                  style={{ maxWidth: '100%', width: '681px' }}
                />
              </td>
              <td className="column27 style1 null" />
            </tr>
            <tr className="row23">
              <td className="column0 style11 s style11" colSpan={2}>
                Вид тр.ед.:
              </td>
              <td className="column2 style19 s style19" colSpan={24}>
                {data?.cargo_transport?.length > 0
                  ? renderTransport(data.cargo_transport)
                  : null}
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row24">
              <td className="column0 style11 s style11" colSpan={2}>
                Тр. единицы:
              </td>
              <td className="column2 style19 s style19" colSpan={24}>
                {data?.cargo_transport?.length > 0
                  ? renderTransportUnit(data.cargo_transport)
                  : null}
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row25">
              <td className="column0 style11 s style11" colSpan={2}>
                Маршрут:
              </td>
              <td className="column2 style19 s style19" colSpan={24}>
                {data?.route}
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row26">
              <td className="column0 style11 s style11" colSpan={2}>
                Комментарий:
              </td>
              <td className="column2 style17 null style17" colSpan={24} />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row27">
              <td className="column0 style11 s style11" colSpan={26}>
                Прибытие на склад:{' '}
                {data?.date_scheduled
                  ? new Intl.DateTimeFormat('ru-RU', {
                      day: 'numeric',
                      month: 'numeric',
                      year: 'numeric',
                      minute: 'numeric',
                      hour: 'numeric',
                    }).format(new Date(data.date_scheduled))
                  : new Intl.DateTimeFormat('ru-RU', {
                      day: 'numeric',
                      month: 'numeric',
                      year: 'numeric',
                      minute: 'numeric',
                      hour: 'numeric',
                    }).format(Date.now())}
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row28">
              <td className="column0 style12 s style12" colSpan={17}>
                Груз
              </td>
              <td className="column17 style12 s style12" colSpan={3}>
                Кол-во
              </td>
              <td className="column20 style12 s style12" colSpan={5}>
                Упак.
              </td>
              <td className="column25 style12 s style12" colSpan={2}>
                Вес, кг
              </td>
              <td className="column27 style1 null" />
            </tr>
            {data?.cargo_transport?.length > 0 ? (
              <>
                {data.cargo_transport.map((cargo, index) => {
                  if (cargo.cargo_transport) {
                    return (
                      <tr key={index} className="row29">
                        <td className="column0 style13 s style13" colSpan={17}>
                          {cargo.cargo_transport.cargo?.name}
                        </td>
                        <td className="column17 style33 n style33" colSpan={3}>
                          {cargo.cargo_transport.cargo_places}
                        </td>
                        <td className="column17 style33 n style33" colSpan={5}>
                          {cargo.cargo_transport.cargo_type_packing?.name}
                        </td>
                        <td className="column25 style4 n">
                          {cargo.cargo_transport.cargo_weight}
                        </td>
                        <td className="column26 style1 null" />
                        <td className="column27 style1 null" />
                      </tr>
                    );
                  }
                })}
              </>
            ) : null}

            <tr className="row30">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row31">
              <td className="column0 style1 null" />
              <td className="column1 style5 null style5" colSpan={26}>
                <img
                  src="data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAqgAAAACCAIAAAAbwrmqAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAGklEQVRIie3BAQ0AAADCoPdPbQ8HFAAAAPBnD/IAAdsSN2gAAAAASUVORK5CYII="
                  style={{ maxWidth: '100%', width: '680px' }}
                />
              </td>
              <td className="column27 style1 null" />
            </tr>
            <tr className="row32">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row33">
              <td className="column0 style14 s style14" colSpan={8}>
                Наименование товара/услуги
              </td>
              <td className="column8 style25 s style25" colSpan={2}>
                Ед.изм.
              </td>
              <td className="column10 style25 s style25" colSpan={2}>
                Кол-во
              </td>
              <td className="column12 style25 s style25" colSpan={2}>
                Цена
              </td>
              <td className="column14 style25 s style25" colSpan={4}>
                Сумма
              </td>
              <td className="column18 style25 s style25" colSpan={4}>
                Ставка
                <br />
                НДС
              </td>
              <td className="column22 style2 s">
                Сумма
                <br />
                НДС
              </td>
              <td className="column23 style25 s style25" colSpan={3}>
                Всего
                <br />с НДС
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            {data?.cost?.length > 0 ? (
              <>
                {data.cost.map((item, index) => {
                  return (
                    <tr key={index} className="row34">
                      <td className="column0 style15 s style15" colSpan={8}>
                        {item?.service?.name}
                      </td>
                      <td className="column8 style26 s style26" colSpan={2}>
                        {item?.service?.units}
                      </td>
                      <td className="column10 style27 n style27" colSpan={2}>
                        {item?.count}
                      </td>
                      <td className="column12 style27 n style27" colSpan={2}>
                        {item.in_currency ? item?.price_currency : item?.price}
                      </td>
                      <td className="column14 style27 n style27" colSpan={4}>
                        {item.in_currency ? item?.sum_currency?.sum : item?.sum}
                      </td>
                      <td className="column18 style26 s style26" colSpan={4}>
                        {item?.vat_rate ? `${item.vat_rate}%` : 'Без НДС'}
                      </td>
                      <td className="column22 style3 n">
                        {item.in_currency
                          ? item?.sum_currency?.sum_vat
                          : item?.sum_vat}
                      </td>
                      <td className="column23 style27 n style27" colSpan={3}>
                        {item.in_currency
                          ? item?.sum_currency?.sum_with_vat
                          : item?.sum_with_vat}
                      </td>
                      <td className="column26 style1 null" />
                      <td className="column27 style1 null" />
                    </tr>
                  );
                })}
              </>
            ) : null}
            <tr className="row35">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td
                className="column21 style35 n style35"
                colSpan={5}
                rowSpan={2}
              >
                {data?.sum}
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row36">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td
                className="column16 style11 s style11"
                colSpan={5}
                rowSpan={2}
              >
                Итого:
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row37">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row38">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row39">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style11 s style11" colSpan={5}>
                в т.ч. НДС:
              </td>
              <td className="column21 style35 n style35" colSpan={5}>
                {data?.sum_vat}
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row40">
              <td className="column0 style16 s style16" colSpan={26}>
                Всего к оплате:{' '}
                {data?.sum
                  ? data?.currency == 'dol'
                    ? changePriceText(rubles(data?.sum, 'EN'))
                    : data?.currency == 'eu'
                    ? changePriceText(rubles(data?.sum, 'EU'))
                    : changePriceText(rubles(data?.sum, 'RU'))
                  : null}
              </td>
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row41">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row42">
              <td className="column0 style1 null" />
              <td className="column1 style18 null style18" colSpan={27} />
            </tr>
            <tr className="row43">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row44" style={{ position: 'relative' }}>
              <td style={{ position: 'absolute', top: '-5px', left: '180px' }}>
                <img style={{ maxWidth: '90px' }} src={podpis} />
              </td>
              <td style={{ position: 'absolute', top: '-30px', left: '180px' }}>
                <img style={{ maxWidth: '90px', opacity: '.8' }} src={pechat} />
              </td>
              <td className="column0 style17 s style17" colSpan={9}>
                Руководитель предприятия: _______________
              </td>
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td
                className="column13 style19 s style19"
                colSpan={11}
                rowSpan={2}
              >
                Директор Кудрявцев Константин Андреевич
              </td>
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row45">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row46">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style30 s style30" colSpan={2}>
                Устав
              </td>
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row47">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style1 null" />
              <td className="column14 style1 null" />
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row48" style={{ position: 'relative' }}>
              <td style={{ position: 'absolute', top: '-5px', left: '140px' }}>
                <img style={{ maxWidth: '90px' }} src={podpis} />
              </td>
              {/*<td style={{position: 'absolute',top: '-30px',left: '140px'}}>*/}
              {/*    <img style={{maxWidth: '90px', opacity: '.8'}} src={pechat}/>*/}
              {/*</td>*/}
              <td className="column0 style17 s style17" colSpan={9}>
                Главный бухгалтер: ________________
              </td>
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td
                className="column13 style19 s style19"
                colSpan={11}
                rowSpan={2}
              >
                Директор Кудрявцев Константин Андреевич
              </td>
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row49">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
            <tr className="row50">
              <td className="column0 style1 null" />
              <td className="column1 style1 null" />
              <td className="column2 style1 null" />
              <td className="column3 style1 null" />
              <td className="column4 style1 null" />
              <td className="column5 style1 null" />
              <td className="column6 style1 null" />
              <td className="column7 style1 null" />
              <td className="column8 style1 null" />
              <td className="column9 style1 null" />
              <td className="column10 style1 null" />
              <td className="column11 style1 null" />
              <td className="column12 style1 null" />
              <td className="column13 style30 s style30" colSpan={2}>
                Устав
              </td>
              <td className="column15 style1 null" />
              <td className="column16 style1 null" />
              <td className="column17 style1 null" />
              <td className="column18 style1 null" />
              <td className="column19 style1 null" />
              <td className="column20 style1 null" />
              <td className="column21 style1 null" />
              <td className="column22 style1 null" />
              <td className="column23 style1 null" />
              <td className="column24 style1 null" />
              <td className="column25 style1 null" />
              <td className="column26 style1 null" />
              <td className="column27 style1 null" />
            </tr>
          </tbody>
        </table>
      </div>
    </Spin>
  );
};

export default BillTemplate;
