import { replaceCpTemplatePlaceholdersToValue } from './replaceCpTemplatePlaceholdersToValue';

const getBranchName = (data) => {
  let html = ``;
  if (data?.manager?.branch?.name) {
    html += `${data?.manager?.branch?.name}`;
  }
  return html;
};

const getManagerSignature = (data) => {
  let html = ``;
  html += `<div style="
    color: #000000;
    font-size: 10px;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-weight: 400;
    line-height: 100%;
    text-align: left;
    direction: ltr;
    letter-spacing: 0;
    text-overflow: clip;">`;
  if (data?.signature) {
    html += `${data.signature}`;
  }
  html += `</div>`;
  return html;
};

export const cpTemplateWithReplacedPlaceholders = (
  data,
  shoulderTable,
  services,
  htmlTemplateCp,
) => {
  const branchName = getBranchName(data);
  const managerSignature = getManagerSignature(data);
  const placeholdersValueBook = {
    branchName: { value: branchName },
    shoulderTable: { value: shoulderTable },
    services: { value: `<div class="ul-list">${services}</div>` },
    signature: { value: managerSignature },
  };
  const htmlTemplateWithReplacedPlaceholders =
    replaceCpTemplatePlaceholdersToValue(htmlTemplateCp, placeholdersValueBook);
  return htmlTemplateWithReplacedPlaceholders;
};
