import PropTypes from 'prop-types';
import React from 'react';
import { Spin } from 'antd';
import { ErrorBoundary } from '../../ErrorBoundary';
import DayOfWeek from '../week/dayOfWeek/DayOfWeek';
import dayjs from 'dayjs';

const Day = (props) => {
  return (
    <>
      <Spin spinning={props.loading} style={{ top: '26%' }}>
        <div className={'calendar-wrapper'}>
          {props.currentDate && (
            <ErrorBoundary>
              <DayOfWeek
                day={dayjs(props.currentDate)}
                tasks={props.tasks.length > 0 ? props.tasks : []}
                index={0}
                selectedStaff={props.selectedStaff}
                useForDayViewCalendar={true}
                handleEditTask={(task) => props.handleEditTask(task)}
                handleCreateTask={(day, time) =>
                  props.handleCreateTask(day, time)
                }
              />
            </ErrorBoundary>
          )}
        </div>
      </Spin>
    </>
  );
};

Day.propTypes = {
  currentDate: PropTypes.any.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedStaff: PropTypes.number,
  handleEditTask: PropTypes.func.isRequired,
  handleCreateTask: PropTypes.func.isRequired,
};

export default React.memo(Day);
