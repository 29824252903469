import PropTypes from 'prop-types';
import { ErrorBoundary } from '../../../ErrorBoundary';
import DayOfWeekHeader from './DayOfWeekHeader';
import DayOfWeekBody from './DayOfWeekBody';
import getClassNameByDayWeek from '../getClassNameByDayWeek';

const DayOfWeek = (props) => {
  return (
    <>
      <div
        className={getClassNameByDayWeek(props.day.format('dd').toUpperCase())}
      >
        <ErrorBoundary>
          <DayOfWeekHeader
            day={props.day}
            useForDayViewCalendar={props.useForDayViewCalendar}
            goToCompetitionDate={(day) => {
              props.goToCompetitionDate(day);
            }}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <DayOfWeekBody
            day={props.day}
            tasks={props.tasks}
            selectedStaff={props.selectedStaff}
            displayTimeInCell={props.index === 0}
            useForDayViewCalendar={props.useForDayViewCalendar}
            goToCompetitionDate={(day) => {
              props.goToCompetitionDate(day);
            }}
            handleEditTask={(task) => props.handleEditTask(task)}
            handleCreateTask={(day, time) =>
              props.handleCreateTask(props.day, time)
            }
          />
        </ErrorBoundary>
      </div>
    </>
  );
};

DayOfWeek.propTypes = {
  day: PropTypes.any,
  tasks: PropTypes.any,
  index: PropTypes.number,
  selectedStaff: PropTypes.number,
  useForDayViewCalendar: PropTypes.bool,
  goToCompetitionDate: PropTypes.func,
  handleEditTask: PropTypes.func,
  handleCreateTask: PropTypes.func,
};

export default DayOfWeek;
