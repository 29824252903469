import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Card, Checkbox, Col, Divider, Dropdown, Row, Space } from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';

const TransportationListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>
              <Col xs={24}>
                <div className={'secondText'}>{'Тип'}</div>
                <div className={'valueText'}>
                  {props.transport?.transport?.transport_unit?.type_transport ||
                    '-'}
                </div>
              </Col>
            </Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.transport)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.transport, props.menuActions]);

  return (
    <>
      <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[0, 10]}>
          <Col xs={12}>
            <div className={'secondText'}>{'Номер контейнера'}</div>
            <div className={'valueText'}>
              {props.transport?.number_container || '-'}
            </div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'Платформа'}</div>
            <div className={'valueText'}>
              {props.transport?.platform || '-'}
            </div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'Номер платформы'}</div>
            <div className={'valueText'}>
              {props.transport?.platform_number || '-'}
            </div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'Наименование груза'}</div>
            <div className={'valueText'}>
              {props.transport?.cargo?.name || '-'}
            </div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'Код'}</div>
            <div className={'valueText'}>
              {props.transport?.cargo?.code ?? '-'}
            </div>
          </Col>

          <Col xs={12}>
            <div className={'secondText'}>{'Охрана'}</div>
            <div className={'valueText'}>
              {props.transport?.cargo_security ? (
                <Checkbox
                  disabled={true}
                  checked={props.transport.cargo_security}
                />
              ) : (
                <Checkbox disabled={true} checked={false} />
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default TransportationListItem;

TransportationListItem.propTypes = {
  transport: PropTypes.object,
  menuActions: PropTypes.func,
};
