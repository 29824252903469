import { Radio, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const { Option } = Select;

const StaffSwitch = ({
  periods,
  staffCurrentOptionProps,
  changeStaff,
  onSearch,
  staffListProps,
  style,
  loading,
}) => {
  const [visibleSelect, setVisibleSelect] = useState(false);

  const [valueSwitch, setValueSwitch] = useState(staffCurrentOptionProps);

  const [openSelect, setOpenSelect] = useState(false);

  const visibleStyle = visibleSelect
    ? {
        borderRadius: '0 10px 10px 0',
        padding: 0,
        backgroundColor: 'var(--accent-color)',
        border: '1px solid var(--primary-color)',
      }
    : { borderRadius: '0 10px 10px 0' };

  const [staffList, setStaffList] = useState([]);

  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    setStaffList(staffListProps);
  }, [staffListProps]);

  useEffect(() => {
    if (valueSwitch !== 'staffSelect') {
      setOpenSelect(false);
      changeStaff(valueSwitch);
    }
  }, [valueSwitch]);

  return (
    <Radio.Group
      size="large"
      style={style}
      value={valueSwitch}
      onChange={(e) => {
        if (e.target.value !== 'staffSelect') {
          setVisibleSelect(false);
          setValueSwitch(e.target.value);
        } else {
          setVisibleSelect(true);
        }
      }}
    >
      {periods?.length > 0 ? (
        periods.map((period, index) => (
          <Radio.Button
            key={index}
            style={index === 0 ? { borderRadius: '10px 0 0 10px' } : {}}
            disabled={loading}
            value={period.value}
          >
            {period?.title}
          </Radio.Button>
        ))
      ) : (
        <></>
      )}
      <Radio.Button
        className={'staffSelect'}
        value={'staffSelect'}
        style={visibleStyle}
        disabled={openSelect || loading}
        onClick={() => {
          setOpenSelect(true);
        }}
      >
        {!visibleSelect ? (
          <>Сотрудник</>
        ) : (
          <Select
            getPopupContainer={(trigger) =>
              isMobile ? trigger.parentNode : document.body
            }
            showSearch
            defaultOpen={true}
            open={openSelect}
            autoFocus={true}
            onSearch={(e) => {
              setOpenSelect(true);
              onSearch(e);
            }}
            filterOption={false}
            placeholder={'Выберите сотрудника'}
            onFocus={() => setOpenSelect(true)}
            onBlur={() => {
              if (valueSwitch === 'all') {
                setOpenSelect(false);
                setVisibleSelect(false);
              } else {
                setOpenSelect(false);
              }
            }}
            className={'select-staff-stat'}
            onChange={(e) => {
              setOpenSelect(false);
              setValueSwitch('staffSelect');
              changeStaff(e);
            }}
            /* filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }*/
          >
            {staffList?.length > 0 ? (
              <>
                {staffList.map((item, ind) => {
                  return (
                    <Option
                      key={ind}
                      data-value={JSON.stringify(item)}
                      value={item?.id}
                    >
                      {item?.full_name || item?.email}
                    </Option>
                  );
                })}
              </>
            ) : (
              <Option disabled>Нет доступных вариантов</Option>
            )}
          </Select>
        )}
      </Radio.Button>
    </Radio.Group>
  );
};

export default StaffSwitch;
