import { TodoActionChatNotification } from '../todo/chatNotification/chatNotificationTodo';

const initialState = {
  count: 0,
  wasViewingMessages: false,
};

export const chatNotificationReducer = (
  state = initialState,
  action: TodoActionChatNotification,
) => {
  switch (action.type) {
    case 'SET_COUNT':
      return {
        count: action.payload,
        wasViewingMessages: state.wasViewingMessages,
      };
    case 'SET_WAS_VIEWING_MESSAGE':
      return { count: state.count, wasViewingMessages: action.payload };
    default:
      return state;
  }
};
