import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';

export default async function getChatNotificationList(offset, userInfo) {
  const params = new URLSearchParams();
  if (offset !== null && offset !== undefined) {
    params.append('offset', `${offset}`);
  }

  params.append('limit', '15');

  try {
    const url = `${restApiHost}/chats/chat_notifications?${params}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;

    return json;
  } catch (e) {
    console.log('get chat notification list fetch err', e);
    message.error('Ошибка запрос на получение списка уведомлений из чатов');
  }
}

export async function getChatNotificationById(chatId, userInfo) {
  if (!chatId) return;

  try {
    const url = `${restApiHost}/chats/chat_notifications/${chatId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;

    return json;
  } catch (e) {
    console.log('get chat notification by chat id fetch err', e);
    message.error('Ошибка запрос на получение уведомления');
  }
}
