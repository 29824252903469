import PropTypes from 'prop-types';
import { Drawer, message, Spin } from 'antd';
import DragAndDropTableColumns from './ dragAndDropColumns/DragAndDropTableColumns';
import { useContext, useState } from 'react';
import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { userInformations } from '../../contex';

const SettingTableColumnsDrawer = (props) => {
  const { userInfo } = useContext(userInformations);

  const [copyColumns, setCopyColumns] = useState([]);

  const prepareColumnsForPatch = (columns) => {
    const result = [];
    columns.forEach((col, index) => {
      result.push({
        id: col?.idInDatabase,
        visible: col.columnId === 'active',
        data_index: col.dataIndex,
        index: index + 1,
      });
    });
    return result;
  };

  const closeDrawer = async (columns) => {
    const columnsForEdit = prepareColumnsForPatch(columns);
    await editTableColumns(2, columnsForEdit);
    props.onCloseDrawer(columns);
  };

  const editTableColumns = async (id, columns) => {
    const body = {
      columns: columns,
    };
    try {
      const url = `${restApiHost}/base/tcf_bulk_update/`;
      await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );
    } catch (e) {
      console.log('edit contract err', e);
      message.error('Ошибка запроса на редактирование позиций колонок');
    }
  };

  return (
    <Drawer
      className={'global-style-drawer'}
      bodyStyle={{
        background: 'url("../static/images/mainBackgroundMoreWhite.png")',
      }}
      visible={props.visible}
      title="Настройка столбцов таблицы"
      placement="right"
      onClose={async () => {
        await closeDrawer(copyColumns);
      }}
    >
      <Spin spinning={props.loading}>
        <DragAndDropTableColumns
          columns={props.columns}
          setChangedColumns={(columns) => setCopyColumns(columns)}
          editTableColumns={async () => {
            // await editTableColumns(id, visible, index)
          }}
        />
      </Spin>
    </Drawer>
  );
};

SettingTableColumnsDrawer.props = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  columns: PropTypes.any,
  onCloseDrawer: PropTypes.func,
};

export default SettingTableColumnsDrawer;
