import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { Modal, Upload } from 'antd';
import React, { useEffect, useState } from 'react';

const UploaderImage = (props) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (props.image) {
      setFileList([props.image]);
    } else {
      setFileList([]);
    }
  }, [props.image]);

  return (
    <>
      <Upload
        listType="picture-card"
        showUploadList={true}
        action={null}
        fileList={fileList}
        onPreview={props.previewImage}
        maxCount={1}
        onChange={(info) => props.handleUploaderChange(info, false)}
      >
        <div>
          {props.loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            {fileList.length > 0 ? 'Заменить' : 'Загрузить'}
          </div>
        </div>
      </Upload>
      <Modal
        visible={props.visibleModalPreview}
        footer={null}
        onCancel={props.closeModalPreview}
      >
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={props.imgUrl}
        />
      </Modal>
    </>
  );
};

UploaderImage.propTypes = {
  handleUploaderChange: PropTypes.func.isRequired,
  imgUrl: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  visibleModalPreview: PropTypes.bool,
  closeModalPreview: PropTypes.func,
  previewImage: PropTypes.func,
};

export default UploaderImage;
