import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const NotificationListItem = (props) => {
  return (
    <>
      <Card
        className={'user-select-none'}
        onDoubleClick={() => {
          props.notification?.id &&
            props.handleViewNotifications(props.notification.id);
          props.redirectToCalendar(
            props.notification?.hyperlink?.calendarevent,
          );
        }}
        hoverable={true}
        bodyStyle={{ padding: '20px 10px 20px 10px' }}
        style={{
          width: '100%',
          marginTop: '10px',
          minHeight: '84px',
          borderRadius: '20px',
          border: '1px solid #0b7a7f',
        }}
      >
        <Row justify={'space-between'}>
          <Col lg={21}>{props.notification?.text}</Col>
          <Col>
            <Button
              type={'primary'}
              shape={'circle'}
              onClick={() => {
                props.handleViewNotifications(props.notification?.id);
              }}
              icon={<CheckOutlined />}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

NotificationListItem.propTypes = {
  notification: PropTypes.object.isRequired,
  redirectToCalendar: PropTypes.func,
  handleViewNotifications: PropTypes.func.isRequired,
};

export default NotificationListItem;
