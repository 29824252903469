import { Button, ConfigProvider, DatePicker, Input, Tooltip } from 'antd';
import moment from 'moment/moment';
import { DownOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import dayjs from 'dayjs';
import ru_RU from 'antd/lib/locale/ru_RU';

dayjs.locale('ru');
moment.locale('ru');

const FilterDatePicker = ({
  selectedKeys,
  tableFilters,
  columnKey,
  confirm,
  //dataList,
}) => {
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);

  const [valueForInput, setValueForInput] = React.useState('');

  const [datePickerValue, setDatePickerValue] = React.useState(null);

  const [valueIsChanged, setValueIsChanged] = useState(false);

  const dateFormat = 'DD-MM-YYYY';

  const refInput = useRef(null);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    return () => {
      setDatePickerValue(null);
      setValueIsChanged(false);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      const value = selectedKeys;

      setDatePickerValue(value?.length > 1 ? value : []);

      if (value?.length > 0) {
        const dateString = value.join(' / ');
        setValueForInput(dateString);
      } else {
        setValueForInput('');
      }
    }
  }, [selectedKeys]);

  useEffect(() => {
    if (datePickerValue?.length > 0 && !isMobile) {
      const dateString = formattedDateForInput(datePickerValue);
      setValueForInput(dateString);
    }
  }, [datePickerValue]);

  /* const onKeyUp = (e) => {
        if (e.key === 'Enter') {
            setDatePickerOpen(false)
        } else {
            setDatePickerOpen(true)
        }
    };*/

  useEffect(() => {
    if (!datePickerOpen) {
      handleConfirm(false, datePickerValue, valueIsChanged);
    }
  }, [datePickerOpen]);

  /* useEffect(() => {
        window.addEventListener('keyup', onKeyUp);
        return () => window.removeEventListener('keyup', onKeyUp)
    }, [datePickerOpen])*/

  const formattedDateForInput = (dates) => {
    return `${moment(dates[0]?._d).format('L')}-${moment(
      dates[1]?._d,
      dateFormat,
    ).format('L')}`;
  };

  const formatedTimestamp = (date) => {
    const d = new Date(date);
    d.setHours(24, 0, 0, 0);
    return moment(d).format('YYYY-MM-DD');
  };

  const handleConfirm = (visible, value, valueIsChange) => {
    if (!visible && valueIsChange) {
      const keys = []; //value?.length > 0 ? value.map((item) => formatedTimestamp(item)) : [];
      if (value.length > 0) {
        for (const item of value) {
          keys.push(formatedTimestamp(item));
        }
      }
      let filters = tableFilters;
      filters[columnKey] = keys;
      confirm(filters);
      setValueIsChanged(false);
    }
  };

  const getDateForPickerValue = () => {
    if (datePickerValue?.length > 0) {
      return [
        moment(datePickerValue[0], dateFormat),
        moment(datePickerValue[1], dateFormat),
      ];
    }
  };

  return (
    <div style={{ padding: 8, width: '100%', display: 'flex' }}>
      <>
        <Tooltip
          trigger={['focus']}
          title={valueForInput}
          placement="topLeft"
          overlayClassName="numeric-input"
        >
          <div ref={refInput} style={{ width: '100%' }}>
            <Input
              style={{ width: '100%' }}
              onChange={(e) => {
                if (e.target.value === '') {
                  setValueForInput('');
                  setDatePickerValue([]);
                  handleConfirm(false, [], true);
                }
              }}
              onInput={() => {
                setDatePickerOpen(true);
              }}
              value={valueForInput}
              autoFocus={false}
              allowClear={true}
            />
          </div>
        </Tooltip>
        <Button
          style={{ height: isMobile ? '35.14px' : '31.6px' }}
          autoFocus={false}
          onClick={() => {
            setDatePickerOpen(!datePickerOpen);
          }}
          icon={<DownOutlined style={{ fontSize: '10px' }} />}
        />
        <ConfigProvider locale={ru_RU}>
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            className={'custom-picker'}
            format={dateFormat}
            value={datePickerValue?.length === 0 ? [] : getDateForPickerValue()}
            onChange={(e) => {
              setValueIsChanged(true);
              setDatePickerValue(e);
            }}
            autoFocus={false}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setDatePickerOpen(false);
              }
            }}
            open={datePickerOpen}
            onOpenChange={(open) => {
              setDatePickerOpen(open);
            }}
          />
        </ConfigProvider>
      </>
    </div>
  );
};

export default FilterDatePicker;
