import * as icons from '@ant-design/icons';
import { Button, List, message } from 'antd';
import dayjs from 'dayjs';
// ts(2865)
import { Session as SessionType } from '../../../types';
import { useContext } from 'react';
import { userInformations } from '../../../contex';
import { call } from '../../../apiUtils/call';
import { restApiHost } from '../../../utils/appVariables';

type OperationSystemIdenticals = {
  [key: string]: string;
};

interface SessionItemProps {
  session: SessionType;
  refreshSessions: () => void;
}

const OPERATION_SYSTEM_IDENTICALS: OperationSystemIdenticals = {
  Android: 'MobileOutlined',
  Windows: 'DesktopOutlined',
  iOS: 'MobileOutlined',
};

const getAppropriateIcon = (key: string) => {
  const translation: string = OPERATION_SYSTEM_IDENTICALS[key];
  // @ts-expect-error: 1
  const IconTag = icons[translation];
  return IconTag ? <IconTag /> : <icons.QuestionOutlined />;
};

// @ts-expect-error: Should have generic type
const stopSession = (id: number, userInfo) => {
  try {
    return call(
      `${restApiHost}/accounts/stop_session/`,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          session: id,
        }),
      },
      userInfo?.current?.haveViewBranch,
      null,
    );
  } catch (e) {
    console.log(e);
  }
};

export default function SessionItem({
  session,
  refreshSessions,
}: SessionItemProps) {
  // @ts-expect-error: Should have generic type
  const { userInfo } = useContext(userInformations);

  const stopSessionHandler = () => {
    stopSession(session.id, userInfo)?.then((data) => {
      refreshSessions();
      message.success(data.data);
    });
  };

  return (
    <List.Item
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyItems: 'start',
        alignItems: 'baseline',
        // border: '1px solid black',
      }}
    >
      <List.Item.Meta
        avatar={getAppropriateIcon(session.operation_system)}
        title={
          <p style={{ fontWeight: 600 }}>
            {!Object.is(session.device, null)
              ? `${session.device_brand} ${session.device}`
              : 'Неизвестное устройство'}
          </p>
        }
        description={
          <>
            <p>
              {session.operation_system} {session.operation_system_version},{' '}
              {session.browser} {session.browser_version}
            </p>
            <p>
              Действительна с{' '}
              {dayjs(session.created).format('HH:mm DD.MM.YYYY')}
              {session.temp &&
                ` до ${dayjs(session.temp).format('HH:mm DD.MM.YYYY')}`}
            </p>
          </>
        }
      />
      <Button
        type={'text'}
        onClick={stopSessionHandler}
        icon={<icons.CloseOutlined />}
      ></Button>
    </List.Item>
  );
}
