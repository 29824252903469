import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  message,
  Dropdown,
  Form,
  Menu,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { restApiHost } from '../../utils/appVariables';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import useAsyncEffect from 'use-async-effect';
import { groupByKey } from '../../utils/groupByKey';
import { getCustomerManagers } from '../../logic/customer/CustomerApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { ErrorBoundary } from '../ErrorBoundary';
import ScrollingLoadedList from '../mobile/scrollingLoadedList/ScrollingLoadedList';
import ManagersListItem from '../mobile/leads/leadModal/ManagersListItem';

const { Option } = Select;

const ManagersTable = ({ customerId, loadingManagers }) => {
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [currManager, setCurrManager] = useState(null);
  const [visibleAddManagerModal, setVisibleAddManagerModal] = useState(false);
  const [visibleEditManagerModal, setVisibleEditManagerModal] = useState(false);
  const [groupManagers, setGroupManagers] = useState([]);
  const { userInfo } = useContext(userInformations);
  const [loading, setLoading] = useState(false);
  const [managers, setManagers] = useState([]);
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    await getStaffList();
  }, []);

  useEffect(async () => {
    customerId && (await handleGetGroupsManagers(customerId));
  }, [customerId]);

  const handleGetGroupsManagers = useCallback(
    async (customerId) => {
      setLoading(true);
      const res = await getCustomerManagers(customerId, userInfo);
      if (res?.data) {
        setGroupManagers(res.data);
      }
      setLoading(false);
    },
    [setGroupManagers, getCustomerManagers, setLoading],
  );

  const menu = (manager) => {
    return (
      <Menu style={{ width: 200 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={async () => {
            setCurrManager(manager);
            await searchStaff(manager?.manager?.full_name);
            setVisibleEditManagerModal(true);
            formEdit.setFieldsValue({
              group: manager.group,
              manager: manager.manager.id,
            });
          }}
        >
          Редактировать
        </Menu.Item>

        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={() => deleteManagerGroup(manager.id)}
          onCancel={() => console.log('Delete item')}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const columns = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (manager) => (
        <Space size="middle">
          <Dropdown overlay={menu(manager)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      width: 50,
      dataIndex: 'id',
    },
    {
      title: 'Группа',
      key: 'group_str',
      dataIndex: 'group_str',
      width: 150,
    },
    {
      title: 'Менеджер',
      key: 'manager',
      dataIndex: 'manager',
      width: 200,
      render: (manager) => manager?.full_name,
    },
  ];

  const addManagerGroup = async (body) => {
    try {
      const url = `${restApiHost}/customers/customer_manager/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setVisibleAddManagerModal(false);
      formAdd.resetFields();
      message.success('Успешно добавлено');

      customerId && (await handleGetGroupsManagers(customerId));
    } catch (e) {
      console.log('add groupManagers err', e);
      message.error('Ошибка запроса на добавление группы');
    }
  };

  const editManagerGroup = async (body, id) => {
    try {
      const url = `${restApiHost}/customers/customer_manager/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      setVisibleEditManagerModal(false);
      formEdit.resetFields();
      message.success('Успешно отредактировано');
      customerId && (await handleGetGroupsManagers(customerId));
    } catch (e) {
      console.log('add groupManagers err', e);
      message.error('Ошибка запроса на добавление группы');
    }
  };

  const deleteManagerGroup = async (id) => {
    try {
      const url = `${restApiHost}/customers/customer_manager/${id}/`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      message.success('Успешно удалено');
      customerId && (await handleGetGroupsManagers(customerId));
    } catch (e) {
      console.log('add groupManagers err', e);
      message.error('Ошибка запроса на добавление группы');
    }
  };

  const getStaffList = useCallback(async () => {
    try {
      const url = `${restApiHost}/accounts/staff_users/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      const groupedUsers = groupByKey(json?.data, 'staff_position');
      setManagers(Object.entries(groupedUsers));
    } catch (e) {
      console.log('fetch profile info error', e);
    }
  }, []);

  const searchStaff = useCallback(async (keyword) => {
    try {
      const url = `${restApiHost}/accounts/staff_users/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      const groupedUsers = groupByKey(json?.data, 'staff_position');
      setManagers(Object.entries(groupedUsers));
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса! Не удалось найти сотрудника');
    }
  }, []);

  return (
    <>
      <div className={'top-table-actions'}>
        <Tooltip placement={'topLeft'} title={'Добавить'}>
          <Button
            size={'small'}
            icon={<PlusOutlined />}
            type={'primary'}
            onClick={() => {
              setVisibleAddManagerModal(true);
            }}
          >
            Добавить
          </Button>
        </Tooltip>
      </div>
      {!isMobile ? (
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'black-text table-row-color'
              : 'black-text table-row-white'
          }
          size="small"
          dataSource={groupManagers}
          columns={columns}
          bordered={true}
          loading={loading}
          pagination={{ size: 'middle', showSizeChanger: false }}
          onRow={(record) => {
            return {
              onDoubleClick: async () => {
                setCurrManager(record);
                await searchStaff(record?.manager?.full_name);
                setVisibleEditManagerModal(true);
                formEdit.setFieldsValue({
                  group: record.group,
                  manager: record.manager.id,
                });
              },
            };
          }}
        />
      ) : (
        <div style={{ paddingTop: '5px' }}>
          <ErrorBoundary>
            <ScrollingLoadedList
              dataList={groupManagers}
              totalData={groupManagers?.length}
              loadMoreItems={async () => {
                //handleUpdate(page + 1, filters, false,true)
              }}
              renderListItem={(item) => (
                <ManagersListItem
                  manager={item}
                  menuActions={(manager) => menu(manager)}
                />
              )}
            />
          </ErrorBoundary>
        </div>
      )}

      <Modal
        visible={visibleAddManagerModal}
        title="Добавить сотрудника"
        onOk={() => {
          setVisibleAddManagerModal(false);
          formAdd.resetFields();
        }}
        onCancel={() => {
          setVisibleAddManagerModal(false);
          formAdd.resetFields();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisibleAddManagerModal(false);
              formAdd.resetFields();
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              formAdd.validateFields().then((values) => {
                let body = {
                  customer: customerId,
                  manager: values.manager,
                  group: values.group,
                };
                addManagerGroup(body);
              });
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form form={formAdd} layout={'vertical'}>
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item name={'group'} label={'Группа'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                >
                  <Option value={'prv'}>Привлек</Option>
                  <Option value={'vdt'}>Ведет</Option>
                  <Option value={'otv'}>Ответственный</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name={'manager'} label={'Менеджер'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  placeholder="Менеджер"
                  optionFilterProp="children"
                  loading={loadingManagers}
                  onInput={async (e) => {
                    if (e.target.value.length > 0) {
                      await searchStaff(e.target.value);
                    } else {
                      await getStaffList();
                    }
                  }}
                >
                  {managers && managers.length > 0 ? (
                    <>
                      {managers.map((item) => {
                        return (
                          <>
                            {item[1] && item[1].length > 0 ? (
                              <>
                                {item[1].map((user, index) => {
                                  return (
                                    <Option key={index} value={user.id}>
                                      {user.full_name
                                        ? user.full_name
                                        : user.email}
                                    </Option>
                                  );
                                })}
                              </>
                            ) : (
                              <Option disabled value={'Загрузка...'}>
                                Загрузка...
                              </Option>
                            )}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <Option>Загрузка...</Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        visible={visibleEditManagerModal}
        title="Редактировать сотрудника"
        onOk={() => {
          setVisibleEditManagerModal(false);
          formEdit.resetFields();
        }}
        onCancel={() => {
          setVisibleEditManagerModal(false);
          formEdit.resetFields();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisibleEditManagerModal(false);
              formEdit.resetFields();
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              formEdit.validateFields().then(async (values) => {
                let body = {
                  customerId,
                  manager: values.manager,
                  group: values.group,
                };
                await editManagerGroup(body, currManager.id);
              });
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form form={formEdit} layout={'vertical'}>
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item name={'group'} label={'Группа'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                >
                  <Option value={'prv'}>Привлек</Option>
                  <Option value={'vdt'}>Ведет</Option>
                  <Option value={'otv'}>Ответственный</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name={'manager'} label={'Менеджер'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  placeholder="Менеджер"
                  optionFilterProp="children"
                  loading={loadingManagers}
                  onInput={async (e) => {
                    if (e.target.value.length > 0) {
                      await searchStaff(e.target.value);
                    } else {
                      await getStaffList();
                    }
                  }}
                >
                  {managers && managers.length > 0 ? (
                    <>
                      {managers.map((item) => {
                        return (
                          <>
                            {item[1] && item[1].length > 0 ? (
                              <>
                                {item[1].map((user, index) => {
                                  return (
                                    <Option key={index} value={user.id}>
                                      {user.full_name
                                        ? user.full_name
                                        : user.email}
                                    </Option>
                                  );
                                })}
                              </>
                            ) : (
                              <Option disabled value={'Загрузка...'}>
                                Загрузка...
                              </Option>
                            )}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ManagersTable;
