import React from 'react';
import PropTypes from 'prop-types';
import getColorTaskByStatus from '../month/tasks/tasksUtils/getColorTaskByStatus';
import { getTime } from '../calendarUtils/getTime';
import { getDate } from '../../email/new/EmailListItem';
import { getMonthName } from '../calendarUtils/getMonthName';
import moment from 'moment/moment';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const TasksSection = (props) => {
  return (
    <>
      <section className={'TasksSection'}>
        <div className={'TaskSection-header'}>
          <div className={'TasksSection-date'}>
            <p className={'TaskSection-date-month-day'}>
              {getMonthName(moment(props.currentDate).month(), true) +
                ' ' +
                moment(props.currentDate).date()}
              ,
            </p>
            <p className={'TaskSection-date-year'}>
              {moment(props.currentDate).year()}
            </p>
          </div>
          <Button
            className={'TaskSection-task-create-button'}
            onClick={() => props.openTaskModal(true)}
            size="small"
            type="primary"
            icon={<PlusOutlined />}
          />
        </div>

        {props.tasks.map((item, key) => {
          if (getDate(props.currentDate) === getDate(item.end)) {
            return (
              <aside
                key={key}
                className={'TasksSection-task-item'}
                onClick={() => props.handleEditTask(item)}
              >
                <div
                  className={'TaskSection-task-item-overflow'}
                  style={{ backgroundColor: getColorTaskByStatus(item.status) }}
                />
                <div
                  className={'TaskSection-task-item-color'}
                  style={{ backgroundColor: getColorTaskByStatus(item.status) }}
                />
                <div className={'TaskSection-task-item-body'}>
                  <div className={'TaskSection-task-header'}>{item.name}</div>
                  <div className={'TaskSection-task-footer'}>
                    {getTime(item.end)}
                  </div>
                </div>
              </aside>
            );
          }
        })}
      </section>
    </>
  );
};

TasksSection.PropTypes = {
  tasks: PropTypes.any,
  handleEditTask: PropTypes.func.isRequired,
  openTaskModal: PropTypes.func.isRequired,
};

export default TasksSection;
