import { Radio } from 'antd';
import { useEffect, useState } from 'react';
import DataPicker from './DataPicker';

const PeriodSwitch = ({ periods, changePeriod, periodDateProps, loading }) => {
  const [periodDates, setPeriodDates] = useState(periodDateProps);
  const [visibleDataPicker, setVisibleDataPicker] = useState(false);
  const [visibleStyle, setVisibleStyle] = useState({});
  const [openDataPickerCelendar, setOpenDataPickerCelendar] = useState(false);

  useEffect(() => {
    if (periodDates) {
      changePeriod(periodDates);
    }
  }, [periodDates]);

  useEffect(() => {
    setOpenDataPickerCelendar(visibleDataPicker);
    if (visibleDataPicker) {
      setVisibleStyle({
        borderRadius: '0 10px 10px 0',
        padding: 0,
      });
    } else {
      setVisibleStyle({
        borderRadius: '0 10px 10px 0',
      });
    }
  }, [visibleDataPicker]);

  return (
    <Radio.Group
      size="large"
      value={periodDateProps}
      onChange={(e) => {
        if (e.target.value !== 'dataPicker') {
          setVisibleDataPicker(false);
          setPeriodDates(e.target.value);
        } else {
          setVisibleDataPicker(true);
        }
      }}
    >
      {periods?.length > 0 ? (
        periods.map((period, index) => (
          <Radio.Button
            style={index === 0 ? { borderRadius: '10px 0 0 10px' } : {}}
            value={period.value}
            disabled={loading}
            key={index}
          >
            {period?.title}
          </Radio.Button>
        ))
      ) : (
        <></>
      )}
      <Radio.Button
        value={'dataPicker'}
        disabled={openDataPickerCelendar || loading}
        style={visibleStyle}
        onClick={() => {
          setOpenDataPickerCelendar(true);
        }}
      >
        {!visibleDataPicker ? (
          <>Период</>
        ) : (
          <DataPicker
            visible={visibleDataPicker}
            periodDateProps={periodDateProps}
            openDataPickerCelendar={openDataPickerCelendar}
            SetOpenDataPickerCelendar={(open) => {
              setOpenDataPickerCelendar(open);
            }}
            periodDates={periodDates}
            setVisibleDataPicker={(visible) => setVisibleDataPicker(visible)}
            setSelectedKeys={(keys) => {
              setVisibleStyle({
                borderRadius: '0 10px 10px 0',
                padding: 0,
                borderColor: '#0b7b7f',
              });
              setPeriodDates(keys);
            }}
          />
        )}
      </Radio.Button>
    </Radio.Group>
  );
};

export default PeriodSwitch;
