import { useCallback, useEffect, useRef } from 'react';
import Favicon from 'react-favicon';
import faviconIcon from '../icons/faviconIcon.ico';
import { useTypedSelector } from '../store/hooks/useTypedSelector';

const FaviconReactiveIcon = () => {
  const renderOverlay = useRef(false);
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );
  const iconSize = 16;

  useEffect(() => {
    renderOverlay.current = notificationCount > 0;
  }, [notificationCount]);

  const getDisplayedCountNotification = useCallback(() => {
    if (!notificationCount || notificationCount === 0) return null;
    return notificationCount <= 9 ? notificationCount : '9';
  }, [notificationCount]);

  const renderOverlayFavicon = useCallback(
    (canvas, context) => {
      if (!renderOverlay.current || !getDisplayedCountNotification())
        return null;
      const top = canvas.height / 2;
      const left = canvas.width / 2;
      const bottom = canvas.height;
      const right = canvas.width;
      const radius = iconSize / 8;

      context.fillStyle = 'red';
      context.strokeStyle = 'red';
      context.lineWidth = 1;

      context.beginPath();
      context.moveTo(left + radius, top);
      context.quadraticCurveTo(left, top, left, top + radius);
      context.lineTo(left, bottom - radius);
      context.quadraticCurveTo(left, bottom, left + radius, bottom);
      context.lineTo(right - radius, bottom);
      context.quadraticCurveTo(right, bottom, right, bottom - radius);
      context.lineTo(right, top + radius);
      context.quadraticCurveTo(right, top, right - radius, top);
      context.closePath();
      context.fill();

      context.font = `bold ${iconSize / 1.8}px arial`;
      context.fillStyle = '#ffffff';
      context.textAlign = 'left';
      context.textBaseline = 'top';
      context.fillText(
        `${getDisplayedCountNotification()}`,
        left + canvas.width / 8,
        top,
      );
    },
    [renderOverlay.current, getDisplayedCountNotification],
  );

  return (
    <Favicon
      alertCount={null}
      alertFillColor={'red'}
      alertTextColor={'white'}
      animated={faviconIcon}
      renderOverlay={(canvas, context) => renderOverlayFavicon(canvas, context)}
      url={faviconIcon}
      iconSize={iconSize}
    />
  );
};

export default FaviconReactiveIcon;
