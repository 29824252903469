import React, { useCallback, useContext, useState } from 'react';
import {
  Button,
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import ContactLeedModal from './ContactLeedModal';
import { restApiHost } from '../../utils/appVariables';
import { ErrorBoundary } from '../ErrorBoundary';
import { call } from '../../apiUtils/call';
import { userInformations } from '../../contex';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import useAsyncEffect from 'use-async-effect';
import { getContactsLead } from '../../logic/lead/LeadApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../mobile/scrollingLoadedList/ScrollingLoadedList';
import LeadContactListItem from '../mobile/leads/leadModal/LeadContactListItem';

const ContactsLeed = ({ lead }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactsList, setContactsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useContext(userInformations);
  const demoAccessIsUsed = useTypedSelector(
    (state) => state.demoAccess.demoAccessIsUsed,
  );
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    if (lead?.id) {
      await handleGetContactsLead(lead.id);
    }
  }, [lead]);

  const handleGetContactsLead = useCallback(
    async (leadId) => {
      setLoading(true);
      const res = await getContactsLead(leadId, userInfo);
      if (res?.data) {
        setContactsList(res.data);
      }
      setLoading(false);
    },
    [lead],
  );

  function menu(contact) {
    return (
      <Menu style={{ width: 'auto' }}>
        <Menu.Item
          key={'edit'}
          icon={<EditOutlined />}
          onClick={() => handleEditContact(contact)}
        >
          Редактировать
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={async () => await deleteContact(contact)}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item
            disabled={demoAccessIsUsed}
            key={'delete'}
            icon={<DeleteOutlined />}
          >
            Удалить
          </Menu.Item>
        </Popconfirm>
      </Menu>
    );
  }

  const handleEditContact = (contact) => {
    setSelectedContact(contact);
    setVisibleModal(true);
  };

  const handleChangeContactsList = useCallback(
    (newListContract) => {
      if (!newListContract) return;
      setContactsList(newListContract);
    },
    [setContactsList],
  );

  const deleteContactQuery = useCallback(
    async (id) => {
      try {
        const url = `${restApiHost}/leads/contact_persons/${id}/`;
        const json = await call(
          url,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          },
          userInfo.current.haveViewBranch,
        );

        if (json?.data) {
          message.success('Контактное лицо успешно добавлено!');
          const newContacts = [...contactsList].filter(
            (item) => item.id !== id,
          );
          handleChangeContactsList(newContacts);
        }
      } catch (e) {
        message.error('Ошибка запроса на добавление контактного лица');
        console.log('add contact error', e);
      }
    },
    [contactsList, handleChangeContactsList],
  );

  const deleteContact = useCallback(
    async (contact) => {
      if (lead) {
        await deleteContactQuery(contact?.id);
      } else {
        const newListContract = [...contactsList].filter(
          (item) =>
            item.email !== contact.email &&
            item.phone_number !== contact.phone_number,
        );
        handleChangeContactsList(newListContract);
      }
    },
    [lead, contactsList, handleChangeContactsList],
  );

  const columns = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (lead) => (
        <Space size="middle">
          <Dropdown overlay={menu(lead)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'ФИО',
      key: 'full_name',
      dataIndex: 'full_name',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'Контактный номер',
      key: 'phone_number',
      dataIndex: 'phone_number',
      width: 200,
    },
    {
      title: 'Доб. номер',
      key: 'add_tel_number',
      dataIndex: 'add_tel_number',
      width: 150,
    },
    {
      title: 'Почта',
      key: 'email',
      dataIndex: 'email',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: 'Должность',
      key: 'position',
      dataIndex: 'position',
      width: 200,
    },
    {
      title: 'Дата рождения',
      key: 'birthday',
      dataIndex: 'birthday',
      width: 200,
    },
    {
      title: 'Комментарий',
      key: 'comment',
      dataIndex: 'comment',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
    },
  ];

  return (
    <>
      <div className={'top-table-actions'}>
        <Tooltip placement={'topLeft'} title={'Добавить контактное лицо'}>
          <Button
            type="primary"
            size={'small'}
            icon={<PlusOutlined />}
            disabled={demoAccessIsUsed}
            onClick={() => {
              setSelectedContact(null);
              setVisibleModal(true);
            }}
          >
            Добавить
          </Button>
        </Tooltip>
      </div>
      {!isMobile ? (
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'black-text table-row-color'
              : 'black-text table-row-white'
          }
          scroll={{ x: 1400 }}
          size="small"
          loading={loading}
          bordered={true}
          pagination={{ size: 'middle', pageSize: 15, showSizeChanger: false }}
          dataSource={contactsList}
          columns={columns}
          onRow={(contact) => {
            return { onDoubleClick: () => handleEditContact(contact) };
          }}
        />
      ) : (
        <div style={{ paddingTop: '5px' }}>
          <ErrorBoundary>
            <ScrollingLoadedList
              dataList={contactsList}
              totalData={contactsList?.length}
              loadMoreItems={async () => {
                //handleUpdate(page + 1, filters, false,true)
              }}
              renderListItem={(item) => (
                <LeadContactListItem
                  leadContact={item}
                  menuActions={(leadContact) => menu(leadContact)}
                />
              )}
            />
          </ErrorBoundary>
        </div>
      )}
      <ErrorBoundary>
        <ContactLeedModal
          lead={lead}
          contacts={contactsList}
          onChangeContacts={(data) => handleChangeContactsList(data)}
          contact={selectedContact}
          visible={visibleModal}
          onClose={() => {
            setSelectedContact(null);
            setVisibleModal(false);
          }}
        />
      </ErrorBoundary>
    </>
  );
};

export default ContactsLeed;
