import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown, Menu, Space, Table, Tooltip } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import moment from 'moment/moment';

const SentKpTable = ({
  sentCommercialOffers,
  loading,
  preview,
  total,
  page,
  updateList,
}) => {
  const [, setSelectedCommercialOffers] = useState(null);
  const [sortCommercialOffers, setSortCommercialOffers] = useState([]);
  const menu = (commercialOffers) => {
    return (
      <Menu style={{ width: 100 }}>
        <Menu.Item
          icon={<FileOutlined />}
          onClick={() => {
            setSelectedCommercialOffers(commercialOffers);
            preview(commercialOffers);
          }}
        >
          Открыть
        </Menu.Item>
      </Menu>
    );
  };
  useEffect(() => {
    if (sentCommercialOffers && sentCommercialOffers?.length > 0) {
      const sorterCommercialOffers = sentCommercialOffers.sort(
        (a, b) =>
          Date.parse(b.comm_prop_sended) - Date.parse(a.comm_prop_sended),
      );
      setSortCommercialOffers(sorterCommercialOffers);
    } else {
      setSortCommercialOffers([]);
    }
  }, [sentCommercialOffers]);

  const columns = [
    {
      title: '',
      key: 'action',
      width: 30,
      align: 'center',
      render: (lead) => (
        <Space size="middle">
          <Dropdown overlay={menu(lead)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Контакт',
      key: 'comm_prop',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (comm_prop) => {
        if (comm_prop?.contacts?.length > 0) {
          const contacts = comm_prop?.contacts
            .map((contact) => contact?.contact_full_name || contact?.email)
            .join(',');
          return (
            <Tooltip placement={'topLeft'} title={contacts}>
              {contacts}
            </Tooltip>
          );
        }
      },
    },
    {
      title: 'ID Пред.заявки',
      key: 'comm_prop',
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (comm_prop) => {
        if (comm_prop?.template_data?.pretasks?.length > 0) {
          const preTaskIds = comm_prop?.template_data?.pretasks
            .map((preTask) => preTask?.id)
            .join(',');
          return (
            <Tooltip placement={'topLeft'} title={preTaskIds}>
              {preTaskIds}
            </Tooltip>
          );
        }
      },
    },
    {
      title: 'Дата отправки',
      key: 'comm_prop_sended',
      dataIndex: 'comm_prop_sended',
      width: 200,
      render: (comm_prop_sended) => (
        <>
          {comm_prop_sended
            ? moment(comm_prop_sended).format('DD/MM/YYYY')
            : '-'}
        </>
      ),
    },
  ];

  const handleTablePageChange = useCallback(
    (page) => {
      if (!page) return;
      updateList && updateList(page);
    },
    [updateList],
  );

  return (
    <>
      <div style={{ position: 'relative', marginTop: 10 }}>
        <Table
          loading={loading}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setSelectedCommercialOffers(record);
                preview(record);
              },
            };
          }}
          showHeader
          onChange={(val) => {
            handleTablePageChange(val?.current);
          }}
          dataSource={sortCommercialOffers}
          bordered={true}
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'black-text table-row-color'
              : 'black-text table-row-white'
          }
          size="small"
          pagination={{
            total,
            current: page,
            position: total ? ['bottomRight'] : ['none'],
            size: 'middle',
            pageSize: 15,
            showSizeChanger: false,
          }}
          columns={columns}
          rowKey={(record) => record.id}
        />
      </div>
    </>
  );
};

export default SentKpTable;
