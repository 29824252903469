import { Spin, Empty, Button } from 'antd';
import React from 'react';
import EmailListItem from './EmailListItem';
import EmailToolbar from './EmailToolbar';

const EmailList = ({
  activeFolder,
  handleDeleteMessages,
  deleteAllEmailsFromDeletedFolder,
  emails,
  total,
  loading,
  showBodyEmail,
  setActiveAll,
  activeAll,
  selectedEmails,
  setSelectedEmails,
  getMessagesFromFolder,
  handleShowSendEmailModal,
  changeMessageFlag,
  handleFlaggedMessages,
  flagged,
  setFlagged,
  profileInfo,
  handleSpamMessages,
  selectedStaff,
}) => {
  return (
    <Spin tip="Загрузка..." spinning={loading}>
      <EmailToolbar
        setActiveAll={setActiveAll}
        disabled={emails?.length === 0 || selectedStaff}
        handleShowSendEmailModal={handleShowSendEmailModal}
        handleDeleteMessages={handleDeleteMessages}
        deleteAllEmailsFromDeletedFolder={deleteAllEmailsFromDeletedFolder}
        setSelectedEmails={setSelectedEmails}
        activeFolder={activeFolder}
        getMessagesFromFolder={getMessagesFromFolder}
        flagged={flagged}
        profileInfo={profileInfo}
        selectedStaff={selectedStaff}
        setFlagged={setFlagged}
        handleFlaggedMessages={handleFlaggedMessages}
        handleSpamMessages={handleSpamMessages}
        selectedEmails={selectedEmails}
      />
      <div className={'email-messages'}>
        {emails?.length > 0 ? (
          <>
            {emails.map((email) => (
              <EmailListItem
                selectedEmails={selectedEmails}
                setSelectedEmails={setSelectedEmails}
                activeAll={activeAll}
                email={email}
                key={email.id || email.msg_id}
                activeFolder={activeFolder}
                showBodyEmail={showBodyEmail}
                setFlag={changeMessageFlag}
                selectedStaff={selectedStaff}
              />
            ))}
            {emails?.length !== total && (
              <Button style={{ marginTop: 10 }} onClick={getMessagesFromFolder}>
                Еще письма
              </Button>
            )}
          </>
        ) : (
          <Empty description={<b>Нет писем</b>} />
        )}
      </div>
    </Spin>
  );
};

export default EmailList;
