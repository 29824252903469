import { message } from 'antd';
import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';

export async function sendContractOnApproval(body, userInfo) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      message.info('Началась отправка договора');
      const url = `${restApiHost}/customers/send_contract_to_customer/`;
      const formData = new FormData();
      body?.contract && formData.append('contract', body?.contract);
      body?.subject && formData.append('subject', body?.subject);
      body?.html && formData.append('html', body?.html);

      body?.to?.length > 0 && body.to.map((to) => formData.append('to', to));
      body?.cc?.length > 0 && body.cc.map((cc) => formData.append('cc', cc));
      body?.bcc?.length > 0 &&
        body.bcc.map((bcc) => formData.append('bcc', bcc));

      if (body?.files.length > 0) {
        body?.files.map((file) => {
          if (file?.originFileObj) {
            formData.append('files', file.originFileObj, file.name);
          }
        });
      }
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: formData,
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;
      resolve({ status: 200 });
      message.success('Договор успешно отправлен!');
    } catch (e) {
      console.log(e);
      reject({ status: 400 });
      message.error('Ошибка запроса на отправку договора');
    }
  });
}
