import { Button, Col, Form, Modal, Row, message, Spin, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { CheckOutlined, InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { ErrorBoundary } from '../../ErrorBoundary';
import PdfViewerModal from '../../contracts/templeteContract/PdfViewerModal';
import { restApiHost } from '../../../utils/appVariables';

const RefBookPresentationsModal = ({
  visible,
  editItem,
  deleteItem,
  form,
  submitForm,
  setDeleteItem,
  fields,
  getComponent,
  handleCancelModal,
  loading,
}) => {
  const [visibleModal, setVisible] = useState(false);
  const [, setDisablePreviewContractTemplate] = useState(true);
  const [fileUrl, setFileUrl] = useState(null);
  const [visiblePreviewModal, setVisiblePreviewModal] = useState(false);
  const [fileList, setFileList] = useState([]);

  const props = {
    beforeUpload: () => false,
    accept: '.pptx, .pptm, .ppt, .pdf',
    maxCount: 1,
    async onChange({ fileList }) {
      setFileList(fileList);
    },
    multiple: false,
    fileList,
  };

  useEffect(() => {
    if (form) {
      const file = form.getFieldValue('file');
      if (file) {
        setFileUrl(`${restApiHost}${file}`);
      } else {
        setFileUrl(null);
      }
    }
  }, [form, visibleModal]);

  useEffect(() => {
    setVisible(visible);
    if (!visible) {
      clearUp();
    }
  }, [visible]);

  useEffect(() => {
    if (!visibleModal) {
      clearUp();
    }
  }, [visibleModal]);

  const submit = async (values, editItem, deleteItem) => {
    const resValue = { ...values };
    if (!resValue?.active) {
      resValue.active = false;
    }
    delete resValue.file;
    if (!editItem && !fileList[0]?.originFileObj) {
      message.warning('Файл не выбран');
      return;
    } else {
      if (fileList[0]?.originFileObj) {
        resValue.file = fileList[0]?.originFileObj;
      }
    }
    editItem
      ? deleteItem
        ? await submitForm(resValue, editItem, deleteItem)
        : await submitForm(resValue, editItem, false, true)
      : await submitForm(resValue, null, false, true);
    clearUp();
  };

  const renderLabelAndTextForFormFile = (label) => {
    return (
      <>
        <Space size={1}>
          <div>{label}</div>
          <div>
            <CheckOutlined style={{ color: 'green', fontSize: '20px' }} />
          </div>
        </Space>
      </>
    );
  };

  const handlePreviewPresentation = useCallback(() => {
    if (fileUrl) {
      setVisiblePreviewModal(true);
    }
  }, [fileUrl]);

  const clearUp = () => {
    setFileList([]);
    setDisablePreviewContractTemplate(true);
    setFileUrl(null);
    setVisiblePreviewModal(false);
  };

  return (
    <>
      <Modal
        title={`${editItem ? 'Редактирование' : 'Добавление'}`}
        visible={visibleModal}
        onCancel={handleCancelModal()}
        footer={[]}
      >
        <Spin spinning={loading}>
          <Form
            layout={'vertical'}
            form={form}
            onFinish={async (values) => {
              await submit(values, editItem, deleteItem);
            }}
          >
            <Row gutter={16}>
              {fields?.length > 0 &&
                fields.map((item) => {
                  return item[0] !== 'file'
                    ? getComponent(item[1]?.type, item)
                    : '';
                })}
              <Col lg={12} xs={24}>
                <Form.Item
                  style={{ overflow: 'hidden' }}
                  label={
                    fileUrl
                      ? renderLabelAndTextForFormFile(
                          'Файл презентация уже загружен',
                        )
                      : 'Презентация'
                  }
                >
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {fileUrl
                        ? 'Кликните или перенесите файл для замены'
                        : ' Кликните или перенесите файл для загрузки'}
                    </p>
                  </Dragger>
                </Form.Item>
              </Col>
            </Row>
            <Row justify={'end'} gutter={10}>
              {fileUrl ? (
                <Col>
                  <Button
                    onClick={() => {
                      handlePreviewPresentation();
                    }}
                  >
                    {'Просмотреть файл'}
                  </Button>
                </Col>
              ) : (
                <></>
              )}
              <Col>
                <Button type={'primary'} htmlType={'submit'}>
                  {editItem ? 'Сохранить' : 'Добавить'}
                </Button>
              </Col>
              <Col style={{ marginLeft: '10px' }}>
                {editItem ? (
                  <Button
                    onClick={() => setDeleteItem(true)}
                    htmlType={'submit'}
                  >
                    {'Удалить'}
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
      <ErrorBoundary>
        <PdfViewerModal
          visible={visiblePreviewModal}
          setVisible={(visible) => {
            setVisiblePreviewModal(visible);
          }}
          width={1200}
          pdfFileUrl={fileUrl}
        />
      </ErrorBoundary>
    </>
  );
};

export default RefBookPresentationsModal;
