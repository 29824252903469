import React from 'react';
import { Table } from 'antd';

const ActiveCallsTable = ({ activeCalls, loading }) => {
  const columns = [
    {
      title: 'ID вызова',
      key: 'callId',
      dataIndex: 'callId',
    },
    {
      title: 'Статус вызова',
      key: 'inConference',
      dataIndex: 'inConference',
      render: (inConference) =>
        inConference ? 'Вызов активен' : 'Вызов завершен',
    },
  ];

  return (
    <Table
      dataSource={activeCalls}
      loading={loading}
      bordered={true}
      columns={columns}
      rowClassName={(record, index) =>
        index % 2 === 0
          ? 'black-text table-row-color'
          : 'black-text table-row-white'
      }
      size="small"
      pagination={{ size: 'middle', pageSize: 15, showSizeChanger: false }}
    />
  );
};

export default ActiveCallsTable;
