import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, message, Modal } from 'antd';
import { getFile } from './utilsFunctions/getFile';
import { ErrorBoundary } from '../../../ErrorBoundary';
import ContactPersonModalForm from './ContactPersonModalForm';
import { restApiHost } from '../../../../utils/appVariables';
import { call } from '../../../../apiUtils/call';
import { userInformations } from '../../../../contex';
import { createCustomerContactPersons } from '../../../../logic/customer/CustomerApiFunctions';
import { objToFormDataContact } from '../../../customer/cutomerModalUtils/customerModalUtilsFunctions';
import dayjs from 'dayjs';

const ContactPersonModal = (props) => {
  const { userInfo } = useContext(userInformations);
  const [contactForm] = Form.useForm();
  const [facsimileImage, setFacsimileImage] = useState(null);
  const [facsimileImageUrl, setFacsimileImageUrl] = useState(null);
  const [stampImage, setStampImage] = useState(null);
  const [stampImageUrl, setStampImageUrl] = useState(null);
  const [isSignatoryContact, setIsSignatoryContact] = useState(false);

  useEffect(async () => {
    if (props.editingItem) {
      await setFormModal(props.editingItem);
    }
  }, [props.editingItem]);

  const setFormModal = async (contact) => {
    if (!contact) return;
    contactForm.setFieldsValue({
      ...contact,
      birthday: contact?.birthday
        ? dayjs(contact.birthday).format('YYYY-MM-DD')
        : null,
      base_doc_date_begin: contact?.base_doc_date_begin
        ? dayjs(contact.base_doc_date_begin).format('YYYY-MM-DD')
        : null,
    });
    setIsSignatoryContact(contact?.can_sign_contract);
    await getAndSetFacsimileAndStamp(contact?.facsimile, contact?.stamp);
  };

  const getAndSetFacsimileAndStamp = async (facsimileUrl, stampUrl) => {
    try {
      if (facsimileUrl) {
        setFacsimileImageUrl(decodeURI(`${restApiHost}${facsimileUrl}`));
        const facsimileFile = await getFile(`${restApiHost}${facsimileUrl}`);
        setFacsimileImage(facsimileFile ? facsimileFile : null);
      }
      if (stampUrl) {
        setStampImageUrl(decodeURI(`${restApiHost}${stampUrl}`));
        const stampFile = await getFile(`${restApiHost}${stampUrl}`);
        setStampImage(stampFile ? stampFile : null);
      }
    } catch (err) {
      console.log(err, 'getAndSetFacsimileAndStamp error');
      message.error('Ошибка преобразования изображения');
    }
  };

  const editContactPersons = async (id, body) => {
    try {
      const url = `${restApiHost}/customers/contact_persons/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: objToFormDataContact(body),
        },
        userInfo?.current?.haveViewBranch,
      );
      if (!json) return;
      message.success('Операция прошла успешно!');
      handleCloseModal();
      props.customerId && props.handleUpdateCustomerData(props.customerId);
    } catch (e) {
      console.log('add contact person err', e);
      message.error('Ошибка запроса на добавление контактного лица');
    }
  };

  const clearUpVariables = () => {
    contactForm.resetFields();
    setFacsimileImage(null);
    setFacsimileImageUrl(null);
    setStampImageUrl(null);
    setIsSignatoryContact(false);
    setStampImage(null);
  };

  const handleCloseModal = () => {
    clearUpVariables();
    props.closeModal();
  };

  const handleCreateContactPerson = useCallback(
    async (body) => {
      if (!body) return;
      const formDateObj = objToFormDataContact(body);
      const res = await createCustomerContactPersons(formDateObj, userInfo);
      if (!res?.data) return;

      message.success('Успешно добавлено!');
      handleCloseModal();
      props.customerId && props.handleUpdateCustomerData(props.customerId);
    },
    [createCustomerContactPersons, handleCloseModal, props.customerId],
  );

  const addOrEditContact = async () => {
    contactForm.validateFields().then(async (values) => {
      let contact = {
        ...values,
        facsimile: facsimileImage ? facsimileImage : null,
        stamp: stampImage ? stampImage : null,
        customer: props.customerId,
        can_sign_contract: isSignatoryContact,
      };
      console.log(contact);
      props.editingItem
        ? await editContactPersons(props.editingItem?.id, contact)
        : await handleCreateContactPerson(contact);
    });
  };

  return (
    <>
      <Modal
        title={
          props.editingItem
            ? 'Редактировать контактное лицо'
            : 'Добавить контактное лицо'
        }
        visible={props.visible}
        width={1000}
        onCancel={() => {
          handleCloseModal();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              handleCloseModal();
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={async () => {
              await addOrEditContact();
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <ErrorBoundary>
          <ContactPersonModalForm
            form={contactForm}
            clientHasCategoryOur={props.clientHasCategoryOur}
            clientHasCategorySkl={props.clientHasCategorySkl}
            facsimileImage={facsimileImage}
            facsimileImageUrl={facsimileImageUrl}
            setFacsimileImage={(facsimileImage) =>
              setFacsimileImage(facsimileImage)
            }
            setFacsimileImageUrl={(facsimileImageUrl) =>
              setFacsimileImageUrl(facsimileImageUrl)
            }
            stampImage={stampImage}
            stampImageUrl={stampImageUrl}
            setStampImageUrl={(stampImageUrl) =>
              setStampImageUrl(stampImageUrl)
            }
            setStampImage={(stampImage) => setStampImage(stampImage)}
            docBasesList={props.docBasesList}
            contactCommentList={props.contactCommentList}
            isSignatoryContact={isSignatoryContact}
            changeIsSignatoryContact={(val) => setIsSignatoryContact(val)}
          />
        </ErrorBoundary>
      </Modal>
    </>
  );
};

ContactPersonModal.propTypes = {
  visible: PropTypes.bool,
  editingItem: PropTypes.any,
  closeModal: PropTypes.func,
  customerId: PropTypes.number,
  clientHasCategoryOur: PropTypes.bool,
  clientHasCategorySkl: PropTypes.bool,
  docBasesList: PropTypes.any,
  contactCommentList: PropTypes.any,
  handleUpdateCustomerData: PropTypes.func,
};

export default ContactPersonModal;
