import React from 'react';
import PropTypes from 'prop-types';

const MobileMenuButton = (props) => {
  return (
    <>
      <section className="MobileMenu if-size-not-pc">
        <button
          onClick={() => {
            props.handleMenu();
          }}
          className={'MobileMenu-button'}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 31 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.678833 2.08333C0.678833 1.5308 0.902126 1.0009 1.29959 0.610195C1.69705 0.219494 2.23613 0 2.79823 0H28.231C28.7931 0 29.3321 0.219494 29.7296 0.610195C30.1271 1.0009 30.3504 1.5308 30.3504 2.08333C30.3504 2.63587 30.1271 3.16577 29.7296 3.55647C29.3321 3.94717 28.7931 4.16667 28.231 4.16667H2.79823C2.23613 4.16667 1.69705 3.94717 1.29959 3.55647C0.902126 3.16577 0.678833 2.63587 0.678833 2.08333ZM0.678833 12.5C0.678833 11.9475 0.902126 11.4176 1.29959 11.0269C1.69705 10.6362 2.23613 10.4167 2.79823 10.4167H28.231C28.7931 10.4167 29.3321 10.6362 29.7296 11.0269C30.1271 11.4176 30.3504 11.9475 30.3504 12.5C30.3504 13.0525 30.1271 13.5824 29.7296 13.9731C29.3321 14.3638 28.7931 14.5833 28.231 14.5833H2.79823C2.23613 14.5833 1.69705 14.3638 1.29959 13.9731C0.902126 13.5824 0.678833 13.0525 0.678833 12.5ZM0.678833 22.9167C0.678833 22.3641 0.902126 21.8342 1.29959 21.4435C1.69705 21.0528 2.23613 20.8333 2.79823 20.8333H28.231C28.7931 20.8333 29.3321 21.0528 29.7296 21.4435C30.1271 21.8342 30.3504 22.3641 30.3504 22.9167C30.3504 23.4692 30.1271 23.9991 29.7296 24.3898C29.3321 24.7805 28.7931 25 28.231 25H2.79823C2.23613 25 1.69705 24.7805 1.29959 24.3898C0.902126 23.9991 0.678833 23.4692 0.678833 22.9167Z"
              fill="white"
            />
          </svg>
        </button>
      </section>
    </>
  );
};

MobileMenuButton.propTypes = {
  handleMenu: PropTypes.func,
};

export default MobileMenuButton;
