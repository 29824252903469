export const setPaginationSettings = (
  total,
  page,
  position = ['right'],
  showSizeChanger = true,
  defaultCurrent = 1,
  size = 'middle',
  pageSize = null,
  pageSizeOptions = [10, 15, 20, 50, 100],
  setPageSizeToLocalStorage = true,
) => {
  if (pageSize !== null && setPageSizeToLocalStorage) {
    localStorage.setItem('paginationPageSize', pageSize);
  }
  // if(pageSize === null){
  if (setPageSizeToLocalStorage) {
    pageSize = getCurrentPaginationPageSize();
  }
  // }
  return {
    position: position,
    pageSize: pageSize,
    showSizeChanger: showSizeChanger,
    total: total,
    defaultCurrent: defaultCurrent,
    onShowSizeChange: setPaginationPageSize,
    size: size,
    current: page,
    pageSizeOptions: pageSizeOptions,
  };
};
export const setPaginationPageSize = (current, pageSize) => {
  localStorage.setItem('paginationPageSize', pageSize);
};

export const getCurrentPaginationPageSize = () => {
  return localStorage.getItem('paginationPageSize')
    ? localStorage.getItem('paginationPageSize')
    : 15;
};
