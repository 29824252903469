export const KpUi = (data) => {
  let html = ``;
  if (data?.services?.length > 0) {
    html += `<ul>`;
    data.services.map((item) => {
      html += ` <li>${item}</li>`;
    });
  }
  html += `</ul>`;

  return html;
};
