import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Card, Col, Divider, Dropdown, Row, Space } from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';

const ManagersListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>
              {props.manager?.manager?.full_name || '-'}
            </Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.manager)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.manager, props.menuActions]);

  return (
    <>
      <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={25}>
          <Col>
            <div className={'secondText'}>{'Группа'}</div>
            <div className={'valueText'}>{props.manager?.group_str || '-'}</div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

ManagersListItem.propTypes = {
  manager: PropTypes.object,
  menuActions: PropTypes.func,
};

export default ManagersListItem;
