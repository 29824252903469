import PropTypes from 'prop-types';
import { Col, Drawer, Form, Row, Spin, message, Button, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
const { TabPane } = Tabs;
import {
  createEvent,
  createEventMembers,
  createTagsOnEvent,
  deleteEventMembers,
  deleteTagOnEvent,
  editEvent,
  deleteEvent,
  getCalendarEventById,
} from '../callApiFuctions/tasks/tasksCallApi';
import moment from 'moment';
import { userInformations } from '../../../contex';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from '../../ErrorBoundary';
import { editCustomer } from '../../../logic/customer/CustomerApiFunctions';
import getMemberCreatorsEvent from './utils/getMemberCreatorsEvent';
import { editLead } from '../../../logic/lead/LeadApiFunctions';
import HeaderCalendarTaskDrawer from './calendarTaskDrawer/header/HeaderCalendarTaskDrawer';
import BodyCalendarTaskDrawer from './calendarTaskDrawer/body/BodyCalendarTaskDrawer';
import Chat from '../../chat/Chat';
import { globalStore } from '../../../store';
import { TodoActionTypes } from '../../../store/todo/chatNotification/chatNotificationTodo';
import { getTime } from '../calendarUtils/getTime';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const CalendarTaskModal = (props) => {
  const { userInfo } = useContext(userInformations);
  const [taskForm] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [visibleCheckboxGeneral, setVisibleCheckboxGeneral] = useState(false);
  const [editingItemFullDataTags, setEditingItemFullDataTags] = useState([]);
  const [editingItemFullDataMembers, setEditingItemFullDataMembers] = useState(
    [],
  );
  const [editFields, setEditFields] = useState(true);
  const [busyTaskButton, setBusyTaskButton] = useState(false);
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    if (props.editingItem) {
      setEditFields(false);
      taskForm.setFieldsValue({
        name: props.editingItem?.name,
        description: getDescriptionTask(props.editingItem),
        priority: props.editingItem?.priority || 0,
        deadline:
          props.editingItem?.lead || props.editingItem?.customer
            ? getTime(props.editingItem?.start, 'YYYY-MM-DD HH:mm')
            : getTime(props.editingItem?.end, 'YYYY-MM-DD HH:mm'),
        general: !!props.editingItem?.general,
        status: props.editingItem?.status ? props.editingItem.status : null,
        tags:
          props.editingItem?.tags?.length > 0
            ? getTagsIdsEvent(props.editingItem.tags)
            : [],
        members: props.editingItem?.members
          ? getMembersIdsEvent(props.editingItem.members)
          : [],
        memberCreator: props.editingItem?.members
          ? getMemberCreatorsEvent(props.editingItem.members)
          : [],
      });
      props.editingItem?.tags &&
        setEditingItemFullDataTags(props.editingItem.tags);
      props.editingItem?.members &&
        setEditingItemFullDataMembers(props.editingItem.members);
      if (props.editingItem?.customer?.date_next_request) {
        taskForm.setFieldsValue({
          date_next_request: moment(
            props.editingItem?.customer?.date_next_request,
          ).format('YYYY-MM-DD hh:mm'),
        });
      }

      if (props.editingItem?.lead?.date_next_request) {
        taskForm.setFieldsValue({
          date_next_request: moment(
            props.editingItem?.lead?.date_next_request,
          ).format('YYYY-MM-DD hh:mm'),
        });
      }
    }
  }, [props.editingItem]);

  useEffect(() => {
    if (!props.editingItem && props.defaultDate) {
      const newDate = props.defaultTime
        ? new Date(`${props.defaultDate} ${props.defaultTime}`)
        : new Date(`${props.defaultDate} 8:00`);
      taskForm.setFieldsValue({
        deadline: moment(newDate).format('YYYY-MM-DD HH:mm'),
      });
    }
  }, [props.defaultDate, props.defaultTime]);

  const handleCloseModal = () => {
    clearUpVariables();
    props.closeModal();
  };

  const clearUpVariables = () => {
    setEditingItemFullDataTags([]);
    setEditingItemFullDataMembers([]);
    setVisibleCheckboxGeneral(false);
    setEditFields(true);
    taskForm.resetFields();
  };

  const callDeleteEvent = async (eventId) => {
    if (!eventId) return;
    const res = await deleteEvent(eventId, userInfo);
    if (res) {
      globalStore.dispatch({
        type: TodoActionTypes.SET_WAS_VIEWING_MESSAGE,
        payload: true,
      });
      handleCloseModal();
    }
  };

  const callCreateEvent = async (setStatusReady) => {
    taskForm
      .validateFields()
      .then(async (values) => {
        const dateStart = Date.now();
        const dateEnd = new Date(`${values.deadline}`);

        if (
          values?.date_next_request &&
          (props.editingItem.lead?.id || props.editingItem?.customer?.id)
        ) {
          // Изменение даты следущего обращения у лида или клиента
          const result = await callChangeDateNextRequestCustomerOrLead(
            values.date_next_request,
          );
          if (!result) return;
        }

        const body = {
          name: values.name,
          description: values.description,
          priority: values.priority || 0,
          end: moment(dateEnd).format(),
          start: moment(dateStart).format(),
          members: values?.members?.length > 0 ? values.members : null,
          tags: values?.tags?.length > 0 ? values.tags : null,
          status: setStatusReady
            ? 'don'
            : values?.status
            ? values.status
            : 'ass',
        };

        if (visibleCheckboxGeneral) {
          body['general'] = !!values?.general;
        }
        if (props.editingItem) {
          delete body['members'];
          delete body['tags'];
        }
        if (props.editingItem?.lead?.id || props.editingItem?.customer?.id) {
          delete body['start'];
          delete body['end'];
          delete body['date_next_request'];
        }
        const res = props.editingItem
          ? await editEvent(props.editingItem?.id, body, userInfo)
          : await createEvent(body, userInfo);
        if (res) {
          setEditFields(false);
          if (setStatusReady) {
            props.setEditingTask(res);
            return;
          }
          handleCloseModal();
          props.handleUpdateTask();
        }
      })
      .catch((err) => {
        console.log('callCreateEvent err', err);
        handlePrintFormError(err);
      });
  };

  const handlePrintFormError = (err) => {
    if (err?.errorFields?.length <= 0) return;
    const objLabelsFields = {
      name: 'Название',
      description: 'Описание',
      status: 'Статус',
    };
    err?.errorFields.map((errField) => {
      if (errField?.name[0]) {
        message.warning(
          `${objLabelsFields[`${errField?.name[0]}`]} обязательное поле!`,
        );
      }
    });
  };
  const getTagsIdsEvent = (tags) => {
    if (tags?.length > 0) {
      return tags.map((tag) => {
        return tag?.tag_id;
      });
    }
  };

  const getMembersIdsEvent = (members) => {
    if (members?.length <= 0) return;
    return members
      .filter((member) => member?.group === 'mem')
      .map((member) => {
        return member?.staff_id;
      });
  };

  const getDescriptionTask = (editingItem) => {
    if (editingItem?.lead?.comment) {
      return editingItem.lead.comment;
    }
    if (editingItem?.customer?.comment) {
      return editingItem.customer.comment;
    }
    return editingItem.description;
  };

  const handleGetEventById = async (eventId, setTag, setMember) => {
    try {
      setLoading(false);
      if (!eventId) return;
      const event = await getCalendarEventById(eventId, userInfo);
      if (event?.tags && setTag) {
        taskForm.setFieldsValue({
          tags: getTagsIdsEvent(event?.tags),
        });
        setEditingItemFullDataTags(event?.tags);
      }
      if (event?.members && setMember) {
        taskForm.setFieldsValue({
          members: getMembersIdsEvent(event?.members),
        });
        setEditingItemFullDataMembers(event?.members);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const callCreateTagsOnEvent = async (tag) => {
    if (!props.editingItem) return;
    await createTagsOnEvent(
      { tag: tag, event: props.editingItem?.id },
      userInfo,
    );
    await handleGetEventById(props.editingItem?.id, true);
  };

  const callDeleteTagOnEvent = async (deleteTagId) => {
    if (editingItemFullDataTags) {
      const fullDeleteTagId = editingItemFullDataTags.find(
        (tag) => tag?.tag_id === deleteTagId,
      );
      if (!fullDeleteTagId?.id) return;
      await deleteTagOnEvent(fullDeleteTagId?.id, userInfo);
      await handleGetEventById(props.editingItem?.id, true);
    }
  };

  const callCreateMembersOnEvent = async (member) => {
    if (!props.editingItem) return;
    await createEventMembers(
      { staff: member, event: props.editingItem?.id },
      userInfo,
    );
    await handleGetEventById(props.editingItem?.id, false, true);
  };

  const callDeleteMembersOnEvent = async (memberId) => {
    if (!props.editingItem) return;
    if (editingItemFullDataMembers) {
      const fullDeleteStaffId = editingItemFullDataMembers.find(
        (member) => member?.staff_id === memberId && member.group === 'mem',
      );
      fullDeleteStaffId?.id &&
        (await deleteEventMembers(fullDeleteStaffId?.id, userInfo));
    }
    await handleGetEventById(props.editingItem?.id, false, true);
  };

  const callChangeDateNextRequestCustomerOrLead = async (date) => {
    if (!date) return;
    const dateNextRequest = date ? new Date(`${date}`) : null;
    const body = {
      date_next_request: moment(dateNextRequest).format(),
    };
    let res = null;
    if (props.editingItem.customer?.id) {
      res = await editCustomer(props.editingItem.customer?.id, body, userInfo);
    } else if (props.editingItem.lead?.id) {
      res = await editLead(props.editingItem.lead?.id, body, userInfo);
    }

    if (res?.data) {
      // props.setEditingTask(res?.data)
      return res?.data;
    }
  };

  const isCallToLeadOrCustomer = () => {
    return (
      props.editingItem &&
      (props.editingItem?.lead?.id || props.editingItem?.customer?.id)
    );
  };

  const handleRedirectToLeadOrCustomer = () => {
    if (!props.editingItem) return;

    if (props.editingItem?.lead?.id) {
      const state = {
        id: props.editingItem?.lead?.id,
      };
      navigate('/leeds', { state });
      return;
    }
    if (props.editingItem?.customer?.id) {
      const state = {
        id: props.editingItem?.customer?.id,
      };
      navigate('/all_customers', { state });
    }
  };

  const taskInfoCol = () => {
    return (
      <>
        <ErrorBoundary>
          <HeaderCalendarTaskDrawer
            form={taskForm}
            callCreateEvent={async () => await callCreateEvent()}
            editFields={editFields}
            setEditFields={(val) => setEditFields(val)}
            setEditingTask={props.setEditingTask}
            editingItem={props.editingItem}
            onCreateEventTag={async (tag) => await callCreateTagsOnEvent(tag)}
            onDeleteEventTag={async (tag) => await callDeleteTagOnEvent(tag)}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <BodyCalendarTaskDrawer
            form={taskForm}
            editFields={editFields}
            setEditFields={(val) => setEditFields(val)}
            visibleCheckboxGeneral={visibleCheckboxGeneral}
            editingItem={props.editingItem}
            setVisibleCheckboxGeneral={(val) => {
              setVisibleCheckboxGeneral(val);
            }}
            handleRedirectToLeadOrCustomer={() =>
              handleRedirectToLeadOrCustomer()
            }
            callDeleteEvent={async (eventId) => await callDeleteEvent(eventId)}
            callCreateEvent={async (setStatusReady) =>
              await callCreateEvent(setStatusReady)
            }
            isCallToLeadOrCustomer={isCallToLeadOrCustomer()}
            onCreateMembersOnEvent={async (member) =>
              await callCreateMembersOnEvent(member)
            }
            onDeleteMembersOnEvent={async (member) =>
              await callDeleteMembersOnEvent(member)
            }
          />
        </ErrorBoundary>
      </>
    );
  };

  const chatCol = () => {
    return (
      <>
        <ErrorBoundary>
          <Chat
            //chatIsCreated={true}
            chatId={props.editingItem?.chat ? props.editingItem?.chat : null}
            chatIsCreated={props.editingItem?.chat}
          />
        </ErrorBoundary>
      </>
    );
  };

  return (
    <>
      <Drawer
        className={'create-modal-in-drawer'}
        visible={props.visible}
        destroyOnClose={true}
        forceRender={false}
        title={props.editingItem ? 'Просмотреть задачу' : 'Добавить задачу'}
        height={'80%'}
        width={1200}
        bodyStyle={{ height: '80%', overflow: 'initial', padding: '0px' }}
        onClose={() => handleCloseModal()}
      >
        <Spin spinning={loading} style={{ height: '100%' }}>
          <Row
            className={
              props.editingItem?.chat ? 'chat-exist' : 'chat-not-exist'
            }
            style={{ minHeight: '100%' }}
          >
            {isMobile ? (
              <>
                <Tabs type="card" defaultActiveKey="1">
                  <TabPane
                    className={'CalendarTaskModal-task-tab'}
                    tabBarStyle={{ margin: '0px' }}
                    tab="Задача"
                    key="1"
                  >
                    {taskInfoCol()}

                    {editFields ? (
                      <Button
                        className={'CalendarTaskModal-create-mobile-button'}
                        onClick={async () => {
                          setBusyTaskButton(true);
                          await callCreateEvent();
                          setBusyTaskButton(false);
                        }}
                        type="default"
                        size="large"
                        disabled={busyTaskButton}
                      >
                        Подтвердить
                      </Button>
                    ) : (
                      <></>
                    )}
                  </TabPane>
                  {props.editingItem?.chat ? (
                    <>
                      <TabPane
                        className={'CalendarTaskModal-chat-tab'}
                        tabBarStyle={{ margin: '0px' }}
                        tab="Чат"
                        key="2"
                      >
                        {chatCol()}
                      </TabPane>
                    </>
                  ) : (
                    <></>
                  )}
                </Tabs>
              </>
            ) : (
              <>
                <Col
                  className={'task-planner'}
                  span={12}
                  style={{
                    maxHeight: '70vh',
                    height: '70vh',
                    overflowY: 'auto',
                  }}
                >
                  {taskInfoCol()}
                </Col>
                <Col span={12} className={'chat-column'}>
                  {chatCol()}
                </Col>
              </>
            )}
          </Row>
        </Spin>
      </Drawer>
    </>
  );
};

CalendarTaskModal.propTypes = {
  visible: PropTypes.bool,
  editingItem: PropTypes.any,
  defaultDate: PropTypes.any,
  defaultTime: PropTypes.any,
  closeModal: PropTypes.func,
  handleUpdateTask: PropTypes.func,
  setEditingTask: PropTypes.func,
  getEventById: PropTypes.func,
};

export default CalendarTaskModal;
