import React, { useCallback, useEffect } from 'react';
import { Result } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Box } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';

const ContinuationAuthorizationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.search) {
      const token = location?.search.split('?')[1];
      tokenReplacement(token);
    } else {
      navigate('/');
    }
  }, [location]);

  const tokenReplacement = useCallback(
    (token) => {
      if (!token) return;
      localStorage.removeItem('branchId');
      localStorage.removeItem('token');
      localStorage.setItem('token', token);
      navigate('/');
    },
    [navigate],
  );

  return (
    <>
      <Box
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Result
          icon={<LoadingOutlined style={{ color: 'var(--primary-color)' }} />}
          title="Подождите. Завершение авторизации"
        />
      </Box>
    </>
  );
};

export default ContinuationAuthorizationPage;
