import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';

export default async function getGroupList(userInfo) {
  try {
    const url = `${restApiHost}/accounts/groups/`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;

    return json;
  } catch (e) {
    console.log('group list fetch err', e);
    message.error('Ошибка запрос на получение групп');
  }
}

export async function getPermissions(userInfo) {
  try {
    const url = `${restApiHost}/accounts/permissions/?size=0`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    console.log('get permissions err', e);
    message.error('Ошибка получения списка прав доступа');
  }
}
export async function changeGroupPermissions(body, userInfo) {
  try {
    const url = `${restApiHost}/accounts/change_permission/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;
    message.success(
      body?.action === 'add' ? 'Успешно добавлено' : 'Успешно удалено',
    );
    return json;
  } catch (e) {
    console.log('change group err', e);
    message.error('Ошибка запроса на смену прав пользователя');
  }
}

export async function getPermissionsBySectionId(sectionId, keyword, userInfo) {
  const params = new URLSearchParams();
  params.append('sections__section_id', `${sectionId}`);
  params.append('size', '0');
  if (keyword) {
    params.append('search', `${keyword}`);
  }

  try {
    const url = `${restApiHost}/accounts/permissions/?${params}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json?.data) {
      return json.data;
    }
  } catch (e) {
    console.log('get permissions err', e);
    message.error('Ошибка получения списка прав доступа секции');
  }
}

export async function searchAdministrationPermissions(
  page,
  pageSize,
  userInfo,
  keyword,
) {
  let searchQuery = new URLSearchParams();

  if (page) {
    searchQuery.append('page', page);
  }
  if (pageSize) {
    searchQuery.append('size', pageSize);
  }
  if (keyword) {
    searchQuery.append('search', keyword.toLowerCase());
  }

  try {
    const url = `${restApiHost}/accounts/permissions/?${searchQuery}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo?.current?.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    console.log('call searchAdministrationPermissions error', e);
    message.error('Ошибка при поиске permissions');
  }
}

export async function getAdministrationPermissions(page, pageSize, userInfo) {
  try {
    const url = `${restApiHost}/administration/permissions/?page=${page}&size=${pageSize}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo?.current?.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    console.log('get permissions err', e);
    message.error('Ошибка получения списка прав доступа секции');
  }
}

export async function addSectionForPermission(body, userInfo) {
  try {
    const url = `${restApiHost}/administration/permission_sections/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    console.log('add permissions section err', e);
    message.error('Ошибка добавление секции к праву');
  }
}
export async function deleteSectionForPermission(
  permissionSectionId,
  userInfo,
) {
  try {
    const url = `${restApiHost}/administration/permission_sections/${permissionSectionId}`;
    const json = await call(
      url,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    console.log('delete permissions section err', e);
    message.error('Ошибка удаления секции у права');
  }
}

export async function updatePermission(permissionSectionId, body, userInfo) {
  try {
    const url = `${restApiHost}/administration/permissions/${permissionSectionId}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    console.log('patch permission err', e);
    message.error('Ошибка изменения права');
  }
}
