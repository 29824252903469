export const prepareAttachmentTypeForSelect = (attachmentTypeList) => {
  if (attachmentTypeList) {
    return Object.entries(attachmentTypeList)?.map((item) => {
      return {
        id: item[0] ? item[0] : null,
        title: item[1] ? item[1] : null,
      };
    });
  }
};
