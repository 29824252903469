import { Avatar, Button, message, Spin, Collapse, Tooltip, Tag } from 'antd';
import React, { useMemo, useState } from 'react';
import parse from 'html-react-parser';
import moment from 'moment';
import EmailBodyToolbar from './EmailBodyToolbar';
import { restApiHost } from '../../../utils/appVariables';
import { FileOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ErrorBoundary } from '../../ErrorBoundary';

const { Panel } = Collapse;

const DownloadFileBtn = ({ file, activeFolder, email, selectedStaff }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async (file) => {
    setLoading(true);
    try {
      const url =
        activeFolder === 'inbox' || activeFolder === 'Sent'
          ? `${restApiHost}/mail/message/get_attach/?folder=${activeFolder}${
              email?.uid ? `&uid=${email.uid}` : ''
            }${selectedStaff ? `&staff_user=${selectedStaff}` : ''}&msg_id=${
              email.msg_id
            }&date=${encodeURIComponent(email.date)}&attach_file=${file}`
          : `${restApiHost}/mail/get_attach/?attach_file=${file.id}`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json; charset=utf-8',
        },
      });

      if (res.ok) {
        const fileBlob = await res.blob();
        const blob = new Blob([fileBlob]);
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = file.filename || file;
        document.body.appendChild(a);
        a.click();
        message.success(`Файл ${file.filename || file} успешно скачан`);
      } else {
        message.error('Ошибка запроса на скачивание вложения');
      }

      setLoading(false);
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на скачивание вложения');
      setLoading(false);
    }
  };

  return (
    <Button
      style={{ marginRight: 10, marginBottom: 10 }}
      icon={<FileOutlined />}
      loading={loading}
      onClick={() => handleDownload(file)}
    >
      {file.filename || file}
    </Button>
  );
};

const EmailBody = ({
  selectedStaff,
  taskType,
  activeFolder,
  profileInfo,
  getMessagesFromFolder,
  handleShowBodyEmail,
  changeMessageSeen,
  loading,
  email,
  back,
  handleDeleteMessageInBody,
}) => {
  const toEmails = useMemo(() => {
    if (email?.to_email && email?.to_emails?.length > 0) {
      const filterToemailFromArray = email.to_emails
        .filter((item) => item.email !== email.to_email)
        .map((item) => `${item.email}<${item.contact_person}>`);
      const unique = [...new Set(filterToemailFromArray)];
      return unique;
    } else {
      return [];
    }
  }, [email]);

  const emailRecipientCopies = useMemo(() => {
    let emails = [];
    if (email?.cc?.length > 0) {
      emails = [...new Set(email.cc.split(', '))];
    }
    return emails;
  }, [email]);

  return (
    <Spin tip="Загрузка..." spinning={loading}>
      <ErrorBoundary>
        <EmailBodyToolbar
          activeFolder={activeFolder}
          taskType={taskType}
          back={back}
          email={email}
          profileInfo={profileInfo}
          selectedStaff={selectedStaff}
          getMessagesFromFolder={getMessagesFromFolder}
          handleShowBodyEmail={handleShowBodyEmail}
          changeMessageSeen={changeMessageSeen}
          handleDeleteMessageInBody={handleDeleteMessageInBody}
        />
      </ErrorBoundary>
      <div className={'email-body'}>
        <div style={{ borderBottom: '1px solid #ddd' }}>
          <div style={{ display: 'flex' }}>
            <Avatar
              style={{
                fontSize: 30,
                height: 70,
                width: 70,
                lineHeight: '70px',
                marginRight: 5,
              }}
            >
              {email.contact_person.slice(0, 1).toUpperCase()}
            </Avatar>
            <div>
              {email.date ? (
                <div className="email-message__item-date">
                  {moment(email.date).format('dddd')}{' '}
                  {moment(email.date).format('L')}{' '}
                  {moment(email.date).format('LT')}
                </div>
              ) : (
                <div className="email-message__item-date">
                  {moment().format('dddd')} {moment().format('L')}{' '}
                  {moment().format('LT')}
                </div>
              )}
              <div
                style={{ display: 'flex', alignItems: 'center', fontSize: 20 }}
              >
                <Tooltip
                  placement="topRight"
                  title={
                    email?.contact_person
                      ? email.contact_person.toUpperCase()
                      : 'НЕИЗВЕСТНЫЙ'
                  }
                >
                  <span
                    style={{
                      maxWidth: 400,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {email?.contact_person
                      ? email.contact_person.toUpperCase()
                      : 'НЕИЗВЕСТНЫЙ'}
                  </span>
                </Tooltip>
                <div
                  className="email-message__item-date"
                  style={{ marginLeft: 10 }}
                >
                  {email.from_email}
                </div>
              </div>
              <b>{email.subject === 'None' ? 'Без темы' : email.subject}</b>
            </div>
          </div>
          <div style={{ margin: '20px 0' }}>
            {toEmails.length > 0 ? (
              <Collapse ghost>
                <Panel
                  header={`Кому: ${
                    email?.to_email ? email.to_email : 'Без email-а'
                  } и еще +${toEmails.length}`}
                  key="1"
                >
                  {toEmails.map((item, index) => (
                    <Tag key={index}>{item}</Tag>
                  ))}
                </Panel>
              </Collapse>
            ) : (
              <div style={{ display: 'flex' }}>
                <p>Кому:</p>
                <div style={{ marginLeft: 5 }}>
                  <Avatar size="small" style={{ marginRight: 5 }}>
                    {email.to_email
                      ? email.to_email.slice(0, 1).toUpperCase()
                      : '!'}
                  </Avatar>
                  {email?.to_email ? email.to_email : 'Без email-а'}
                </div>
              </div>
            )}
            {emailRecipientCopies.length > 0 && (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <p>Копии:</p>
                {emailRecipientCopies.map((email, index) => {
                  return (
                    <div key={index} style={{ marginLeft: 5 }}>
                      <Avatar size="small" style={{ marginRight: 5 }}>
                        {email ? email.slice(0, 1).toUpperCase() : '!'}
                      </Avatar>
                      {email ? email : 'Без email-а'}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {email?.attached_files?.length > 0 && (
            <div style={{ borderTop: '1px solid #ddd', padding: '10px 0' }}>
              {email.attached_files.map((item, index) => {
                return (
                  <ErrorBoundary key={index}>
                    <DownloadFileBtn
                      key={item.id}
                      file={item}
                      activeFolder={activeFolder}
                      email={email}
                      selectedStaff={selectedStaff}
                    />
                  </ErrorBoundary>
                );
              })}
            </div>
          )}
          {!taskType && email?.related_task?.length > 0 && (
            <Collapse ghost className={'collapse-email'} defaultActiveKey={'1'}>
              <Panel
                header={`Привязанных сделок ${email.related_task.length}`}
                key="1"
              >
                {email.related_task.map((item) => {
                  return item?.number_task ? (
                    <Link to={'/applications'} state={item}>
                      {item?.number_task}{' '}
                      {item?.customer?.short_name || item?.customer?.legal_name}
                    </Link>
                  ) : (
                    <Link to={'/pre-applications'} state={item}>
                      {item.pre_task_number} {item?.lead?.company_name}
                    </Link>
                  );
                })}
              </Panel>
            </Collapse>
          )}
        </div>
        {email?.body && (
          <div style={{ marginTop: 10, maxHeight: 400, overflow: 'auto' }}>
            {parse(email?.body)}
          </div>
        )}
      </div>
    </Spin>
  );
};

export default EmailBody;
