import PropTypes from 'prop-types';
import { Dropdown, Menu, message, Popconfirm, Space, Table } from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import ManagerModal from './modal/ManagerModal';
import { ErrorBoundary } from '../../../../../../ErrorBoundary';
import { deleteShippingTaskManager } from '../../../../../../../logic/task/TaskApiFunctions';
import { userInformations } from '../../../../../../../contex';
import ScrollingLoadedList from '../../../../../../mobile/scrollingLoadedList/ScrollingLoadedList';
import ManagersListItem from '../../../../../../mobile/leads/leadModal/ManagersListItem';
import useCheckMobileScreen from '../../../../../../../hooks/useCheckMobileScreen';
import SearchDataFilter from '../../../../../../leeds/filters/SearchData';
import useStateRef from 'react-usestateref';
import {
  changeColumnSizeInColumnsGroup,
  changeSizeColumn,
  prepareColumnsForResizing,
  sortingColumnsTable,
} from '../../../../../../settingTable/sortingColumnsTable';
import { editTableColumn } from '../../../../../../../logic/profileInfo/mainTable/MainTableApiFunctions';
import { getProfileInfo } from '../../../../../../../logic/account/AccountsApiFunctions';
import { userInfoHaveViewAllBranch } from '../../../../../../../logic/account/utils';
import filterActiveColumns from '../../../../../../table/utils';
import ResizableTableColumnHeader from '../../../../../../table/ResizableTableColumnHeader';
import changeWidthStyleMainTable from '../../../../../../table/changeWidthStyleMainTable';
import FilterSelect from '../../../../../../leeds/filters/FilterSelect';
import { getFiltersTable } from '../../../../../../../utils/getFilters';
import UniversalFiltersModal from '../../../../../../mobile/filtersModal/UniversalFiltersModal';

const ManagersTable = (props) => {
  const tableName = 'tasks.staffs';
  const { userInfo } = useContext(userInformations);
  const [editingManager, setEditingManager] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const isMobile = useCheckMobileScreen();
  const [baseColumns, setBaseColumns, baseColumnsRef] = useStateRef([]);
  const mainTableRef = useRef(null);
  const [
    ,
    setColumnFiltersFromTableComponent,
    columnFiltersFromTableComponentRef,
  ] = useStateRef([]);

  useEffect(() => {
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      props.visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );
    setBaseColumns(preparedColumnsForResizing);
  }, [props.visibleFilters]);

  useEffect(() => {
    if (!props.tableFilters) {
      setColumnFiltersFromTableComponent([]);
    }
  }, [props.tableFilters]);

  const handleEditManager = useCallback(
    (managerRow) => {
      setEditingManager(managerRow);
      setVisibleModal(true);
    },
    [setEditingManager, setVisibleModal],
  );

  const handleCloseModal = useCallback(async () => {
    setVisibleModal(false);
    props.handleUpdateList(props.tableFilters);
  }, [setVisibleModal, props.tableFilters]);

  const handleDeleteManager = useCallback(
    async (managerId) => {
      const res = await deleteShippingTaskManager(managerId, userInfo);
      if (res) {
        message.success('Менеджер успешно удален');
        props.handleUpdateList(props.tableFilters);
      }
    },
    [props.handleUpdateList, props.tableFilters],
  );

  const handleResize = useCallback(
    (index) =>
      async (e, { size, uniqueIndex, isGroupItem }) => {
        let databaseColumnsId = null;
        const copyColumns = [...baseColumnsRef.current];

        if (!isGroupItem) {
          setBaseColumns((columns) => {
            return changeSizeColumn(columns, index, size);
          });

          databaseColumnsId = copyColumns[index]?.idInDatabase;
        } else {
          setBaseColumns((columns) => {
            const { nextColumns, idInDatabase } =
              changeColumnSizeInColumnsGroup(columns, index, uniqueIndex, size);
            databaseColumnsId = idInDatabase;
            return nextColumns;
          });
        }

        if (!databaseColumnsId) return;

        await editTableColumn(databaseColumnsId, { width: size }, userInfo);
        const res = await getProfileInfo(userInfo);
        if (res) {
          userInfo.current = res;
          const haveViewBranch = userInfoHaveViewAllBranch(res);
          if (haveViewBranch) {
            userInfo.current['haveViewBranch'] = true;
          }
        }
      },
    [baseColumnsRef.current],
  );

  const filteringColumnsChildrenPillarByVisible = useMemo(() => {
    const copyColumns = [...baseColumns];
    return filterActiveColumns(copyColumns);
  }, [baseColumns]);

  const mainTableWidth = useMemo(() => {
    const copyColumns = [...baseColumns];
    let filteredColumns = filterActiveColumns(copyColumns);
    return filteredColumns.reduce((n, { width }) => n + width, 0);
  }, [baseColumns]);

  const getMainTableWidth = useCallback(() => {
    return mainTableWidth;
  }, [mainTableWidth]);

  useEffect(() => {
    if (mainTableRef.current.children[0]) {
      changeWidthStyleMainTable(mainTableRef, mainTableWidth);
    }
  }, [mainTableWidth]);

  const menu = (manager) => {
    return (
      <Menu style={{ width: 200 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => {
            handleEditManager(manager);
          }}
        >
          Редактировать
        </Menu.Item>

        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={async () => await handleDeleteManager(manager?.id)}
          onCancel={() => console.log('Delete item')}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const handleTableChange = (filtersTable) => {
    const { filters } = getFiltersTable({ current: 1 }, filtersTable);
    setColumnFiltersFromTableComponent(filtersTable);
    props.handleUpdateList(filters);
  };

  const columns = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      index: 2,
      uniqueIndex: 'action',
      render: (manager) => (
        <Space size="middle">
          <Dropdown overlay={menu(manager)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      width: 50,
      dataIndex: 'id',
      uniqueIndex: 'id',
      index: 4,
      children: [
        {
          title: '',
          visible: props.visibleFilters,
          key: 'id',
          width: 50,
          dataIndex: 'id',
          uniqueIndex: 'id',
          index: 4,
        },
      ],
    },
    {
      title: 'Группа',
      key: 'group_str',
      dataIndex: 'group_str',
      width: 150,
      uniqueIndex: 'group_str',
      index: 4,
      children: [
        {
          title: (
            <FilterSelect
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              columnKey={'group'}
              selectedKeys={columnFiltersFromTableComponentRef.current?.group}
              dataList={[
                { title: 'Ведет', value: 'vdt' },
                { title: 'Ответственный', value: 'otv' },
              ]}
            />
          ),
          visible: props.visibleFilters,
          key: 'group_str',
          dataIndex: 'group_str',
          width: 150,
          uniqueIndex: 'group_str',
          index: 4,
        },
      ],
    },
    {
      title: 'Менеджер',
      key: 'manager',
      dataIndex: 'manager',
      width: 200,
      uniqueIndex: 'manager',
      index: 6,
      render: (manager) => manager?.full_name,
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Менеджер'}
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.manager__full_name__icontains
              }
              columnKey={'manager__full_name__icontains'}
            />
          ),
          visible: props.visibleFilters,
          key: 'manager',
          dataIndex: 'manager',
          width: 200,
          uniqueIndex: 'manager',
          index: 6,
          render: (manager) => manager?.full_name,
        },
      ],
    },
  ];

  return (
    <>
      <div ref={mainTableRef} style={{ position: 'relative' }}>
        {!isMobile ? (
          <Table
            style={{
              position: 'relative',
              zIndex: '0',
            }}
            scroll={{ x: getMainTableWidth() }}
            dataSource={props.managersDataList}
            columns={filteringColumnsChildrenPillarByVisible}
            rowClassName={(record, index) =>
              index % 2 === 0
                ? 'black-text table-row-color'
                : 'black-text table-row-white'
            }
            size="small"
            bordered={true}
            pagination={{
              position: ['none'],
              size: 'middle',
              showSizeChanger: false,
            }}
            sticky={true}
            onRow={(managerRow) => {
              return {
                onDoubleClick: () => {
                  handleEditManager(managerRow);
                },
              };
            }}
            components={{
              header: {
                cell: ResizableTableColumnHeader,
              },
            }}
          />
        ) : (
          <>
            <div style={{ paddingTop: '5px' }}>
              <ErrorBoundary>
                <ScrollingLoadedList
                  dataList={props.managersDataList}
                  totalData={props.managersDataList?.length}
                  loadMoreItems={async () => {
                    //handleUpdate(page + 1, filters, false,true)
                  }}
                  renderListItem={(item) => (
                    <ManagersListItem
                      manager={item}
                      menuActions={(manager) => menu(manager)}
                    />
                  )}
                />
              </ErrorBoundary>
            </div>
          </>
        )}
      </div>
      <ErrorBoundary>
        <ManagerModal
          editingManager={editingManager}
          taskData={props.taskData}
          visible={visibleModal}
          choices={props.choices}
          closeModal={async () => await handleCloseModal()}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <UniversalFiltersModal
          visibleModal={props.visibleFilters && isMobile}
          handleClose={() => {
            props.setVisibleFilters(false);
          }}
          resetFilters={async () => {
            props.setVisibleFilters(false);
            await props.handleUpdateList(null);
          }}
          columnsList={columns}
        />
      </ErrorBoundary>
    </>
  );
};

export default ManagersTable;

ManagersTable.propTypes = {
  managersDataList: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  taskData: PropTypes.object.isRequired,
  choices: PropTypes.array,
  visibleFilters: PropTypes.bool,
  tableFilters: PropTypes.any,
  setVisibleFilters: PropTypes.func,
  handleUpdateList: PropTypes.func.isRequired,
};
