import { List } from 'antd';
import CardSection from '../../card/CardSection';
import PropTypes from 'prop-types';

const ListSections = (props) => {
  return (
    <div style={{ marginTop: '50px' }}>
      {props.sectionsList?.length > 0 ? (
        <List
          grid={{
            gutter: 16,
            column: 4,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 4,
          }}
          size="small"
          dataSource={props.sectionsList}
          renderItem={(item, key) => (
            <List.Item>
              <CardSection
                key={key}
                item={item}
                onSelected={(item) => props.onSelectedSection(item)}
              />
            </List.Item>
          )}
        />
      ) : (
        <>
          <List.Item>
            <span style={{ color: 'black' }}>Разделы отсутствуют</span>
          </List.Item>
        </>
      )}
    </div>
  );
};

ListSections.propTypes = {
  sectionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectedSection: PropTypes.func,
};

export default ListSections;
