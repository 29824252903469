import NavItem from '../../NavItem';
import { Dropdown, Menu } from 'antd';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { EyeInvisibleOutlined } from '@ant-design/icons';

const NavItemWithContextMenu = (props) => {
  const [visibleBtnShow, setVisibleBtnShow] = useState(false);

  const menu = () => {
    return (
      <Menu style={{ width: 200 }}>
        <div onMouseLeave={() => setVisibleBtnShow(false)}>
          <Menu.Item
            icon={<EyeInvisibleOutlined />}
            onClick={() => {
              props.handleChangeVisibleSection(
                props.itemMenu?.section_id,
                false,
              );
              setVisibleBtnShow(false);
            }}
          >
            Скрыть
          </Menu.Item>
        </div>
      </Menu>
    );
  };

  const handleChangeVisibleContextMenu = useCallback(
    (value) => {
      setVisibleBtnShow(value);
    },
    [setVisibleBtnShow],
  );

  return (
    <>
      <Dropdown
        overlay={props.itemMenu?.href !== 'account' ? menu(props.itemMenu) : ''}
        visible={visibleBtnShow}
        onVisibleChange={(value) => handleChangeVisibleContextMenu(value)}
        trigger={['contextMenu']}
      >
        <div
          onMouseEnter={() => setVisibleBtnShow(false)}
          onClick={() => {
            props.onClick && props.onClick();
          }}
        >
          <NavItem
            href={`/${props.itemMenu.href}`}
            key={props.itemMenu?.section}
            title={props.itemMenu?.section}
            iconName={props.itemMenu?.icon}
            blocked={props.itemMenu?.blocked}
            onlyIcon={props.closeFullMenu}
          />
        </div>
      </Dropdown>
    </>
  );
};

NavItemWithContextMenu.propTypes = {
  itemMenu: PropTypes.object,
  onClick: PropTypes.func,
};

export default NavItemWithContextMenu;
