import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { message } from 'antd';

export async function getListTags(userInfo) {
  try {
    const url = `${restApiHost}/refbook/calendar_tags/?active=true`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );
    if (!json) return;
    return json;
  } catch (e) {
    console.log('call getListTags error', e);
    message.error('Ошибка запроса на получение списка тэгов');
  }
}

export async function searchListTags(keyword, userInfo) {
  try {
    const url = `${restApiHost}/refbook/calendar_tags/?active=true&search=${keyword}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );
    if (!json) return;
    return json;
  } catch (e) {
    console.log('call searchListTags error', e);
    message.error('Ошибка запроса на поиск тэгов');
  }
}
