import getBlobFromUrl from './genereteContracts/getBlobFromUrl';

export async function getDocxContractFileFormUrl(contractDocxUrl, filename) {
  try {
    const contentType =
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    const blob = await getBlobFromUrl(contractDocxUrl);
    const fileDocx = new File([blob], filename, { type: contentType });
    return fileDocx;
  } catch (e) {
    throw new Error(e);
  }
}
