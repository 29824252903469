import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Menu,
  Dropdown,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { restApiHost } from '../../utils/appVariables';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { priceFormatter } from '../../utils/priceFormatter';
import { userInformations } from '../../contex';
import { call } from '../../apiUtils/call';
import { showConfirm } from '../UiComponents/showConfirn/showConfirn';
import {
  getPreTaskCosts,
  getPreTaskTransportCargo,
} from '../../logic/preTasks/PreTaskApiFunctions';
import useAsyncEffect from 'use-async-effect';
import { getListOperationWaypoint } from '../../logic/refbook/operationWaypoint/OperationWaypointApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { ErrorBoundary } from '../ErrorBoundary';
import ScrollingLoadedList from '../mobile/scrollingLoadedList/ScrollingLoadedList';
import ServicesListItem from '../mobile/preTasks/modal/ServicesListItem';

const { Option } = Select;

const PreAddServicesTable = ({
  data,
  preTaskId,
  //upfrontSum,
  refresh,
  preTask,
  currentTab,
}) => {
  const [visiblePreCostModal, setVisiblePreCostModal] = useState(false);
  const [visiblePreCostModalEdit, setVisiblePreCostModalEdit] = useState(false);
  const [costForm] = Form.useForm();
  const [costFormEdit] = Form.useForm();
  const [services, setServices] = useState([]);
  const [operations, setOperations] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);
  const [costs, setCosts] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [currEditCost, setCurrEditCost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [, setInCurrency] = useState(false);
  const [firstTransport, setFirstTransport] = useState(null);
  const [transportList, setTransportList] = useState([]);
  const [transportListIsEmpty, setTransportListIsEmpty] = useState(true);
  const { userInfo } = useContext(userInformations);
  const [isImportTemplete, setIsImportTemplete] = useState(true);
  const [disableBtnValidate, setDisableBtnValidate] = useState(false);
  const [price, setPrice] = useState({
    value: '0.00',
    validateStatus: '',
  });
  const isMobile = useCheckMobileScreen();

  /* useAsyncEffect(async () => {
        await callGetCosts(preTaskId)
    },[])*/

  useAsyncEffect(async () => {
    if (currentTab === '3') {
      await callGetTransportation(preTaskId);
      await callGetCosts(preTaskId);
    }
  }, [currentTab]);

  useEffect(() => {
    if (data) {
      setCosts(data);
    }
  }, [data]);

  useAsyncEffect(async () => {
    if (visiblePreCostModal || visiblePreCostModalEdit) {
      await getTemplatesOperation();
    }
  }, [visiblePreCostModal, visiblePreCostModalEdit]);

  useEffect(() => {
    if (
      preTask?.template?.short !== 'ИП' &&
      preTask?.template?.short !== 'ОП-Э'
    ) {
      setIsImportTemplete(false);
    } else {
      setIsImportTemplete(true);
    }
  }, [preTask]);

  useEffect(() => {
    if (transportList !== null && transportList.length !== 0) {
      if (transportList.length > 1) {
        setFirstTransport(null);
      } else {
        setFirstTransport(transportList[0]);
      }
      setTransportListIsEmpty(false);
    } else {
      setTransportListIsEmpty(true);
      setDisableBtn(true);
    }
  }, [transportList]);

  const callGetTransportation = useCallback(
    async (taskId) => {
      setLoading(true);
      const res = await getPreTaskTransportCargo(taskId, userInfo);
      if (res?.data) {
        setTransportList(res.data);
      }
      setLoading(false);
    },
    [getPreTaskTransportCargo, setTransportList],
  );

  const callGetCosts = useCallback(
    async (taskId) => {
      setLoading(true);
      const res = await getPreTaskCosts(taskId, userInfo);
      if (res?.data) {
        setCosts(res.data);
      }
      setLoading(false);
    },
    [getPreTaskTransportCargo, setCosts, setLoading],
  );

  const handleGetListOperationWaypoint = useCallback(
    async (keyword) => {
      const res = await getListOperationWaypoint(userInfo, keyword);
      if (res?.data) {
        setOperations(res.data);
      }
    },
    [setOperations],
  );

  const getTemplatesOperation = async () => {
    try {
      const url = `${restApiHost}/refbook/operation_waypoint/?active=true`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setOperations(json.data);
      }
    } catch (e) {
      setOperations([]);
      console.log('get template operations error', e);
      message.error('Ошибка запроса для получения шаблонов операций!');
    }
  };

  const deleteCost = async (id) => {
    try {
      const url = `${restApiHost}/leads/pre_upfront_costs/${id}/`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;

      message.success('Успешно удалено');
      refresh && preTaskId && refresh(preTaskId);
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на удаление!');
    }
  };

  const menu = (costs) => {
    return (
      <Menu style={{ width: 200 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={async () => {
            costs && costs.service && (await searchService(costs.service.name));
            costs?.in_currency && setInCurrency(true);
            setCurrEditCost(costs);
            setVisiblePreCostModalEdit(true);
            costFormEdit.setFieldsValue({
              ...costs,
              transport: costs?.transport?.id,
              customer: costs?.customer?.id,
              in_currency: costs?.in_currency,
              operation: costs?.operation?.id,
              service: costs?.service?.id,
            });
            await handleGetListOperationWaypoint(costs?.operation?.name);
          }}
        >
          Редактировать
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={() => {
            costs && deleteCost(costs.id);
          }}
          onCancel={() => console.log('Delete item')}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };

  const columns = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (costs) => (
        <Space size="middle">
          <Dropdown overlay={menu(costs)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    // {
    //     title: 'Перевозка',
    //     key: 'type_transport',
    //     ellipsis: {
    //         showTitle: false
    //     },
    //     width: 150,
    //     render: (data) => data?.transport?.transport?.number_container || ''
    // },
    {
      title: 'Операция',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'center',
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (operation) =>
        operation?.name ? (
          <Tooltip title={operation.name} placement={'topLeft'}>
            {operation.name}
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      title: 'Наименование',
      dataIndex: 'service',
      key: 'service',
      fixed: 'center',
      ellipsis: {
        showTitle: false,
      },
      render: (service) =>
        service?.name ? (
          <Tooltip title={service.name} placement={'topLeft'}>
            {service.name}
          </Tooltip>
        ) : (
          ''
        ),
      width: 190,
    },
    // {
    //     title: 'Поставщик',
    //     key: 'customer',
    //     dataIndex: 'customer',
    //     ellipsis: {
    //         showTitle: false,
    //     },
    //     width: 200,
    //     render: (customer) => customer?.legal_name ?
    //         <Tooltip title={customer.legal_name} placement={'topLeft'}>{customer.short_name || customer.legal_name}</Tooltip> : ''
    // },
    {
      title: 'Валюта',
      fixed: 'center',
      children: [
        {
          title: 'Вал.',
          key: 'currency',
          dataIndex: 'currency',
          render: (currency) =>
            currency === 'rub'
              ? 'Рубли'
              : currency === 'dol'
              ? 'Доллар'
              : currency === 'eur'
              ? 'Евро'
              : currency === 'cny'
              ? 'Юань '
              : null,
          width: 100,
        },
      ],
    },
    {
      title: 'Цена',
      fixed: 'center',
      children: [
        {
          title: 'Вал.',
          key: 'price_currency',
          width: 150,
          render: (data) =>
            data?.in_currency
              ? priceFormatter(+data.price_currency)
              : priceFormatter(data.price),
        },
      ],
    },
    {
      title: 'Единицы',
      fixed: 'center',
      children: [
        {
          title: 'Кол-во транспортной ед.',
          key: 'count',
          width: 140,
          render: (data) =>
            data?.transport?.transport?.count
              ? data.transport.transport.count
              : 1,
        },
        {
          title: 'Кол-во услуг',
          key: 'count_services',
          dataIndex: 'count_services',
          width: 140,
        },
        {
          title: 'Ед. изм.',
          dataIndex: 'service',
          key: 'service',
          render: (service) => (service ? service.units : ''),
          width: 150,
        },
      ],
    },
    {
      title: 'НДС',
      fixed: 'center',
      children: [
        {
          title: 'Ставка',
          dataIndex: 'vat_rate_str',
          key: 'vat_rate_str',
          width: 150,
        },
        {
          title: 'Валюта',
          key: 'sum_vat',
          dataIndex: 'sum_vat',
          width: 150,
        },
      ],
    },
    {
      title: 'Всего',
      fixed: 'center',
      children: [
        {
          title: 'Всего',
          key: 'sum_currency',
          width: 150,
          render: (data) =>
            data?.in_currency
              ? priceFormatter(data?.sum_currency?.sum_with_vat || 0)
              : priceFormatter(data.sum_with_vat || 0),
        },
      ],
    },
  ];

  const searchService = async (keyword) => {
    setSearchLoading(true);
    try {
      const url = `${restApiHost}/refbook/service/?search=${keyword}&active=true`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setServices(json?.data);
      }
    } catch (e) {
      setSearchLoading(false);
      console.log('search service err', e);
      message.error('Ошибка запроса для поиска услуги');
    } finally {
      setSearchLoading(false);
    }
  };

  const saveCost = async (body) => {
    try {
      const url = `${restApiHost}/leads/pre_upfront_costs/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      json && setCosts([...costs, json]);
      setVisiblePreCostModal(false);
      setDisableBtn(true);
      costForm.resetFields();
      refresh(preTaskId);
      message.success('Операция прошла успешно');
    } catch (e) {
      console.log('save error', e);
      message.error('');
    }
  };

  const edit = async (id, body) => {
    try {
      const url = `${restApiHost}/leads/pre_upfront_costs/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      const newCosts = [...costs];
      const objIndex = newCosts.findIndex((obj) => obj.id == id);
      newCosts[objIndex] = { ...json };
      setCosts(newCosts);
      setVisiblePreCostModalEdit(false);
      setDisableBtn(true);
      refresh(preTaskId);
      message.success('Редактирование прошло успешно!');
    } catch (e) {
      console.log('edit err', e);
    }
  };

  const handleCancelEditModal = () => {
    setVisiblePreCostModalEdit(false);
    setInCurrency(false);
    setDisableBtn(true);
    costFormEdit.resetFields();
  };

  const handleSubmitEditModal = () => {
    costFormEdit
      .validateFields()
      .then((values) => {
        let body = {
          ...values,
          in_currency: true,
          service: JSON.parse(values.service).id,
          task: preTaskId,
        };
        currEditCost && edit(currEditCost.id, body);
        setInCurrency(false);
      })
      .catch(() => setDisableBtn(true));
  };

  const openModalCreate = () => {
    setVisiblePreCostModal(true);
    costForm.setFieldsValue({
      currency: 'rub',
      price_currency: '0.00',
    });
  };

  const validatePrice = (price) => {
    if (price) {
      const value = price.target.value.replace(/,/g, '.');
      if (value < 0) {
        setDisableBtnValidate(true);
        return {
          validateStatus: 'error',
          errorMsg: 'Цена меньше 0',
        };
      } else if (Math.abs(Number(Number(value).toFixed(2) - value)) !== 0) {
        setDisableBtnValidate(true);
        return {
          validateStatus: 'error',
          errorMsg: 'Округлите до сотых',
        };
      } else {
        setDisableBtnValidate(false);
        return {
          validateStatus: 'success',
          errorMsg: '',
        };
      }
    }
  };

  const onPriceChange = (value) => {
    setPrice({
      ...validatePrice(value),
      value,
    });
  };
  return (
    <>
      <div className={'top-table-actions'}>
        <Tooltip placement={'topLeft'} title={'Добавить'}>
          <Button
            type="primary"
            size={'small'}
            icon={<PlusOutlined />}
            onClick={() => openModalCreate()}
          />
        </Tooltip>
      </div>

      <Modal
        visible={visiblePreCostModalEdit}
        title="Редактирование"
        onCancel={() => {
          setDisableBtn(true);
          showConfirm(
            disableBtn ? handleCancelEditModal : handleSubmitEditModal,
            handleCancelEditModal,
          );
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setDisableBtn(true);
              showConfirm(
                disableBtn ? handleCancelEditModal : handleSubmitEditModal,
                handleCancelEditModal,
              );
              setPrice({
                value: '0.00',
                validateStatus: '',
              });
            }}
          >
            Закрыть
          </Button>,
          <Button
            disabled={disableBtn || disableBtnValidate}
            key="submit"
            type="primary"
            onClick={() => {
              costFormEdit.validateFields().then(async (values) => {
                if (firstTransport || !transportListIsEmpty) {
                  let body = {
                    ...values,
                    in_currency: true,
                    //service: JSON.parse(values.service).id,
                    task: preTaskId,
                  };
                  if (firstTransport) {
                    body['transport'] = firstTransport?.id;
                  }
                  currEditCost && (await edit(currEditCost.id, body));
                  setInCurrency(false);
                } else {
                  message.warning('Не добавлена перевозка');
                }
              });
              setPrice({
                value: '0.00',
                validateStatus: '',
              });
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form
          form={costFormEdit}
          layout={'vertical'}
          onValuesChange={() => {
            setTimeout(() => {
              costFormEdit
                .validateFields()
                .then(() => setDisableBtn(false))
                .catch(() => setDisableBtn(true));
            }, 0);
          }}
        >
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item name={'operation'} label={'Операция'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  // defaultValue={record && record.operation ? record.operation.id : ''}
                  filterOption={null}
                  onSearch={async (keyword) => {
                    await handleGetListOperationWaypoint(keyword);
                  }}
                  placeholder="Поиск операции"
                >
                  {operations && operations.length > 0 ? (
                    <>
                      {operations.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </>
                  ) : (
                    <Option disabled value="Загрузка">
                      Нет данных
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name={'service'} label={'Услуга'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  loading={searchLoading}
                  filterOption={false}
                  onSearch={async (value) => await searchService(value)}
                  placeholder="Найти услугу"
                >
                  {services && services?.length > 0 ? (
                    <>
                      {services.map((item) => {
                        return (
                          <Option key={item.id} value={item?.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </>
                  ) : null}
                </Select>
              </Form.Item>
            </Col>
            {transportList && transportList?.length > 1 ? (
              <Col xs={24} lg={12}>
                <Form.Item
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                  name={'transport'}
                  label={'Транспорт'}
                >
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    placeholder={'Выберите транспорт с заявки'}
                  >
                    {transportList?.length > 0 ? (
                      transportList.map((item, index) => {
                        return (
                          <Option key={index} value={item?.id}>
                            {item?.transport?.transport_unit?.type_full}
                          </Option>
                        );
                      })
                    ) : (
                      <Option disabled>Нет доступных вариантов</Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            {isImportTemplete ? (
              <Col xs={24} lg={6}>
                <Form.Item name={'currency'} label={'Валюта'}>
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    defaultValue={'rub'}
                  >
                    <Option value={'rub'}>Рубли</Option>
                    <Option value={'dol'}>Доллар</Option>
                    <Option value={'eur'}>Евро</Option>
                    <Option value={'cny'}>Юань</Option>
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            <Col xs={24} lg={6}>
              <Form.Item
                name={'price_currency'}
                label={'Цена за ед.'}
                help={price.errorMsg}
                validateStatus={price.validateStatus}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <Input
                  value={price.value}
                  onChange={(val) => onPriceChange(val)}
                  onFocus={() => {
                    const value = costFormEdit.getFieldsValue([
                      'price_currency',
                    ]);
                    if (value.price_currency === '0.00') {
                      costFormEdit.setFieldsValue({
                        price_currency: '',
                      });
                    }
                  }}
                  onBlur={() => {
                    {
                      const value = costFormEdit.getFieldsValue([
                        'price_currency',
                      ]);
                      if (!value.price_currency.trim()) {
                        costFormEdit.setFieldsValue({
                          price_currency: '0.00',
                        });
                      }
                    }
                  }}
                  placeholder={'Введите цену за ед.'}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={6}>
              <Form.Item name={'count_services'} label={'Кол-во услуг'}>
                <InputNumber
                  placeholder={'Количество'}
                  defaultValue={1}
                  min={1}
                  max={100000}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name={'vat_rate'} label={'НДС'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  placeholder={'НДС'}
                >
                  <Option value={null}>Без НДС</Option>
                  <Option value={0}>0%</Option>
                  <Option value={20}>20%</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        visible={visiblePreCostModal}
        title="Добавить"
        onOk={() => {
          setVisiblePreCostModal(false);
          setInCurrency(false);
          setDisableBtn(true);
          costForm.resetFields();
          setPrice({
            value: '0.00',
            validateStatus: '',
          });
        }}
        onCancel={() => {
          setVisiblePreCostModal(false);
          setInCurrency(false);
          setDisableBtn(true);
          costForm.resetFields();
          setPrice({
            value: '0.00',
            validateStatus: '',
          });
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisiblePreCostModal(false);
              setInCurrency(false);
              costForm.resetFields();
              setPrice({
                value: '0.00',
                validateStatus: '',
              });
            }}
          >
            Закрыть
          </Button>,
          <Button
            disabled={disableBtn || disableBtnValidate}
            key="submit"
            type="primary"
            onClick={() => {
              costForm
                .validateFields()
                .then(async (values) => {
                  if (firstTransport || !transportListIsEmpty) {
                    let body = {
                      ...values,
                      in_currency: true,
                      //service: JSON.parse(values.service).id,
                      task: preTaskId,
                    };
                    if (firstTransport) {
                      body['transport'] = firstTransport?.id;
                    }
                    await saveCost(body);
                    setInCurrency(false);
                    setPrice({
                      value: '0.00',
                      validateStatus: '',
                    });
                  } else {
                    message.warning('Не добавлена перевозка');
                  }
                })
                .catch(() => setDisableBtn(true));
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form
          form={costForm}
          layout={'vertical'}
          onValuesChange={() => {
            setTimeout(() => {
              costForm
                .validateFields()
                .then(() => setDisableBtn(false))
                .catch(() => setDisableBtn(true));
            }, 0);
          }}
        >
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item name={'operation'} label={'Операция'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  // defaultValue={record && record.operation ? record.operation.id : ''}
                  filterOption={false}
                  onSearch={async (keyword) => {
                    await handleGetListOperationWaypoint(keyword);
                  }}
                  placeholder="Поиск операции"
                >
                  {operations && operations.length > 0 ? (
                    <>
                      {operations.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </>
                  ) : (
                    <Option disabled value="Загрузка">
                      Нет данных
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name={'service'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
                label={'Услуга'}
              >
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  loading={searchLoading}
                  filterOption={false}
                  onSearch={async (value) => await searchService(value)}
                  placeholder="Найти услугу"
                >
                  {services && services.length > 0 ? (
                    <>
                      {services.map((item) => {
                        return (
                          <Option key={item.id} value={item?.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </>
                  ) : null}
                </Select>
              </Form.Item>
            </Col>
            {transportList && transportList?.length > 1 ? (
              <Col xs={24} lg={12}>
                <Form.Item
                  rules={[{ required: true, message: 'Обязательное поле!' }]}
                  name={'transport'}
                  label={'Транспорт'}
                >
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    placeholder={'Выберите транспорт с заявки'}
                  >
                    {transportList?.length > 0 ? (
                      transportList.map((item, index) => {
                        return (
                          <Option key={index} value={item.id}>
                            {item?.transport?.transport_unit?.type_full}
                          </Option>
                        );
                      })
                    ) : (
                      <Option disabled>Нет доступных вариантов</Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            {isImportTemplete ? (
              <Col xs={24} lg={6}>
                <Form.Item name={'currency'} label={'Валюта'}>
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    defaultValue={'rub'}
                  >
                    <Option value={'rub'}>Рубли</Option>
                    <Option value={'dol'}>Доллар</Option>
                    <Option value={'eur'}>Евро</Option>
                    <Option value={'cny'}>Юань</Option>
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            <Col xs={24} lg={6}>
              <Form.Item
                name={'price_currency'}
                label={'Цена за ед.'}
                validateStatus={price.validateStatus}
                help={price.errorMsg}
              >
                <Input
                  value={price.value}
                  onChange={(val) => onPriceChange(val)}
                  onFocus={() => {
                    const value = costForm.getFieldsValue(['price_currency']);
                    if (value.price_currency === '0.00') {
                      costForm.setFieldsValue({
                        price_currency: '',
                      });
                    }
                  }}
                  onBlur={() => {
                    {
                      const value = costForm.getFieldsValue(['price_currency']);
                      if (!value.price_currency.trim()) {
                        costForm.setFieldsValue({
                          price_currency: '0.00',
                        });
                      }
                    }
                  }}
                  placeholder={'Введите цену за ед.'}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name={'count_services'} label={'Кол-во услуг'}>
                <InputNumber
                  placeholder={'Количество'}
                  defaultValue={1}
                  min={1}
                  max={100000}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item name={'vat_rate'} label={'НДС'}>
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  placeholder={'НДС'}
                >
                  <Option value={null}>Без НДС</Option>
                  <Option value={0}>0%</Option>
                  <Option value={20}>20%</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {!isMobile ? (
        <Table
          onRow={(costs) => {
            return {
              onDoubleClick: async () => {
                costs &&
                  costs.service &&
                  (await searchService(costs.service.name));
                costs?.in_currency && setInCurrency(true);
                setCurrEditCost(costs);
                setVisiblePreCostModalEdit(true);
                costFormEdit.setFieldsValue({
                  ...costs,
                  transport: costs?.transport?.id,
                  customer: costs?.customer?.id,
                  in_currency: costs?.in_currency,
                  operation: costs?.operation?.id,
                  service: costs?.service?.id,
                });
                await handleGetListOperationWaypoint(costs?.operation?.name);
              },
            };
          }}
          loading={loading}
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'black-text table-row-color'
              : 'black-text table-row-white'
          }
          size="small"
          scroll={{ x: 1000 }}
          bordered={true}
          pagination={{ position: ['none'] }}
          dataSource={costs}
          columns={columns}
        />
      ) : (
        <>
          <div style={{ paddingTop: '5px' }}>
            <ErrorBoundary>
              <ScrollingLoadedList
                dataList={costs}
                totalData={costs?.length}
                loadMoreItems={async () => {
                  //handleUpdate(page + 1, filters, false,true)
                }}
                renderListItem={(item) => (
                  <ServicesListItem
                    cost={item}
                    menuActions={(manager) => menu(manager)}
                  />
                )}
              />
            </ErrorBoundary>
          </div>
        </>
      )}
    </>
  );
};

export default PreAddServicesTable;
