export default function changeWidthStyleMainTable(
  mainTableRef,
  mainTableWidth,
) {
  if (!mainTableRef) return;
  if (mainTableRef.current.children[0]) {
    const wrapElementTable =
      mainTableRef.current.children[0].getElementsByClassName('ant-table-body');
    if (!wrapElementTable[0]) return;
    //change style width for body
    const tableBody = wrapElementTable[0];
    const tableElement = tableBody.getElementsByTagName('table');
    if (tableElement[0]?.style) {
      tableElement[0].style = `width: ${mainTableWidth}px; min-width: ${mainTableWidth}px; table-layout: fixed `;
    }

    //change style width for table header
    const tableHeader =
      mainTableRef.current.children[0].getElementsByClassName(
        'ant-table-header',
      )[0];
    if (!tableHeader) return;

    const tableHeaderTableTag = tableHeader.getElementsByTagName('table');
    if (!tableHeaderTableTag[0]?.style) return;

    tableHeaderTableTag[0].style = `width: ${mainTableWidth}px; overflow: hidden;`;
  }
}
