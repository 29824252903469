function generateFiltersByCategory(splitNameItem, filter, item) {
  if (splitNameItem[1] === 'periodDate') {
    filter = {
      ...filter,
    };
    if (item[1].length > 0) {
      if (item[1][0] === item[1][1]) {
        return {
          ...filter,
          [`${splitNameItem[0]}`]: item[1][0],
        };
      } else {
        filter[`${splitNameItem[0]}__gte`] = `${item[1][0]}`;
        filter[`${splitNameItem[0]}__lte`] = `${item[1][1]}`;
      }
    }
    return filter;
  } else if (splitNameItem[1] === 'range') {
    filter = {
      ...filter,
    };
    if (item[1][0]) {
      filter[`${splitNameItem[0]}__lte`] = `${item[1][0]}`;
    }
    if (item[1][1]) {
      filter[`${splitNameItem[0]}__gte`] = `${item[1][1]}`;
    }
    return filter;
  } else if (splitNameItem[1] === 'search') {
    return {
      ...filter,
      [`${splitNameItem[0]}__icontains`]: item[1][0],
    };
  } else {
    return { ...filter, [item[0]]: [...new Set(item[1])].join(',') };
  }
}

function convertToObjectFilters(filtersTable, newFilters) {
  Object.entries(filtersTable)
    .filter((item) => item[1])
    .map((item) => {
      let filter = {
        ...newFilters.filter,
      };
      const categoryName = item[0]?.split('__');
      filter = generateFiltersByCategory(categoryName, filter, item);
      newFilters = {
        ...newFilters,
        filter,
      };
    });
  return newFilters;
}

const getFiltersTable = (pagination, filtersTable, sorter) => {
  let newFilters = {
    filter: {},
    order: {},
  };

  if (
    typeof filtersTable === 'object' &&
    filtersTable !== null &&
    Object.entries(filtersTable).length > 0
  ) {
    newFilters = convertToObjectFilters(filtersTable, newFilters);
  }

  if (typeof sorter === 'object' && sorter !== null && sorter.order) {
    newFilters = {
      ...newFilters,
      order: {
        ...newFilters.order,
        [sorter.field]:
          sorter.order == 'ascend' ? `${sorter.field}` : `-${sorter.field}`,
      },
    };
  } else {
    newFilters = {
      ...newFilters,
      order: {},
    };
  }

  return {
    page: pagination.current,
    filters: newFilters,
  };
};

const convertObjFilterToUrl = (object, type) => {
  let url = '';
  if (object) {
    let order = Object.entries({ ...object.order }).filter((item) => item[1]);
    let filter = Object.entries({ ...object.filter }).filter((item) => item[1]);

    if (filter.length > 0) {
      url += `&${filter
        .map(
          (item) =>
            `${
              item[1].split(',').length > 1 && !item[0].includes('__range')
                ? `${item[0]}__in`
                : item[0]
            }=${item[1].replace('+', '%2B')}`,
        )
        .join('&')}`;
    }

    if (order.length > 0) {
      url += `${type ? '&order' : '&ordering'}=${order
        .map((item) => `${item[1]}`)
        .join(',')}`;
    }
    return url;
  } else {
    return '';
  }
};

const convertFiltersToObj = (filters) => {
  let resultObjectFilter = {};
  if (filters && filters?.filter) {
    let filter = Object.entries({ ...filters.filter }).filter(
      (item) => item[1],
    );
    for (const arrKeyVal of filter) {
      if (
        arrKeyVal[1].split(',').length > 1 &&
        !arrKeyVal[0].includes('__range')
      ) {
        resultObjectFilter[`${arrKeyVal[0]}__in`] = arrKeyVal[1];
      } else {
        resultObjectFilter[`${arrKeyVal[0]}`] = arrKeyVal[1];
      }
    }
  }
  return resultObjectFilter;
};

export { getFiltersTable, convertObjFilterToUrl, convertFiltersToObj };
