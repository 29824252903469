import { getBranchId } from '../localStorageUtils/getBranchId';
import { getCurrentPaginationPageSize } from '../localStorageUtils/paginationSettings';
import { message } from 'antd';

export async function call(
  url: string,
  config: RequestInit,
  havePermissionsViewBranch: boolean | null,
  addPaginationPageSize: boolean | null,
) {
  const isParams = url.includes('?');
  const urlIncludesBranch = url.includes('?branch') || url.includes('&branch');

  if (config.body && getCompanyIdFromStorage()) {
    if (typeof config.body === 'string') {
      const updateBody = JSON.parse(config.body);
      if (!Object.prototype.hasOwnProperty.call(updateBody, 'company')) {
        updateBody['company'] = getCompanyIdFromStorage();
        config.body = JSON.stringify(updateBody);
      }
    }
  }

  if (config.body && havePermissionsViewBranch) {
    if (typeof config.body === 'string') {
      const updateBody = JSON.parse(config.body);
      if (!Object.prototype.hasOwnProperty.call(updateBody, 'branch')) {
        updateBody['branch'] = getBranchId();
        config.body = JSON.stringify(updateBody);
      }
    }
  }
  if (config.body && addPaginationPageSize) {
    if (typeof config.body === 'string') {
      const updateBody = JSON.parse(config.body);
      if (!Object.prototype.hasOwnProperty.call(updateBody, 'size')) {
        updateBody['size'] = getCurrentPaginationPageSize();
        config.body = JSON.stringify(updateBody);
      }
    }
  }
  //}
  // TODO: so bad1
  const res = await fetch(
    `${url}${
      getCompanyIdFromStorage() &&
      (config.method === 'GET' || config.method === 'DELETE')
        ? isParams
          ? `&company=${getCompanyIdFromStorage()}`
          : `?company=${getCompanyIdFromStorage()}`
        : ''
    }${
      havePermissionsViewBranch &&
      !urlIncludesBranch &&
      (config.method === 'GET' || config.method === 'DELETE')
        ? isParams || getCompanyIdFromStorage()
          ? `&branch=${getBranchId()}`
          : `?branch=${getBranchId()}`
        : ''
    }${
      addPaginationPageSize &&
      (config.method === 'GET' || config.method === 'DELETE')
        ? isParams
          ? `&size=${getCurrentPaginationPageSize()}`
          : `?size=${getCurrentPaginationPageSize()}`
        : ''
    }`,
    config,
  );
  let json = null;
  // TODO: so bad2
  try {
    json = await res.json();
  } catch (e) {
    console.log(e, 'response not found json');
  }

  if (res.status !== 200 && res.status !== 201) {
    if (res && res?.status === 401) {
      removeToken();
      window.location.href = '/';
      return;
    }
    if (typeof json?.data === 'string') {
      message.error(json?.data);
      return null;
    }

    if (json?.data?.data) {
      message.error(json?.data?.data);
      return null;
    }

    if (typeof json?.data === 'object') {
      Object.entries(json?.data).map(async (err) => {
        if (err[0] && err[1]) {
          await message.error(`${err[0]} - ${err[1]}`);
        }
      });
      return null;
    }
  }
  return json ? json : res;
}

export const getToken = (): string | null => {
  return localStorage.getItem('token');
};

const removeToken = () => {
  localStorage.removeItem('token');
};

export const addCompanyIdToLocalStorage = (companyId: number) => {
  if (!companyId) return;
  localStorage.removeItem('companyId');
  localStorage.setItem('companyId', `${companyId}`);
};

export const getCompanyIdFromStorage = () => {
  const companyId = localStorage.getItem('companyId');
  if (companyId) {
    return parseInt(companyId);
  }
};

export const removeCompanyIdFromStorage = () => {
  localStorage.removeItem('companyId');
};
