import React, { useEffect, useRef, useState } from 'react';
import { Table, Checkbox, Tooltip, Pagination } from 'antd';

const RefBookTable = ({
  data,
  total,
  columnsData,
  loading,
  handleChangePage,
  selectedOption,
  page,
  setItemForEditing,
  submitForm,
}) => {
  const [columns, setColumns] = useState([]);
  const [tableWidth, setTableWidth] = useState(800);
  const currentRow = useRef(null);

  useEffect(() => {
    if (columnsData) {
      generateColumns(columnsData);
    } else {
      setColumns([]);
    }
  }, [columnsData]);

  const filterColumns = (columns) => {
    let filteredColumns = [...columns];
    filteredColumns = filteredColumns.filter((item) => item[0] !== 'id');

    filteredColumns = filteredColumns.filter(
      (item) => item[0] !== 'type_point' && item[0] !== 'location',
    );

    if (selectedOption.includes('type_point=port')) {
      filteredColumns = filteredColumns.filter((item) => item[0] !== 'route');
    }
    if (selectedOption.includes('contract_template')) {
      filteredColumns = filteredColumns.filter(
        (item) => item[0] !== 'contract_type',
      );
    }
    if (selectedOption.includes('presentations')) {
      filteredColumns = filteredColumns.filter((item) => item[0] !== 'file');
    }
    return filteredColumns;
  };

  const getValueFromOptionsObject = (option, value) => {
    switch (option) {
      case 'branch':
        return value?.name;
      case 'customer':
        return value?.short_name;
      case 'route':
        return value?.name;
      case 'timezone':
        return value?.name;
      default:
        return value;
    }
  };

  const generateColumns = (options) => {
    const newColumns = filterColumns(options).map((option) => {
      return {
        title: option[1]?.label,
        key: option[0],
        dataIndex: option[0],
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (value) => {
          if (option[1]?.type === 'boolean') {
            return (
              <Checkbox
                //disabled
                checked={value}
                onChange={(e) => {
                  changeCheckboxValueWithoutModal(option[0], e.target.checked);
                }}
              />
            );
          } else if (option[1]?.type === 'field') {
            return (
              <Tooltip
                placement={'topLeft'}
                title={getValueFromOptionsObject(option[0], value)}
              >
                {getValueFromOptionsObject(option[0], value)}
              </Tooltip>
            );
          } else if (option[1]?.type === 'string') {
            return (
              <Tooltip
                placement={'topLeft'}
                title={getValueFromOptionsObject(option[0], value)}
              >
                {getValueFromOptionsObject(option[0], value)}
              </Tooltip>
            );
          } else {
            return getValueFromOptionsObject(option[0], value);
          }
        },
      };
    });
    const sumTableWidth = newColumns.reduce(
      (acc, item) => (acc += item.width),
      0,
    );
    setTableWidth(sumTableWidth);
    setColumns(newColumns);
  };

  const changeCheckboxValueWithoutModal = (fieldName, value) => {
    if (!fieldName || !currentRow?.current?.id) return;
    const body = {
      id: currentRow?.current?.id,
      [`${fieldName}`]: value,
    };
    submitForm(body, currentRow?.current, false, false);
    currentRow.current = null;
  };
  return (
    <>
      <Table
        rowClassName={(record, index) =>
          index % 2 === 0
            ? 'black-text table-row-color'
            : 'black-text table-row-white'
        }
        onRow={(record) => {
          return {
            onClick: () => {
              currentRow.current = record;
            },
            onDoubleClick: () => {
              let body = { ...record };

              if (body?.route) {
                body = {
                  ...body,
                  route: body?.id,
                };
              }
              if (body?.branch) {
                body = {
                  ...body,
                  branch: body?.branch?.id,
                };
              }
              if (body?.customer) {
                body = {
                  ...body,
                  customer: body?.customer?.id,
                };
              }
              if (body?.timezone) {
                body = {
                  ...body,
                  timezone: body?.timezone?.id,
                  timezoneName: body?.timezone?.name,
                };
              }
              setItemForEditing(body);
            },
          };
        }}
        columns={columns}
        dataSource={data}
        loading={loading}
        bordered={true}
        size="small"
        rowKey={(record) => record.id}
        pagination={{ position: ['none'], pageSize: 15 }}
        showHeader
        scroll={{ x: tableWidth }}
      />
      <Pagination
        style={{ marginTop: '10px', float: 'right' }}
        defaultCurrent={1}
        current={page}
        showSizeChanger={false}
        pageSize={15}
        total={total}
        onChange={(page) => {
          handleChangePage(page);
        }}
      />
    </>
  );
};

export default RefBookTable;
