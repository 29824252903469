import React from 'react';
import PropTypes from 'prop-types';
import '../briefInformationAboutTaskPopover/briefInformationAboutTaskPopover.css';
import { Button, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const BriefInformationAboutTaskPopoverTitle = (props) => {
  return (
    <>
      <div
        onClick={(event) => {
          event.preventDefault();
        }}
        onDoubleClick={() => {
          props.handleEditTask(props.task);
        }}
        className={'popover-title user-select-none'}
      >
        <Row justify={'end'}>
          <Button
            onClick={() => {
              props.handleEditTask(props.task);
            }}
            type="white"
            shape="circle"
            icon={<EditOutlined />}
          />
        </Row>
        <div style={{ padding: '20px 25px 0 30px' }}>{props.task?.name}</div>
      </div>
    </>
  );
};

BriefInformationAboutTaskPopoverTitle.propTypes = {
  task: PropTypes.object.isRequired,
  handleEditTask: PropTypes.func.isRequired,
};
export default React.memo(BriefInformationAboutTaskPopoverTitle);
