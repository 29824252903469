import { restApiHost } from '../../../utils/appVariables';
import { call } from '../../../apiUtils/call';
import { message } from 'antd';

export default async function getTransportList(userInfo, keyword) {
  const params = new URLSearchParams();

  params.set('active', 'true');

  if (keyword?.length > 0) {
    params.set('search', keyword);
  }

  try {
    const url = `${restApiHost}/refbook/transport_units/?${params}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json?.data) {
      return json.data;
    }
  } catch (e) {
    console.log(e);
    message.error('Ошибка получения списка вида тр. ед.');
  }
}

export async function getTransportById(userInfo, id) {
  const params = new URLSearchParams();

  params.set('active', 'true');

  try {
    const url = `${restApiHost}/refbook/transport_units/${id}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json?.data) {
      return [json.data];
    }
  } catch (e) {
    console.log(e);
    message.error('Ошибка получения вида тр. ед.');
  }
}
