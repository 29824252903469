import { Card, CardContent, Typography } from '@material-ui/core';
import MoneyIcon from '@material-ui/icons/Money';
import { Tooltip } from 'antd';
import React from 'react';

const Budget = (props) => (
  <Card sx={{ height: '100%' }} {...props}>
    <CardContent className={'TaskProgress-item'}>
      <div className="TaskProgress-content-item">
        <Typography
          color="textSecondary"
          gutterBottom
          style={{
            maxWidth: '200px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="h6"
        >
          Суммы пред. заявок
        </Typography>
        <Typography
          color="textPrimary"
          variant="h4"
          className={'typography-sums-preTasks'}
        >
          <Tooltip
            overlayStyle={{ fontSize: '17px' }}
            placement={'topLeft'}
            title={props.stat?.pre_tasks?.sum || 0}
          >
            {props.stat?.pre_tasks?.sum || 0}
          </Tooltip>
        </Typography>
      </div>
      <div className="TaskProgress-content-item TaskProgress-avatar avatar-red">
        <MoneyIcon />
      </div>
    </CardContent>
  </Card>
);

export default Budget;
