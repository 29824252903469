import { sortColumnsByIndex } from './sortColumnsByIndex';
import { changeVisibleFiltersInColumnsArr } from './changeVisibleFiltersInColumnsArr';

export function sortingColumnsTable(
  userInfo,
  tableName,
  columnsFromTable,
  visibleFilters,
) {
  if (userInfo?.current?.table_columns) {
    const arrayOfOrders = userInfo?.current?.table_columns[tableName];
    if (arrayOfOrders) {
      let columns = sortColumnsByIndex(columnsFromTable, arrayOfOrders);
      columns = changeVisibleFiltersInColumnsArr(columns, visibleFilters);
      return columns;
    }
  }
  return [];
}

export const prepareColumnsForResizing = (col, handleResize) => {
  /** Задача этой функции каждому столбцу добавить onHeaderCell для будущей возможности resize */
  return col.map((item, index) => {
    if (!item?.isGroup) {
      const children = item?.children;
      if (children && children[0]?.width) {
        children[0] = {
          ...children[0],
          onHeaderCell: (item) => ({
            width: item.width,
            isChildren: true,
            onResize: handleResize(index),
          }),
        };
      }
      return {
        ...item,
        children: children ? [...children] : children,
        onHeaderCell: (item) => ({
          width: item.width,
          isChildren: false,
          onResize: handleResize(index),
        }),
      };
    }
    /** Добавляем onHeaderCell колонкам которые состояет в группе
     * они помечаны параметров isGroup: true
     * Выглядит они вот так:
     * |        Название группы      |
     * |-----------------------------|
     * | name1      |     testName2  | <------- вот эти 2 колонки name1 и testName2 являются элементами группы
     * |-----------------------------|
     *  */
    const resultItemsGroup = [];
    const groupItems = item?.children;
    for (const item of groupItems) {
      const children = item?.children;
      if (children && children[0]?.width) {
        children[0] = {
          ...children[0],
          onHeaderCell: (item) => ({
            width: item.width,
            isChildren: true,
            uniqueIndex: item.uniqueIndex,
            isGroupItem: true,
            onResize: handleResize(index),
          }),
        };
      }
      resultItemsGroup.push({
        ...item,
        children: children ? [...children] : children,
        onHeaderCell: (item) => ({
          width: item.width,
          isChildren: false,
          uniqueIndex: item.uniqueIndex,
          isGroupItem: true,
          onResize: handleResize(index),
        }),
      });
    }
    return {
      ...item,
      children: [...resultItemsGroup],
    };
  });
};

export function changeSizeColumn(columns, index, size) {
  const nextColumns = [...columns];
  const children = nextColumns[index]?.children
    ? // eslint-disable-next-line no-unsafe-optional-chaining
      [...nextColumns[index]?.children]
    : null;
  if (children && children[0]?.width) {
    children[0].width = size;
  }
  nextColumns[index] = {
    ...nextColumns[index],
    width: size,
    children: children ? [...children] : [],
  };
  return nextColumns;
}

export function changeColumnSizeInColumnsGroup(
  columns,
  index,
  uniqueIndex,
  size,
) {
  let idInDatabase = null;
  const nextColumns = [...columns];
  const groupItems = nextColumns[index]?.children;
  const resizingItemInGroupIndex = nextColumns[index]?.children.findIndex(
    (item) => item?.uniqueIndex === uniqueIndex,
  );

  if (resizingItemInGroupIndex > -1) {
    const children = groupItems[resizingItemInGroupIndex]?.children
      ? // eslint-disable-next-line no-unsafe-optional-chaining
        groupItems[resizingItemInGroupIndex].children
      : null;
    idInDatabase = groupItems[resizingItemInGroupIndex]?.idInDatabase;
    if (children && children[0]?.width) {
      children[0].width = size;
    }
    groupItems[resizingItemInGroupIndex] = {
      ...groupItems[resizingItemInGroupIndex],
      width: size,
      children: children ? [...children] : [],
    };
    nextColumns[index] = {
      ...nextColumns[index],
      children: [...groupItems],
    };
  }
  return { nextColumns, idInDatabase };
}

export function convertingColumnsWithGroupsIntoRegularOnesForUseInMobileFilters(
  columns,
) {
  if (!columns?.length) return;
  const resColumnsList = [];
  for (const item of columns) {
    if (item?.isGroup && item?.children?.length) {
      for (const groupItem of item.children) {
        resColumnsList.push(groupItem);
      }
      continue;
    }
    resColumnsList.push(item);
  }
  return resColumnsList;
}
