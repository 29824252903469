import PropTypes from 'prop-types';
import '../../leads/list/leadListItem.css';
import React, { useCallback } from 'react';
import { Card, Col, Divider, Dropdown, Row, Space } from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';

const StaffListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>{props.staff?.full_name || '-'}</Col>
            <Col className={'secondText'}>{props.staff?.email || '-'}</Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.staff)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.staff]);

  return (
    <>
      <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[0, 15]}>
          <Col xs={24}>
            <div className={'secondText'}>{'Группы'}</div>
            <div className={'valueText'}>
              {(props.staff?.groups?.length &&
                props.staff?.groups.map((item) => item?.name).join(', ')) ||
                '-'}
            </div>
          </Col>

          <Row gutter={15}>
            <Col>
              <div className={'secondText'}>{'Номер телефона'}</div>
              <div className={'valueText'}>
                {props.staff?.phone_number || '-'}
              </div>
            </Col>
          </Row>
        </Row>
      </Card>
    </>
  );
};

StaffListItem.propTypes = {
  staff: PropTypes.object,
  menuActions: PropTypes.func,
};

export default StaffListItem;
