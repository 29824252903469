import React from 'react';
import { Table, message } from 'antd';
import { PhoneIncoming, PhoneOutgoing } from 'react-feather';

const HistoryCallCustomerTable = ({ data, customer, getData }) => {
  const columns = [
    {
      title: 'Направление вызова',
      key: 'call_direction',
      dataIndex: 'call_direction',
      width: 200,
      render: (direction) =>
        direction == 'Originator' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PhoneOutgoing
              style={{ color: '#1890ff', width: '20px', marginRight: '10px' }}
            />
            Исходящий
          </div>
        ) : direction == 'Terminator' ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PhoneIncoming
              style={{ color: '#37bd00', width: '20px', marginRight: '10px' }}
            />
            Входящий
          </div>
        ) : (
          'Не известно'
        ),
    },
    {
      title: 'Номер телефона',
      key: 'phone_number',
      dataIndex: 'phone_number',
      width: 150,
    },

    {
      title: 'Дата звонка',
      key: 'start_time',
      dataIndex: 'start_time',
      width: 150,
    },
    {
      title: 'Время ответа на звонок',
      key: 'answer_time',
      dataIndex: 'answer_time',
      width: 200,
    },
    {
      title: 'Окончание звонка',
      key: 'end_time',
      dataIndex: 'end_time',
      width: 200,
    },
    {
      title: 'Время ответа на вызов(сек.)',
      key: 'answer_duration',
      dataIndex: 'answer_duration',
      width: 200,
    },
    {
      title: 'Время вызова(сек.)',
      key: 'call_duration',
      dataIndex: 'call_duration',
      width: 200,
    },
    {
      title: 'Абонент',
      key: 'abonent',
      children: [
        {
          title: 'ФИО',
          key: 'fio',
          dataIndex: 'abonent',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (abonent) => abonent?.fio || '-',
        },
        {
          title: 'Email',
          key: 'email',
          dataIndex: 'abonent',
          width: 150,
          ellipsis: {
            showTitle: false,
          },
          render: (abonent) => abonent?.email || '-',
        },
      ],
    },
  ];

  const handleChangeTable = (pagination) => {
    if (customer?.id && pagination?.current) {
      getData(customer.id, pagination.current, customer);
    } else {
      message.warning('Непредвиденная ошибка');
    }
  };

  return (
    <Table
      onChange={handleChangeTable}
      showHeader
      bordered={true}
      scroll={{ x: 1750, y: 700 }}
      columns={columns}
      dataSource={data?.calls || []}
      pagination={{
        total: data?.total || 0,
      }}
    />
  );
};

export default HistoryCallCustomerTable;
