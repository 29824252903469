export enum ActionActiveChatIdTypes {
  SET_ACTIVE_CHAT_ID = 'SET_ACTIVE_CHAT_ID',
  SET_WAS_TASK_CARD_OPENING = 'SET_WAS_TASK_CARD_OPENING',
}

interface SetActiveChatId {
  type: ActionActiveChatIdTypes.SET_ACTIVE_CHAT_ID;
  payload: number | null;
}

interface SetWasTaskCardOpening {
  type: ActionActiveChatIdTypes.SET_WAS_TASK_CARD_OPENING;
  payload: boolean;
}

export type TodoActionActiveChatIdTypes =
  | SetActiveChatId
  | SetWasTaskCardOpening;
