import { Card } from 'antd';
import PropTypes from 'prop-types';

const CardSection = (props) => {
  return (
    <>
      <Card
        onClick={() => props.onSelected(props.item)}
        style={{
          minWidth: '185px',
          borderRadius: '20px',
          fontWeight: '500',
          border: '1px solid #0b7b7f',
        }}
        hoverable={true}
      >
        {props.item?.section}
      </Card>
    </>
  );
};

CardSection.propTypes = {
  item: PropTypes.object.isRequired,
  key: PropTypes.number.isRequired,
  onSelected: PropTypes.func.isRequired,
};

export default CardSection;
