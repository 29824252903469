import { Button, List, Space, Tag } from 'antd';
import { call } from '../../../apiUtils/call';
import { restApiHost } from '../../../utils/appVariables';
import { useContext } from 'react';
import { userInformations } from '../../../contex';
import { Table, TableColumn } from '../../../types';

interface TableItemProps {
  table: Table;
  setTableContext: (table?: Table) => void;
  refreshTables: () => void;
}

export default function TableItem({
  table,
  setTableContext,
  refreshTables,
}: TableItemProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { userInfo } = useContext(userInformations);

  const deleteColumn = (table_column: TableColumn) => {
    call(
      `${restApiHost}/administration/table_columns/${table_column.id}/`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
      null,
      // @typescript-eslint/no-unused-vars
    ).then(() => {
      refreshTables();
    });
  };

  const onAddColumn = () => {
    setTableContext(table);
  };

  const renderColumn = (table_column: TableColumn) => (
    <Tag onClose={() => deleteColumn(table_column)} closable={true}>
      {table_column.name} ({table_column.data_index})
    </Tag>
  );

  return (
    <List.Item style={{ backgroundColor: 'white' }}>
      <Space direction={'vertical'}>
        <p style={{ fontWeight: 600, fontSize: 18 }}>Таблица: {table.name}</p>

        <List dataSource={table.columns} renderItem={renderColumn} />

        <Button size="small" onClick={onAddColumn}>
          Добавить колонку
        </Button>
      </Space>
    </List.Item>
  );
}
