import { restApiHost } from '../../utils/appVariables';
import { message } from 'antd';
import { call } from '../../apiUtils/call';

export async function editProfileInfo(id, body, userInfo) {
  try {
    const url = `${restApiHost}/accounts/staff_users/${id}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo?.current?.haveViewBranch,
    );

    if (!json) return;

    return json;
  } catch (e) {
    console.log('edit profile info fetch err', e);
    message.error('Ошибка запроса на изменения данных профиля пользователя');
  }
}
