import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Modal, Steps, Form, Button, message } from 'antd';
import { restApiHost } from '../../../utils/appVariables';
import AddTransport from '../../tasks/transportation/new/AddTransport';
import AddCargoFormTask from '../../tasks/transportation/AddCargoFormTask';
import { userInformations } from '../../../contex';
import { call } from '../../../apiUtils/call';
import { ErrorBoundary } from '../../ErrorBoundary';

const { Step } = Steps;

const TransportationEdit = ({
  visible,
  createdCargo,
  handleChangeVisible,
  cargo,
  cargos,
  task,
  taskEdit,
  waypoints,
  options,
  onSearchTransportOptions,
  refreshData,
}) => {
  const [addCargoForm] = Form.useForm();
  const [currentAddStep, setCurrentAddStep] = useState(0);
  const [disableNextBtn, setDisableNextBtn] = useState(true);
  const [, setTransportList] = useState([]);
  const [disableTransportStep, setDisableTransportStep] = useState(false);

  const { userInfo } = useContext(userInformations);

  //transport
  const [createdTransport, setCreatedTransport] = useState(null);
  const [transportValues, setTransportValues] = useState(null);
  const [cargoDetailsValues, setCargoDetailsValues] = useState(null);

  useEffect(() => {
    if (cargo) {
      setCargoDetailsValues(cargo);
      setTransportValues(cargo.transport);
      setCreatedTransport(cargo);
      setDisableNextBtn(false);

      addCargoForm.setFieldsValue({
        cargo: cargo.task?.cargo?.name,
        cargo_description: cargo.cargo_description,
        cargo_gng: cargo.cargo_gng,
        cargo_places: cargo.cargo_places,
        cargo_security: cargo.cargo_security,
        cargo_type_packing: cargo.cargo_type_packing?.id || null,
        cargo_type_packing_name: cargo.cargo_type_packing?.name || null,
        cargo_volume: cargo.cargo_volume,
        cargo_waypoint: cargo.cargo_waypoint,
        cargo_weight: cargo.cargo_weight,
      });
    }
  }, [cargo]);

  const addModalSteps = [
    {
      title: 'Транспорт',
      disable: disableTransportStep,
      content: () => {
        return (
          <ErrorBoundary>
            <AddTransport
              data={transportValues}
              handleFormChange={handleFormTransportChange}
              onSearchTransportOptions={(keyword) => {
                onSearchTransportOptions(keyword);
              }}
              waypoints={waypoints}
              options={options}
            />
          </ErrorBoundary>
        );
      },
    },
    {
      title: 'Груз',
      content: () => {
        return (
          <ErrorBoundary>
            <AddCargoFormTask
              form={addCargoForm}
              createdCargo={createdCargo}
              cargoDetailsValues={cargoDetailsValues}
              handleChangeForm={handleChangeCargoForm}
              cargos={cargos}
              cargo={cargo}
              task={task}
              currentStep={currentAddStep}
              taskEdit={taskEdit}
            />
          </ErrorBoundary>
        );
      },
    },
  ];

  const handleCancelAddModal = () => {
    handleChangeVisible(false);
    setCurrentAddStep(0);
    setTransportList([]);
    addCargoForm.resetFields();
    setDisableNextBtn(true);
    // setCreatedCargo(null);
    refreshData(task);
  };

  const handleChangeAddStep = (current) => {
    setCurrentAddStep(current);
  };

  const handleChangeCargoForm = async () => {
    setTimeout(() => {
      setDisableTransportStep(true);
      addCargoForm
        .validateFields()
        .then((values) => {
          setDisableNextBtn(false);
          console.log(values);
          setCargoDetailsValues({
            ...values,
            transport_unit: values?.transport_unit,
          });
        })
        .catch(() => setDisableNextBtn(true));
    }, 0);
  };

  const handleFormTransportChange = (form) => {
    form
      .validateFields()
      .then((values) => {
        setDisableNextBtn(false);
        setTransportValues({ ...transportValues, ...values });
      })
      .catch((e) => {
        setDisableNextBtn(true);
        console.log(e);
      });
  };

  const editCargo = async (id, body) => {
    try {
      const url = `${restApiHost}/leads/pre_transport_cargo_task/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;
      message.success('Груз успешно отредактирован!');
    } catch (e) {
      console.log('add transport err', e);
      message.error('Ошибка запроса на редактирование груза');
    }
  };

  const editTransport = async (id, body) => {
    try {
      const url = `${restApiHost}/leads/pre_transport/${id}/`;
      const json = await call(
        url,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );

      if (!json?.data) return;
      setCreatedTransport(json.data);
      message.success('Транспорт успешно отредактирован!');
    } catch (e) {
      console.log('add transport err', e);
      message.error('Ошибка запроса на редактирование транспорта');
    }
  };

  const handleEditTransport = useCallback(() => {
    transportValues &&
      editTransport(transportValues.id, {
        ...transportValues,
        transport_unit: transportValues?.transport_unit?.id
          ? transportValues?.transport_unit?.id
          : transportValues?.transport_unit,
      });
  }, [transportValues, editTransport]);

  return (
    <>
      <Modal
        visible={visible}
        title="Редактирование перевозок"
        onCancel={() => {
          handleCancelAddModal();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              handleCancelAddModal();
            }}
          >
            Закрыть
          </Button>,

          <>
            {currentAddStep === 0 && createdTransport ? (
              <Button
                key="submit"
                type="primary"
                disabled={disableNextBtn}
                onClick={async () => {
                  await handleEditTransport();
                }}
              >
                Обновить
              </Button>
            ) : null}
          </>,
          <>
            {currentAddStep == 0 && createdTransport ? (
              <Button
                key="submit"
                type="primary"
                disabled={disableNextBtn}
                onClick={() => {
                  setCurrentAddStep(currentAddStep + 1);
                }}
              >
                Далее
              </Button>
            ) : null}
          </>,
          <>
            {currentAddStep == 1 && createdTransport ? (
              <Button
                disabled={disableTransportStep}
                key="submit"
                type="primary"
                onClick={() => {
                  setCurrentAddStep(currentAddStep - 1);
                }}
              >
                Назад
              </Button>
            ) : null}
          </>,
          <>
            {currentAddStep === 1 && createdTransport ? (
              <Button
                key="submit"
                type="primary"
                disabled={disableNextBtn}
                onClick={async () => {
                  let body = {
                    ...cargoDetailsValues,
                    cargo_type_packing:
                      typeof cargoDetailsValues?.cargo_type_packing === 'number'
                        ? cargoDetailsValues?.cargo_type_packing
                        : cargoDetailsValues?.cargo_type_packing?.id,
                    task,
                  };
                  delete body.transport;
                  delete body.id;
                  cargo && (await editCargo(cargo.id, body));
                  disableTransportStep && setDisableTransportStep(false);
                  handleCancelAddModal();
                  // setCurrentAddStep(currentAddStep + 1);
                }}
              >
                Сохранить
              </Button>
            ) : null}
          </>,
        ]}
      >
        <Steps
          style={{ marginBottom: '20px' }}
          current={currentAddStep}
          onChange={handleChangeAddStep}
        >
          {addModalSteps.map((step, index) => (
            <Step
              key={index}
              disabled={
                (step.title == 'Груз' && !createdTransport) || step?.disable
              }
              title={step.title}
            />
          ))}
        </Steps>
        {addModalSteps[currentAddStep].content()}
      </Modal>
    </>
  );
};

export default TransportationEdit;
