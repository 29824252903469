import PropTypes from 'prop-types';
import { Button, Col, Form, Modal, Row, Spin, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import CpEditorDrawer from '../../cpEditor/CpEditorDrawer';
import { ErrorBoundary } from '../../ErrorBoundary';
import { CheckOutlined } from '@ant-design/icons';
import { getCpTemplateById } from '../../sendKpOrPresentation/getCpTemplates';
import { userInformations } from '../../../contex';
import ColorPicker from '../../ColorPicker';
import { useContractTemplateEditor } from '../../../utils/appVariables';

const RefBookCPModal = (props) => {
  const [visibleCpEditorDrawer, setVisibleCpEditorDrawer] = useState(false);
  const [cpJsonDesign, setCpJsonDesign] = useState(null);
  const [cpHtml, setCpHtml] = useState(null);
  const { userInfo } = useContext(userInformations);
  const [loading, setLoading] = useState(false);
  const [colorHeadTableSum, setColorHeadTableSum] = useState('#4471C4');
  const [colorTextHeadTableSum, setColorTextHeadTableSum] = useState('#ffffff');
  const [colorBodyTableSum, setColorBodyTableSum] = useState('#CFD4EA');
  const [colorTextBodyTableSum, setColorTextBodyTableSum] = useState('#000000');

  useEffect(() => {
    if (!props.visible) {
      clear();
    }
  }, [props.visible]);

  useEffect(async () => {
    if (props.editingItem) {
      await getHtmlAndJsonTemplateById(props.editingItem?.id, userInfo);
      if (props.editingItem?.table_color) {
        const tableColorObject = JSON.parse(props.editingItem.table_color);
        setColorHeadTableSum(
          tableColorObject?.colorHead ? tableColorObject?.colorHead : '#4471C4',
        );
        setColorTextHeadTableSum(
          tableColorObject?.colorTextHead
            ? tableColorObject?.colorTextHead
            : '#ffffff',
        );
        setColorBodyTableSum(
          tableColorObject?.colorBody ? tableColorObject?.colorBody : '#CFD4EA',
        );
        setColorTextBodyTableSum(
          tableColorObject?.colorTextBody
            ? tableColorObject?.colorTextBody
            : '#000000',
        );
      }
    }
  }, [props.editingItem]);

  const openCpEditorDrawer = async () => {
    setVisibleCpEditorDrawer(true);
  };

  const getHtmlAndJsonTemplateById = async (id, userInfo) => {
    setLoading(true);
    const template = await getCpTemplateById(id, userInfo);
    if (template?.data) {
      setCpJsonDesign(
        template.data?.json ? JSON.parse(template.data.json) : null,
      );
      setCpHtml(template?.data?.html ? template.data.html : null);
    }
    setLoading(false);
  };

  const submit = (values, editItem, deleteItem) => {
    const resValue = { ...values };
    if (cpJsonDesign) {
      resValue['json'] = JSON.stringify(cpJsonDesign);
    }
    if (cpHtml) {
      resValue['html'] = cpHtml;
    }
    resValue['table_color'] = JSON.stringify({
      colorHead: colorHeadTableSum,
      colorBody: colorBodyTableSum,
      colorTextHead: colorTextHeadTableSum,
      colorTextBody: colorTextBodyTableSum,
    });
    editItem
      ? deleteItem
        ? props.submitForm(resValue, editItem, deleteItem)
        : props.submitForm(resValue, editItem, null)
      : props.submitForm(resValue, null, null);
  };

  const closeCpEditorDrawer = (html, jsonDesign) => {
    setCpJsonDesign(jsonDesign);
    setCpHtml(html);
    setVisibleCpEditorDrawer(false);
  };

  const clear = () => {
    setCpJsonDesign(null);
    setCpHtml(null);
    setColorHeadTableSum('#4471C4');
    setColorBodyTableSum('#CFD4EA');
    setColorTextHeadTableSum('#ffffff');
    setColorTextBodyTableSum('#000000');
  };

  return (
    <>
      <Modal
        title={`${props.editingItem ? 'Редактирование' : 'Добавление'}`}
        visible={props.visible}
        onCancel={props.handleCancelModal()}
        footer={[]}
      >
        <Spin spinning={loading}>
          <Form
            layout={'vertical'}
            form={props.form}
            onFinish={(values) => {
              submit(values, props.editingItem, props.deleteItem);
            }}
          >
            <Row gutter={16}>
              {props.fields?.length > 0 &&
                props.fields.map((item) => {
                  return item[0] !== 'html' &&
                    item[0] !== 'json' &&
                    item[0] !== 'table_color'
                    ? props.getComponent(item[1]?.type, item)
                    : '';
                })}
              {cpHtml && cpJsonDesign ? (
                <Col lg={12} xs={24}>
                  <Form.Item>
                    <Space size={5}>
                      <div>Шаблон КП подкреплен</div>
                      <div>
                        <CheckOutlined
                          style={{ color: 'green', fontSize: '20px' }}
                        />
                      </div>
                    </Space>
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              <Col lg={24} xs={24}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <h4>Настройка цвета таблицы "Плечи/Суммы"</h4>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item label={'Цвет шапки'}>
                  <ColorPicker
                    color={colorHeadTableSum}
                    setColor={(color) => setColorHeadTableSum(color?.hex)}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item label={'Цвет основы'}>
                  <ColorPicker
                    color={colorBodyTableSum}
                    setColor={(color) => setColorBodyTableSum(color?.hex)}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item label={'Цвет текста шапки'}>
                  <ColorPicker
                    color={colorTextHeadTableSum}
                    setColor={(color) => setColorTextHeadTableSum(color?.hex)}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item label={'Цвет текста основы'}>
                  <ColorPicker
                    color={colorTextBodyTableSum}
                    setColor={(color) => setColorTextBodyTableSum(color?.hex)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify={'end'} gutter={10}>
              {props.editingItem !== null &&
              useContractTemplateEditor === 'true' ? (
                <Col>
                  <Button
                    type={'primary'}
                    onClick={async () => {
                      await openCpEditorDrawer();
                    }}
                  >
                    {cpHtml && cpJsonDesign
                      ? 'Изменить шаблон КП'
                      : 'Создать шаблон КП'}
                  </Button>
                </Col>
              ) : (
                <></>
              )}
              <Col>
                <Button type={'primary'} htmlType={'submit'}>
                  {props.editingItem ? 'Сохранить' : 'Добавить'}
                </Button>
              </Col>
              <Col style={{ marginLeft: '10px' }}>
                {props.editingItem ? (
                  <Button
                    onClick={() => props.setDeleteItem(true)}
                    htmlType={'submit'}
                  >
                    {'Удалить'}
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
      <ErrorBoundary>
        <CpEditorDrawer
          visible={visibleCpEditorDrawer}
          jsonDesign={cpJsonDesign}
          cpTemplateId={
            props.editingItem?.id ? props.editingItem.id.toString() : null
          }
          closeDrawer={(html, jsonDesign) =>
            closeCpEditorDrawer(html, jsonDesign)
          }
        />
      </ErrorBoundary>
    </>
  );
};

RefBookCPModal.props = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  form: PropTypes.any,
  fields: PropTypes.any,
  editingItem: PropTypes.any,
  deleteItem: PropTypes.any,
  getComponent: PropTypes.func,
  setDeleteItem: PropTypes.func,
  submitForm: PropTypes.func,
  handleCancelModal: PropTypes.func,
};
export default RefBookCPModal;
