import PropTypes from 'prop-types';
import { Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const MultipleSelectFromForm = (props) => {
  const refSelect = useRef(null);
  const [visibleSelectOptions, setVisibleSelectOptions] = useState(false);
  const isMobile = useCheckMobileScreen();

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      setVisibleSelectOptions(false);
      refSelect?.current?.blur();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    return () => window.removeEventListener('keyup', onKeyUp);
  }, [visibleSelectOptions]);

  const renderOptions = () => {
    return props.options;
  };
  return (
    <Select
      getPopupContainer={(trigger) =>
        isMobile ? trigger.parentNode : document.body
      }
      ref={refSelect}
      style={props.style}
      open={visibleSelectOptions}
      value={props.value}
      mode={'multiple'}
      maxTagCount={'responsive'}
      placeholder={props.placeholder}
      onChange={(values) => {
        props.onChange(values);
      }}
      onInputKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.stopPropagation();
        }
      }}
      onDropdownVisibleChange={(visible) => {
        setVisibleSelectOptions(visible);
      }}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {renderOptions()}
    </Select>
  );
};

export default MultipleSelectFromForm;

MultipleSelectFromForm.propTypes = {
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.any.isRequired,
  style: PropTypes.any,
};
