import getBlobFromUrl from '../components/contracts/genereteContracts/getBlobFromUrl';
import { restApiHost } from './appVariables';

export async function exportFileFormUrl(url, filename = '') {
  try {
    if (url) {
      const blob = await getBlobFromUrl(`${restApiHost}${url}`);
      if (!blob) {
        return;
      }
      const createObjectUR = URL.createObjectURL(blob);

      // Create download link element
      const downloadLink = document.createElement('a');

      document.body.appendChild(downloadLink);

      if (navigator?.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        downloadLink.href = createObjectUR;
        downloadLink.download = filename;
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    }
  } catch (e) {
    throw new Error(e);
  }
}
