import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent } from '@material-ui/core';
import {
  Button,
  Input,
  Dropdown,
  Menu,
  Form,
  Row,
  Col,
  Select,
  Modal,
  Divider,
  message,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';

const { Option } = Select;
const { Search } = Input;

const StocksToolbar = ({ addStock }) => {
  const [hideToolbar, setHideToolbar] = useState(false);

  //create container modal
  const [openModal, setOpenModal] = useState(false);
  const [mainForm] = Form.useForm();
  const [disableSaveBtn, setDisableAddBtn] = useState(true);

  //form data
  //customers
  const [customers, setCustomers] = useState([]);
  const [loadingCustomers, setLoadingCustomers] = useState(false);

  //tasks
  const [tasks, setTasks] = useState([]);
  const [, setLoadingTasks] = useState([]);

  //containers
  const [containers, setContainers] = useState([]);

  useEffect(() => {
    let hidden = JSON.parse(localStorage.getItem('hide_toolbar'));
    hidden && hidden.leads && setHideToolbar(hidden.leads);
    getCustomers();
  }, []);

  useEffect(() => {
    let hidden = JSON.parse(localStorage.getItem('hide_toolbar'));
    if (hideToolbar) {
      localStorage.setItem(
        'hide_toolbar',
        JSON.stringify({ ...hidden, stocks: true }),
      );
    } else {
      localStorage.setItem(
        'hide_toolbar',
        JSON.stringify({ ...hidden, stocks: false }),
      );
    }
  }, [hideToolbar]);

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setHideToolbar(true)}>
        Скрыть
      </Menu.Item>
    </Menu>
  );

  const handleFormSubmit = () => {
    mainForm
      .validateFields()
      .then(async (values) => {
        let data = {
          id: Date.now() + 1,
          title: values.stock,
          data: [{ ...values, id: Date.now() }],
        };
        addStock(data);
        setOpenModal(false);
        message.success('Сток успешно создан!');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchTasks = async (keyword) => {
    setLoadingTasks(true);
    try {
      const url = `${restApiHost}/shipping/task/?search=${keyword}`;
      const json = await call(url, {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });

      if (!json) return;

      setTasks(json?.data ? json.data : []);
    } catch (e) {
      console.log('search error', e);
      message.error('Ошибка поиска заявки');
      setLoadingTasks(false);
    } finally {
      setLoadingTasks(false);
    }
  };

  const searchCustomers = async (keyword) => {
    setLoadingCustomers(true);
    try {
      const url = `${restApiHost}/customers/list/?search=${keyword}`;
      const json = await call(url, {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });

      if (json?.data) {
        setCustomers(json.data);
      }
    } catch (e) {
      console.log('search clients err', e);
      message.error('Ошибка запроса для поиска клиента');
      setLoadingCustomers(false);
    } finally {
      setLoadingCustomers(false);
    }
  };

  const getCustomers = async (page = 1, params) => {
    let urlParams;

    setLoadingCustomers(true);

    if (params) {
      urlParams = convertObjFilterToUrl(params);
    }

    try {
      const url = urlParams
        ? `${restApiHost}/customers/list/?page=${page}&${urlParams}`
        : `${restApiHost}/customers/list/?page=${page}`;
      const res = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      const json = await res.json();

      if (res.status == 403) {
        message.warning(
          'У вас недостаточно прав для взаимодействия с клиентами',
        );
        setLoadingCustomers(false);
        return;
      }

      setCustomers(json?.data ? json.data : []);
      setLoadingCustomers(false);
    } catch (e) {
      console.log('fetch profile info error', e);
      setLoadingCustomers(false);
    }
  };

  const renderAddModal = () => {
    return (
      <Modal
        visible={openModal}
        title="Создание стока"
        onCancel={() => setOpenModal(false)}
        footer={[
          <Button key="back" onClick={() => setOpenModal(false)}>
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleFormSubmit}
            disabled={disableSaveBtn}
          >
            Создать
          </Button>,
        ]}
      >
        <Form
          form={mainForm}
          onValuesChange={() => {
            setTimeout(() => {
              mainForm
                .validateFields()
                .then(() => {
                  setDisableAddBtn(false);
                })
                .catch(() => setDisableAddBtn(true));
            }, 0);
          }}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col lg={24} xs={24}>
              <Form.Item
                name={'stock'}
                label={'Сток'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <Input placeholder="Введите название стока" />
              </Form.Item>
            </Col>
            <Divider style={{ margin: '10px 0' }} />
            <Col lg={24} xs={24}>
              <h3>Контейнер</h3>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item label="Статус" name="status">
                <Select placeholder="Выберите из списка">
                  <Option value="В пути">В пути</Option>
                  <Option value="Прибыл">Прибыл</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item label="Владелец" name="owner">
                <Select
                  placeholder="Введите для поиска"
                  loading={loadingCustomers}
                  showSearch
                  filterOption={false}
                  onSearch={(value) =>
                    value.length > 0 ? searchCustomers(value) : getCustomers()
                  }
                  onChange={(value) => value && searchTasks(value)}
                >
                  {customers.length > 0 && (
                    <>
                      {customers.map((customer) => {
                        return (
                          <Option
                            key={customer.id}
                            // data-value={JSON.stringify(customer)}
                            value={
                              customer.short_name
                                ? customer.short_name
                                : customer.legal_name
                                ? customer.legal_name
                                : customer.email
                            }
                          >
                            {customer.short_name
                              ? customer.short_name
                              : customer.legal_name
                              ? customer.legal_name
                              : customer.email}
                          </Option>
                        );
                      })}
                    </>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item name={'task_number'} label={'Заявка'}>
                <Select
                  placeholder="Выбертие из списка"
                  // loading={loadingCustomers}
                  showSearch
                  filterOption={false}
                  onChange={(_, data) => {
                    const task = JSON.parse(data['data-value']);

                    if (task.transport_cargo_task.length > 0) {
                      setContainers(task.transport_cargo_task);
                    }
                  }}
                >
                  {tasks.length > 0 && (
                    <>
                      {tasks.map((task) => {
                        return (
                          <Option
                            key={task.id}
                            data-value={JSON.stringify(task)}
                            value={task.task_number}
                          >
                            {task.task_number}
                          </Option>
                        );
                      })}
                    </>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item name={'number_container'} label={'Номер контейнера'}>
                <Select placeholder="Выберите из списка" filterOption={false}>
                  {containers.length > 0 && (
                    <>
                      {containers.map((container) => {
                        return (
                          <Option
                            key={container.id}
                            data-value={JSON.stringify(container)}
                            value={container?.transport?.number_container}
                          >
                            {container?.transport?.number_container ||
                              'Не указано'}
                          </Option>
                        );
                      })}
                    </>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item name={'type_container'} label={'Тип контейнера'}>
                <Select placeholder="Выберите из списка" filterOption={false}>
                  {containers.length > 0 && (
                    <>
                      {containers.map((container) => {
                        return (
                          <Option
                            key={container.id}
                            data-value={JSON.stringify(container)}
                            value={
                              container?.transport?.transport_unit?.type_full
                            }
                          >
                            {container?.transport?.transport_unit?.type_full ||
                              'Не указано'}
                          </Option>
                        );
                      })}
                    </>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label="Дата сдачи" name={'delivery_date'}>
                <input className={'ant-input'} max="9999-12-31" type={'date'} />
              </Form.Item>
            </Col>
            <Col lg={24} xs={24}>
              <Form.Item label="Комментарий" name={'comment'}>
                <Input.TextArea
                  style={{ height: 120 }}
                  placeholder="Введите комментарий"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };

  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        {!hideToolbar ? (
          <>
            <Dropdown overlay={menu} trigger={['contextMenu']}>
              <Card>
                <CardContent>
                  <Box style={{ display: 'flex' }}>
                    <Search placeholder="Поиск стока" enterButton />

                    <Button
                      style={{ marginLeft: 10 }}
                      type="ghost"
                      icon={<PlusOutlined />}
                      onClick={() => setOpenModal(true)}
                    >
                      Создать
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Dropdown>
            {renderAddModal()}
          </>
        ) : (
          <b
            style={{ cursor: 'pointer', float: 'right', marginBottom: '20px' }}
            onClick={() => setHideToolbar(false)}
          >
            Вернуть панель
          </b>
        )}
      </Box>
    </Box>
  );
};

export default StocksToolbar;
