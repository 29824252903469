import AttachmentsTable from './AttachmentsTable';
import PropTypes from 'prop-types';
import { restApiHost } from '../../../utils/appVariables';
import { message } from 'antd';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { convertObjFilterToUrl } from '../../../utils/getFilters';
import { call } from '../../../apiUtils/call';
import { userInformations } from '../../../contex';
import { ErrorBoundary } from '../../ErrorBoundary';
import { prepareAttachmentTypeForSelect } from '../../contracts/attachments/prepareAttachmentTypeForSelect';

const AttachmentTab = (props) => {
  const [attachmentList, selectedAttachment] = useState([]);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(null);
  const [contractList, setContractList] = useState([]);
  const { userInfo } = useContext(userInformations);
  const [choices, setChoices] = useState([]);
  const scrollingLoadedListRef = useRef(null);

  useEffect(async () => {
    props.customerId &&
      (await getAttachmentAndCreateDefaultFilters(props.customerId));
    const filter = generateDefaultFilters(props.customerId);
    await getCustomerContracts(1, filter, props.customerId);
    await getChoices();
  }, []);

  const getAttachmentAndCreateDefaultFilters = async (customerId) => {
    const filters = generateDefaultFilters(customerId);
    await handleUpdate(1, filters, customerId);
  };

  const generateDefaultFilters = (customerId) => {
    return {
      filter: {
        customer: customerId ? customerId.toString() : null,
      },
      order: {},
    };
  };

  const getAttachmentCustomer = async (
    page = 1,
    params,
    customerId,
    concatData,
  ) => {
    setLoading(true);
    try {
      let urlParams = ``;

      if (params) {
        urlParams = convertObjFilterToUrl(params, null);
      }
      const url = urlParams
        ? `${restApiHost}/customers/contract_attachments/?page=${page}${urlParams}`
        : `${restApiHost}/customers/contract_attachments/?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
        true,
      );

      if (json?.data) {
        concatData
          ? selectedAttachment((prevState) => prevState.concat(json?.data))
          : selectedAttachment(json?.data);
        setTotal(json?.count);
      }
    } catch (e) {
      message.error('Ошибка запроса получения вложений', e);
    } finally {
      setLoading(false);
    }
  };
  const getCustomerContracts = async (page = 1, params) => {
    let urlParams = ``;

    if (params) {
      urlParams = convertObjFilterToUrl(params, null);
    }

    try {
      const url = urlParams
        ? `${restApiHost}/customers/contracts/?page=${page}${urlParams}`
        : `${restApiHost}/customers/contracts/?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
        true,
      );

      if (json?.data) {
        setContractList(json.data);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка получения договоров');
    }
  };

  const searchCustomerContracts = async (customerId, contractCode) => {
    try {
      const url =
        contractCode?.length > 0
          ? `${restApiHost}/customers/contracts/?page=1&customer=${customerId}&code_contract__icontains=${contractCode}`
          : `${restApiHost}/customers/contracts/?page=1&customer=${customerId}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
        true,
      );

      if (json?.data) {
        setContractList(json.data);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка получения договоров');
    }
  };

  const getChoices = async () => {
    try {
      const url = `${restApiHost}/customers/type_choices/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );
      if (json?.data) {
        setChoices(json.data);
      }
    } catch (e) {
      console.log('get choices err', e);
    }
  };

  const handleUpdate = async (
    page = 1,
    params,
    customerId,
    concatData = false,
  ) => {
    setPage(page);
    setFilters(params);
    await getAttachmentCustomer(page, params, customerId, concatData);
  };

  const handleScrollToTopMobileList = useCallback(() => {
    if (scrollingLoadedListRef.current?.childNodes[0]?.childNodes[0]) {
      scrollingLoadedListRef.current.childNodes[0].childNodes[0].scrollTop = 0;
    }
  }, [scrollingLoadedListRef]);

  return (
    <ErrorBoundary>
      <AttachmentsTable
        attachmentList={attachmentList}
        contractList={contractList}
        loading={loading}
        page={page}
        filter={filters}
        customerId={props.customerId}
        total={total}
        scrollingLoadedListRef={scrollingLoadedListRef}
        handleScrollToTopMobileList={handleScrollToTopMobileList}
        typesAttachmentList={prepareAttachmentTypeForSelect(
          choices?.attachment_type,
        )}
        searchCustomerContracts={(customerId, contractCode) =>
          searchCustomerContracts(customerId, contractCode)
        }
        updatePage={(page) => setPage(page)}
        defaultFilter={[{ name: 'customer', value: props.customerId }]}
        handleUpdate={(page, filters, concatData) =>
          handleUpdate(page, filters, props.customerId, concatData)
        }
      />
    </ErrorBoundary>
  );
};

export default AttachmentTab;

AttachmentTab.propTypes = {
  customerId: PropTypes.number,
};
