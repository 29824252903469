import React, { useState } from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import CallEndIcon from '@material-ui/icons/CallEnd';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PhoneTimer from './PhoneTimer';
import { message } from 'antd';
import { restApiHost } from '../utils/appVariables';

/*function getSteps() {
  return ['Профиль клиента', 'Подача контейнера', 'Выбор маршрута отправления'];
}*/

const IncomingCall = ({ avatar, typeCall, visible, setVisible, user }) => {
  const [, setCallAccept] = useState(false);
  const [, setOpen] = useState(false);
  const [startCall, setStartCall] = useState(false);
  //const [personData, setPersonData] = useState(undefined);
  const [callData, setCallData] = useState(null);

  const mock_data = {
    avatar: '/static/images/avatars/avatar_6.png',
    company: user?.company_name,
    firstName: user?.contact_person,
    lastName: 'Гуменников',
    phone: user?.phone_number,
    email: user?.email,
    address: user?.address,
  };

  const callReject = async (call_id) => {
    try {
      const url = `${restApiHost}/telephony/stop_call/2/${call_id}`;
      const res = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      const json = await res.json();

      if (json.status == 'ok') {
        setCallData(null),
          setStartCall(false),
          message.success('Вызов завершен');
      }

      if (json.status == 'error') {
        message.error(json.message);
      }
      setVisible(false);
    } catch (e) {
      console.log('reject call err', e);
      message.error('Ошибка запроса на отклонение звонка');
    }
  };
  const call = async (phone_number) => {
    try {
      const url = `${restApiHost}/telephony/start_call/2`;
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ phone_number }),
      });
      const json = await res.json();

      if (json.status == 'ok') {
        setStartCall(true);
        setCallData(json);
        setCallAccept(true);
      }

      if (json.status == 'ok') {
        message.error(json.message);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса совершения звонка');
    }
  };

  const acceptСall = (phone) => {
    call(phone);
    // data && setPersonData(data);
  };

  /*  const handleSaveLeed = () => {
    console.log('it works!');
  };*/

  return (
    <>
      {visible ? (
        <>
          {!startCall ? (
            <div className={'incoming-call'}>
              <div className="incoming-call-wrapper">
                <div className="incoming-call__avatar">
                  {avatar ? <img src={avatar} alt="" /> : <AccountCircleIcon />}
                </div>
                <div className="incoming-call__info">
                  <div className="incoming-call__name">{mock_data.company}</div>
                  <p style={{ margin: 0, fontSize: '13px' }}>
                    {mock_data.phone}
                  </p>
                  <div className="incoming-call__type-call">
                    {'Мобильный телефон'}
                  </div>
                </div>
                <div className="incoming-call__action-btns">
                  <div
                    className="incoming-call__action-btn accept"
                    onClick={() => acceptСall(mock_data.phone)}
                  >
                    <div className="circle1"></div>
                    <div className="circle2"></div>
                    <PhoneIcon />
                  </div>
                  <div
                    className="incoming-call__action-btn decline"
                    onClick={() => setVisible(false)}
                  >
                    <CallEndIcon />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={'incoming-call'} onClick={() => setOpen(true)}>
              <div className="incoming-call-wrapper">
                <div className="incoming-call__avatar">
                  {avatar ? <img src={avatar} alt="" /> : <AccountCircleIcon />}
                </div>
                <div className="incoming-call__info">
                  <div className="incoming-call__name">{mock_data.company}</div>
                  <div className="incoming-call__type-call">
                    {typeCall ? typeCall : 'Продолжителньность вызова '}
                    <PhoneTimer />
                  </div>
                </div>
                <div className="incoming-call__action-btns">
                  <div
                    className="incoming-call__action-btn decline"
                    onClick={(e) => {
                      e.stopPropagation();
                      //   setVisible(false);
                      callReject(callData?.new_call_id);
                    }}
                  >
                    <CallEndIcon />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default IncomingCall;
