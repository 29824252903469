import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Popconfirm,
  Row,
  Select,
  Tooltip,
} from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
  getStaffList,
  searchStaffList,
} from '../../../../../logic/staff/StaffApiFunctions';
import { userInformations } from '../../../../../contex';
import '../header/HeaderCalendarTaskDrawer.css';
import TextArea from 'antd/es/input/TextArea';
import {
  ArrowRightOutlined,
  CheckOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { getBranchList } from '../../../../../logic/branch/BrachApiFunctional';
import { getBranchId } from '../../../../../localStorageUtils/getBranchId';
import useCheckMobileScreen from '../../../../../hooks/useCheckMobileScreen';

const { Option } = Select;
const BodyCalendarTaskDrawer = (props) => {
  const { userInfo } = useContext(userInformations);
  const [staffList, setStaffList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [branchId, setBranchId] = useState(
    userInfo.current?.haveViewBranch
      ? getBranchId()
      : userInfo.current?.branch?.id,
  );
  const isMobile = useCheckMobileScreen();

  useEffect(async () => {
    if (userInfo.current?.haveViewBranch) {
      setBranchId(getBranchId());
    } else if (userInfo.current?.branch?.id) {
      setBranchId(userInfo.current.branch.id);
    }

    if (userHavePermissionAddEventsToAnotherBranch()) {
      await callGetBranchList();
    }
  }, []);

  useEffect(async () => {
    console.log('branchId', branchId);
    await callGetStaffList(branchId);
  }, [branchId]);

  const userHavePermissionAddEventsToAnotherBranch = () => {
    return userInfo.current?.permissions?.some(
      (o) => o.codename === 'add_events_to_another_branch',
    );
  };

  const handleChangeMembers = async (selectedMembers) => {
    if (selectedMembers?.length > 0 && !props.editingItem) {
      props.setVisibleCheckboxGeneral(true);
    } else if (selectedMembers?.length <= 0 && !props.editingItem) {
      props.setVisibleCheckboxGeneral(false);
    }
  };

  const callGetStaffList = async (branchId) => {
    const staffList = await getStaffList(userInfo, true, branchId);
    setStaffList(staffList?.data ? staffList.data : []);
  };

  const callGetBranchList = async () => {
    const branchList = await getBranchList(userInfo, true);
    setBranchList(branchList?.data ? branchList.data : []);
  };

  const callSearchStaff = async (keyword, branchId) => {
    if (!keyword) return;
    const staffList = await searchStaffList(keyword, userInfo, true, branchId);
    setStaffList(staffList?.data ? staffList.data : []);
  };

  const getLabelBntIsLeadOrCustomer = () => {
    if (props.editingItem?.lead?.id) {
      return 'Перейти к лиду';
    }
    if (props.editingItem?.customer?.id) {
      return 'Перейти к контрагенту';
    }
  };

  const renderMembers = () => {
    return props.editFields ? (
      <>
        <Col lg={16} md={15}>
          {userHavePermissionAddEventsToAnotherBranch() ? (
            <Form.Item label={'Филиал сотрудника:'} name={'branches'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                placeholder={'Филиал сотрудника:'}
                onChange={(val) => {
                  setBranchId(val);
                }}
                allowClear={false}
                defaultValue={branchId}
                value={branchId}
              >
                {branchList.length > 0 ? (
                  <>
                    {branchList.map((item, index) => (
                      <Option key={index} value={item?.id}>
                        {item?.name}
                      </Option>
                    ))}
                  </>
                ) : (
                  <Option disabled value={null}>
                    Нет доступных вариантов
                  </Option>
                )}
              </Select>
            </Form.Item>
          ) : (
            <></>
          )}
          <Form.Item label={'Кому:'} name={'members'}>
            <Select
              getPopupContainer={(trigger) =>
                isMobile ? trigger.parentNode : document.body
              }
              disabled={props.isCallToLeadOrCustomer}
              placeholder={isMobile ? 'Выберите из списка' : 'Кому:'}
              onSelect={async (value) => {
                if (props.editingItem) {
                  props.onCreateMembersOnEvent(value);
                }
                await callGetStaffList(branchId);
              }}
              onDeselect={async (value) => {
                if (props.editingItem) {
                  props.onDeleteMembersOnEvent(value);
                }
                await callGetStaffList(branchId);
              }}
              onChange={async (val) => {
                await handleChangeMembers(val);
              }}
              allowClear={false}
              onSearch={async (val) => {
                if (val?.length > 0) {
                  await callSearchStaff(val, branchId);
                } else {
                  await callGetStaffList(branchId);
                }
              }}
              filterOption={false}
              showSearch
              mode={'multiple'}
            >
              {staffList.length > 0 ? (
                <>
                  {staffList.map((item, index) => (
                    <Option key={index} value={item?.id}>
                      {item?.full_name}
                    </Option>
                  ))}
                </>
              ) : (
                <Option disabled value={null}>
                  Нет доступных вариантов
                </Option>
              )}
            </Select>
          </Form.Item>
        </Col>
      </>
    ) : (
      <>
        <Col lg={20} xs={24}>
          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
            {`Кому: ${
              props.editingItem &&
              props.editingItem?.members
                ?.filter((member) => member?.group === 'mem')
                .map((member) => {
                  return member?.full_name;
                })
                .join(', ')
            }`}
          </div>
        </Col>
      </>
    );
  };
  return (
    <>
      <div className={'BodyCalendarTaskDrawer'}>
        <Row justify={'end'} style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {props.editingItem ? (
              <div>
                <Tooltip title={'Готово'}>
                  <Button
                    style={{
                      backgroundColor: 'rgb(83,203,69)',
                      color: 'white',
                      border: 'none',
                    }}
                    onClick={async () => {
                      props.callCreateEvent(true);
                    }}
                    icon={<CheckOutlined />}
                  />
                </Tooltip>
                <Tooltip title={'Удалить'}>
                  <Popconfirm
                    title="Вы действительно хотите удалить задачу?"
                    onConfirm={() =>
                      props.callDeleteEvent(props.editingItem?.id)
                    }
                    okText="Да"
                    cancelText="Нет"
                    placement="rightBottom"
                  >
                    <Button
                      style={{
                        backgroundColor: 'rgb(224,54,54)',
                        color: 'white',
                        border: 'none',
                        marginLeft: '10px',
                      }}
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                </Tooltip>
                {props.isCallToLeadOrCustomer ? (
                  <Tooltip title={getLabelBntIsLeadOrCustomer()}>
                    <Button
                      style={{
                        backgroundColor: 'rgba(79,226,243,0.82)',
                        color: 'white',
                        border: 'none',
                        marginLeft: '10px',
                      }}
                      onClick={async () => {
                        props.handleRedirectToLeadOrCustomer();
                      }}
                      icon={<ArrowRightOutlined />}
                    />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </Row>
        <Form
          form={props.form}
          onChange={() => props.setEditFields(true)}
          className={'reset-margin-form'}
          layout={'vertical'}
        >
          <Row gutter={16}>
            {renderMembers()}
            {!props.editingItem && props.visibleCheckboxGeneral ? (
              <Col
                lg={8}
                md={7}
                style={{
                  display: 'flex',
                  alignContent: 'end',
                  alignItems: 'end',
                }}
              >
                <Form.Item name={'general'} valuePropName={'checked'}>
                  <Checkbox disabled={props.editingItem}>
                    <span style={{ color: 'black' }}>
                      Поставить общую задачу
                    </span>
                  </Checkbox>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            <Col lg={24} xs={24} className={'gutter-top'}>
              <Form.Item
                label={'Срок выполнения'}
                name={'deadline'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <input
                  disabled={props.isCallToLeadOrCustomer}
                  className={'ant-input'}
                  type="datetime-local"
                  max="9999-12-31"
                  pattern="[0-2][0-9]:[0-5][0-9]"
                  min={moment().format('YYYY-MM-DD hh:mm')}
                />
              </Form.Item>
            </Col>
            {props.isCallToLeadOrCustomer ? (
              <Col lg={24} xs={24} className={'gutter-top'}>
                <Form.Item label="След. общение" name={'date_next_request'}>
                  <input
                    className={'ant-input'}
                    type="datetime-local"
                    max="9999-12-31"
                    pattern="[0-2][0-9]:[0-5][0-9]"
                    min={moment().format('YYYY-MM-DD hh:mm')}
                  />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            <Col lg={24} xs={24} className={'gutter-top'}>
              <Form.Item
                label={'Описание'}
                name={'description'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <TextArea
                  placeholder={
                    isMobile ? 'Введите описание задачи' : 'Описание'
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

BodyCalendarTaskDrawer.propTypes = {
  form: PropTypes.any,
  editingItem: PropTypes.object,
  isCallToLeadOrCustomer: PropTypes.bool,
  editFields: PropTypes.bool,
  visibleCheckboxGeneral: PropTypes.bool,
  setVisibleCheckboxGeneral: PropTypes.func,
  setEditFields: PropTypes.func,
  callCreateEvent: PropTypes.func,
  callDeleteEvent: PropTypes.func,
  handleRedirectToLeadOrCustomer: PropTypes.func,
};

export default BodyCalendarTaskDrawer;
