import PropTypes from 'prop-types';
import { Button, Col, Form, Modal, Row, Select, Spin } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import searchTimezone from '../../../logic/refbook/timezone/TimeZoneApiFunctions';
import { userInformations } from '../../../contex';
import useAsyncEffect from 'use-async-effect';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';
import { Option } from 'antd/es/mentions';

const CitesModal = (props) => {
  const [timezoneList, setTimezoneList] = useState([]);
  const { userInfo } = useContext(userInformations);
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    if (props.visible) {
      await handleGetTimezoneList();
    }
  }, [props.visible]);

  useAsyncEffect(async () => {
    if (props.editingItem?.timezoneName) {
      await handleGetTimezoneList(props.editingItem.timezoneName);
      await handleGetTimezoneList();
    }
  }, [props.editingItem]);

  const handleGetTimezoneList = useCallback(
    async (keyword = null) => {
      const res = await searchTimezone(keyword, userInfo);
      setTimezoneList(res.length > 0 ? res : []);
    },
    [searchTimezone, setTimezoneList],
  );

  return (
    <>
      <Modal
        title={`${props.editingItem ? 'Редактирование' : 'Добавление'}`}
        visible={props.visible}
        onCancel={props.handleCancelModal}
        footer={[]}
      >
        <Spin spinning={props.loading}>
          <Form
            layout={'vertical'}
            form={props.form}
            onFinish={(values) => {
              props.submitForm(values, props.editingItem, props.deleteItem);
            }}
          >
            <Row gutter={16}>
              {props.fields?.length > 0 &&
                props.fields.map((item) => {
                  return item[0] !== 'timezone'
                    ? props.getComponent(item[1]?.type, item)
                    : '';
                })}
              <Col lg={12} xs={24} key={'timezone'}>
                <Form.Item
                  rules={[{ required: true, message: 'Обязательно поле!' }]}
                  label={'Временная зона'}
                  name={'timezone'}
                >
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    showSearch={true}
                    filterOption={false}
                    onSearch={async (keyword) => {
                      await handleGetTimezoneList(keyword);
                    }}
                    placeholder={'Временная зона'}
                  >
                    {timezoneList &&
                      timezoneList.map((choice) => {
                        return (
                          <Option value={choice?.id} key={choice?.id}>
                            {choice.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify={'end'} gutter={10}>
              <Button type={'primary'} htmlType={'submit'}>
                {props.editingItem ? 'Сохранить' : 'Добавить'}
              </Button>
              <Col style={{ marginLeft: '10px' }}>
                {props.editingItem ? (
                  <Button
                    onClick={() => {
                      props.submitForm(null, props.editingItem, true);
                    }}
                  >
                    {'Удалить'}
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

CitesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  form: PropTypes.any,
  fields: PropTypes.any,
  editingItem: PropTypes.any,
  deleteItem: PropTypes.any,
  getComponent: PropTypes.func,
  setDeleteItem: PropTypes.func,
  submitForm: PropTypes.func,
  handleCancelModal: PropTypes.func,
};

export default CitesModal;
