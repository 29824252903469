import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import './App.css';
import { useEffect, useRef } from 'react';
import RouterForPage from './router/RouterForPage';
import { userInformations } from './contex';
import FaviconReactiveIcon from './favicon/FaviconReactiveIcon';

const App = () => {
  const userInfo = useRef({});
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <FaviconReactiveIcon />
      <userInformations.Provider value={{ userInfo }}>
        <GlobalStyles />
        <RouterForPage />
      </userInformations.Provider>
    </ThemeProvider>
  );
};

export default App;
