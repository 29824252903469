import PropTypes from 'prop-types';
import '../../leads/list/leadListItem.css';
import React, { useCallback } from 'react';
import { Card, Col, Divider, Dropdown, Row, Space } from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';

const CustomerListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>
              {props.customer?.short_name || '-'}
            </Col>
            <Col className={'secondText'}>
              {props.customer?.type_str || '-'}
            </Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.customer)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.customer]);

  return (
    <>
      <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[0, 15]}>
          <Col xs={24}>
            <div className={'secondText'}>{'Юр.имя'}</div>
            <div className={'valueText'}>
              {props.customer?.legal_name || '-'}
            </div>
          </Col>

          <Col>
            <div className={'secondText'}>{'Тип компании'}</div>
            <div className={'valueText'}>
              {`${
                props.customer?.category?.length > 0 &&
                props.customer?.category
                  .map((item) => item?.category_str)
                  .join(', ')
              }` || '-'}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

CustomerListItem.propTypes = {
  customer: PropTypes.object,
  menuActions: PropTypes.func,
};

export default CustomerListItem;
