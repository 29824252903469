import PropTypes from 'prop-types';
import InputColor from 'react-input-color';

const ColorPicker = (props) => {
  return (
    <div>
      <InputColor
        initialValue={props.color}
        onChange={(color) => props.setColor(color)}
        placement="right"
      />
      {/* <div
                style={{
                    width: 50,
                    height: 50,
                    marginTop: 20,
                    backgroundColor: props.color,
                }}
            />*/}
    </div>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  setColor: PropTypes.func,
};

export default ColorPicker;
