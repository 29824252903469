import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';
import { convertObjFilterToUrl } from '../../utils/getFilters';

export default async function getLeedsList(page, params, userInfo) {
  let urlParams = ``;
  if (params) {
    console.log(params, 'params getLeedsList if');
    urlParams = convertObjFilterToUrl(params);
  }
  try {
    const url = `${restApiHost}/leads/list/?page=${page}${urlParams}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
      true,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    console.log('fetch leed error', e);
    message.error('Ошибка загрузки списка лидов');
  }
}

export async function editLead(leadId, body, userInfo) {
  try {
    const url = `${restApiHost}/leads/list/${leadId}/`;
    const json = await call(
      url,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;

    message.success('Основная информация лида успешно отредактирована!');
    return json;
  } catch (e) {
    console.log('lead edit error', e);
    message.error('Ошибка редактирования лида');
  }
}

export async function createLead(body, userInfo) {
  try {
    const url = `${restApiHost}/leads/list/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    console.log('add error new lead', e);
    message.error('Ошибка запроса на добавления лида');
  }
}

export async function createLeadContact(body, userInfo) {
  try {
    const url = `${restApiHost}/leads/contact_persons/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    console.log('createLeadContact err', e);
    message.error('Ошибка запроса на добавления контактного лица лида');
  }
}

export async function getLeadById(leadId, userInfo) {
  try {
    const url = `${restApiHost}/leads/list/${leadId}/`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );
    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка запроса на получение лида по id');
    console.log('get lead by id err', e);
  }
}
export async function getChoicesLead(userInfo) {
  try {
    const url = `${restApiHost}/leads/type_choices`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json?.data) {
      return json.data;
    }
  } catch (e) {
    message.error('Ошибка получение списка типов для лидов');
    console.log('get choices err', e);
  }
}

export async function getLeadCallsHistory(leadId, page = 1, userInfo) {
  try {
    const url = `${restApiHost}/telephony/lead_calls/${leadId}?page=${page}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json) return;

    return json;
  } catch (e) {
    console.log('get history calls err', e);
    message.error('Ошибка получения истории вызовов');
  }
}

export async function getContactsLead(leadId, userInfo) {
  if (!leadId) return;
  try {
    const url = `${restApiHost}/leads/contact_persons/?lead=${leadId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение списка контактных лиц лида');
    console.log('get getContactsLead err', e);
  }
}

export async function getCommPropHistoryLead(leadId, userInfo, page) {
  if (!leadId) return;
  try {
    const url = `${restApiHost}/leads/comm_prop_history/?lead=${leadId}&page=${page}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение списка отправленых кп лида');
    console.log('getCommPropHistoryLead err', e);
  }
}

export async function getManagersGroupsLead(leadId, userInfo) {
  if (!leadId) return;
  try {
    const url = `${restApiHost}/leads/lead_managers/?lead=${leadId}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (json) {
      return json;
    }
  } catch (e) {
    message.error('Ошибка получение списка контактных лиц лида');
    console.log('get getContactsLead err', e);
  }
}
