import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import ApplicationsListToolbar from '../components/applications/ApplicationsListToolbar';
import { restApiHost } from '../utils/appVariables';
import PreAppicationsTable from '../components/applications/PreAppicationsTable';
import { convertObjFilterToUrl } from '../utils/getFilters';
import { useLocation } from 'react-router-dom';
import { userInformations } from '../contex';
import { call } from '../apiUtils/call';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import formationDocumentTitle from '../utils/formationDocumentTitle';
import useCheckMobileScreen from '../hooks/useCheckMobileScreen';

const PreApplications = () => {
  const params = useLocation();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [modalPreApp, setModalPreApp] = useState(null);
  const { userInfo } = useContext(userInformations);
  const [preTaskForKP, setPreTaskForKP] = useState(null);
  const [tableRows, setTableRows] = useState([]);
  const [filters, setFilters] = useState(null);
  const [page, setPage] = useState(1);
  const [visibleFilters, setVisibleFilters] = useState(false);
  const [visibleTableSetting, setVisibleTableSetting] = useState(false);
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );
  const isMobile = useCheckMobileScreen();
  const scrollingLoadedListRef = useRef(null);

  useEffect(async () => {
    await handleUpdate(1, null, false);
  }, []);

  useEffect(() => {
    if (params?.state?.id) {
      setModalPreApp(params.state);
    } else {
      setModalPreApp(null);
    }
  }, [params?.state?.id]);

  useEffect(() => {
    if (applications) {
      setTableRows(applications);
    }
  }, [applications]);

  const setLoadingSpin = (value) => {
    setLoading(value);
  };
  const handleUpdate = async (page = 1, params, me, concatData = false) => {
    setPage(page ? page : 1);
    setFilters(params);
    await getApplications(page, params, me, concatData);
  };

  const getApplications = async (page = 1, params, me, concatData = false) => {
    let urlParams;
    setLoading(true);

    if (params) {
      urlParams = convertObjFilterToUrl(params, null);
    }

    try {
      const url =
        urlParams && me
          ? `${restApiHost}/leads/pre_tasks/?filter=me&page=${page}${urlParams}`
          : urlParams && !me
          ? `${restApiHost}/leads/pre_tasks/?page=${page}${urlParams}`
          : !urlParams && me
          ? `${restApiHost}/leads/pre_tasks/?filter=me&page=${page}`
          : `${restApiHost}/leads/pre_tasks/?page=${page}`;

      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
        true,
      );

      if (!json) return;

      concatData
        ? setApplications((prevState) => prevState.concat(json?.data))
        : setApplications(json?.data ? json.data : []);
      setTotal(json.count);
    } catch (e) {
      console.log('fetch applications error', e);
    } finally {
      setLoading(false);
    }
  };

  const handleSendKpApplication = (task) => {
    setPreTaskForKP(task);
  };

  const handleScrollToTopMobileList = useCallback(() => {
    if (scrollingLoadedListRef.current?.scrollTop) {
      scrollingLoadedListRef.current.scrollTop = 0;
    }
  }, [scrollingLoadedListRef]);

  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle(
            'Пред. заявки | Квик Транс',
            notificationCount,
          )}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ErrorBoundary>
            <ApplicationsListToolbar
              applications={applications}
              totalApplications={total}
              handleUpdate={async (page, params) => {
                isMobile && handleScrollToTopMobileList();
                await handleUpdate(isMobile ? 1 : page, params, false, false);
              }}
              loading={loading}
              tableFilters={filters}
              page={page}
              handleScrollToTopMobileList={handleScrollToTopMobileList}
              changeVisibleFilters={async () => {
                setVisibleFilters(!visibleFilters);
                if (visibleFilters) {
                  await handleUpdate(1, null, false, false);
                }
              }}
              changeVisibleTableSetting={() =>
                setVisibleTableSetting(!visibleTableSetting)
              }
              //search={searchPreApp}
              handleSendKpApplication={(task) => handleSendKpApplication(task)}
            />
          </ErrorBoundary>
          <Box sx={{ pt: 3 }}>
            <ErrorBoundary>
              <PreAppicationsTable
                modalPreApp={modalPreApp}
                total={total}
                tableFilters={filters}
                pageProps={page}
                loading={loading}
                applications={tableRows}
                setLoading={(val) => setLoading(val)}
                visibleFilters={visibleFilters}
                setVisibleFilters={(visible) => {
                  setVisibleFilters(visible);
                }}
                scrollingLoadedListRef={scrollingLoadedListRef}
                handleScrollToTopMobileList={handleScrollToTopMobileList}
                visibleTableSetting={visibleTableSetting}
                onCloseDrawerTableSetting={() => setVisibleTableSetting(false)}
                //updateTable={async (page, params, filterMe) => await updateTable(page, params, filterMe)}
                updateTable={async (page, params, me, concatData) => {
                  console.log(page, params, me, concatData);
                  await handleUpdate(page, params, me, concatData);
                }}
                setLoadingSpin={setLoadingSpin}
                fromPreApplicationPage={true}
                preTaskForKP={preTaskForKP}
              />
            </ErrorBoundary>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PreApplications;
