export enum TodoNotificationCountActionTypes {
  SET_COUNT_NOTIFICATION_COUNT = 'SET_COUNT_NOTIFICATION_COUNT',
}

interface SetNotificationCount {
  type: TodoNotificationCountActionTypes.SET_COUNT_NOTIFICATION_COUNT;
  payload: number | null;
}

export type TodoActionNotificationCount = SetNotificationCount;
