import { useSortable } from '@dnd-kit/sortable';
import { useCallback } from 'react';
import { CSS } from '@dnd-kit/utilities';

/*
const SortableItemContext = createContext({
  attributes: {},
  listeners: undefined,
  ref() {},
});
*/

const SortableItemSidebarMenu = (props) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    //setActivatorNodeRef,
  } = useSortable({
    id: props.index ? props.index : '',
  });

  /*const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );*/

  const style = useCallback(() => {
    return {
      transition,
      transform: CSS.Transform.toString(transform),
      opacity: isDragging ? 0.4 : undefined,
    };
  }, [transition, transform, isDragging]);

  return (
    <>
      <div ref={setNodeRef} {...attributes} {...listeners} style={style()}>
        <>{props.children}</>
      </div>
    </>
  );
};

export default SortableItemSidebarMenu;
