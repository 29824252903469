import {
  Button as ButtonAntd,
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Select,
  Switch,
  Tabs,
  Tooltip,
} from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { KpUi } from '../leeds/editTemplateUtils/KpUi';
import { KpTableSum } from '../leeds/editTemplateUtils/KpTableSum';
import PreAppicationsTable from '../applications/PreAppicationsTable';
import React, { useContext, useEffect, useRef, useState } from 'react';
import html2pdf from 'html2pdf.js/src';
import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { userInformations } from '../../contex';
import { formationOfShoulderFromPoints } from './formationOfShoulderFromWayPoints';
import { Box, Card, CardContent } from '@material-ui/core';
import { ReloadOutlined } from '@ant-design/icons';
import { getCpTemplateById, getCpTemplates } from './getCpTemplates';
import { cpTemplateWithReplacedPlaceholders } from './replacePlaceholdersInCp/cpTemplateWithReplacedPlaceholders';
import { additionalStyleLine } from './replacePlaceholdersInCp/additionalStyleLine';
import * as ClassicEditor from '../../ckeditor';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

const CreationOfKpModal = ({
  visibleModal,
  preTasks,
  updatePreTask,
  loading,
  closeVisibleModal,
  pageTable,
  totalPreTask,
  tableFilters,
  setFileToFileList,
}) => {
  const [preTaskList, setPreTaskList] = useState([]);

  const [preShowKp, setPreShowKp] = useState(false);

  const [selectedTaskIds, setSelectedTaskIds] = useState([]);

  const [fullHtmlDataForKp, setFullHtmlDataForKp] = useState({ __html: `` });

  const { userInfo } = useContext(userInformations);

  const [kpData, setKpData] = useState('');

  const [activeTabMenuEditKp, setActiveTabMenuEditKp] = useState('1');

  const [searchPreTask, setSearchPreTask] = useState('');

  const [currentCpTemplateId, setCurrentCpTemplateId] = useState(null);

  const [cpTemplates, setCpTemplates] = useState([]);
  const [currentCpTemplateHtml, setCurrentCpTemplateHtml] = useState(null);
  const innerHTMLRef = useRef();
  const [colorTableSum, setColorTableSum] = useState({
    colorHead: '#4471C4',
    colorBody: '#CFD4EA',
    colorTextHead: '#ffffff',
    colorTextBody: '#000000',
  });

  /*kpTable*/
  const [showTableEditing, setShowTableEditing] = useState(false);

  const [isViewSentCP] = useState(false);

  let dataShoulderForTable = useRef([]);

  const [editDataForTable, setEitDataForTable] = useState('');

  const [tableKpIsEditing, setTableKpIsEditing] = useState(false);

  const [viewImgRuble] = useState(true);

  /*kpUi*/
  const [editDataForUi, setEitDataForUi] = useState('');

  const [uiKpIsEditing, setUiKpIsEditing] = useState(false);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (preTasks) {
      setPreTaskList(preTasks);
    }
  }, [preTasks]);

  useEffect(() => {
    getHtmlCP();
  }, [kpData, dataShoulderForTable, editDataForUi, editDataForTable]);

  /*useEffect(() => {
        const scriptChangeDisplay = `
        if  (document.getElementsByClassName('shoulder_table')[0]) {
        if (document.getElementsByClassName('shoulder_table')[0].offsetHeight > 200) {
        return false
           }
            }    return true`
        const exicutScript = new Function(scriptChangeDisplay)
        const viewImg =  exicutScript()
        setViewImgRuble(viewImg)
    },[fullHtmlDataForKp,activeTabMenuEditKp])*/

  useEffect(() => {
    getHtmlCP();
  }, [viewImgRuble]);

  useEffect(async () => {
    await getAndSetCpTemplates(userInfo);
  }, []);

  function addStyleToHtmlCP(innerHTMLRef) {
    let el = innerHTMLRef.current;
    if (el) {
      const style = additionalStyleLine(colorTableSum);
      const elementStyle = el.getElementsByTagName('style')[0];
      if (elementStyle?.textContent) {
        //const textContentWitchReplaceValue = `${elementStyle.textContent}`.replace('table, td { color: #000000; }', '')
        elementStyle.textContent = elementStyle?.textContent + style;
      }
    }
  }

  useEffect(() => {
    if (innerHTMLRef.current) {
      addStyleToHtmlCP(innerHTMLRef);
    }
  }, [fullHtmlDataForKp, innerHTMLRef.current]);

  const disableBtnPreShow =
    selectedTaskIds.length === 0 || currentCpTemplateId === null;

  const convertHtmlToPdf = (html) => {
    const opt = {
      filename: `Коммерческое предложение Квиктранс.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf()
      .set(opt)
      .from(html)
      .toPdf()
      .output('datauristring')
      .then(async (pdf) => {
        const blob = await (await fetch(pdf)).blob();
        let filename = `${opt.filename}`;
        const filePDF = new File([blob], filename, { type: 'application/pdf' });
        setFileToFileList(filePDF);
        setActiveTabMenuEditKp('1');
        setShowTableEditing(false);
        setPreShowKp(false);
        setSelectedTaskIds([]);
        setCurrentCpTemplateHtml(null);
        setCurrentCpTemplateId(null);
        innerHTMLRef.current = null;
      });
  };

  const savePdfFileToStorage = (html) => {
    const opt = {
      filename: `Коммерческое предложение Квиктранс.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 3, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
    if (html) {
      html2pdf()
        .set(opt)
        .from(html)
        .toPdf()
        .output('datauristring')
        .save()
        .then(() => message.success('PDF Сохранен'));
    }
  };

  const getPreviewKp = async (preTasksIds) => {
    console.log('getPreviewKp');
    try {
      let body = {
        pre_tasks: preTasksIds,
      };
      console.log('getPreviewKp');
      const url = `${restApiHost}/leads/preview/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        userInfo.current.haveViewBranch,
      );
      console.log(json);
      if (!json?.data) {
        setPreShowKp(false);
        return;
      }
      await getCpHtmlTemplatesById(currentCpTemplateId, userInfo);

      dataShoulderForTable.current = formationOfShoulderFromPoints(
        json?.data?.pretasks,
      );
      setEitDataForTable('');
      setTableKpIsEditing(false);
      setEitDataForUi('');
      setUiKpIsEditing(false);
      setKpData(json);
      return json;
    } catch (e) {
      console.log(e);
    }
  };

  const getCpHtmlTemplatesById = async (id, userInfo) => {
    const template = await getCpTemplateById(id, userInfo);
    if (template?.data?.html) {
      setCurrentCpTemplateHtml(
        `<div style="max-height: 1120px !important; overflow: hidden; position: relative">${template.data.html}</div>`,
      );
    }
    if (template?.data?.table_color) {
      const tableColorObject = JSON.parse(template.data.table_color);
      if (
        tableColorObject?.colorHead &&
        tableColorObject?.colorBody &&
        tableColorObject?.colorTextHead &&
        tableColorObject?.colorTextBody
      ) {
        setColorTableSum(tableColorObject);
      }
    }
  };

  const getAndSetCpTemplates = async (userInfo) => {
    const templates = await getCpTemplates(userInfo);
    if (templates) {
      setCpTemplates(templates);
    }
  };

  const getHtmlCP = () => {
    const data = {
      __html: cpTemplateWithReplacedPlaceholders(
        kpData,
        tableKpIsEditing
          ? editDataForTable
          : KpTableSum(dataShoulderForTable.current, colorTableSum),
        uiKpIsEditing ? editDataForUi : KpUi(kpData),
        currentCpTemplateHtml,
      ),
    };
    setFullHtmlDataForKp(data);
  };
  const changeTabMenuEditKP = (activeTab) => {
    setActiveTabMenuEditKp(activeTab);
  };
  const handleSearch = async (value) => {
    const firstPage = 1;
    if (value) {
      tableFilters?.filter
        ? await updatePreTask(firstPage, {
            ...tableFilters,
            filter: { ...tableFilters.filter, search: value },
          })
        : await updatePreTask(firstPage, {
            ...tableFilters,
            filter: { search: value },
          });
    } else {
      const newFilter = { ...tableFilters };
      delete newFilter?.filter?.search;
      updatePreTask(firstPage, newFilter);
    }
  };
  const onSelectChange = (keys) => {
    setSelectedTaskIds(keys);
  };

  const rowSelection = {
    selectedTaskIds,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record?.send_comm_prop, //record.send_comm_prop === true, - old value
    }),
  };

  return (
    <Modal
      width={1000}
      visible={visibleModal}
      title={'Сформировать КП'}
      onCancel={() => {
        setActiveTabMenuEditKp('1');
        setShowTableEditing(false);
        setPreShowKp(false);
        setSelectedTaskIds([]);
        setCurrentCpTemplateHtml(null);
        setCurrentCpTemplateId(null);
        innerHTMLRef.current = null;
        closeVisibleModal();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setActiveTabMenuEditKp('1');
            setShowTableEditing(false);
            setPreShowKp(false);
            setSelectedTaskIds([]);
            setCurrentCpTemplateHtml(null);
            setCurrentCpTemplateId(null);
            innerHTMLRef.current = null;
            closeVisibleModal();
          }}
        >
          Закрыть
        </Button>,
        <>
          {!preShowKp ? (
            <Tooltip title="Воспользуйтесь функцией предпросмотра">
              <Button
                style={{ marginLeft: '8px' }}
                type="primary"
                disabled={true}
              >
                Скачать
              </Button>
            </Tooltip>
          ) : (
            <Button
              onClick={async () => {
                message.info('Началось формирование pdf файла');
                await savePdfFileToStorage(fullHtmlDataForKp.__html);
              }}
              type="primary"
              disabled={disableBtnPreShow}
            >
              Скачать
            </Button>
          )}
        </>,
        <>
          {!preShowKp ? (
            <Tooltip title="Воспользуйтесь функцией предпросмотра">
              <Button
                style={{ marginLeft: '8px' }}
                type="primary"
                disabled={true}
              >
                {' '}
                Прикрепить PDF
              </Button>
            </Tooltip>
          ) : (
            <Button
              onClick={async () => {
                message.info('Началось формирование pdf файла');
                await convertHtmlToPdf(fullHtmlDataForKp.__html);
              }}
              type="primary"
              disabled={disableBtnPreShow}
            >
              Прикрепить PDF
            </Button>
          )}
        </>,
      ]}
    >
      <div style={{ width: '100%', marginBottom: 10 }}>
        <Row gutter={15}>
          <Col lg={24} md={24} xs={24}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                marginTop: '5px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    disabled={loading || preShowKp}
                    value={currentCpTemplateId ? currentCpTemplateId : null}
                    onChange={async (value) => {
                      setCurrentCpTemplateId(value);
                    }}
                    style={{ width: '200px' }}
                    filterOption={true}
                    placeholder={'Выберите шаблон КП из списка'}
                    notFoundContent={'Шаблоны отсутствуют'}
                  >
                    {cpTemplates?.length > 0 && (
                      <>
                        {cpTemplates.map((template, index) => {
                          return (
                            <Option key={index} value={template.id}>
                              {template?.name
                                ? template.name
                                : 'Название не заданно'}
                            </Option>
                          );
                        })}
                      </>
                    )}
                  </Select>
                </div>
                <Card style={{ width: '100%' }}>
                  <CardContent>
                    <Box style={{ display: 'flex' }}>
                      <Search
                        disabled={loading || preShowKp}
                        value={searchPreTask}
                        placeholder="Поиск заявки (id, название компании, контакт)"
                        enterButton
                        onSearch={(value) => handleSearch(value)}
                        onChange={(e) => {
                          setSearchPreTask(e.target.value);
                        }}
                      />
                      <Tooltip title={'Обновить'}>
                        <ButtonAntd
                          style={{ margin: '0 10px' }}
                          disabled={loading || preShowKp}
                          onClick={() => {
                            updatePreTask(pageTable, tableFilters);
                          }}
                          type="text"
                          icon={<ReloadOutlined />}
                        />
                      </Tooltip>
                    </Box>
                  </CardContent>
                </Card>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px',
                  }}
                >
                  <p style={{ marginRight: '10px' }}>Предпросмотр</p>
                  <Switch
                    style={{ width: '55px' }}
                    disabled={disableBtnPreShow}
                    checked={preShowKp}
                    onChange={async (e) => {
                      setPreShowKp(e);
                      e && (await getPreviewKp(selectedTaskIds));
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {preShowKp && kpData ? (
        <div>
          <Tabs
            activeKey={activeTabMenuEditKp}
            onChange={changeTabMenuEditKP}
            defaultActiveKey="1"
          >
            <TabPane tab="Просмотр КП" key="1" className="tab-panel-scroll">
              <div
                className="view-CP"
                ref={innerHTMLRef}
                dangerouslySetInnerHTML={fullHtmlDataForKp}
              />
            </TabPane>
            {!isViewSentCP ? (
              <TabPane tab="Редактировать КП" key="2">
                <div className="email-editor">
                  <CKEditor
                    editor={ClassicEditor}
                    data={uiKpIsEditing ? editDataForUi : KpUi(kpData)}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEitDataForUi(data);
                      if (!uiKpIsEditing) {
                        setUiKpIsEditing(true);
                      }
                    }}
                  />
                </div>
                <div style={{ marginTop: '10px', whiteSpace: 'nowrap' }}>
                  <Row
                    sm={5}
                    lg={2}
                    gutter={5}
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <Col>
                      <Switch
                        value={showTableEditing}
                        disabled={isViewSentCP}
                        defaultChecked={isViewSentCP}
                        onChange={(e) => {
                          setShowTableEditing(e);
                          if (!e) {
                            if (isViewSentCP) {
                              setEitDataForTable(
                                tableKpIsEditing
                                  ? editDataForTable
                                  : KpTableSum(
                                      dataShoulderForTable.current,
                                      colorTableSum,
                                    ),
                              );
                            } else {
                              setEitDataForTable(
                                KpTableSum(
                                  dataShoulderForTable.current,
                                  colorTableSum,
                                ),
                              );
                            }
                          }
                        }}
                      />
                    </Col>
                    <Col>
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      <p>Редактировать таблицу "Маршрут/Стоимость перевозки"</p>
                    </Col>
                  </Row>
                  {showTableEditing ? (
                    <div className="email-editor">
                      <CKEditor
                        editor={ClassicEditor}
                        data={
                          isViewSentCP && tableKpIsEditing
                            ? editDataForTable
                            : KpTableSum(
                                dataShoulderForTable.current,
                                colorTableSum,
                              )
                        }
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          if (!tableKpIsEditing) {
                            setTableKpIsEditing(true);
                          }
                          setEitDataForTable(data);
                        }}
                      />{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </TabPane>
            ) : (
              <></>
            )}
          </Tabs>
        </div>
      ) : (
        <PreAppicationsTable
          tableFilters={tableFilters}
          loading={loading}
          total={totalPreTask}
          pageProps={pageTable}
          fromPreApplicationPage={false}
          rowSel={rowSelection}
          applications={preTaskList}
          renderInOutPagePreTask={true}
          updateTable={updatePreTask}
        />
      )}
    </Modal>
  );
};

export default CreationOfKpModal;
