import PropTypes from 'prop-types';
import { defaultDropAnimationSideEffects, DragOverlay } from '@dnd-kit/core';

const SortableOverlay = (props) => {
  const dropAnimationOptions = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: '0.4',
        },
      },
    }),
  };

  return (
    <>
      <DragOverlay dropAnimation={dropAnimationOptions}>
        {props.children}
      </DragOverlay>
    </>
  );
};

SortableOverlay.propTypes = {
  children: PropTypes.any,
};

export default SortableOverlay;
