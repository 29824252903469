import { Button, Drawer, Form, Input } from 'antd';
import { call } from '../../../apiUtils/call';
import { restApiHost } from '../../../utils/appVariables';
import { userInformations } from '../../../contex';
import { useContext } from 'react';

export default function TableColumnModal({
  open,
  onClose,
  table,
  refreshTables,
}) {
  const [columnForm] = Form.useForm();
  const { userInfo } = useContext(userInformations);

  const onCloseModal = () => {
    columnForm.resetFields();
    onClose();
  };

  const addColumn = (values) => {
    if (table)
      call(
        `${restApiHost}/administration/table_columns/`,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...values,
            table: table.id,
          }),
        },
        userInfo.current.haveViewBranch,
        null,
      ).then(() => {
        refreshTables();
        onClose();
      });
  };

  const onAddColumn = () => {
    columnForm.validateFields().then(addColumn);
  };

  return (
    <Drawer title={'Добавление колонки'} visible={open} onClose={onCloseModal}>
      <Form form={columnForm}>
        <Form.Item name="name" label="Название колонки">
          <Input />
        </Form.Item>
        <Form.Item
          name="data_index"
          label="data_index"
          rules={[
            {
              required: true,
              message: 'Введите data_index!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="default_width"
          label="default_width"
          rules={[
            {
              required: true,
              message: 'Введите default_width !',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="group_index" label="group_index">
          <Input />
        </Form.Item>
      </Form>

      <Button onClick={onAddColumn}>Сохранить</Button>
    </Drawer>
  );
}
