import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import TabsStocks from '../components/stocks/StocksTabs';

const Stocks = () => {
  return (
    <>
      <Helmet>
        <title>Стоки | КвикТранс-Логистика</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          {/* <StaffListToolbar search={searchStaff} handleUpdate={handleUpdate} data={staffList.results}/> */}
          <Box sx={{ pt: 3 }}>
            <TabsStocks />
            {/* <StaffTable totalStaff={staffList.count} handleUpdate={handleUpdate} loading={loadingStaffList} data={staffList.results}/> */}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Stocks;
