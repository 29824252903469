import PropTypes from 'prop-types';
import '../../leads/list/leadListItem.css';
import React, { useCallback } from 'react';
import { Card, Col, Divider, Dropdown, Row, Space, Tag } from 'antd';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';

const PreTaskListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>
              {props.preTask?.pre_task_number || '-'}
            </Col>
            <Col className={'secondText'}>
              {props.preTask?.send_comm_prop ? (
                <Tag color={'green'}>Отправлено КП</Tag>
              ) : (
                <Tag color={'blue'}>Новая</Tag> || '-'
              )}
            </Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.preTask)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.preTask]);

  return (
    <>
      <Card className={'mainCard'} bodyStyle={{ padding: '12px' }}>
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[0, 10]}>
          <Row gutter={[0, 10]}>
            <Col xs={24}>
              <div className={'secondText'}>{'Юр.имя'}</div>
              <div className={'valueText'}>
                {props.preTask?.our_customer?.legal_name || '-'}
              </div>
            </Col>
          </Row>

          <Row gutter={20} justify={'start'}>
            <Col xs={12}>
              <div className={'secondText'}>{'Тип заявки'}</div>
              <div className={'valueText'}>
                {props.preTask?.template?.short || '-'}
              </div>
            </Col>
            <Col xs={12}>
              <div className={'secondText'}>{'Ответственный'}</div>
              <div className={'valueText'}>
                {props.preTask?.otv_manager || '-'}
              </div>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col xs={8}>
              <div className={'secondText'}>{'Сумма клиенту'}</div>
              <div className={'valueText'}>{props.preTask?.sum_str || '0'}</div>
            </Col>
            <Col xs={8}>
              <div className={'secondText'}>{'Предв. расходы'}</div>
              <div className={'valueText'}>
                {props.preTask?.upfronts_sum || '0'}
              </div>
            </Col>
            <Col xs={8}>
              <div style={{ minHeight: '44px' }} className={'secondText'}>
                {'Дельта'}
              </div>
              <div className={'valueText'}>
                {props.preTask?.delta_str || '0'}
              </div>
            </Col>
          </Row>
        </Row>
      </Card>
    </>
  );
};

PreTaskListItem.propTypes = {
  preTask: PropTypes.object,
  menuActions: PropTypes.func,
};

export default PreTaskListItem;
