export default function getColorTaskByStatus(status) {
  switch (status) {
    case 'my':
      return '#fff57e';
    case 'ass':
      return '#3d66ee';
    case 'don':
      return '#4eee3d';
    case 'unc':
      return '#ff0000';
    default:
      return '#fff57e';
  }
}
