import PropTypes from 'prop-types';
import { ConfigProvider, Empty } from 'antd';
import React from 'react';
import locale from 'antd/lib/locale-provider/ru_RU';
import SubsTabs from './subsTabs/SubsTabs';

const CustomerChangeHistoryTabs = (props) => {
  return (
    <>
      {props.subsHistory?.length > 0 ? (
        <>
          <SubsTabs subs={props.subsHistory} />
        </>
      ) : (
        <ConfigProvider locale={locale}>
          <Empty />
        </ConfigProvider>
      )}
    </>
  );
};
CustomerChangeHistoryTabs.propTypes = {
  currenTab: PropTypes.any,
  subsHistory: PropTypes.array,
};

export default CustomerChangeHistoryTabs;
