import { Empty, List, Spin } from 'antd';
import PermissionItem from './PermissionItem';

const PermissionList = ({
  loading,
  permissionList,
  update,
  handleAddSection,
  handleDeleteSection,
}) => {
  const renderPermission = (permission) => (
    <PermissionItem
      permission={permission}
      update={update}
      handleAddSection={handleAddSection}
      handleDeleteSection={handleDeleteSection}
    />
  );

  return (
    <Spin spinning={loading}>
      {permissionList && permissionList?.length > 0 && !loading ? (
        <List
          dataSource={permissionList}
          loading={loading}
          renderItem={renderPermission}
        />
      ) : (
        <>
          <Empty />
        </>
      )}
    </Spin>
  );
};

export default PermissionList;
