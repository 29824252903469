import React, { useContext, useEffect, useState } from 'react';
import {
  addSectionForPermission,
  deleteSectionForPermission,
  getAdministrationPermissions,
  searchAdministrationPermissions,
} from '../../../logic/permissions/PermissionsApiFunctions';
import { userInformations } from '../../../contex';
import PermissionList from './PermissionList';
import { ErrorBoundary } from '../../ErrorBoundary';
import PermissionModalAddSections from './PermissionModalAddSections';
import { Col, Row, Button, Input, Pagination, Drawer } from 'antd';

const Permissions = ({ open, permissions, setPermissions, onClose }) => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { userInfo } = useContext(userInformations);
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [currentPermission, setCurrentPermission] = useState(undefined);
  const [pageSize, setPageSize] = useState(20);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    callGetAdministrationPermissions(page, pageSize, userInfo);

    return () => {
      setPage(1);
      setPageSize(20);
    };
  }, []);

  const update = () => {
    callGetAdministrationPermissions(page, pageSize, userInfo);
  };

  const callGetAdministrationPermissions = async (
    page,
    pageSize,
    userInfo,
    searchString,
    clear,
  ) => {
    setLoading(true);
    let response;
    if (searchString && !clear) {
      response = await searchAdministrationPermissions(
        page,
        pageSize,
        userInfo,
        searchString,
      );
    } else {
      response = await getAdministrationPermissions(page, pageSize, userInfo);
      setSearchString('');
    }
    setPermissions(response?.data ? response.data : []);
    setTotal(response?.count ? response.count : []);
    setLoading(false);
  };

  const callAddSectionForPermission = async (body) => {
    const res = await addSectionForPermission(body, userInfo);
    if (res) {
      setOpenModal(false);
      await callGetAdministrationPermissions(page, pageSize, userInfo);
    }
  };

  const callDeleteSectionForPermission = async (permissionsSection) => {
    if (!permissionsSection) return;
    const res = await deleteSectionForPermission(
      permissionsSection.id,
      userInfo,
    );
    if (res) {
      await callGetAdministrationPermissions(page, pageSize, userInfo);
    }
  };

  return (
    <Drawer
      visible={open}
      title={'Права'}
      height={'100%'}
      placement="bottom"
      onClose={onClose}
    >
      <ErrorBoundary>
        <Row style={{ justifyContent: 'space-between' }}>
          <Col span={17}>
            <Input
              autoFocus={false}
              allowClear={true}
              placeholder={'Поиск...'}
              onChange={(element) => {
                setSearchString(element.target.value);
              }}
              value={searchString}
            />
          </Col>
          <Col>
            <Button
              onClick={async () => {
                await callGetAdministrationPermissions(
                  page,
                  pageSize,
                  userInfo,
                  searchString,
                );
              }}
              type="primary"
            >
              Найти
            </Button>
            <Button
              onClick={async () => {
                await callGetAdministrationPermissions(
                  page,
                  pageSize,
                  userInfo,
                  searchString,
                  true,
                );
              }}
              style={{
                backgroundColor: 'var(--accent-color)',
                color: 'var(--primary-color)',
                marginLeft: 10,
              }}
              type="primary"
            >
              Сбросить
            </Button>
          </Col>
        </Row>
        <PermissionList
          permissionList={permissions}
          loading={loading}
          update={update}
          handleAddSection={(permission) => {
            setCurrentPermission(permission);
            setOpenModal(true);
          }}
          handleDeleteSection={async (permissionSection) => {
            await callDeleteSectionForPermission(permissionSection);
          }}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <PermissionModalAddSections
          visible={openModal}
          addedSectionPermissions={
            currentPermission?.sections ? currentPermission.sections : []
          }
          currentPermission={currentPermission}
          addSectionForPermission={async (body) => {
            await callAddSectionForPermission(body);
          }}
          handleCancel={() => {
            setOpenModal(false);
          }}
        />
      </ErrorBoundary>

      <Pagination
        style={{ marginTop: 8 }}
        current={page}
        onChange={async (page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
          await callGetAdministrationPermissions(
            page,
            pageSize,
            userInfo,
            searchString,
          );
        }}
        total={total}
        pageSize={pageSize}
      />
    </Drawer>
  );
};

export default Permissions;
