export enum TodoUserInfoActionTypes {
  SET_USER_INFO = 'SET_USER_INFO',
}

interface SetUserInfo {
  type: TodoUserInfoActionTypes.SET_USER_INFO;
  payload: number | null;
}

export type TodoActionUserInfo = SetUserInfo;
