import React, { useCallback, useContext, useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { userInformations } from '../../contex';
import {
  getStaffList,
  searchStaffList,
} from '../../logic/staff/StaffApiFunctions';
import { useTypedSelector } from '../../store/hooks/useTypedSelector';
import useAsyncEffect from 'use-async-effect';
import { getManagersGroupsLead } from '../../logic/lead/LeadApiFunctions';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { ErrorBoundary } from '../ErrorBoundary';
import ScrollingLoadedList from '../mobile/scrollingLoadedList/ScrollingLoadedList';
import ManagersListItem from '../mobile/leads/leadModal/ManagersListItem';

const { Option, OptGroup } = Select;

const LeedsManagersTable = ({ leadId }) => {
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();
  const [currManager, setCurrManager] = useState(null);
  const [visibleAddManagerModal, setVisibleAddManagerModal] = useState(false);
  const [visibleEditManagerModal, setVisibleEditManagerModal] = useState(false);
  const [groupsManagers, setGroupsManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null);
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingManagers] = useState(false);
  const { userInfo } = useContext(userInformations);
  const demoAccessIsUsed = useTypedSelector(
    (state) => state.demoAccess.demoAccessIsUsed,
  );
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    if (leadId) {
      await handleGetManagersGroupsLead(leadId);
    }
    await callGetStaffList();
  }, [leadId]);

  const handleGetManagersGroupsLead = useCallback(
    async (leadId) => {
      setLoading(true);
      const res = await getManagersGroupsLead(leadId, userInfo);
      if (res?.data) {
        setGroupsManagers(res.data);
      }
      setLoading(false);
    },
    [leadId],
  );

  const menu = (manager) => {
    return (
      <Menu style={{ width: 200 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={async () => {
            setCurrManager(manager);
            await callSearchStaff(manager?.manager?.full_name);
            handleChangeSelectManager(null, {
              'data-value': JSON.stringify(manager?.manager),
            });
            setVisibleEditManagerModal(true);
            formEdit.setFieldsValue({
              group: manager.group,
              manager: manager.manager.id,
            });
          }}
        >
          Редактировать
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (manager) => (
        <Space size="middle">
          <Dropdown overlay={menu(manager)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      width: 50,
      dataIndex: 'id',
    },
    {
      title: 'Группа',
      key: 'group_str',
      dataIndex: 'group_str',
      width: 150,
    },
    {
      title: 'Менеджер',
      key: 'manager',
      dataIndex: 'manager',
      width: 200,
      render: (manager) => manager?.full_name,
    },
  ];

  const callGetStaffList = useCallback(async () => {
    const res = await getStaffList(userInfo);
    if (res?.data) {
      setManagers(res.data);
    }
  }, [setManagers, getStaffList]);

  const callSearchStaff = useCallback(
    async (keyword) => {
      const res = await searchStaffList(keyword, userInfo);
      if (res?.data) {
        setManagers(res.data);
      }
    },
    [setManagers, searchStaffList],
  );

  const addManagerGroup = useCallback(
    async (body) => {
      try {
        const url = `${restApiHost}/leads/lead_managers/`;
        const json = await call(
          url,
          {
            method: 'POST',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          },
          userInfo.current.haveViewBranch,
        );

        if (!json) return;

        setVisibleAddManagerModal(false);
        const newManager = {
          manager: selectedManager,
          id: json.id,
          group: json.group,
          group_str:
            json.group === 'prv'
              ? 'Привлёк'
              : json.group === 'otv'
              ? 'Ответственный'
              : 'Ведёт',
        };
        const newGroup = [...groupsManagers, newManager];
        setGroupsManagers(newGroup);
        setSelectedManager(null);
        formAdd.resetFields();
        message.success('Успешно добавлено');
      } catch (e) {
        console.log('add groupsManagers err', e);
        message.error(
          e?.message ? e.message : 'Ошибка запроса на добавление группы',
        );
      }
    },
    [
      groupsManagers,
      setGroupsManagers,
      setSelectedManager,
      setVisibleAddManagerModal,
    ],
  );

  const editManagerGroup = useCallback(
    async (body, id) => {
      try {
        const url = `${restApiHost}/leads/lead_managers/${id}/`;
        const json = await call(
          url,
          {
            method: 'PATCH',
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          },
          userInfo.current.haveViewBranch,
        );

        if (!json?.data) return;

        setVisibleEditManagerModal(false);
        const editIndex = groupsManagers.findIndex((item) => item.id === id);
        const copyData = [...groupsManagers];
        copyData[editIndex] = {
          manager: selectedManager,
          id: json?.data?.id,
          group: json?.data?.group,
          group_str:
            json?.data?.group === 'prv'
              ? 'Привлёк'
              : json?.data?.group === 'otv'
              ? 'Ответственный'
              : 'Ведёт',
        };

        setGroupsManagers(copyData);
        formEdit.resetFields();
        setSelectedManager(null);
        message.success('Успешно отредактировано');
      } catch (e) {
        console.log('edit groupsManagers err', e);
        message.error(
          e?.message ? e.message : 'Ошибка запроса на редактирование группы',
        );
      }
    },
    [
      selectedManager,
      groupsManagers,
      setVisibleEditManagerModal,
      setGroupsManagers,
      formEdit,
      setSelectedManager,
    ],
  );

  const handleChangeSelectManager = useCallback(
    (_, data) => {
      const parsedManager = JSON.parse(data['data-value']);
      const newSelectedManager = {
        id: parsedManager?.id,
        full_name: parsedManager?.full_name,
        phone_number: parsedManager?.phone_number,
        email: parsedManager?.email,
      };
      setSelectedManager(newSelectedManager);
    },
    [setSelectedManager],
  );

  const handleSearchManager = useCallback(
    async (e) => {
      if (e.target.value.length > 0) {
        await callSearchStaff(e.target.value);
      } else {
        await callGetStaffList();
      }
    },
    [callSearchStaff, callGetStaffList],
  );

  const handleEditManagerGroup = useCallback(
    async (record) => {
      if (!record) return;
      setCurrManager(record);
      await callSearchStaff(record?.manager?.full_name);
      handleChangeSelectManager(null, {
        'data-value': JSON.stringify(record?.manager),
      });
      setVisibleEditManagerModal(true);
      formEdit.setFieldsValue({
        group: record.group,
        manager: record.manager.id,
      });
    },
    [
      setCurrManager,
      callSearchStaff,
      handleChangeSelectManager,
      setVisibleEditManagerModal,
      formEdit,
    ],
  );

  return (
    <>
      {!isMobile ? (
        <Table
          dataSource={groupsManagers}
          columns={columns}
          size="small"
          bordered={true}
          loading={loading}
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'black-text table-row-color'
              : 'black-text table-row-white'
          }
          pagination={{ position: ['none'] }}
          onRow={(record) => {
            return {
              onDoubleClick: async () => {
                await handleEditManagerGroup(record);
              },
            };
          }}
        />
      ) : (
        <>
          <div style={{ paddingTop: '5px' }}>
            <ErrorBoundary>
              <ScrollingLoadedList
                dataList={groupsManagers}
                totalData={groupsManagers?.length}
                loadMoreItems={async () => {
                  //handleUpdate(page + 1, filters, false,true)
                }}
                renderListItem={(item) => (
                  <ManagersListItem
                    manager={item}
                    menuActions={(manager) => menu(manager)}
                  />
                )}
              />
            </ErrorBoundary>
          </div>
        </>
      )}

      <Modal
        visible={visibleAddManagerModal}
        title="Добавить сотрудника"
        onCancel={() => {
          setVisibleAddManagerModal(false);
          formAdd.resetFields();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisibleAddManagerModal(false);
              formAdd.resetFields();
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={demoAccessIsUsed}
            onClick={() => {
              formAdd.validateFields().then(async (values) => {
                let body = {
                  lead: leadId,
                  manager: values.manager,
                  group: values.group,
                };
                await addManagerGroup(body);
              });
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form form={formAdd} layout={'vertical'}>
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item
                name={'group'}
                label={'Группа'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <Select
                  placeholder="Выберите из списка"
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                >
                  <Option value={'prv'}>Привлек</Option>
                  <Option value={'vdt'}>Ведет</Option>
                  <Option value={'otv'}>Ответственный</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name={'manager'}
                label={'Менеджер'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  placeholder="Менеджер"
                  filterOption={false}
                  loading={loadingManagers}
                  onChange={handleChangeSelectManager}
                  onInput={async (e) => {
                    await handleSearchManager(e);
                  }}
                >
                  {managers && managers.length > 0 ? (
                    <>
                      {managers.map((item, index) => {
                        return (
                          <Option
                            key={index}
                            value={item.id}
                            data-value={JSON.stringify(item)}
                          >
                            {item.full_name ? item.full_name : item.email}
                          </Option>
                        );
                      })}
                    </>
                  ) : (
                    <OptGroup label={'Поиск сотрудников'}>Загрузка...</OptGroup>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        visible={visibleEditManagerModal}
        title="Редактировать сотрудника"
        onCancel={() => {
          setVisibleEditManagerModal(false);
          formEdit.resetFields();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisibleEditManagerModal(false);
              formEdit.resetFields();
            }}
          >
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={demoAccessIsUsed}
            onClick={() => {
              formEdit.validateFields().then(async (values) => {
                let body = {
                  lead: leadId,
                  manager: values.manager,
                  group: values.group,
                };
                await editManagerGroup(body, currManager.id);
              });
            }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form form={formEdit} layout={'vertical'}>
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item
                name={'group'}
                label={'Группа'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <Select
                  placeholder="Выберите из списка"
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                >
                  <Option value={'prv'}>Привлек</Option>
                  <Option value={'vdt'}>Ведет</Option>
                  <Option value={'otv'}>Ответственный</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name={'manager'}
                label={'Менеджер'}
                rules={[{ required: true, message: 'Обязательное поле!' }]}
              >
                <Select
                  getPopupContainer={(trigger) =>
                    isMobile ? trigger.parentNode : document.body
                  }
                  showSearch
                  placeholder="Менеджер"
                  filterOption={false}
                  loading={loadingManagers}
                  onChange={handleChangeSelectManager}
                  onInput={async (e) => {
                    await handleSearchManager(e);
                  }}
                >
                  {managers && managers.length > 0 ? (
                    <>
                      {managers.map((item, index) => {
                        return (
                          <Option
                            key={index}
                            value={item.id}
                            data-value={JSON.stringify(item)}
                          >
                            {item.full_name ? item.full_name : item.email}
                          </Option>
                        );
                      })}
                    </>
                  ) : (
                    <OptGroup label={'Поиск сотрудников'}>Загрузка...</OptGroup>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default LeedsManagersTable;
