import React, { useCallback, useContext, useState } from 'react';
import { userInformations } from '../../../../../../../contex';
import useAsyncEffect from 'use-async-effect';
import { getShippingTaskCostsList } from '../../../../../../../logic/task/TaskApiFunctions';
import { Button, Space, Tooltip } from 'antd';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { priceFormatter } from '../../../../../../../utils/priceFormatter';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '../../../../../../ErrorBoundary';
import CostsTable from './CostsTable';
import CostModal from './modal/CostModal';
import useStateRef from 'react-usestateref';
import { FilterOutlined } from '@ant-design/icons';
import useCheckMobileScreen from '../../../../../../../hooks/useCheckMobileScreen';

const CostsTab = (props) => {
  const { userInfo } = useContext(userInformations);
  const [visibleModal, setVisibleModal] = useState(false);
  const [costsDataList, setCostsDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, setVisibleFilters, visibleFiltersRef] = useStateRef(false);
  const [, setTableFilters, tableFiltersRef] = useStateRef(null);
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    await handleGetCostsList();
  }, []);

  useAsyncEffect(async () => {
    if (!visibleFiltersRef.current && !isMobile) {
      await handleGetCostsList(null);
    }
  }, [visibleFiltersRef.current, isMobile]);

  const handleGetCostsList = useCallback(
    async (params = null) => {
      const endpoint = props.apiEndpoint ?? null;
      setTableFilters(params);
      try {
        setLoading(true);
        const res = await getShippingTaskCostsList(
          props.taskData?.id,
          endpoint,
          params,
          userInfo,
        );
        setCostsDataList(res ?? []);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [setCostsDataList, setLoading, setTableFilters],
  );

  const handleAddCost = useCallback(() => {
    setVisibleModal(true);
  }, [setVisibleModal]);

  const handleCloseModal = useCallback(async () => {
    setVisibleModal(false);
    await handleGetCostsList(tableFiltersRef.current);
  }, [setVisibleModal, tableFiltersRef.current]);

  return (
    <>
      <div className={'top-table-actions'}>
        <Space>
          <Tooltip placement={'topLeft'} title={'Добавить'}>
            <Button
              type="primary"
              size={'small'}
              icon={<PlusOutlined />}
              onClick={() => handleAddCost()}
            />
          </Tooltip>
          <Tooltip placement={'topLeft'} title={'Отобразить фильтры'}>
            <Button
              size={'small'}
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => {
                setVisibleFilters(!visibleFiltersRef.current);
              }}
            />
          </Tooltip>
        </Space>
        <div style={{ textAlign: 'right', marginLeft: 'auto' }}>
          <h4>Итоговая сумма</h4>
          <b>{priceFormatter(props.totalSum ?? 0)}</b>
        </div>
      </div>
      <ErrorBoundary>
        <CostsTable
          costsDataList={costsDataList}
          apiEndpoint={props.apiEndpoint}
          taskData={props.taskData}
          loading={loading}
          visibleFilters={visibleFiltersRef.current}
          tableFilters={tableFiltersRef.current}
          setVisibleFilters={(val) => {
            setVisibleFilters(val);
          }}
          handleUpdateList={async (tableFilters) =>
            await handleGetCostsList(tableFilters)
          }
          refreshTaskInfo={() => {
            props.refreshTaskInfo();
          }}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <CostModal
          visible={visibleModal}
          taskData={props.taskData}
          apiEndpoint={props.apiEndpoint}
          closeModal={async () => await handleCloseModal()}
          refreshTaskInfo={() => {
            props.refreshTaskInfo();
          }}
        />
      </ErrorBoundary>
    </>
  );
};

export default CostsTab;

/**
 * props apiEndpoint
 * Обязательный props apiEndpoint. В него передаем либо endpoint для Предварительных расходов либо для фактических расходов
 */
CostsTab.propTypes = {
  taskData: PropTypes.object.isRequired,
  apiEndpoint: PropTypes.string.isRequired,
  totalSum: PropTypes.number,
  refreshTaskInfo: PropTypes.func.isRequired,
};
