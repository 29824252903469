import { ErrorBoundary } from '../ErrorBoundary';
import NotificationWebSocket from './NotificationWebSocket';
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import NotificationDrawer from './NotificationDrawer';
import { getChatNotificationById } from '../../logic/notifications/ChatNotificationApiFunction';
import { userInformations } from '../../contex';
import { notification } from 'antd';

const GlobalNotification = (props) => {
  const { userInfo } = useContext(userInformations);
  const copyVisibleDrawerChatNotifications = useRef(null);
  const copyVisibleDrawerNotifications = useRef(null);
  const copyNotificationList = useRef([]);

  useEffect(() => {
    copyVisibleDrawerChatNotifications.current =
      props.visibleDrawerChatNotifications;
  }, [props.visibleDrawerChatNotifications]);

  useEffect(() => {
    copyVisibleDrawerNotifications.current = props.visibleDrawerNotifications;
  }, [props.visibleDrawerNotifications]);

  useEffect(() => {
    copyNotificationList.current = props.chatNotificationList;
  }, [props.chatNotificationList]);

  async function handleAddNewNotifications(notificationData, notificationType) {
    if (
      copyVisibleDrawerNotifications.current &&
      notificationType === 'notifications.new'
    ) {
      props.pushNewNotifications(notificationData);
      return;
    }

    if (
      copyVisibleDrawerChatNotifications.current &&
      notificationType === 'chat_message.new'
    ) {
      console.log('pushAddNewChatNotification');
      await pushAddNewChatNotification(notificationData);
      return;
    }

    if (notificationType === 'notifications.new') {
      props.callGetNotificationsList(0, false, true);
    } else if (notificationType === 'chat_message.new') {
      props.callGetChatNotificationsList(0, true);
    }
  }

  const pushAddNewChatNotification = async (notificationData) => {
    if (!notificationData) return;
    let chatElement = {};
    const copyChatNotificationList = [...copyNotificationList.current];

    const chatIndexFromArr = copyChatNotificationList?.findIndex(
      (item) => item?.id === Number(notificationData?.chat),
    );

    if (chatIndexFromArr >= 0) {
      console.log(notificationData);
      chatElement = { ...copyChatNotificationList[chatIndexFromArr] };
      chatElement['unread_count'] += 1;
      chatElement['last_message'] = {
        text: notificationData?.originalText
          ? notificationData?.originalText
          : notificationData?.text,
        created: notificationData?.created,
        creator: notificationData?.creator?.full_name,
      };
    } else {
      if (!Number(notificationData?.chat)) return;
      const res = await getChatNotificationById(
        Number(notificationData?.chat),
        userInfo,
      );
      chatElement = res?.data[0] ? res.data[0] : {};
    }

    if (chatElement) {
      props.setChatNotification(
        [chatElement].concat(
          copyChatNotificationList.filter(
            (item) => item?.id !== chatElement?.id,
          ),
        ),
      );
    }
  };

  return (
    <>
      <ErrorBoundary>
        <NotificationDrawer
          visible={props.visibleDrawerNotifications}
          notificationList={props.notificationList}
          removeAllNotificationsFromList={() => {
            props.removeAllNotificationsFromList();
            notification.destroy();
          }}
          loadMoreNotification={() => props.loadMoreNotifications()}
          handleGetNotificationList={async () => {
            props.callGetNotificationsList(0, false, true);
          }}
          removeNotificationFormList={(notificationId) => {
            props.removeNotificationFormList(notificationId);
          }}
          onClose={() => {
            props.handleCloseNotificationDrawer();
          }}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <NotificationDrawer
          useForChatNotification={true}
          visible={props.visibleDrawerChatNotifications}
          notificationList={props.chatNotificationList}
          removeAllNotificationsFromList={() => {
            props.removeAllNotificationsFromList(true);
            notification.destroy();
          }}
          loadMoreNotification={() => props.loadMoreChatNotification()}
          onClose={() => {
            props.handleCloseChatNotificationDrawer();
          }}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <NotificationWebSocket
          useSound={props.toggleSound}
          useChatNotifications={props.useChatNotifications}
          visibleDrawerChatNotifications={props.visibleDrawerChatNotifications}
          handleGetNotificationList={async (
            notificationData,
            notificationType,
          ) => {
            await handleAddNewNotifications(notificationData, notificationType);
          }}
        />
      </ErrorBoundary>
    </>
  );
};

GlobalNotification.propTypes = {
  toggleSound: PropTypes.bool,
  useChatNotifications: PropTypes.bool,
  visibleDrawerNotifications: PropTypes.bool,
  visibleDrawerChatNotifications: PropTypes.bool,
  notificationList: PropTypes.arrayOf(PropTypes.object),
  chatNotificationList: PropTypes.arrayOf(PropTypes.object),
  loadMoreNotifications: PropTypes.func,
  loadMoreChatNotification: PropTypes.func,
  pushNewNotifications: PropTypes.func,
  setChatNotification: PropTypes.func,
  callGetNotificationsList: PropTypes.func,
  callGetChatNotificationsList: PropTypes.func,
  removeNotificationFormList: PropTypes.func,
  removeAllNotificationsFromList: PropTypes.func,
  handleCloseNotificationDrawer: PropTypes.func,
  handleCloseChatNotificationDrawer: PropTypes.func,
};

export default GlobalNotification;
