import PropTypes from 'prop-types';
import { Card, Col, Divider, Dropdown, Row, Space } from 'antd';
import React, { useCallback } from 'react';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';

const AttachmentListItem = (props) => {
  const cardTitle = useCallback(() => {
    return (
      <>
        <Row justify={'space-between'} wrap={false}>
          <Col>
            <Col className={'mainText'}>
              {props.attachment?.file_info?.name || '-'}
            </Col>
            <Col className={'secondText'}>
              {props.getStatusContractFromServer(
                props.attachment?.contract?.status,
              )}
            </Col>
          </Col>
          <Row justify={'end'}>
            <Space size="middle" align={'start'}>
              <Dropdown
                overlay={props.menuActions(props.attachment)}
                trigger={['click']}
              >
                <EllipsisOutlined
                  style={{ cursor: 'pointer', fontSize: '24px' }}
                />
              </Dropdown>
            </Space>
          </Row>
        </Row>
      </>
    );
  }, [props.attachment]);

  return (
    <>
      <Card
        className={`mainCard`}
        bodyStyle={{ padding: '12px', borderRadius: '10px' }}
      >
        {cardTitle()}
        <Divider style={{ margin: '10px 0' }} />

        <Row gutter={[0, 15]}>
          <Col xs={24}>
            <div className={'secondText'}>{'Тип вложения'}</div>
            <div className={'valueText'}>
              {props.prepareAttachmentTypeForColumn(
                props.attachment?.type_attachment,
              ) || '-'}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

AttachmentListItem.propTypes = {
  attachment: PropTypes.object,
  prepareAttachmentTypeForColumn: PropTypes.func,
  getStatusContractFromServer: PropTypes.func,
  menuActions: PropTypes.func,
};

export default AttachmentListItem;
