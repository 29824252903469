export const currencyFormatter = (currency) => {
  switch (currency) {
    case 'rub':
      return 'руб';
    case 'dol':
      return '$';
    case 'eur':
      return '€';
    case 'cny':
      return '¥';
  }
};
