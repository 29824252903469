import { Button, Dropdown, Input, InputNumber, Menu, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const RangeNumberFilters = ({
  selectedKeys,
  tableFilters,
  columnKey,
  confirm,
}) => {
  const [from, setFrom] = useState(
    selectedKeys && selectedKeys[0] ? selectedKeys[0] : null,
  );

  const [to, setTo] = useState(
    selectedKeys && selectedKeys[1] ? selectedKeys[1] : null,
  );

  const [visibleMenuItems, setVisibleMenuItems] = useState(false);

  const refBtnOpen = useRef(null);

  const refMenu = useRef(null);

  const [valueForInput, setValueForInput] = React.useState('');

  const [valueIsChanged, setValueIsChanged] = useState(false);

  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (isMobile) {
      setFrom(selectedKeys && selectedKeys[0] ? selectedKeys[0] : null);

      setTo(selectedKeys && selectedKeys[1] ? selectedKeys[1] : null);
    }
  }, [selectedKeys]);
  useEffect(() => {
    const preperePiriodString = prepaereValueForInput(to, from);
    setValueForInput(preperePiriodString);
  }, [to, from]);

  useEffect(() => {
    if (!visibleMenuItems && valueIsChanged) {
      let filters = tableFilters;
      filters[columnKey] = [to, from];
      confirm(filters);
      setValueIsChanged(false);
    }
  }, [visibleMenuItems, valueIsChanged]);

  const onClick = (e) => {
    if (
      (refMenu.current && refMenu.current.contains(e.target)) ||
      (refBtnOpen.current && refBtnOpen.current.contains(e.target))
    ) {
      setVisibleMenuItems(true);
    } else {
      setVisibleMenuItems(false);
    }
  };
  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      setVisibleMenuItems(false);
    } else if (
      (refMenu.current && refMenu.current.contains(e.target)) ||
      (refBtnOpen.current && refBtnOpen.current.contains(e.target))
    ) {
      setVisibleMenuItems(true);
    }
  };
  useEffect(() => {
    window.addEventListener('click', onClick);
    return () => window.removeEventListener('click', onClick);
  }, [visibleMenuItems]);

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    return () => window.removeEventListener('keyup', onKeyUp);
  }, [visibleMenuItems]);

  const prepaereValueForInput = (to, from) => {
    if (to || from) {
      return `${from ? 'От' + ' ' + from : ''} ${to ? 'До' + ' ' + to : ''}`;
    }
    return '';
  };
  const clear = () => {
    setValueForInput('');
    setValueIsChanged(false);
    setFrom(null);
    setTo(null);
    let filters = tableFilters;
    filters[columnKey] = null;
    confirm(filters);
    setValueIsChanged(false);
  };

  const menu = (
    <div ref={refMenu}>
      <Menu placement={'bottomRight'} style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            padding: '8px',
          }}
        >
          <InputNumber
            className="min-input-main"
            placeholder="От"
            value={from}
            autoFocus={false}
            onChange={(e) => {
              setFrom(e);
              setValueIsChanged(true);
            }}
            min={0}
          />
          <InputNumber
            placeholder="До"
            value={to}
            autoFocus={false}
            onChange={(e) => {
              setTo(e);
              setValueIsChanged(true);
            }}
            min={0}
            style={{ marginTop: '8px' }}
          />
        </div>
      </Menu>
    </div>
  );

  return (
    <div style={{ padding: 8, width: '100%' }}>
      <>
        <div style={{ display: 'flex' }}>
          <Tooltip
            trigger={['focus']}
            title={valueForInput}
            placement="topLeft"
            overlayClassName="numeric-input"
          >
            <Input
              onInput={() => {
                setVisibleMenuItems(true);
              }}
              onChange={(e) => {
                if (e.target.value === '') {
                  clear();
                }
              }}
              autoFocus={false}
              value={valueForInput}
              allowClear={true}
            />
          </Tooltip>
          <Dropdown
            overlay={menu}
            placement={'bottomRight'}
            getPopupContainer={(trigger) =>
              isMobile ? trigger.parentNode : document.body
            }
            visible={visibleMenuItems}
            autoFocus={false}
          >
            <Button
              style={{ height: isMobile ? '35px' : '31.6px' }}
              autoFocus={false}
              ref={refBtnOpen}
              size={'middle'}
              onClick={() => {
                setVisibleMenuItems(!visibleMenuItems);
              }}
              icon={<DownOutlined />}
            />
          </Dropdown>
        </div>
      </>
    </div>
  );
};

export default RangeNumberFilters;
