import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import DepartmentsListToolbar from '../components/departments/DepartmentsListToolbar';
import { restApiHost } from '../utils/appVariables';
import DepartamentsTable from '../components/departments/DepartamentsTable';
import { userInformations } from '../contex';
import { call } from '../apiUtils/call';

const Departments = () => {
  const [departments, setDepartments] = useState({ data: [], count: 0 });
  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const { userInfo } = useContext(userInformations);

  useEffect(() => {
    const getDepartments = async () => {
      setLoadingDepartments(true);
      try {
        const url = `${restApiHost}/accounts/departments/`;
        const json = await call(
          url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
          },
          userInfo.current.haveViewBranch,
        );
        if (json) {
          setDepartments({ data: json?.data, count: json?.count });
        }
      } catch (e) {
        console.log('fetch profile info error', e);
        setLoadingDepartments(false);
      } finally {
        setLoadingDepartments(false);
      }
    };

    getDepartments();
  }, []);

  const handleUpdate = () => {
    setLoadingDepartments(true);
    setTimeout(() => {
      setLoadingDepartments(false);
    }, 1000);
  };

  return (
    <>
      <Helmet>
        <title>Отделы | Квик Транс</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <DepartmentsListToolbar handleUpdate={handleUpdate} />
          <Box sx={{ pt: 3 }}>
            {/*<DepartmentsListResults departments={departments} />*/}
            <DepartamentsTable
              data={departments?.data}
              loading={loadingDepartments}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Departments;
