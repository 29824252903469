export function getDisplayedSteps(steps) {
  if (!steps) return;
  return steps.filter((step) => step?.visible === true);
}

export function customerIsStock(customer) {
  if (!customer?.category) return false;
  const category = customer?.category.map((item) => {
    return item?.category;
  });
  return category?.length === 1 && category?.includes('skl');
}

export function objToFormDataContact(obj) {
  const copyObj = { ...obj };
  const formData = new FormData();

  if (copyObj?.facsimile?.originFileObj) {
    formData.append('facsimile', copyObj.facsimile.originFileObj);
    delete copyObj?.facsimile;
  }
  if (copyObj?.stamp?.originFileObj) {
    formData.append('stamp', copyObj.stamp.originFileObj);
    delete copyObj?.stamp;
  }

  for (let key in copyObj) {
    if (copyObj[key] !== undefined) {
      if (copyObj[key] === null) {
        formData.append(key, '');
      } else {
        formData.append(key, copyObj[key]);
      }
    }
  }

  return formData;
}
