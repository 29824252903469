import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import TextArea from 'antd/es/input/TextArea';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const ContractModalMinimalInfoTheAbilityToAddAttachments = (props) => {
  const [draggingFile, setDraggingFile] = useState(false);
  const mainModalRef = useRef(null);
  const contractStatusesWhichCannotDragFiles = ['con', 'sig', 'arc'];
  const isMobile = useCheckMobileScreen();

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDraggingFile(true);
    }
  };
  const handleDragOut = () => {
    setDraggingFile(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingFile(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = Object.entries(e.dataTransfer?.files).map((fileArr) => {
        return { originFileObj: fileArr[1], name: fileArr[1]?.name };
      });

      props.propsDragger.onChange({ file: null, fileList: files });
      e.dataTransfer.clearData();
    }
  };

  useEffect(() => {
    if (!contractStatusesWhichCannotDragFiles.includes(props.contractStatus)) {
      const element = mainModalRef.current;
      if (element) {
        element.addEventListener('dragenter', handleDragIn);
        element.addEventListener('dragleave', handleDragOut);
        element.addEventListener('dragover', handleDrag);
        element.addEventListener('drop', handleDrop);
        return () => {
          element.removeEventListener('dragenter', handleDragIn);
          element.removeEventListener('dragleave', handleDragOut);
          element.removeEventListener('dragover', handleDrag);
          element.removeEventListener('drop', handleDrop);
        };
      }
    }
  }, [props.visible, props.contractStatus]);

  return (
    <Modal
      visible={props.visible}
      width={700}
      style={{ zIndex: 101 }}
      title={'Редактирование договора/добавление вложений'}
      //bodyStyle={draggingFile ? {padding: 10} : {}}
      onCancel={props.onCancel}
      footer={[
        <Button key="back" onClick={props.onCancel}>
          Закрыть
        </Button>,
        <Button key="submit" type="primary" onClick={props.saveContract}>
          Сохранить
        </Button>,
      ]}
    >
      <div ref={mainModalRef} style={{ width: '100%', height: '100%' }}>
        {draggingFile &&
        !contractStatusesWhichCannotDragFiles.includes(props.contractStatus) ? (
          <div
            className={'ant-upload'}
            style={{
              width: '100%',
              height: '172px',
              textAlign: 'center',
              border: '3px dashed #d9d9d9',
              borderRadius: '2px',
              backgroundColor: '#fafafa',
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              pointerEvents: 'none',
            }}
          >
            <div>Перенесите файл вложения</div>
          </div>
        ) : (
          <Form form={props.form} layout="vertical">
            <Row gutter={5}>
              <Col lg={8} xs={24}>
                <Form.Item label="Наша компания" name={'customer'}>
                  <Input disabled placeholder="Наша компания" />
                </Form.Item>
              </Col>
              <Col lg={8} xs={24}>
                <Form.Item label="Клиент" name={'our_customer'}>
                  <Input disabled placeholder="Клиент" />
                </Form.Item>
              </Col>
              <Col lg={8} xs={24}>
                <Form.Item label="Номер договора" name={'code_contract'}>
                  <Input disabled={true} placeholder="Номер договора" />
                </Form.Item>
              </Col>
              <Col
                lg={8}
                xs={24}
                style={props.uploadedDoc ? { display: 'none' } : {}}
              >
                <Form.Item label={'Состояние'} name={'status'}>
                  <Select
                    getPopupContainer={(trigger) =>
                      isMobile ? trigger.parentNode : document.body
                    }
                    disabled={!props.admin}
                    value={props.contractStatus}
                    onChange={props.onChange}
                  >
                    {props.statusOptions.map(props.callbackfn)}
                  </Select>
                </Form.Item>
              </Col>
              {!contractStatusesWhichCannotDragFiles.includes(
                props.contractStatus,
              ) ? (
                <Col lg={8} xs={24} style={{ width: '213px' }}>
                  <Form.Item label={'Добавить вложение'}>
                    <Dragger
                      className={'small-dragger'}
                      {...props.propsDragger}
                    >
                      Кликните или перенесите файл
                    </Dragger>
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
              {props.contractStatus === 'arc' ? (
                <Col lg={8} xs={24}>
                  <Form.Item
                    label={'Комментарий'}
                    name={'comment'}
                    rules={[{ required: true, message: 'Обязательно поле!' }]}
                  >
                    <TextArea
                      style={{ height: '32px' }}
                      placeholder={'Комментарий'}
                    />
                  </Form.Item>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </Form>
        )}
      </div>
    </Modal>
  );
};
ContractModalMinimalInfoTheAbilityToAddAttachments.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  saveContract: PropTypes.func,
  form: PropTypes.any,
  uploadedDoc: PropTypes.any,
  admin: PropTypes.bool,
  contractStatus: PropTypes.any,
  onChange: PropTypes.func,
  statusOptions: PropTypes.any,
  callbackfn: PropTypes.func,
  propsDragger: PropTypes.shape({
    onChange: PropTypes.func,
    multiple: PropTypes.bool,
    maxCount: PropTypes.number,
    fileList: PropTypes.arrayOf(PropTypes.any),
    beforeUpload: PropTypes.func,
  }),
  docCommentChoices: PropTypes.arrayOf(PropTypes.any),
  callbackfn1: PropTypes.func,
};

export default ContractModalMinimalInfoTheAbilityToAddAttachments;
