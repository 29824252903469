import React from 'react';
import { Collapse, Menu, Empty, Table, Dropdown, Space, Tooltip } from 'antd';
import {
  EllipsisOutlined,
  SwapOutlined,
  PlusOutlined,
} from '@ant-design/icons';

const { Panel } = Collapse;

const DosylList = ({
  stocks,
  deleteContainer,
  addStockContainer,
  addStock,
  anotherStocks,
}) => {
  /* const dosylList = [
    {
      id: 1,
      title: 'Ижевск',
      data: [
        {
          id: 1,
          status: 'Тест',
          number_container: 'AAABBB11',
          type_container: '20`',
          tare_weight: '20 тонн',
          owner: 'Квиктранс',
          departure_terminal: 'Терминал 1',
          destination_station: 'Ижевск',
          date_ub: '25-02-2022',
          delivery_date: '12-03-2022',
        },
        {
          id: 2,
          status: 'Тест',
          number_container: 'AAABBB11',
          type_container: '20`',
          tare_weight: '20 тонн',
          owner: 'Квиктранс',
          departure_terminal: 'Терминал 1',
          destination_station: 'Ижевск',
          date_ub: '25-02-2022',
          delivery_date: '12-03-2022',
        },
      ],
    },
    {
      id: 2,
      title: 'Казань',
      data: [
        {
          id: 1,
          status: 'Тест',
          number_container: 'AAABBB11',
          type_container: '20`',
          tare_weight: '20 тонн',
          owner: 'Квиктранс',
          departure_terminal: 'Терминал 1',
          destination_station: 'Ижевск',
          date_ub: '25-02-2022',
          delivery_date: '12-03-2022',
        },
      ],
    },
  ];*/

  const onChange = (key) => {
    console.log(key);
  };
  const transfer = (container) => {
    const isCreatedStock = anotherStocks.findIndex(
      (item) => item.title === container.stock,
    );

    if (anotherStocks.length == 0 || isCreatedStock < 0) {
      addStock({
        id: Date.now() + 1,
        title: container.stock,
        data: [{ ...container }],
      });
    } else {
      addStockContainer(container.stock, container);
    }

    deleteContainer(container.stock, container.id);
  };

  const menu = (container) => {
    return (
      <Menu style={{ width: 200 }}>
        <Menu.Item
          icon={<SwapOutlined />}
          onClick={() => {
            transfer(container);
          }}
        >
          В подход
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (container) => (
        <Space size="middle">
          <Dropdown overlay={menu(container)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <b>{status}</b>,
      width: 150,
    },
    {
      title: 'Номер контейнера',
      dataIndex: 'number_container',
      key: 'number_container',
      width: 200,
    },
    {
      title: 'Тип контейнера',
      dataIndex: 'type_container',
      key: 'type_container',
      width: 200,
    },
    {
      title: 'Масса тары',
      dataIndex: 'tare_weight',
      key: 'tare_weight',
      width: 200,
    },
    {
      title: 'Собственник',
      dataIndex: 'owner',
      key: 'owner',
      width: 200,
    },
    {
      title: 'Терминал отправления',
      dataIndex: 'departure_terminal',
      key: 'departure_terminal',
      width: 200,
    },
    {
      title: 'Станция назначения',
      dataIndex: 'destination_station',
      key: 'destination_station',
      width: 200,
    },
    {
      title: 'Дата убытия',
      dataIndex: 'date_ub',
      key: 'date_ub',
      width: 200,
    },
    {
      title: 'Дата сдачи в сток',
      dataIndex: 'delivery_date',
      key: 'delivery_date',
      width: 200,
    },
  ];

  return (
    <>
      {stocks.length > 0 ? (
        <Collapse defaultActiveKey={['1']} onChange={onChange}>
          {stocks.map((item) => {
            return (
              <Panel
                header={`${item.title} (${item?.data?.length || 0})`}
                key={item.id.toString()}
                extra={
                  <Tooltip
                    title="Добавить контейнер(в разработке)"
                    placement="topLeft"
                  >
                    <PlusOutlined
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  </Tooltip>
                }
              >
                <Table
                  dataSource={item.data}
                  columns={columns}
                  pagination={false}
                  scroll={{ x: 1550, y: 500 }}
                />
              </Panel>
            );
          })}
        </Collapse>
      ) : (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={<span>Список пуст</span>}
        ></Empty>
      )}
    </>
  );
};

export default DosylList;
