import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import AbonentsTable from '../components/telephony/AbonentsTable';
import TelephonyToolBar from '../components/telephony/TelephonyToolBar';
import { Button, message, Select, Tabs } from 'antd';
import { restApiHost } from '../utils/appVariables';
import HistoryCallTable from '../components/telephony/HistoryCallTable';
import ActiveCallsTable from '../components/telephony/ActiveCallsTable';
import { convertObjFilterToUrl } from '../utils/getFilters';
import * as _ from 'lodash';
import { userInformations } from '../contex';
import { call } from '../apiUtils/call';
import { useTypedSelector } from '../store/hooks/useTypedSelector';
import formationDocumentTitle from '../utils/formationDocumentTitle';

const { TabPane } = Tabs;
const { Option, OptGroup } = Select;

const Telephony = () => {
  const [abonents, setAbonents] = useState([]);
  const [staff, setStaff] = useState([]);

  const [historyCall, setHistoryCall] = useState([]);
  const [historyPage, setHistoryPage] = useState(1);

  const [activeCalls, setActiveCalls] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [loadingActiveCalls, setLoadingActiveCalls] = useState(false);
  const [loadingAbonents, setLoadingAbonents] = useState(false);
  const [loadingStaffList, setLoadingStaffList] = useState(false);
  const [currUser, setCurrUser] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState(null);
  const { userInfo } = useContext(userInformations);
  const notificationCount = useTypedSelector(
    (state) => state.notificationCount.count,
  );

  useEffect(() => {
    getAbonents();
    getStaff();
    getAllCalls();
  }, []);

  //   useEffect(() => {
  //     if (currUser && currUser.id) {
  //       getHistoryCall(currUser.id);
  //     }
  //   }, [currUser]);

  // const filterCalls = (id, direction, status, called_number)

  const getAllCalls = async (page = 1, filters, searchQuery) => {
    let urlParams;

    setLoadingHistory(true);

    if (filters) {
      urlParams = convertObjFilterToUrl(filters, true);
    }
    try {
      const url =
        urlParams && searchQuery
          ? `${restApiHost}/telephony/all_calls/?page=${page}&${urlParams}&search=${searchQuery}`
          : urlParams && !searchQuery
          ? `${restApiHost}/telephony/all_calls/?page=${page}&${urlParams}`
          : !urlParams && searchQuery
          ? `${restApiHost}/telephony/all_calls/?page=${page}&search=${searchQuery}`
          : `${restApiHost}/telephony/all_calls/?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json) {
        setHistoryCall(json);
      }
    } catch (e) {
      setLoadingHistory(false);
      setHistoryCall([]);
      console.log('get all calls err', e);
      message.error('Ошибка запроса на получение всех звонков!');
    } finally {
      setLoadingHistory(false);
    }
  };

  const getUserCalls = async (id, page = 1, filters, searchQuery) => {
    let urlParams;

    setLoadingHistory(true);

    if (filters || userInfo.current.haveViewBranch) {
      urlParams = convertObjFilterToUrl(filters, true);
    }
    try {
      const url =
        urlParams && searchQuery
          ? `${restApiHost}/telephony/user_calls/${id}?page=${page}&${urlParams}&search=${searchQuery}`
          : !urlParams && searchQuery
          ? `${restApiHost}/telephony/user_calls/${id}?page=${page}&search=${searchQuery}`
          : urlParams && !searchQuery
          ? `${restApiHost}/telephony/user_calls/${id}?page=${page}&${urlParams}`
          : `${restApiHost}/telephony/user_calls/${id}?page=${page}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json) {
        setHistoryCall(json);
      }
    } catch (e) {
      setLoadingHistory(false);
      setHistoryCall([]);
      console.log('get all calls err', e);
      message.error('Ошибка запроса на получение всех звонков!');
    } finally {
      setLoadingHistory(false);
    }
  };

  const getActiveCalls = async (id) => {
    setLoadingActiveCalls(true);
    try {
      const url = `${restApiHost}/telephony/active_calls/${id}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setActiveCalls(json.data);
      }
    } catch (e) {
      setActiveCalls([]);
      console.log('fetch active calls err', e);
      message.error('Ошибка запроса на получение активных звонков');
    } finally {
      setLoadingActiveCalls(false);
    }
  };

  const getAbonents = async () => {
    setLoadingAbonents(true);
    try {
      const url = `${restApiHost}/telephony/users/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setAbonents(json.data);
      }
    } catch (e) {
      message.error('Ошибка запроса для получения абонентов!');
      console.log('fetch err', e);
      setLoadingAbonents(false);
    } finally {
      setLoadingAbonents(false);
    }
  };

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, []);
  };

  const searchStaff = async (keyword) => {
    setLoadingStaffList(true);
    try {
      const url = `${restApiHost}/accounts/staff_users/?search=${keyword}`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;
      const groupedUsers = groupBy(json?.data, 'staff_position');
      setStaff(Object.entries(groupedUsers));
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса! Не удалось найти лида');
      setLoadingStaffList(false);
    } finally {
      setLoadingStaffList(false);
    }
  };

  const getStaff = async () => {
    try {
      const url = `${restApiHost}/accounts/staff_users/`;
      const json = await call(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!json) return;

      const groupedUsers = groupBy(json?.data, 'staff_position');
      setStaff(Object.entries(groupedUsers));
    } catch (e) {
      console.log('fetch profile info error', e);
    }
  };

  const getPositionUser = (position) => {
    switch (position) {
      case 'dir':
        return 'Директор';
      case 'led':
        return 'Руководитель ОП';
      case 'man':
        return 'Менеджер';
      default:
        return 'Не определен';
    }
  };

  const handleSearchHistory = (value) => {
    setSearchQuery(value);
    setHistoryPage(1);
    currUser
      ? getUserCalls(currUser.id, 1, null, value)
      : getAllCalls(1, null, value);
  };

  const debouncedSearch = _.debounce(handleSearchHistory, 500);

  return (
    <>
      <Helmet>
        <title>
          {formationDocumentTitle('Телефония | КвикТранс', notificationCount)}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <TelephonyToolBar search={debouncedSearch} />
          <Box sx={{ pt: 3 }}>
            <Tabs
              defaultActiveKey="1"
              onChange={(key) => {
                if (key === '1') {
                  getAllCalls(1);
                } else if (key === '2') {
                  getAbonents();
                }
              }}
            >
              <TabPane tab="История вызовов" key="1">
                <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                  <Select
                    style={{ width: 200 }}
                    showSearch
                    value={currUser ? JSON.stringify(currUser) : null}
                    placeholder="Сотрудник"
                    optionFilterProp="children"
                    loading={loadingStaffList}
                    onChange={(value) => {
                      setCurrUser(JSON.parse(value));
                      getUserCalls(JSON.parse(value).id);
                    }}
                    onInput={(e) => {
                      if (e.target.value.length > 0) {
                        searchStaff(e.target.value);
                      } else {
                        getStaff();
                      }
                    }}
                  >
                    {staff && staff.length > 0 ? (
                      <>
                        {staff.map((item, idx) => {
                          return (
                            <OptGroup
                              key={idx}
                              label={getPositionUser(item[0])}
                            >
                              {item[1] && item[1].length > 0 ? (
                                <>
                                  {item[1].map((user) => {
                                    return (
                                      <Option
                                        key={user.id}
                                        value={JSON.stringify(user)}
                                      >
                                        {user.full_name
                                          ? user.full_name
                                          : user.email}
                                      </Option>
                                    );
                                  })}
                                </>
                              ) : (
                                <Option disabled value={'Загрузка...'}>
                                  Загрузка...
                                </Option>
                              )}
                            </OptGroup>
                          );
                        })}
                      </>
                    ) : (
                      <OptGroup label={'Поиск сотрудников'}>
                        Загрузка...
                      </OptGroup>
                    )}
                  </Select>

                  <Button
                    style={{ marginLeft: '10px' }}
                    type="primary"
                    disabled={!currUser}
                    onClick={() => {
                      setCurrUser(null);
                      getAllCalls(1);
                    }}
                  >
                    Сбросить
                  </Button>
                </div>

                <HistoryCallTable
                  page={historyPage}
                  setPage={setHistoryPage}
                  filters={filters}
                  setFilters={setFilters}
                  filterCalls={(page, filters) => {
                    currUser
                      ? getUserCalls(currUser.id, page, filters, searchQuery)
                      : getAllCalls(page, filters, searchQuery);
                  }}
                  totalCalls={historyCall?.total}
                  historyCalls={historyCall?.calls}
                  loading={loadingHistory}
                  currUser={currUser}
                />
              </TabPane>
              <TabPane tab="Абоненты" key="2">
                <AbonentsTable
                  abonents={abonents}
                  loading={loadingAbonents}
                  loadingAbonents={loadingAbonents}
                />
              </TabPane>
              <TabPane tab="Список активных вызовов" key="3">
                <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                  <Select
                    style={{ width: 200 }}
                    showSearch
                    placeholder="Сотрудник"
                    optionFilterProp="children"
                    loading={loadingStaffList}
                    onChange={(value) => {
                      setCurrUser(JSON.parse(value));
                      getActiveCalls(JSON.parse(value).id);
                    }}
                    onInput={(e) => {
                      if (e.target.value.length > 0) {
                        searchStaff(e.target.value);
                      } else {
                        getStaff();
                      }
                    }}
                  >
                    {staff && staff.length > 0 ? (
                      <>
                        {staff.map((item, idx) => {
                          return (
                            <OptGroup
                              key={idx}
                              label={getPositionUser(item[0])}
                            >
                              {item[1] && item[1].length > 0 ? (
                                <>
                                  {item[1].map((user) => {
                                    return (
                                      <Option
                                        key={user.id}
                                        value={JSON.stringify(user)}
                                      >
                                        {user.full_name
                                          ? user.full_name
                                          : user.email}
                                      </Option>
                                    );
                                  })}
                                </>
                              ) : (
                                <Option disabled value={'Загрузка...'}>
                                  Загрузка...
                                </Option>
                              )}
                            </OptGroup>
                          );
                        })}
                      </>
                    ) : (
                      <OptGroup label={'Поиск сотрудников'}>
                        Загрузка...
                      </OptGroup>
                    )}
                  </Select>
                </div>
                <ActiveCallsTable
                  activeCalls={activeCalls}
                  loading={loadingActiveCalls}
                />
              </TabPane>
            </Tabs>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Telephony;
