import {
  Button,
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ErrorBoundary } from '../../../ErrorBoundary';
import ContactPersonModal from './ContactPersonModal';
import { Box } from '@material-ui/core';
import { restApiHost } from '../../../../utils/appVariables';
import { call } from '../../../../apiUtils/call';
import { userInformations } from '../../../../contex';
import { getContactsCustomer } from '../../../../logic/customer/CustomerApiFunctions';
import useAsyncEffect from 'use-async-effect';
import useCheckMobileScreen from '../../../../hooks/useCheckMobileScreen';
import ScrollingLoadedList from '../../../mobile/scrollingLoadedList/ScrollingLoadedList';
import LeadContactListItem from '../../../mobile/leads/leadModal/LeadContactListItem';

const ContactPersonTable = (props) => {
  const { userInfo } = useContext(userInformations);
  const [currentContract, setCurrentContract] = useState(null);
  const [visibleContactModal, setVisibleContactModal] = useState(false);
  const [contactPersons, setContactPersons] = useState([]);
  const isMobile = useCheckMobileScreen();

  useAsyncEffect(async () => {
    props.customerId && (await handleGetContactPersons(props.customerId));
  }, [props.customerId]);

  const handleGetContactPersons = useCallback(
    async (customerId) => {
      const res = await getContactsCustomer(customerId, userInfo);
      if (res?.data) {
        setContactPersons(res.data);
      }
    },
    [setContactPersons, getContactsCustomer],
  );

  function menu(contact) {
    return (
      <Menu style={{ width: 190 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={async () => {
            openContactModal(contact);
          }}
        >
          Редактировать
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={() => deleteContactPerson(contact.id)}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  }

  const columnsTable = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (contact) => (
        <Space size="middle">
          <Dropdown overlay={menu(contact)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'ФИО',
      key: 'full_name',
      dataIndex: 'full_name',
      ellipsis: {
        showTitle: false,
      },
      render: (full_name) => {
        return <Tooltip title={full_name}>{full_name}</Tooltip>;
      },
    },
    {
      title: 'Контактный номер',
      key: 'phone_number',
      dataIndex: 'phone_number',
      ellipsis: {
        showTitle: false,
      },
      render: (phone_number) => {
        return <Tooltip title={phone_number}>{phone_number}</Tooltip>;
      },
    },
    {
      title: 'Почта',
      key: 'email',
      dataIndex: 'email',
      ellipsis: {
        showTitle: false,
      },
      render: (email) => {
        return <Tooltip title={email}>{email}</Tooltip>;
      },
    },
    {
      title: 'Должность',
      key: 'position',
      dataIndex: 'position',
      ellipsis: {
        showTitle: false,
      },
      render: (position) => {
        return <Tooltip title={position}>{position}</Tooltip>;
      },
    },
    {
      title: 'Дата рождения',
      key: 'birthday',
      dataIndex: 'birthday',
      ellipsis: {
        showTitle: false,
      },
      render: (birthday) => {
        return <Tooltip title={birthday}>{birthday}</Tooltip>;
      },
    },
    {
      title: 'Комментарий',
      key: 'doc_comment_str',
      dataIndex: 'doc_comment_str',
      ellipsis: {
        showTitle: false,
      },
      render: (doc_comment_str) => {
        return <Tooltip title={doc_comment_str}>{doc_comment_str}</Tooltip>;
      },
    },
  ];

  const openContactModal = (contact) => {
    setCurrentContract(contact ? contact : null);
    setVisibleContactModal(true);
  };

  const closeContactModal = () => {
    setCurrentContract(null);
    setVisibleContactModal(false);
  };

  const deleteContactPerson = async (id) => {
    try {
      const url = `${restApiHost}/customers/contact_persons/${id}/
`;
      const response = await call(
        url,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
        userInfo.current.haveViewBranch,
      );

      if (!response) return;
      message.success('Успешно удалено!');
      props.customerId && (await handleGetContactPersons(props.customerId));
    } catch (e) {
      console.log('delete contact person err', e);
      message.error('Ошибка запроса на удаление контактного лица');
    }
  };

  return (
    <>
      <Box>
        <Button
          style={{ marginBottom: '10px', width: '150px' }}
          onClick={() => {
            openContactModal();
          }}
        >
          Добавить контакт
        </Button>
        {!isMobile ? (
          <Table
            rowClassName={(record, index) =>
              index % 2 === 0
                ? 'black-text table-row-color'
                : 'black-text table-row-white'
            }
            onRow={(contact) => {
              return {
                onDoubleClick: async () => {
                  openContactModal(contact);
                },
              };
            }}
            size="small"
            bordered={true}
            style={{ margin: '10px 0' }}
            dataSource={contactPersons}
            columns={columnsTable}
            pagination={{ size: 'middle', showSizeChanger: false }}
          />
        ) : (
          <div style={{ paddingTop: '5px' }}>
            <ErrorBoundary>
              <ScrollingLoadedList
                dataList={contactPersons}
                totalData={contactPersons?.length}
                loadMoreItems={async () => {
                  //handleUpdate(page + 1, filters, false,true)
                }}
                renderListItem={(item) => (
                  <LeadContactListItem
                    leadContact={item}
                    menuActions={(leadContact) => menu(leadContact)}
                  />
                )}
              />
            </ErrorBoundary>
          </div>
        )}
        <ErrorBoundary>
          <ContactPersonModal
            visible={visibleContactModal}
            editingItem={currentContract ? currentContract : null}
            closeModal={() => closeContactModal()}
            customerId={props.customerId}
            clientHasCategoryOur={props.clientHasCategoryOur}
            docBasesList={props.docBasesList}
            contactCommentList={props.contactCommentList}
            clientHasCategorySkl={props.clientHasCategorySkl}
            handleUpdateCustomerData={async (customerId) =>
              await handleGetContactPersons(customerId)
            }
          />
        </ErrorBoundary>
      </Box>
    </>
  );
};

ContactPersonTable.propTypes = {
  contactPersonsList: PropTypes.any,
  clientHasCategoryOur: PropTypes.bool,
  clientHasCategorySkl: PropTypes.bool,
  customerId: PropTypes.any,
  docBasesList: PropTypes.any,
  contactCommentList: PropTypes.any,
};

export default ContactPersonTable;
