import { TodoActionUserInfo } from '../todo/userInfo/userInfoTodo';

const initialState = {
  userInfo: null,
};

export const userInfoReducer = (
  state = initialState,
  action: TodoActionUserInfo,
) => {
  switch (action.type) {
    case 'SET_USER_INFO':
      return { userInfo: action.payload };
    default:
      return state;
  }
};
